import { Grid, Stack } from "@mui/material";
import { USER_SERVICE_KEY } from "helper/constant";
import { ReactComponent as LogoDineIn } from "assets/icons/Dine In.svg";
import { ReactComponent as LogoTakeAway } from "assets/icons/Take Away.svg";
import { ReactComponent as LogoDelivery } from "assets/icons/Delivery.svg";
import { ReactComponent as LogoPickup } from "assets/icons/Pickup Point.svg";
import { ReactComponent as LogoInStore } from "assets/icons/In Store Pickup.svg";
import { NormalText } from "components/styled/text.styled";

interface ServiceItemProps {
  name: string;
  name_key: string;
  color?: string;
  goToHome?: any;
}

const ServiceItem = ({ name, name_key, color, goToHome }: ServiceItemProps) => {
  return (
    <Grid item xs>
      <Stack
        height="170px"
        width="100%"
        justifyContent={"center"}
        alignItems={"stretch"}
        direction={"row"}
        sx={{ cursor: "pointer" }}
      >
        <div
          className="card-menu text-center px-2 pt-4 pb-3"
          onClick={() => {
            localStorage.setItem(USER_SERVICE_KEY, name_key);
            //handleMoveToHome();
            goToHome();
          }}
          style={{ width: "148px", height: "170px" }}
        >
          <div className="text-center" style={{ margin: "0px 0 0 0" }}>
            {name_key === "DINE IN" ? (
              <LogoDineIn
                style={{
                  height: 70,
                  width: 70,
                }}
              />
            ) : name_key === "TAKE AWAY" ? (
              <LogoTakeAway
                style={{
                  height: 70,
                  width: 70,
                }}
              />
            ) : name_key === "DELIVERY" ? (
              <LogoDelivery
                style={{
                  height: 70,
                  width: 70,
                }}
              />
            ) : name_key === "PICKUP POINT" ? (
              <LogoPickup
                style={{
                  height: 70,
                  width: 70,
                }}
              />
            ) : (
              <LogoInStore
                style={{
                  height: 70,
                  width: 70,
                }}
              />
            )}
          </div>

          <NormalText
            color={"#000000"}
            fontSize="14px"
            fontWeight="bold"
            margin="20px 0 0px 0"
            className="service-text"
            letterSpacing="0.5px"
            fontFamily="D-DIN Exp"
            lineHeight="1.5"
          >
            {name}
          </NormalText>
        </div>
      </Stack>
    </Grid>
  );
};

export default ServiceItem;
