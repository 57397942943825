import { Stack, Button } from "@mui/material";
import NoPhoto from "assets/icons/icon store.svg";

interface StoreListItemProps {
  storeName?: string;
  storeLogo?: string;
  storeLocation?: string;
  type?: "row" | "column";
  primaryColor?: string;
  onClick: any;
}

const StoreListItem = ({
  storeName,
  storeLogo,
  storeLocation,
  type,
  primaryColor,
  onClick,
}: StoreListItemProps) => {
  return type === "row" ? (
    <Stack className="mx-3 store-item-container" >
      <Stack
        direction="row"
        justifyContent={"center"}
        alignItems={"center"}
        className="store-item-logo mt-1"
        onClick={onClick}
      >
        <img
          src={storeLogo ? storeLogo : NoPhoto}
          alt="store-logo"
          className="store-item-logo__img"
        />
      </Stack>
      <p className="store-item-name mb-0 mt-3">
        {/* {storeName} */}
        {storeName && storeName.length > 20
          ? storeName?.substring(0, 20) + "..."
          : storeName}
      </p>
      <p className="store-item-location mb-0">{storeLocation}</p>
    </Stack>
  ) : (
    <Stack
      className="store-item-container-v2"
      direction={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      height={"150px"}
      onClick={onClick}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="store-item-logo-v2 mt-1"
      >
        <img
          src={storeLogo ? storeLogo : NoPhoto}
          alt="store-logo"
          className="store-item-logo-v2__img"
        />
      </Stack>
      <p className="store-item-name mb-0 mt-1">
        {/* {storeName} */}
        {storeName && storeName.length > 10
          ? storeName?.substring(0, 10) + "..."
          : storeName}
      </p>
      <Button
        size="small"
        className="mt-1"
        sx={{
          textTransform: "none",
          border: `1px solid ${primaryColor}`,
          color: `${primaryColor}`,
          fontSize: "0.7rem",
        }}
      >
        Kunjungi Toko
      </Button>
    </Stack>
  );
};

export default StoreListItem;
