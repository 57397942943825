import { Box } from "@mui/material";
import "./header.css";

interface HeaderPickServicesPageProps {
  app_logo?: string;
  store_logo?: string;
  access_type: string;
}

const HeaderPickServicesPage = ({
  app_logo,
  store_logo,
  access_type,
}: HeaderPickServicesPageProps) => {
  return (
    <header className="w-100 d-flex justify-content-center align-items-center">
      <Box
        style={{
          width: "112px",
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={app_logo} alt="app-logo" className="header__app-logo" />
      </Box>
      {access_type === "store" ? (
        <>
          {store_logo ? (
            <Box
              style={{
                width: "112px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="ms-4"
            >
              <img
                src={store_logo}
                alt="store-logo"
                className="header__store-logo"
              />
            </Box>
          ) : null}
        </>
      ) : null}
    </header>
  );
};

export default HeaderPickServicesPage;
