import { PickupPointState, PickupPointResponseProps } from './types';
import { createSlice } from '@reduxjs/toolkit';
import {
  getPickupPoint,
  ConfirmPickup,
  ConfirmPup,
} from './pickuppoint.services';

export const initialState: PickupPointState = {
  data: {} as PickupPointResponseProps,
  status: 'loading',
  error: {},
  items: {} as ConfirmPup,
};

export const PickupPointSlice = createSlice({
  name: 'PickupPoint',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPickupPoint.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPickupPoint.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getPickupPoint.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      })
      .addCase(ConfirmPickup.fulfilled, (state, action) => {
        if (action.payload) {
          state.items = action.payload;
          state.status = 'success';
          state.error = "";
        }
      })
      .addCase(ConfirmPickup.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(ConfirmPickup.rejected, (state, action) => {
        state.status = 'error';
      });
  },
});

export const PickupPointReducer = PickupPointSlice.reducer;
