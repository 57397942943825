import ApiService from "services/ApiService";
import { toast } from "react-toastify";

export const getConfigSetting = async (config: string) => {
  let response = await ApiService.get(`/master/config-setting/${config}`);
  return response;
};

export const formatCurrency = (money: number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
};

export const groupBy = (objectArray: any, property: string) => {
  return objectArray.reduce((acc: any, obj: any) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    let objVal = {
      value: false,
    };
    let resTemp = {
      ...objVal,
      ...obj,
    };
    acc[key].push(resTemp);
    return acc;
  }, {});
};

export function generateString(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const notify = (
  type: "success" | "warning" | "error",
  message: string
) => {
  let result;
  switch (type) {
    case "success":
      result = toast.success(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    case "error":
      result = toast.error(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    case "warning":
      result = toast.warning(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
    default:
      result = toast(message, {
        position: "top-center",
        autoClose: 4000,
      });
      break;
  }

  return result;
};

export function getPaymentLabel(label: string) {
  switch (label) {
    case "virtual_account":
      return "Virtual Account";
    case "qr_code":
      return "QRIS";
    case "cash":
      return "Cash";
    case "credit":
      return "Credit";
    case "debit":
      return "Debit";
    default:
      return "Pilih";
  }
}

export const validateEmail = (email: string) => {
  let valid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (valid) {
    return true;
  }

  return false;
};

export const getDecryptedUser = () => {
  const localStorage =
    window.localStorage.getItem("@sandboxcustomer") ?? undefined;
  return JSON.parse(localStorage ?? "{}");
};

export const isAuthenticated = () => {
  let isAuth = false;
  const decrypt = getDecryptedUser();
  if (decrypt && decrypt.GoogleSignIn) {
    const auth = JSON.parse(decrypt.GoogleSignIn);
    if (auth && auth.data && auth.data.expires_in) {
      let exp: number = auth.data.expires_in;
      if (exp < Date.now() / 1000) {
        isAuth = false;
      } else {
        isAuth = true;
      }
    }
  }
  return isAuth;
};

export const urlExists = (url: string) => {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  http.send();
  if (http.status !== 404) {
    return true;
  } else {
    return false;
  }
};
