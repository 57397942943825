import { Box } from "@mui/material";
import ForbiddenImage from "assets/logo/no-access.png";
import { NormalText } from "components/styled/text.styled";
import useRedux from "redux/useRedux";
import { Button } from "components/styled/button.styled";

const FailedPage = ({
  onReorder,
  show,
  loading,
}: {
  show: boolean;
  loading: boolean;
  onReorder: any;
}) => {
  const {
    storeState: {
      AppSetting: {
        data: {
          data: { primary_color },
        },
      },
    },
  } = useRedux();

  return show ? (
    <Box width={"100%"} height={"100vh"}>
      <div className="container text-center" style={{ margin: "0 0 0 0" }}>
        <img
          src={ForbiddenImage}
          alt="logo"
          style={{ margin: "150px 0 0 0", userSelect: "none" }}
        />
        <NormalText
          fontSize="18px"
          margin="20px"
          fontWeight="600"
          fontFamily="D-DIN EXP"
        >
          PEMBAYARAN TIDAK BERHASIL
        </NormalText>
        <NormalText
          wordWrap="break-word"
          color="#7E7E7E"
          fontSize="12px"
          fontFamily="Open Sans"
          margin={"15px 0 0 0"}
        >
          Pembayaran tidak berhasil. Silahkan coba lagi dalam beberapa saat.
        </NormalText>

        <Button
          background={primary_color}
          onClick={onReorder}
          style={{
            color: "white",
            borderRadius: "0",
            width: "80%",
            minWidth: 200,
            margin: "20px 0 0 0",
          }}
        >
          {loading ? "Loading" : "Coba Lagi"}
        </Button>
      </div>
    </Box>
  ) : (
    <></>
  );
};

export default FailedPage;
