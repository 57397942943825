import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import { formatCurrency } from "helper/general";
import CounterButton, { CounterProps } from "components/Button/counter";
import PlusAddIcon from "assets/icons/ic_plus_addItem.svg";
import NoPhoto from "assets/images/NoPhotoList2.svg";
import { urlExists } from "helper/general";

export interface ProductTypes {
  product_id?: number;
  image?: string;
  product_name?: string;
  brand?: string;
  product_type?: string;
  product_price?: string;
  product_price_after_discount?: string;
  product_image_main?: string;
  discount_type?: string;
  discount?: number;
  is_customizable?: boolean;
}
interface ProductItemTypes {
  onClick?: any;
  product: ProductTypes;
  onAddToCart?: any;
  isOnCart?: boolean;
  counterProps?: CounterProps;
  style?: React.CSSProperties;
}

const ProductItem = ({
  onClick,
  product,
  onAddToCart,
  isOnCart,
  counterProps,
  style,
}: ProductItemTypes) => {
  return (
    <Container style={style}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            width: "75px!important",
            height: "75px!important",
          }}
        >
          <Image
            src={
              product.product_image_main &&
              product.product_image_main.length > 0
                ? urlExists(product.product_image_main)
                  ? product.product_image_main
                  : NoPhoto
                : NoPhoto
            }
            alt={"Product 1"}
            onClick={onClick}
            loading="lazy"
          />
        </Box>

        <Box width={"45%"} margin={"0 0 0 10px"}>
          <Stack direction={"column"} sx={{ wordBreak: "break-word" }}>
            <NormalText
              fontSize="14px"
              fontWeight="700"
              fontFamily="D-DIN Exp"
              lineHeight="15.2px"
              style={{ cursor: "pointer" }}
              onClick={onClick}
              margin="0 0 0 0"
            >
              {product && product.product_name
                ? product?.product_name[0]?.toLocaleUpperCase() +
                  product?.product_name?.substring(1)
                : ""}
            </NormalText>
            {Number(product.discount ?? 0) > 0 && (
              <NormalText
                fontSize="10px"
                fontFamily="Roboto"
                style={{ cursor: "pointer" }}
                onClick={onClick}
              >
                Disc{" "}
                {product.discount_type === "percent"
                  ? product.discount + "%"
                  : formatCurrency(Number(product.discount))}
                {/* {product.product_type} */}
              </NormalText>
            )}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {product &&
              product.product_price &&
              product.product_price_after_discount &&
              product.discount_type &&
              product.discount &&
              Number(product.discount) > 0 &&
              Number(product.product_price) > 0 &&
              Number(product.product_price_after_discount) > 0 ? (
                <Stack direction={"column"} alignItems={"flex-start"}>
                  <NormalText
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="D-DIN Exp"
                    lineHeight="15.2px"
                    textDecoration={"line-through"}
                  >
                    {formatCurrency(Number(product.product_price))}{" "}
                  </NormalText>
                  <NormalText
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="D-DIN Exp"
                    lineHeight="15.2px"
                  >
                    {formatCurrency(
                      Number(product.product_price_after_discount)
                    )}
                  </NormalText>
                </Stack>
              ) : null}

              {product &&
              product.product_price &&
              Number(product.product_price) > 0 &&
              Number(product.discount) === 0 ? (
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                  margin="5px 0 0 0"
                >
                  {formatCurrency(
                    Number(product.product_price_after_discount) > 0
                      ? Number(product.product_price_after_discount)
                      : Number(product.product_price)
                  )}
                </NormalText>
              ) : null}
              {/* {Number(product.discount_type ?? 0) > 0 &&
                Number(product.product_price_after_discount) > 0 ? (
                <Stack direction={"column"} alignItems={"flex-start"}>
                  <NormalText
                    fontSize="12px"
                    fontWeight="normal"
                    fontFamily="Open Sans"
                    textDecoration={"line-through"}
                  >
                    {formatCurrency(Number(product.product_price))}{" "}
                  </NormalText>
                  <NormalText
                    fontSize="12px"
                    fontWeight="700"
                    fontFamily="Open Sans"
                  >
                    {formatCurrency(
                      Number(product.product_price_after_discount)
                    )}
                  </NormalText>
                </Stack>
              ) : (
                <NormalText
                  fontSize="12px"
                  fontWeight="normal"
                  fontFamily="Open Sans"
                >
                  {formatCurrency(
                    Number(product.product_price_after_discount) > 0
                      ? Number(product.product_price_after_discount)
                      : Number(product.product_price)
                  )}
                </NormalText>
              )} */}
            </Stack>
          </Stack>
        </Box>

        <Box margin={"auto 0 auto 0"}>
          {isOnCart && counterProps ? (
            product.is_customizable ? (
              <ButtonAdd style={{ fontSize: "10px" }} onClick={onAddToCart}>
                Customize Another
              </ButtonAdd>
            ) : (
              <CounterButton
                background={counterProps?.background}
                value={
                  counterProps && counterProps.value ? counterProps.value : 0
                }
                onIncrease={counterProps?.onIncrease}
                onDecrease={counterProps?.onDecrease}
              />
            )
          ) : product.is_customizable ? (
            <Stack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <ButtonAdd onClick={onAddToCart}>
                Add <PlusIcon src={PlusAddIcon} />
              </ButtonAdd>

              <NormalText
                fontFamily="Roboto Condensed"
                fontWeight="400"
                fontSize="10px"
                lineHeight="1"
                margin="15px 0 0 0"
              >
                + Bisa Custom
              </NormalText>
            </Stack>
          ) : (
            <ButtonAdd onClick={onAddToCart}>
              Add <PlusIcon src={PlusAddIcon} />
            </ButtonAdd>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

const ButtonAdd = styled.button`
  background: #ffffff;
  width: 61.82px;
  height: 30px;
  font-family: "D-DIN Exp";
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;

  :hover,
  :focus,
  :active {
    opacity: 0.5;
  }
`;

const Container = styled.div`
  position: relative;
  background: white;
  min-height: 110px;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 20px;
`;

const Image = styled.img`
  width: 75px;
  height: auto;
  cursor: pointer;
  background: #ffffff;
`;

const PlusIcon = styled.img`
  width: 5px;
  height: 5px;
`;

export default ProductItem;
