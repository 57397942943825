import {
  Stack,
  SwipeableDrawer,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

//component
import { BankProps } from "redux/payment";
import { CheckoutPaymentDetailProps } from "redux/checkout";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { NormalText } from "components/styled/text.styled";
import BankItem from "components/Item/bank.item";

import "./drawer.css";

interface BanksDrawerProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
  banks: BankProps[];
  color?: string;
  paymentDetail: CheckoutPaymentDetailProps;
  handlePickPayment: any;
  handleChangePaymentDetail: any;
}

const BanksDrawer = ({
  open,
  onClose,
  onOpen,
  banks,
  color,
  paymentDetail,
  handlePickPayment,
  handleChangePaymentDetail,
}: BanksDrawerProps) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose();
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #cccccc", padding: "0.9rem 1rem" }}
        >
          <Typography
            sx={{
              color: "#2d3436",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "800",
              fontSize: "0.9rem",
            }}
          >
            Daftar Bank
          </Typography>

          <IconButton
            sx={{
              color: "#000000",
              height: "25px",
              width: "25px",
            }}
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Stack>
        <Box
          className={"bank-drawer mt-1"}
          maxHeight={"50vh"}
          width={"100%"}
          sx={{ overflow: "auto" }}
        >
          {banks && banks.length > 0 ? (
            banks.map((bank, index) => {
              return (
                <BankItem
                  key={index}
                  code={bank.code}
                  name={bank.name}
                  background={color ?? "#b92362"}
                  selected={bank.code === paymentDetail.bank_code}
                  onChange={() => {
                    handleChangePaymentDetail({
                      ...paymentDetail,
                      bank_code: bank.code,
                    });

                    handlePickPayment(false);
                    onClose();
                  }}
                />
              );
            })
          ) : (
            <Box padding={"10px 20px 10px 15px"} width={"100%"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                textAlign={"left"}
                fontWeight={"bold"}
              >
                Pilihan bank tidak tersedia
              </NormalText>
            </Box>
          )}
        </Box>
      </Stack>
    </SwipeableDrawer>
  );
};

export default BanksDrawer;
