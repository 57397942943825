import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Grid, Stack, Switch, Typography, Button } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import useRedux from "redux/useRedux";
import IconTimes from "assets/icons/ic_times.svg";
import styled from "styled-components";
import { CategoryLabel } from "views/Brand/detail.page";
import CartProduct from "./cart.product";
import BottomSheet, { ChekoutButton } from "components/BottomSheet";
import { changeQuantity, ChangeQuantityProps } from "redux/cart/cart.reducer";
import ConfirmationModal from "components/Modal/confirmation";
import ServiceTypeComponent from "./servicetype.component";
import DeliveryTypeComponent from "./deliverytype.component";
import { getTotalPrice } from "redux/cart/cart.reducer";
import { formatCurrency, getPaymentLabel, notify } from "helper/general";

import {
  getPaymentMethodList,
  IPaymentMethod,
  getAvailableBanks,
  BankProps,
} from "redux/payment";

import {
  checkoutOrder,
  CheckoutPaymentDetailProps,
  CheckoutProductAddonsProps,
  CheckoutProductProps,
  CheckoutProps,
  CheckoutDeliveryProps,
  getOrderInvoice,
} from "redux/checkout";
import {
  getFeeSetting,
  IFeeSetting,
} from "redux/feesetting/feesetting.services";
import { useNavigate } from "react-router-dom";
import PaymentView, { IMetadataPayment } from "./Information/paymentVIew";
import FailedPage from "./Information/failed";

//loyaty point
import { getLoyaltyPoint, LoyaltyPointData } from "redux/loyaltyPoint";
import {
  SELECTED_TABLE_NAME_KEY,
  SELECTED_TABLE_KEY,
  USER_SERVICE_KEY,
} from "helper/constant";
import { getPickupPoint } from "redux/pickuppoint";
import { listAllTable } from "redux/table";
import {
  getRate,
  orderSetData,
  PricingsProps,
  CourierProps,
} from "redux/delivery";
import AddressStoreCheckout from "components/Cards/AddressStoreCheckout";
import PickUpPointImage from "assets/images/pickup_point.png";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { getEmailData } from "redux/googleSignIn/googleSignIn.services";
import { registerSetData } from "redux/registerAddress";
import {
  setUserGoogle,
  clearUserGoogle,
  verifyEmail,
} from "redux/googleSignIn/googleSignIn.services";
import { isAuthenticated } from "helper/general";
import BankSelected from "./Information/bank.selected";

// drawer
import PaymentMethodsDrawer from "components/Drawer/payment.methods.drawer";
import BanksDrawer from "components/Drawer/banks.drawer";
import ServicesDrawer from "components/Drawer/services.drawer";
import DeliveryTypesDrawer from "components/Drawer/delivery.types.drawer";
import CourierDrawer from "components/Drawer/courier.drawer";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPaymentView, setShowPaymentView] = React.useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = React.useState(false);
  const [pickBank, setPickBank] = React.useState(false);
  const [availableBanks, setAvailableBanks] = React.useState<BankProps[]>();
  const [metadata, setMetadata] = React.useState<IMetadataPayment>({
    payment_method: "",
    payment_string: "",
  });
  const [customerCanPickup, setCustomerCanPickup] = React.useState(false);
  const [customerCanPickTable, setCustomerCanPickTable] = React.useState(false);

  const [feeSetting, setFeeSetting] = React.useState<IFeeSetting>({
    date: "",
    payment_fee_type: "CUSTOMER",
    platform_fee_type: "",
    service: 0,
    tax: 0,
    id: 0,
    updated_by: "",
  });
  const [services, setServices] = React.useState({
    payment_fee: 0,
  });

  //loyaty point
  const [loyaltyPointData, setLoyaltyPointData] =
    React.useState<LoyaltyPointData>();

  const [reducePayload, setReducePayload] =
    React.useState<ChangeQuantityProps>();
  const {
    thunkDispatch,
    storeState: {
      StoreInfo,
      Cart,
      PickupPoint,
      RegisterAddress,
      RegisterUser,
      CheckCustomer,
      AppSetting: {
        data: {
          data: { primary_color, secondary_color, favicon },
        },
      },
      GoogleSignIn,
      // Voucher,
    },
  } = useRedux();

  const [paymentMethods, setPaymentMethods] =
    React.useState<IPaymentMethod[]>();
  const [pickPayment, setPickPayment] = React.useState(false);
  const [pickService, setPickService] = React.useState(false);
  const [paymentDetail, setPaymentDetail] =
    React.useState<CheckoutPaymentDetailProps>({
      payment_type: "",
      payment_channel: "",
      tax_percentage: 0,
      service_percentage: 0,
      tax_price: 0,
      service_price: 0,
      payment_fee_type: "OWNER",
      payment_fee_customer: 0,
      payment_fee_owner: 0,
      total_amount: 0,
      bank_code: "",
    });

  const [loyaltyPayload, setLoyaltyPayload] = React.useState({
    use_loyalty: false,
    loyalty_usage: 0,
  });

  const [service, setService] = React.useState<
    | "TAKE AWAY"
    | "DINE IN"
    | "DELIVERY"
    | "PICKUP POINT"
    | "IN STORE PICKUP"
    | any
  >(localStorage.getItem(USER_SERVICE_KEY)! ?? "");

  const [pickDeliveryTime, setPickDeliveryTime] = React.useState(false);
  const [pickDeliveryCourier, setPickDeliveryCourier] = React.useState(false);
  const [deliveryTime, setDeliveryTime] = React.useState("");
  const [deliveryCourier, setDeliveryCourier] = React.useState<CourierProps>();

  const [deliveryCourierOptions, setDeliveryCourierOptions] = React.useState<
    PricingsProps[]
  >([]);
  const [isAuth, setIsAuth] = React.useState(false);

  const reduceQuantity = (props: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(props))
      .unwrap()
      .then(() => null);
  };

  const fetchPickup = () => {
    let data = {
      store_id: StoreInfo?.data?.data?.id ?? "",
    };
    thunkDispatch(getPickupPoint(data))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.pickUpList &&
            res.data.data.pickUpList.length > 0
          ) {
            console.log("PIKCUP YES");
            setCustomerCanPickup(true);
          } else {
            console.log("PIKCUP NO");
            setCustomerCanPickup(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchTables = () => {
    if (
      StoreInfo &&
      StoreInfo.status === "success" &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.id
    ) {
      thunkDispatch(listAllTable(StoreInfo.data.data.id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (res.data.data && res.data.data.length > 0) {
              console.log("TABLE YES");
              setCustomerCanPickTable(true);
            } else {
              console.log("TABLE NO");
              setCustomerCanPickTable(false);
            }
          }
        });
    }
  };

  async function getDeliveryFee(data: any) {
    try {
      const getFee = await thunkDispatch(getRate(data)).unwrap();
      const response = getFee;

      let pricings = response.data.data.pricings;
      if (pricings && pricings.length > 0) {
        if (
          StoreInfo.data.data.merchant_type === "FOOD" &&
          (deliveryTime === "same-day" || deliveryTime === "express")
        ) {
          let findPaxel = pricings.find(
            (item: any) => item?.logistic?.name === "Paxel"
          );
          setDeliveryCourierOptions([findPaxel]);
          return;
        } else {
          setDeliveryCourierOptions(pricings);
          return;
        }
      } else {
        setDeliveryCourierOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCheckout = () => {
    if (service === "DELIVERY") {
      if (
        !RegisterAddress?.data?.address ||
        RegisterAddress?.data?.address?.length === 0 ||
        !deliveryTime ||
        !deliveryCourier?.id ||
        deliveryCourier?.id === 0
      ) {
        if (
          !RegisterAddress?.data?.address ||
          RegisterAddress?.data?.address?.length === 0
        ) {
          notify("error", "Alamat wajib diisi");
          return;
        }

        if (
          !deliveryTime ||
          !deliveryCourier?.id ||
          deliveryCourier?.id === 0
        ) {
          notify("error", "Jasa Pengiriman Wajib diisi");
          return;
        }
      }
    } else {
      if (
        !RegisterAddress?.data?.full_name ||
        RegisterAddress?.data?.full_name?.length === 0 ||
        (!GoogleSignIn?.data?.email_profile?.sub &&
          !RegisterAddress?.data?.phone)
      ) {
        notify("error", "Data Customer wajib diisi!");
        notify(
          "error",
          !GoogleSignIn?.data?.email_profile?.sub &&
            !RegisterAddress?.data?.phone
            ? "No telepon wajib diisi!"
            : ""
        );
        return;
      }
    }
    doCheckout();
  };

  const doCheckout = () => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.store_code
    ) {
      if (
        paymentDetail &&
        paymentDetail.payment_channel === "virtual_account" &&
        !paymentDetail.bank_code
      ) {
        notify(
          "warning",
          "Silahkan pilih bank virtual account terlebih dahulu"
        );
        return false;
      }

      if (
        paymentDetail &&
        paymentDetail.payment_channel === "virtual_account" &&
        paymentDetail.bank_code === "BCA" &&
        Number(paymentDetail.total_amount) < 10000
      ) {
        notify("error", "Transaksi minimal Rp. 10.000");
        return;
      }

      if (Cart && Cart.items.length > 0) {
        setLoading(true);
        let products: CheckoutProductProps[] = [];
        Cart.items.forEach((item) => {
          item.products.forEach((product) => {
            let addons: CheckoutProductAddonsProps[] = [];
            if (product.additionals && product.additionals.length > 0) {
              product.additionals.forEach((addon) => {
                addons.push({
                  product_id: addon.product_id,
                  product_assign_id: Number(addon.id),
                  product_price: Number(addon.price),
                  product_qty: addon.qty,
                  sub_total: Number(addon.price) * addon.qty,
                  notes: "",
                });
              });
            }
            products.push({
              product_id: product.product_id,
              product_price: Number(product.price),
              product_qty: product.qty,
              sub_total: Number(product.price) * product.qty,
              is_customizable: product.is_customizable,
              notes: product.notes,
              product_add_on: addons,
            });
          });
        });

        let checkoutPayload: CheckoutProps | CheckoutDeliveryProps =
          service === "DELIVERY"
            ? {
                customer_google_id: GoogleSignIn?.data?.email_profile?.sub
                  ? GoogleSignIn.data.email_profile.sub
                  : "",
                store_code: StoreInfo.data.data.store_code,
                device_type: "MOBILEQR",
                order_type: service,
                payment_detail: {
                  ...paymentDetail,
                  bank_code:
                    paymentDetail.payment_channel === "virtual_account"
                      ? paymentDetail.bank_code ?? ""
                      : "",
                },
                product_order_detail: products,
                sub_total: Math.ceil(Cart.total_price),
                total_price: Math.ceil(paymentDetail.total_amount),
                customer_email:
                  CheckCustomer &&
                  CheckCustomer.data &&
                  CheckCustomer.data.data &&
                  CheckCustomer.data.data.email
                    ? CheckCustomer.data.data.email
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.email
                    ? RegisterUser.items.email
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.email
                    ? RegisterAddress.data.email
                    : "",
                customer_name:
                  CheckCustomer && CheckCustomer.data && CheckCustomer.data.data
                    ? CheckCustomer.data.data.name
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.full_name
                    ? RegisterUser.items.full_name
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.full_name
                    ? RegisterAddress.data.full_name
                    : "",
                customer_phone_number:
                  CheckCustomer &&
                  CheckCustomer.data &&
                  CheckCustomer.data.data &&
                  CheckCustomer.data.data.phone_number
                    ? CheckCustomer.data.data.phone_number
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.phone
                    ? RegisterUser.items.phone
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.phone
                    ? RegisterAddress.data.phone
                    : "",
                use_loyalty: loyaltyPayload.use_loyalty,
                loyalty_usage: loyaltyPayload.loyalty_usage,
                origin_area_id: StoreInfo?.data?.data?.area.id,
                destination_area_id: Number(RegisterAddress?.data?.area_id),
                length: 10, // kedepannya bakal tidak fix value
                wide: 10, // kedepannya bakal tidak fix value
                height: 10, // kedepannya bakal tidak fix value
                weight: 1, // kedepannya bakal tidak fix value
                rate_id: deliveryCourier?.id,
                content: "order",
                package_type: 2, // kedepannya bakal tidak fix value
                origin_address: StoreInfo?.data?.data?.address,
                destination_address: RegisterAddress?.data?.address,
                use_insurance: deliveryCourier?.insurance_applied,
                cod: false,
                use_voucher: false,
                voucher_id: [],
                // use_voucher: Voucher && Voucher.items.length > 0 ? true : false,
                // voucher_id:
                //   Voucher && Voucher.items
                //     ? Voucher.items.map((item: any) => {
                //         return item.id;
                //       })
                //     : [],
              }
            : {
                customer_google_id: GoogleSignIn?.data?.email_profile?.sub
                  ? GoogleSignIn.data.email_profile.sub
                  : "",
                store_code: StoreInfo.data.data.store_code,
                device_type: "MOBILEQR",
                order_type: service,
                payment_detail: {
                  ...paymentDetail,
                  bank_code:
                    paymentDetail.payment_channel === "virtual_account"
                      ? paymentDetail.bank_code ?? ""
                      : "",
                },
                product_order_detail: products,
                sub_total: Math.ceil(Cart.total_price),
                total_price: Math.ceil(paymentDetail.total_amount),
                customer_email:
                  CheckCustomer &&
                  CheckCustomer.data &&
                  CheckCustomer.data.data &&
                  CheckCustomer.data.data.email
                    ? CheckCustomer.data.data.email
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.email
                    ? RegisterUser.items.email
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.email
                    ? RegisterAddress.data.email
                    : "",
                customer_name:
                  CheckCustomer && CheckCustomer.data && CheckCustomer.data.data
                    ? CheckCustomer.data.data.name
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.full_name
                    ? RegisterUser.items.full_name
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.full_name
                    ? RegisterAddress.data.full_name
                    : "",
                customer_phone_number:
                  CheckCustomer &&
                  CheckCustomer.data &&
                  CheckCustomer.data.data &&
                  CheckCustomer.data.data.phone_number
                    ? CheckCustomer.data.data.phone_number
                    : RegisterUser &&
                      RegisterUser.items &&
                      RegisterUser.items.phone
                    ? RegisterUser.items.phone
                    : RegisterAddress &&
                      RegisterAddress.data &&
                      RegisterAddress.data.phone
                    ? RegisterAddress.data.phone
                    : "",
                use_loyalty: loyaltyPayload.use_loyalty,
                loyalty_usage: loyaltyPayload.loyalty_usage,
                use_voucher: false,
                voucher_id: [],
                // use_voucher: Voucher && Voucher.items.length > 0 ? true : false,
                // voucher_id:
                //   Voucher && Voucher.items
                //     ? Voucher.items.map((item: any) => {
                //         return item.id;
                //       })
                //     : [],
              };

        // makesure that service match
        if (service === "PICKUP POINT") {
          checkoutPayload.pickup_point_id =
            PickupPoint && PickupPoint.items && PickupPoint.items.id
              ? PickupPoint.items.id
              : "";
        } else if (
          service === "DINE IN" &&
          localStorage.getItem(SELECTED_TABLE_KEY)
        ) {
          checkoutPayload.table_id = Number(
            localStorage.getItem(SELECTED_TABLE_KEY)
          );
        }

        thunkDispatch(checkoutOrder(checkoutPayload))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              thunkDispatch(orderSetData(res?.data.data)).unwrap();

              if (res && res.data && res.data.data) {
                thunkDispatch(
                  getOrderInvoice({
                    order_number: res.data.data.order_number,
                  })
                )
                  .unwrap()
                  .then((inv) => {
                    if (inv && inv.status === "success") {
                      // thunkDispatch(registerClearData()).unwrap();
                      if (res.data.data.payment_method === "virtual_account") {
                        setMetadata({
                          payment_string:
                            res.data.data.metadata.account_number ?? "",
                          payment_method: res.data.data.payment_method,
                        });
                        setShowPaymentFailed(false);
                        setShowPaymentView(true);
                      } else if (res.data.data.payment_method === "qr_code") {
                        setMetadata({
                          payment_string: res.data.data.metadata.qr_code,
                          payment_method: res.data.data.payment_method,
                        });
                        setShowPaymentFailed(false);
                        setShowPaymentView(true);
                      } else {
                        navigate("/checkout/success");
                      }
                    }
                  });
              } else {
                notify("error", res.data.message);
              }
            } else if (res && res.status === "error") {
              if (
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.data &&
                res.error.response.data.data.data &&
                res.error.response.data.data.data.error_code
              ) {
                setShowPaymentFailed(true);
              } else if (res.error.response) {
                notify("error", res.error.response.data.message);
              }
            }
          })
          .catch((e) => {
            if (e.response) {
              notify("error", e.response.data.message);
            } else {
              notify("error", e.message);
            }
          })
          .finally(() => setLoading(false));
      } else {
        notify(
          "error",
          "Produk tidak ditemukan, silahkan pilih produk terlebih dahulu"
        );
      }
    } else {
      notify("error", "Terjadi Kesalahan, Silahkan scan ulang QRCode toko");
    }
  };

  const logIn = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      getEmailId(codeResponse?.access_token);
      thunkDispatch(setUserGoogle(codeResponse));
    },
    onError: (error: any) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    setIsAuth(false);
    googleLogout();
    thunkDispatch(clearUserGoogle());
  };

  const getEmailId = (token: any) => {
    thunkDispatch(getEmailData(token))
      .unwrap()
      .then((res: any) => {
        // console.log(res);
        setIsAuth(true);
        let data = {
          full_name: res.data?.name,
          email: res.data?.email,
          customer_google_id: res.data?.sub,
        };
        thunkDispatch(registerSetData(data));
        thunkDispatch(setUserGoogle({ email_profile: res.data }));
      });
  };

  const verifyEmailGoogleByServer = () => {
    let payload = {
      google_user_id: GoogleSignIn?.data?.email_profile?.sub
        ? GoogleSignIn?.data?.email_profile?.sub
        : "",
      access_token: GoogleSignIn?.data?.access_token
        ? GoogleSignIn?.data?.access_token
        : "",
      first_name: GoogleSignIn?.data?.email_profile?.given_name
        ? GoogleSignIn?.data?.email_profile?.given_name
        : "",
    };
    thunkDispatch(verifyEmail(payload));
  };

  const handlePickBank = (status: boolean) => {
    setPickBank(status);
  };

  const handlePickPayment = (status: boolean) => {
    setPickPayment(status);
  };

  const handleChangePaymentDetail = (data: CheckoutPaymentDetailProps) => {
    setPaymentDetail(data);
  };

  const handleServices = (data: any) => {
    setServices(data);
  };

  const handlePickService = (data: any) => {
    setService(data);
  };

  const handleDeliveryTime = (data: any) => {
    setDeliveryTime(data);
  };

  const handleDeliveryCourier = (data: any) => {
    setDeliveryCourier(data);
  };

  // get shipment

  React.useEffect(() => {
    let data = {
      destinationAreaId: RegisterAddress?.data?.area_id,
      storeId: StoreInfo?.data?.data?.id,
      storeType: 0,
      deliveryType: 0,
      wide: 10,
      height: 10,
      length: 10,
      weight: 1,
      price: Cart.total_price,
      rateType: deliveryTime,
    };

    if (RegisterAddress?.data?.area_id) {
      getDeliveryFee(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTime, Cart.total_price]);
  // get shipment

  // get loyalty point
  React.useEffect(() => {
    let dataLoyalty = {
      storeCode:
        StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.store_code
          ? StoreInfo.data.data.store_code
          : "",
      phoneNumber:
        CheckCustomer &&
        CheckCustomer.data &&
        CheckCustomer.data.data &&
        CheckCustomer.data.data.phone_number
          ? CheckCustomer.data.data.phone_number
          : RegisterUser.items && RegisterUser.items.phone
          ? RegisterUser.items.phone
          : RegisterAddress.data && RegisterAddress.data.phone
          ? RegisterAddress.data.phone
          : "",
    };
    if (dataLoyalty.storeCode && dataLoyalty.phoneNumber) {
      thunkDispatch(getLoyaltyPoint(dataLoyalty))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (res.data && res.data.data) {
              setLoyaltyPointData(res.data.data);
            }
          }
        })
        .catch((e) => {
          console.log("ERR Banner", e);
        });
    }
  }, [
    thunkDispatch,
    StoreInfo,
    RegisterUser,
    CheckCustomer,
    RegisterAddress.data,
  ]);
  //get loyalty point

  React.useEffect(() => {
    thunkDispatch(getTotalPrice()).catch(() => null);
  }, [thunkDispatch, Cart]);

  React.useEffect(() => {
    getAvailableBanks().then((res) => {
      if (res && res.length > 0) {
        setAvailableBanks(res);
      }
    });

    if (StoreInfo && StoreInfo.status === "success") {
      fetchPickup();
      fetchTables();
      if (StoreInfo?.data?.data?.merchant_id) {
        thunkDispatch(getPaymentMethodList(StoreInfo.data.data.merchant_id))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setPaymentMethods(res.data.data);
            }
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
            }
          });
        getFeeSetting({
          store_id: StoreInfo.data.data.id,
          user_type: "user_merchant",
        }).then((res) => {
          setFeeSetting(res);
        });
      }
    }
  }, [thunkDispatch, StoreInfo]); // eslint-disable-line

  React.useEffect(() => {
    let tax = 0;
    let service = 0;
    let grandTotal = 0;
    let deliveryFee = deliveryCourier?.total_price
      ? deliveryCourier.total_price
      : 0;
    let paymentFee = services.payment_fee;
    let customerFee = paymentFee;
    let ownerFee = 0;
    let subTotal = 0;
    let taxPercent = 0;
    let servicePercent = 0;
    let pointUsed = 0;

    if (Cart) {
      subTotal = Cart.total_price;

      if (feeSetting.tax) {
        tax = subTotal * (feeSetting.tax / 100);
        taxPercent = feeSetting.tax;
      }

      if (feeSetting.service) {
        service = subTotal * (feeSetting.service / 100);
        servicePercent = feeSetting.service;
      }

      if (feeSetting.payment_fee_type) {
        if (feeSetting.payment_fee_type === "SPLIT") {
          customerFee = paymentFee / 2;
          ownerFee = paymentFee / 2;
        } else if (feeSetting.payment_fee_type === "OWNER") {
          customerFee = 0;
          ownerFee = paymentFee;
        } else {
          customerFee = paymentFee;
          ownerFee = 0;
        }
        setPaymentDetail({
          ...paymentDetail,
          payment_fee_type: feeSetting.payment_fee_type,
        });
      }
    }

    if (loyaltyPayload.use_loyalty) {
      if (loyaltyPointData && loyaltyPointData.loyalty_point) {
        pointUsed = parseInt(loyaltyPointData?.loyalty_point) ?? 0;
        if (pointUsed > subTotal) {
          pointUsed = subTotal;
        }
        setLoyaltyPayload({ ...loyaltyPayload, loyalty_usage: pointUsed });
      }
    }

    customerFee = Math.ceil(customerFee);
    ownerFee = Math.ceil(ownerFee);
    tax = Math.ceil(tax);
    service = Math.ceil(service);

    grandTotal =
      subTotal + tax + service + customerFee + deliveryFee - pointUsed;

    setPaymentDetail({
      ...paymentDetail,
      total_amount: grandTotal,
      payment_fee_customer: customerFee,
      payment_fee_owner: ownerFee,
      tax_price: tax,
      tax_percentage: taxPercent,
      service_price: service,
      service_percentage: servicePercent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Cart,
    feeSetting,
    services,
    loyaltyPayload.use_loyalty,
    deliveryCourier?.total_price,
  ]); // eslint-disable-line

  React.useEffect(() => {
    if (!isAuthenticated()) {
      logOut();
    } else {
      setIsAuth(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      GoogleSignIn &&
      GoogleSignIn.data &&
      GoogleSignIn.data.access_token &&
      GoogleSignIn.data.email_profile &&
      GoogleSignIn.data.email_profile.email
    ) {
      verifyEmailGoogleByServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GoogleSignIn?.data?.email_profile?.email]);

  return (
    <MainLayout background="white" title={"Checkout"} favIcon={favicon}>
      <ConfirmationModal
        handleClose={() => setConfirm(false)}
        handleConfirm={() => {
          if (reducePayload) {
            reduceQuantity(reducePayload);
            setConfirm(false);
          }
        }}
        backgroundButton={primary_color ?? "#B92362"}
        description={"Apakah anda yakin ingin menghapus item ini?"}
        open={confirm}
        hasCancel={true}
      />
      {showPaymentView ? (
        <PaymentView metadata={metadata} show={showPaymentView} />
      ) : showPaymentFailed ? (
        <FailedPage
          show={showPaymentFailed}
          onReorder={() => handleCheckout()}
          loading={loading}
        />
      ) : (
        <Box margin={"0 0 0 0"} paddingBottom={"20vh"}>
          <Box
            padding={"20px 20px 12px 20px"}
            sx={{ background: "white" }}
            minHeight={"48px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <ImageIcon
                src={IconTimes}
                alt={"ic_times"}
                loading={"lazy"}
                onClick={() => navigate("/cart")}
              />
              <NormalText
                fontSize="14px"
                fontWeight="bold"
                fontFamily="D-DIN Exp"
              >
                {StoreInfo?.data?.data?.store_name}
              </NormalText>
              <div></div>
            </Stack>
          </Box>
          <SpacerLine height="5px" />

          <ServiceTypeComponent
            service={service}
            onClick={() => setPickService(true)}
          />

          <SpacerLine height="3px" />

          <Box
            sx={{
              borderBottom:
                service === "DINE IN" ||
                (service === "DELIVERY" && RegisterAddress.data.address) ||
                service === "PICKUP POINT"
                  ? "3px solid #F2F2F2"
                  : "none",
            }}
          >
            {service === "DINE IN" && customerCanPickTable ? (
              <Box padding={2} width={"100%"}>
                <Typography
                  fontFamily={"D-DIN, sans-serif"}
                  fontSize={"0.95rem"}
                  fontWeight={800}
                  variant="h6"
                >
                  {localStorage.getItem(SELECTED_TABLE_NAME_KEY)
                    ? `Meja - ${localStorage.getItem(SELECTED_TABLE_NAME_KEY)}`
                    : "Pilih Meja Anda"}
                </Typography>

                <Button
                  sx={{
                    textTransform: "none",
                    background: `${primary_color ?? "#B92362"}`,
                    width: "100%",
                    color: "#ffffff",
                    border: `1px solid ${primary_color}`,
                    "&:hover": {
                      backgroundColor: primary_color,
                    },
                    fontFamily: "D-DIN, sans-serif",
                  }}
                  className="mt-3"
                  onClick={() => navigate("/table")}
                >
                  {localStorage.getItem(SELECTED_TABLE_NAME_KEY)
                    ? "Ganti Meja"
                    : "Pilih Meja Anda"}
                </Button>
              </Box>
            ) : null}

            {service === "DELIVERY" && RegisterAddress.data.address ? (
              <Box padding={2}>
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #ededed",
                  }}
                >
                  <DeliveryTypeComponent
                    time={deliveryTime}
                    courier={deliveryCourier}
                    handlerClickTime={() => setPickDeliveryTime(true)}
                    handlerClickCourier={() => setPickDeliveryCourier(true)}
                  />
                </Box>
              </Box>
            ) : null}

            {service === "PICKUP POINT" && customerCanPickup ? (
              <Grid marginTop={1} container spacing={1} textAlign={"center"}>
                <Grid item xs={4} sm={4} md={4} lg={5}>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100px"}
                    height={"100px"}
                    borderRadius={"10px"}
                    overflow={"hidden"}
                    marginX="auto"
                  >
                    <img
                      style={{
                        width: "auto",
                        height: "100%",
                      }}
                      src={
                        PickupPoint?.data?.data?.image_pup ?? PickUpPointImage
                      }
                      alt={"pickup_point_image"}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={5}>
                  <Stack direction={"column"}>
                    <NormalText
                      fontFamily="Open Sans"
                      fontSize={"12px"}
                      fontWeight={"bold"}
                      textAlign={"left"}
                    >
                      {PickupPoint &&
                      PickupPoint.items &&
                      PickupPoint.items.pupoint_name
                        ? PickupPoint.items.pupoint_name
                        : "Silahkan pilih pickup point"}
                    </NormalText>
                    <ChekoutButton
                      background={primary_color ?? "#B92362"}
                      style={{
                        borderRadius: "2px",
                        fontSize: "10px",
                        margin: "10px 0 0 0",
                        fontFamily: "Open Sans",
                        height: "31px",
                      }}
                      onClick={() => navigate("/pickup-point")}
                    >
                      {PickupPoint &&
                      PickupPoint.items &&
                      PickupPoint.items.pupoint_name
                        ? "Ganti pickup point"
                        : "Pilih pickup point"}
                    </ChekoutButton>
                  </Stack>
                </Grid>
              </Grid>
            ) : null}
          </Box>

          {/* Fill the form */}

          <Stack
            direction="column"
            className="p-3"
            sx={{ wordBreak: "break-word" }}
          >
            <AddressStoreCheckout
              address={StoreInfo?.data?.data?.address}
              phone_number={StoreInfo?.data?.data?.store_phone}
              primary_color={primary_color}
            />
            <Typography
              fontFamily={"D-DIN, sans-serif"}
              fontSize={"0.95rem"}
              fontWeight={800}
              variant="h6"
            >
              Data Customer
            </Typography>

            <Typography
              fontFamily={"D-DIN, sans-serif"}
              fontSize={"0.9rem"}
              fontWeight={400}
              lineHeight={"1.2"}
              variant="body1"
            >
              {RegisterAddress.data.full_name
                ? RegisterAddress.data.full_name
                : ""}
            </Typography>
            <Stack direction={"row"}>
              <Typography
                fontFamily={"D-DIN, sans-serif"}
                fontSize={"0.9rem"}
                fontWeight={400}
                lineHeight={"1.2"}
                variant="body1"
              >
                {RegisterAddress.data.phone ? RegisterAddress.data.phone : ""}
              </Typography>
              <Typography
                fontFamily={"D-DIN, sans-serif"}
                fontSize={"0.9rem"}
                fontWeight={400}
                lineHeight={"1.2"}
                variant="body1"
              >
                {RegisterAddress.data.phone ? "/" : ""}
              </Typography>
              <Typography
                fontFamily={"D-DIN, sans-serif"}
                fontSize={"0.9rem"}
                fontWeight={400}
                lineHeight={"1.2"}
                variant="body1"
              >
                {RegisterAddress.data.email ? RegisterAddress.data.email : ""}
              </Typography>
            </Stack>
            <Typography
              fontFamily={"D-DIN, sans-serif"}
              fontSize={"0.9rem"}
              fontWeight={400}
              lineHeight={"1.2"}
              variant="body1"
            >
              {RegisterAddress.data.address}
            </Typography>

            <Button
              sx={{
                textTransform: "none",
                background: `${loading ? "grey" : primary_color ?? "#B92362"}`,
                fontFamily: "D-DIN, sans-serif",
                color: "#ffffff",
                border: `1px solid ${primary_color}`,
                "&:hover": {
                  backgroundColor: primary_color,
                },
              }}
              className="mt-3"
              onClick={() => navigate("/register-address")}
            >
              {service === "DELIVERY" && RegisterAddress.data.address
                ? "Ganti Alamat"
                : service === "DELIVERY" && !RegisterAddress.data.address
                ? "Masukkan Alamat"
                : service !== "DELIVERY" && RegisterAddress.data.full_name
                ? "Edit Data Customer"
                : "Isi Data Customer"}
            </Button>
            <Button
              sx={{
                textTransform: "none",
                border: `1px solid #dadce0`,
                color: `#000000`,
                fontFamily: "D-DIN, sans-serif",

                "&:hover": {
                  color: "#000000",
                },
                textAlign: "center",
                position: "relative",
              }}
              className="mt-2"
              onClick={() => (isAuth ? logOut() : logIn())}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                className="LgbsSe-Bz112c"
                width="20"
                height="20"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  translate: "0 -50%",
                }}
              >
                <g>
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </g>
              </svg>{" "}
              {isAuth ? "Keluar akun Google" : "Daftar dengan Google"}
            </Button>
          </Stack>

          <SpacerLine height="3px" />

          <Box
            sx={{
              margin: "15px 0 0 0",
            }}
          >
            <NormalText
              margin="0 0 15px 20px"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="D-DIN"
            >
              Pesanan Saya
            </NormalText>
            {Cart.items && Cart.items.length > 0 ? (
              Cart.items.map((brand, prodItem) => (
                <div key={"cartcontainer-" + prodItem}>
                  <CategoryLabel background={primary_color ?? "#fff4f8"}>
                    <NormalText
                      fontSize={"12px"}
                      color={secondary_color ?? "#B92362"}
                      fontFamily="D-DIN Exp"
                      fontWeight="bold"
                      margin="0 0 0 20px"
                    >
                      {brand.brand_name}
                    </NormalText>
                  </CategoryLabel>
                  {brand.products &&
                    brand.products.length &&
                    brand.products.map((item, index: number) => (
                      <CartProduct
                        key={"cartitem-" + index}
                        background={primary_color ?? "#B92362"}
                        onQtyIncrease={() => {
                          reduceQuantity({
                            brand_id: brand.brand_id,
                            type: "increase",
                            cart_id: item.cart_id ?? "",
                          });
                        }}
                        onQtyDecrease={() => {
                          if (item.qty === 1) {
                            setReducePayload({
                              brand_id: brand.brand_id,
                              type: "decrease",
                              cart_id: item.cart_id ?? "",
                            });
                            setConfirm(true);
                          } else {
                            reduceQuantity({
                              brand_id: brand.brand_id,
                              type: "decrease",
                              cart_id: item.cart_id ?? "",
                            });
                          }
                        }}
                        qty={item.qty}
                        customizable={item.additionals}
                        product_name={item.product_name}
                        product_price={Number(item.price)}
                        notes={item.notes}
                      />
                    ))}
                </div>
              ))
            ) : (
              <NormalText fontSize="12px" textAlign="center" margin="50px 0">
                Silahkan pilih produk terlebih dahulu
              </NormalText>
            )}
            <CategoryLabel background={primary_color ?? "#fff4f8"}>
              <NormalText
                fontSize={"12px"}
                color={secondary_color ?? "#B92362"}
                fontFamily="D-DIN Exp"
                fontWeight="bold"
                margin="0 0 0 20px"
              >
                Pembayaran
              </NormalText>
            </CategoryLabel>
            <Box>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                marginBottom={"4px"}
                padding="20px 20px 0px 20px"
              >
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  Order Fee
                </NormalText>
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  {formatCurrency(paymentDetail?.payment_fee_customer ?? 0)}
                </NormalText>
              </Stack>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                marginBottom={"4px"}
                padding="0px 20px 0px 20px"
              >
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  Tax {"&"} Services
                </NormalText>
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  {formatCurrency(
                    (paymentDetail?.tax_price ?? 0) +
                      (paymentDetail?.service_price ?? 0)
                  )}
                </NormalText>
              </Stack>

              {service === "DELIVERY" && (
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  marginBottom={"4px"}
                  padding="0px 20px 0px 20px"
                >
                  <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                    Delivery Fee
                  </NormalText>
                  <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                    {deliveryCourier && deliveryCourier.total_price
                      ? formatCurrency(Number(deliveryCourier.total_price))
                      : formatCurrency(0)}
                  </NormalText>
                </Stack>
              )}

              {loyaltyPayload.use_loyalty && (
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  marginBottom={"4px"}
                  padding="0px 20px 0px 20px"
                >
                  <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                    Loyalty Usage
                  </NormalText>
                  <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                    - {formatCurrency(Number(loyaltyPayload.loyalty_usage))}
                  </NormalText>
                </Stack>
              )}

              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                marginBottom={"4px"}
                padding="0px 20px 20px 20px"
              >
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  Sub Total
                </NormalText>
                <NormalText fontSize={"12px"} fontFamily={"Open Sans"}>
                  {formatCurrency(Cart.total_price)}
                </NormalText>
              </Stack>
              <CategoryLabel background={primary_color ?? "#fff4f8"}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid item xs={10}>
                    <NormalText
                      fontFamily={"Open Sans"}
                      fontSize="12px"
                      margin="10px 0 0 20px"
                      fontWeight="bold"
                      color={secondary_color ?? "#000"}
                    >
                      {loyaltyPointData && loyaltyPointData.loyalty_point
                        ? `Gunakan ${formatCurrency(
                            Number(loyaltyPointData.loyalty_point)
                          )} Point`
                        : "Gunakan Loyalty Point"}
                    </NormalText>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Switch
                      checked={loyaltyPayload.use_loyalty}
                      onChange={(e: any) =>
                        setLoyaltyPayload({
                          ...loyaltyPayload,
                          use_loyalty: e.target.checked,
                        })
                      }
                      disabled={
                        loyaltyPointData && loyaltyPointData.loyalty_point
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </Grid>
              </CategoryLabel>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                margin={"10px 0 0 0"}
                padding="20px 20px 20px 20px"
              >
                <NormalText
                  fontSize={"12px"}
                  fontFamily={"Open Sans"}
                  fontWeight={"bold"}
                >
                  Total harga
                </NormalText>
                <NormalText
                  fontSize={"12px"}
                  fontFamily={"Open Sans"}
                  fontWeight={"bold"}
                >
                  {formatCurrency(paymentDetail?.total_amount ?? 0)}
                </NormalText>
              </Stack>
            </Box>
          </Box>

          {/* Checkout Button */}
          <BottomSheet height="auto" padding="10px 20px 20px 20px">
            {/* <Stack
              direction={"row"}
              sx={{
                border: "2px solid #CCCCCC",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              alignItems={"center"}
              justifyContent={"space-between"}
              className="my-3 px-3 pb-3 pt-2"
              onClick={() => navigate("/voucher")}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <img
                  src={IconVoucher}
                  alt="icon-voucher"
                  style={{ height: "25px" }}
                  className="mr-2"
                />
                <Stack direction={"column"} alignItems={"flex-start"}>
                  <NormalText fontSize="14px" fontWeight="800">
                    Hemat dengan voucher
                  </NormalText>
                  <NormalText fontSize="10px">
                    Isi data customer dulu sebelum pakai voucher
                  </NormalText>
                </Stack>
              </Stack>

              <Stack>
                <ArrowForwardIosRoundedIcon sx={{ color: "#cccccc" }} />
              </Stack>
            </Stack> */}

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              margin={"10px 0"}
            >
              <NormalText
                textAlign="left"
                fontFamily="Open Sans"
                fontWeight="bold"
                fontSize="12px"
              >
                Metode Pembayaran
              </NormalText>
              <PickPaymentButton onClick={() => setPickPayment(true)}>
                {getPaymentLabel(paymentDetail.payment_channel)}
              </PickPaymentButton>
            </Stack>

            {paymentDetail &&
            paymentDetail.payment_channel === "virtual_account" &&
            paymentDetail.bank_code ? (
              <BankSelected
                bank_code={paymentDetail.bank_code}
                payment_method={getPaymentLabel(paymentDetail.payment_channel)}
                className={"my-3"}
              />
            ) : null}

            <ChekoutButton
              background={loading ? "grey" : primary_color ?? "#B92362"}
              onClick={() => (loading ? null : handleCheckout())}
            >
              {loading ? "Mohon Tunggu" : "Pesan"}
            </ChekoutButton>
          </BottomSheet>

          {/* Pick Payment */}
          <PaymentMethodsDrawer
            open={pickPayment}
            methods={
              paymentMethods && paymentMethods.length > 0 ? paymentMethods : []
            }
            onClose={() => {
              setPickPayment(false);
            }}
            paymentDetail={paymentDetail}
            service={service}
            handlePickBank={handlePickBank}
            handleChangePaymentDetail={handleChangePaymentDetail}
            handleServices={handleServices}
            color={primary_color}
            cart={Cart}
          />

          {/* Select Bank */}
          <BanksDrawer
            open={pickBank}
            banks={
              availableBanks && availableBanks.length > 0
                ? availableBanks.filter(
                    (availableBank) => availableBank.is_activated === true
                  )
                : []
            }
            onClose={() => {
              setPickBank(false);
            }}
            paymentDetail={paymentDetail}
            handlePickPayment={handlePickPayment}
            handleChangePaymentDetail={handleChangePaymentDetail}
            color={primary_color}
          />

          {/* Pick Service */}
          <ServicesDrawer
            open={pickService}
            onClose={() => {
              setPickService(false);
            }}
            color={primary_color}
            pickUp={customerCanPickup}
            merchantType={StoreInfo?.data?.data?.merchant_type}
            service={service}
            handlePickService={handlePickService}
          />

          {/* Select Delivery Time */}
          <DeliveryTypesDrawer
            open={pickDeliveryTime}
            onClose={() => {
              setPickDeliveryTime(false);
            }}
            color={primary_color}
            merchantType={StoreInfo?.data?.data?.merchant_type}
            deliveryType={deliveryTime}
            handleDeliveryTime={handleDeliveryTime}
            handleDeliveryCourier={handleDeliveryCourier}
          />

          {/* Select Delivery Courier */}
          <CourierDrawer
            open={pickDeliveryCourier}
            onClose={() => {
              setPickDeliveryCourier(false);
            }}
            color={primary_color}
            courier={deliveryCourier ? deliveryCourier : null}
            couriers={
              deliveryCourierOptions && deliveryCourierOptions.length > 0
                ? deliveryCourierOptions
                : []
            }
            handleDeliveryCourier={handleDeliveryCourier}
          />
        </Box>
      )}
      <br />
    </MainLayout>
  );
};

const ImageIcon = styled.img`
  cursor: pointer;
`;

interface PickPaymentButtonProps {
  background?: string;
}

const PickPaymentButton = styled.button<PickPaymentButtonProps>`
  background: transparent;
  color: white;
  font-size: 12px;
  border: none;
  font-family: D-DIN Exp, sans-serif;
  border: 1px solid black;
  color: black;
  border-radius: 50px;
  min-width: 100px;
  width: 123px;
  height: 24px;
`;

export default CheckoutPage;
