import { Stack } from "@mui/material";
import NoIcon from 'assets/images/NoIcon.svg'
import { NormalText } from "components/styled/text.styled";

interface CategoryListItemProps {
  categoryName?: string;
  categoryLogo?: string;
}

const CategoryListItem = ({
  categoryName,
  categoryLogo,
}: CategoryListItemProps) => {
  return (
    <Stack direction='column' justifyContent='flex-start' alignItems='center' className="category-container mt-2">
      <Stack direction='row' justifyContent={'center'} alignItems={'center'} className="category-logo">
        <img
          src={categoryLogo ? categoryLogo : NoIcon}
          alt="store-logo"
          className="category-logo__img"
        />
      </Stack>
      {/* <p className="store-item-name my-3">{categoryName?categoryName:'lorem ipsum'}</p> */}
      <NormalText
        fontFamily="D-DIN Exp"
        fontStyle="normal"
        fontWeight="400"
        fontSize="10px"
        lineHeight="11px"
        textAlign="center"
        margin="15px 0 0 0"
      >
        {categoryName && categoryName.length > 18 ? categoryName.substring(0, 18) + "..." : categoryName}
      </NormalText>
    </Stack>
  );
};

export default CategoryListItem;
