import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductState, ProductDetailState, ProductAddOnState } from "./types";
import axios from "axios";

export const listRecommendation = createAsyncThunk(
  "productRecommendation/list",
  async (merchant: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/recommendation?merchant_id=${merchant}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductState;
    }
  }
);

export const listBestSeller = createAsyncThunk(
  "product/bestseller/list",
  async (merchant: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/bestseller?merchant_id=${merchant}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductState;
    }
  }
);

export interface DetailProps {
  product_id?: any;
  merchant_id?: any;
  store_id?: any;
}

export const detailProduct = createAsyncThunk(
  "product/detail",
  async (props: DetailProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/detail?product_id=${props.product_id}&merchant_id=${props.merchant_id}&store_id=${props.store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductDetailState;
    }
  }
);

export const addOnProduct = createAsyncThunk(
  "product/addOn",
  async (props: DetailProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/additional?product_id=${props.product_id}&merchant_id=${props.merchant_id}&store_id=${props.store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductAddOnState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductAddOnState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductAddOnState;
    }
  }
);

export const listSearchProductResult = createAsyncThunk(
  "productSearchResult/list",
  async ({ merchant_id, keyword, store_id, limit }: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/product/mobile-qr/list?merchant_id=${merchant_id}&store_id=${store_id}${
          keyword ? `&keyword=${keyword}` : ""
        }${limit ? `&limit=${limit}` : ""}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        };
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        };
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        };
    }
  }
);

export const listSearchNewProductResult = createAsyncThunk(
  "new-product-search-result",
  async ({ merchant_id, store_id, keyword, limit }: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/productstore/mobile-qr/list?merchant_id=${merchant_id}&store_id=${store_id}${
          keyword ? `&keyword=${keyword}` : ""
        }${limit ? `&limit=${limit}` : ""}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        };
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        };
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        };
    }
  }
);

export const listQrGroupProduct = createAsyncThunk(
  "merchant/qr-group/product",
  async ({ qr_group_code, offset, keyword, limit }: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/group/${qr_group_code}/product/list?limit=${limit}&offset=${offset}${
          keyword ? `&keyword=${keyword}` : ""
        }`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        };
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        };
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        };
    }
  }
);

interface listQrGroupNewProductProps {
  qr_group_code: string;
  limit: string | number;
  offset: string | number;
  filter: string | number;
}

export const listQrGroupNewProduct = createAsyncThunk(
  "newProduct/list",
  async ({
    qr_group_code,
    limit,
    offset,
    filter,
  }: listQrGroupNewProductProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/group/${qr_group_code}/product?${
          limit ? `limit=${limit}` : ""
        }${offset ? `&offset=${offset}` : ""}${
          filter ? `&filter=${filter}` : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        };
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        };
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        };
    }
  }
);
