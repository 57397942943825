import Skeleton from "@mui/material/Skeleton";
import { Box, Stack } from "@mui/material";

const DetailPageCategorySkeleton = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton
        sx={{
          width: "100%",
          height: "37px",
        }}
        variant="rectangular"
      ></Skeleton>
      <Stack sx={{ width: "100%", padding: "0 10px", marginTop: '10px' }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Skeleton
          sx={{
            width: "75px",
            height: "75px",
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>
        <Box width={"50%"} margin={"0 0 0 0"} >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        </Box>
        <Skeleton
          sx={{
            width: '61.82px',
            height: '30px',
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>

      </Stack>
      <Stack sx={{ width: "100%", padding: "0 10px", marginTop: '10px' }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Skeleton
          sx={{
            width: "75px",
            height: "75px",
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>
        <Box width={"50%"} margin={"0 0 0 0"} >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        </Box>
        <Skeleton
          sx={{
            width: '61.82px',
            height: '30px',
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>

      </Stack>
      <Stack sx={{ width: "100%", padding: "0 10px", marginTop: '10px' }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Skeleton
          sx={{
            width: "75px",
            height: "75px",
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>
        <Box width={"50%"} margin={"0 0 0 0"} >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        </Box>
        <Skeleton
          sx={{
            width: '61.82px',
            height: '30px',
            borderRadius: "10px"
          }}
          variant="rectangular"
        ></Skeleton>

      </Stack>
    </Box>
  )
}

export default DetailPageCategorySkeleton;