import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubUrbanState } from "./types";
import axios from "axios";

export const getAllSubUrban = createAsyncThunk(
  "suburban/get",
  async (city_id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/master/suburb/${city_id}/city`,
        {
          headers: {
            api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
          },
        }
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubUrbanState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubUrbanState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubUrbanState;
    }
  }
);
