import { useState } from "react";
import { CategoryInterface } from "redux/category";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

interface CategoryListFilterProps {
  list: CategoryInterface[];
  type?: string;
  handleClose?: any;
}

const CategoryListFilter = ({
  list,
  type,
  handleClose,
}: CategoryListFilterProps) => {
  const [openCol, setOpenCol] = useState();
  const [openSubCol, setOpenSubCol] = useState();

  const handleClick = (itemId: any) => {
    if (openCol !== itemId) {
      setOpenCol(itemId);
    } else {
      setOpenCol(undefined);
    }
  };

  const handleClickSubCategory = (itemId: any) => {
    if (openSubCol !== itemId) {
      setOpenSubCol(itemId);
    } else {
      setOpenSubCol(undefined);
    }
  };

  return (
    <Box className="filter-container">
      <List
        sx={{
          width: "100%",
          maxWidth: 320,
          bgcolor: "background.paper",
        }}
        component="nav"
      >
        {/* {console.log(listCategory)} */}
        {/* List Test Level 1 & 2*/}
        {list && list.length > 0
          ? list
              .filter((category) => category.is_active === true)
              .filter((category) => category.total_product > 0)
              .map((item, index) => (
                <>
                  <ListItem key={"list" + index} sx={{ padding: 0 }}>
                    <ListItemButton
                      sx={{
                        paddingX: 1,
                        borderBottom: "1px solid #f1f1f1",
                      }}
                    >
                      <ListItemText
                        primary={item.category_name}
                        onClick={() => {
                          handleClose();
                        }}
                      />
                      {item.subCategory &&
                      item.subCategory.length > 0 &&
                      item.subCategory
                        ?.filter(
                          (subCategory) => subCategory.is_active === true
                        )
                        ?.filter((subCategory) => subCategory.total_product > 0)
                        .length > 0 ? (
                        openCol === item.id ? (
                          <ExpandLess onClick={() => handleClick(item.id)} />
                        ) : (
                          <ExpandMore onClick={() => handleClick(item.id)} />
                        )
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                  <Collapse
                    in={openCol === item.id}
                    timeout="auto"
                    unmountOnExit
                    key={"collapse" + index}
                  >
                    <List component="div" disablePadding>
                      {item.subCategory &&
                      item.subCategory.filter(
                        (subCategoryItem) => subCategoryItem.is_active === true
                      ).length > 0
                        ? item.subCategory
                            .map((subItem, subIndex) => {
                              return (
                                <>
                                  <ListItem
                                    sx={{ py: 0, px: 0 }}
                                    key={"subcategory-" + subItem.id}
                                  >
                                    <ListItemButton sx={{ pl: 3, pr: 1 }}>
                                      <ListItemText
                                        primary={subItem.subcategory_name}
                                        onClick={() => {handleClose()}}
                                      />

                                      {subItem.subsCategory &&
                                      subItem.subcategory_name.length > 0 &&
                                      subItem.subsCategory.filter(
                                        (i) => i.is_active
                                      ).length > 0 &&
                                      subItem.subsCategory
                                        .filter((i) => i.is_active)
                                        .filter((i) => i.total_product > 0)
                                        .length > 0 ? (
                                        openSubCol === subItem.id ? (
                                          <ExpandLess
                                            onClick={() =>
                                              handleClickSubCategory(subItem.id)
                                            }
                                          />
                                        ) : (
                                          <ExpandMore
                                            onClick={() =>
                                              handleClickSubCategory(subItem.id)
                                            }
                                          />
                                        )
                                      ) : null}
                                    </ListItemButton>
                                  </ListItem>
                                  <Collapse
                                    in={openSubCol === subItem.id}
                                    timeout="auto"
                                    unmountOnExit
                                    key={"collapse" + subIndex}
                                  >
                                    <List component="div" disablePadding>
                                      {subItem.subsCategory &&
                                      subItem.subsCategory.length > 0 &&
                                      subItem.subsCategory.filter(
                                        (i) => i.is_active === true
                                      ).length > 0 &&
                                      subItem.subsCategory
                                        .filter((i) => i.is_active === true)
                                        .filter((i) => i.total_product > 0)
                                        .length > 0
                                        ? subItem.subsCategory
                                            .filter((i) => i.is_active === true)
                                            .filter((i) => i.total_product > 0)
                                            .map((subsItem, subsIndex) => {
                                              return (
                                                <>
                                                  <ListItem
                                                    sx={{
                                                      py: 0,
                                                      px: 0,
                                                    }}
                                                    key={
                                                      "subscategory-" +
                                                      subsItem.id
                                                    }
                                                  >
                                                    <ListItemButton
                                                      sx={{ pl: 4 }}
                                                      onClick={() => {handleClose()}}
                                                    >
                                                      <ListItemText
                                                        primary={
                                                          subsItem.subscategory_name
                                                        }
                                                      />
                                                    </ListItemButton>
                                                  </ListItem>
                                                </>
                                              );
                                            })
                                        : null}
                                    </List>
                                  </Collapse>
                                </>
                              );
                            })
                        : null}
                    </List>
                  </Collapse>
                </>
              ))
          : null}

        {/* List Test Level 3 */}
        {/* {storeInfo?.data?.data?.merchant_type === "FOOD" &&
        categories &&
        categories.length
          ? categories
              .filter((category) => category.is_active === true)
              .map((subCat) =>
                subCat.subCategory
                  ?.filter((subActive) => subActive.is_active === true)
                  .map((subsCat) =>
                    subsCat.subsCategory
                      ?.filter((subsActive) => subsActive.is_active === true)
                      .filter((subsActive) => subsActive.total_product > 0)
                      .map((subsCategoryItem) => {
                        return (
                          <ListItem
                            sx={{ padding: 0 }}
                            key={"subsCategory" + subsCategoryItem.id}
                            onClick={() => {
                              
                              handleClose();
                            }}
                          >
                            <ListItemButton
                              sx={{
                                paddingX: 1,
                                borderBottom: "1px solid #f1f1f1",
                              }}
                            >
                              <ListItemText
                                primary={subsCategoryItem.subscategory_name}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })
                  )
              )
          : null} */}
      </List>
    </Box>
  );
};

export default CategoryListFilter;
