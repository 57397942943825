import { Grid } from "@mui/material";
import ServiceItem from "./ServiceItem";

interface ServiceListProps {
  merchant_type: string;
  color?: string;
  goToHome?: any;
  pickUp: boolean;
}

const fNbServices = [
  { name: "SANTAP DI TEMPAT", name_key: "DINE IN" },
  { name: "BAWA PULANG", name_key: "TAKE AWAY" },
  { name: "AMBIL DI TITIK PENGAMBILAN", name_key: "PICKUP POINT" },
  { name: "PESAN ANTAR", name_key: "DELIVERY" },
];

const retailServices = [
  { name: "AMBIL DI TOKO", name_key: "IN STORE PICKUP" },
  { name: "AMBIL DI TITIK PENGAMBILAN", name_key: "PICKUP POINT" },
  { name: "PESAN ANTAR", name_key: "DELIVERY" },
];

const ServiceList = ({
  merchant_type,
  color,
  goToHome,
  pickUp,
}: ServiceListProps) => {
  return (
    <Grid container sx={{ width: "100%" }} rowSpacing={2} alignItems="stretch">
      {merchant_type === "RETAIL" ? (
        <>
          {pickUp
            ? retailServices.map((retailService, index) => (
                <ServiceItem
                  key={`service-${index}`}
                  name={retailService.name}
                  name_key={retailService.name_key}
                  color={color}
                  goToHome={goToHome}
                />
              ))
            : retailServices
                .filter((item) => item.name_key !== "PICKUP POINT")
                .map((retailService, index) => (
                  <ServiceItem
                    key={`service-${index}`}
                    name={retailService.name}
                    name_key={retailService.name_key}
                    color={color}
                    goToHome={goToHome}
                  />
                ))}
        </>
      ) : (
        <>
          {pickUp
            ? fNbServices.map((fNbService, index) => (
                <ServiceItem
                  key={`service-${index}`}
                  name={fNbService.name}
                  name_key={fNbService.name_key}
                  color={color}
                  goToHome={goToHome}
                />
              ))
            : fNbServices
                .filter((item) => item.name_key !== "PICKUP POINT")
                .map((fNbService, index) => (
                  <ServiceItem
                    key={`service-${index}`}
                    name={fNbService.name}
                    name_key={fNbService.name_key}
                    color={color}
                    goToHome={goToHome}
                  />
                ))}
        </>
      )}
    </Grid>
  );
};

export default ServiceList;
