import { Stack, Button } from "@mui/material";

interface StoreListFilterProps {
  filterAge?: any;
  filterAsc?: any;
  filterDesc?: any;
  handleFilterAge?: any;
  handleFilterAsc?: any;
  handleFilterDesc?: any;
  primaryColor: string;
}

const StoreListFilter = ({
  filterAge,
  filterAsc,
  filterDesc,
  handleFilterAge,
  handleFilterAsc,
  handleFilterDesc,
  primaryColor,
}: StoreListFilterProps) => {
  return (
    <Stack direction={"row"} className="scroll-box">
      <Button
        onClick={handleFilterAge}
        className="mx-3"
        sx={{
          minWidth: "120px",
          textTransform: "none",
          borderRadius: "20px",
          border: `1px solid ${primaryColor}`,
          background: filterAge ? primaryColor : "#ffffff",
          "&:hover": {
            background: primaryColor,
          },
          color: "#000000",
        }}
        size="small"
      >
        Terbaru
      </Button>
      <Button
        onClick={handleFilterAsc}
        className="mx-3"
        sx={{
          minWidth: "120px",
          textTransform: "none",
          borderRadius: "20px",
          border: `1px solid ${primaryColor}`,
          background: filterAsc ? primaryColor : "#ffffff",
          "&:hover": {
            background: primaryColor,
          },
          color: "#000000",
        }}
        size="small"
      >
        Nama: A-Z
      </Button>
      <Button
        onClick={handleFilterDesc}
        className="mx-3"
        sx={{
          minWidth: "120px",
          textTransform: "none",
          borderRadius: "20px",
          border: `1px solid ${primaryColor}`,
          background: filterDesc ? primaryColor : "#ffffff",
          "&:hover": {
            background: primaryColor,
          },
          color: "#000000",
        }}
        size="small"
      >
        Nama: Z-A
      </Button>
    </Stack>
  );
};

export default StoreListFilter;
