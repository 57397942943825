import styled from "styled-components";
import ArrowIcon from "../../assets/icons/ic_arrow_white.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import history from "helper/history";
import { useNavigate } from "react-router-dom";

const BackButtonToHome = () => {
  const navigate = useNavigate();
  return (
    <ButtonStyle onClick={() => navigate("/home")}>
      <Icon style={{ cursor: "pointer" }} src={ArrowIcon} alt={"arrow_icon"} />
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  background: transparent;
  border: none;
`;

const Icon = styled.img`
  user-select: none;
  -webkit-user-drag: none;
`;

export default BackButtonToHome;
