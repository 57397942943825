import { AreaState, AreaResponseProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getAllArea } from "./area.services";

export const initialState: AreaState = {
  data: {} as AreaResponseProps,
  status: "loading",
  error: {},
};

export const AreaSlice = createSlice({
  name: "suburban",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllArea.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllArea.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getAllArea.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const AreaReducer = AreaSlice.reducer;
