import React from "react";
import { Box } from "@mui/material";
import LoyaltyItem from "../loyalty.item";
import useRedux from "redux/useRedux";
import { getHistoryLoyalty, HistoryLoyaltyPoint } from "redux/loyaltyPoint";
import { useNavigate } from "react-router-dom";
import { NormalText } from "components/styled/text.styled";

const IncomeView = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { CheckCustomer, StoreInfo },
  } = useRedux();
  const [incomes, setIncomes] = React.useState<HistoryLoyaltyPoint[]>();

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.status === "success" &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.store_code
    ) {
      if (
        CheckCustomer &&
        CheckCustomer.status === "success" &&
        CheckCustomer.data &&
        CheckCustomer.data.data &&
        CheckCustomer.data.data.email
      ) {
        thunkDispatch(
          getHistoryLoyalty({
            type: "email",
            loyaltyType: "pendapatan",
            storeCode: StoreInfo.data.data.store_code,
            email: CheckCustomer.data.data.email,
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.data && res.data.data) {
              setIncomes(res.data.data);
            }
          });
      } else {
        navigate("/access-forbidden");
      }
    } else {
      navigate("/access-forbidden");
    }
  // eslint-disable-next-line
  }, []);
  return (
    <Box>
      {incomes && incomes.length > 0 ? (
        incomes.map((income, index) => (
          <LoyaltyItem
            key={"income-" + index}
            type={"income"}
            poin={income.added}
            order_number={income.order_number}
          />
        ))
      ) : (
        <NormalText textAlign={"center"} margin={"20px 0 0 0"}>
          Belum ada history
        </NormalText>
      )}
    </Box>
  );
};

export default IncomeView;
