import React, { useState } from "react";
import { NormalText } from "../../components/styled/text.styled";
import { Stack } from "../../components/styled/layout.styled";
import MainLayout from "components/Layouts/MainLayout";
import Footer from "components/Layouts/Footer";
import LogoGotBeef from "../../assets/logo/ic-got-beef.svg";
import LogoPowerdBy from "../../assets/logo/sandbox-powered-logo.svg";
import "./styles.css";
import {
  registerUser,
  registerSetData,
  removeRegisterdata,
} from "../../../src/redux/userRegister";
import useRedux from "../../../src/redux/useRedux";
import { useNavigate } from "react-router-dom";
import { AppSettingData } from "redux/appsetting";
import { removeCustomerData } from "redux/checkCustomer";
import ConfirmationModal from "components/Modal/confirmation";

interface Props {
  showSkipBtn?: boolean;
}

const RegisterPage = ({ showSkipBtn = true }: Props) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorNama, setErrorNama] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [data, setData] = useState({
    nama: "",
    email: "",
    phoneNo: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const {
    thunkDispatch,
    storeState: { RegisterUser, AppSetting, StoreInfo },
  } = useRedux();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  const handleChange = (event: any) => {
    const numberOnly = /^[0-9\b]+$/;
    if (event.target.name === "phoneNo") {
      if (event.target.value === "" || numberOnly.test(event.target.value)) {
        setData({ ...data, [event.target.name]: event.target.value });
        return;
      }
      return;
    }
    // use spread operator
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddUser = () => {
    let dataObject = {
      full_name: data.nama,
      email: data.email,
      phone: data.phoneNo,
      merchant_id: StoreInfo?.data?.data?.merchant_id ?? "",
    };
    thunkDispatch(registerUser(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          thunkDispatch(registerSetData(dataObject)).unwrap().catch();
          thunkDispatch(removeCustomerData()).unwrap().catch();
          setStatusModal("success");
          setOpenModal(true);
        } else {
          setStatusModal("failed");
          setOpenModal(true);
        }
      })
      .catch((err: any) => {
        setStatusModal("failed");
        setOpenModal(true);
      });
  };

  const setDataRegister = () => {
    thunkDispatch(removeCustomerData())
      .unwrap()
      .then(() => {
        thunkDispatch(removeRegisterdata())
          .unwrap()
          .then(() => {
            navigate("/home");
          })
          .catch();
      });
  };

  const validateEmail = () => {
    const regex =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const regexNumberOnly = /[0-9]/;
    if (
      !data.email ||
      regex.test(data.email) === false ||
      !data.nama ||
      !data.phoneNo ||
      regexNumberOnly.test(data.phoneNo) === false
    ) {
      setError(true);
      setErrorText(
        !data.email
          ? "Wajib diisi"
          : regex.test(data.email) === false
          ? "Format data harus berupa email (contoh: email@sandbox.co.id)"
          : ""
      );
      setErrorPhone(
        !data.phoneNo
          ? "Nomor Telepon Wajib diisi"
          : regexNumberOnly.test(data.phoneNo) === false
          ? "Hanya angka saja"
          : ""
      );
      setErrorNama(!data.nama ? "Nama Wajib diisi" : "");
    } else if (
      data.email.length > 255 ||
      data.nama.length > 50 ||
      !data.phoneNo ||
      regexNumberOnly.test(data.phoneNo) === false
    ) {
      setError(true);
      setErrorText(
        data.email.length > 255 ? "Maksimal karakter adalah 255 Karakter" : ""
      );
      setErrorPhone(
        !data.phoneNo
          ? "Nomor Telepon Wajib diisi"
          : regexNumberOnly.test(data.phoneNo) === false
          ? "Hanya angka saja"
          : ""
      );
      setErrorNama(
        !data.nama
          ? "Nama Wajib diisi"
          : data.nama.length > 50
          ? "Nama tidak boleh melebihi 50 karekter"
          : ""
      );
    } else {
      setError(false);
      setErrorText("");
      setErrorPhone("");
      handleAddUser();
    }
  };

  return (
    <MainLayout
      background={dataApp?.secondary_color ?? "#FFF4F8"}
      favIcon={dataApp?.favicon ?? ""}
      title={dataApp?.merchant_name ?? ""}
    >
      <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(!openModal)}
        handleConfirm={() => {
          setOpenModal(!openModal);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          statusModal === "success" ? navigate("/home") : null;
        }}
        backgroundButton={AppSetting.data.data.primary_color}
        confirmText={"Oke"}
        title={statusModal === "success" ? "Sukses" : "Gagal"}
        description={
          statusModal === "success"
            ? "Sukses register user"
            : RegisterUser?.error?.response?.data?.message
            ? RegisterUser.error.response.data.message
            : "Gagal register user, Mohon check ulang form data anda"
        }
      />
      <Stack direction="row" align="start">
        <div className="container mt-3">
          <img
            src={dataApp?.app_logo ?? LogoGotBeef}
            alt="logo"
            style={{ height: 100, width: 100 }}
            className="logo"
          />
          <h3
            className="font-16 mt-3"
            style={{ color: dataApp?.primary_color ?? "#B92362" }}
          >
            Silahkan isi Form dibawah untuk mendapatkan Point tiap transaksi!
          </h3>
          <div className="form-group mb-3 mt-3">
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Nama *
            </label>
            <input
              type="text"
              name="nama"
              placeholder="Masukan Nama Anda"
              className="form-control w-100"
              value={data.nama}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />

            <NormalText fontSize="12px" color="red">
              {errorNama}
            </NormalText>
          </div>
          <div className={`form-group mb-3 mt-3`}>
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Email *
            </label>
            <input
              type="text"
              name="email"
              placeholder="Masukan Email Anda"
              className={`form-control w-100  ${error ? "error" : ""}`}
              value={data.email}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />
            <NormalText fontSize="12px" color="red">
              {errorText}
            </NormalText>
          </div>
          <div className="form-group mb-3 mt-3">
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Nomor Telepon *
            </label>
            <input
              type="text"
              name="phoneNo"
              placeholder="Masukan Nomor Telepon Anda"
              className="form-control w-100"
              value={data.phoneNo}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />
            <NormalText fontSize="12px" color="red">
              {errorPhone}
            </NormalText>
          </div>

          <button
            className="btn-lanjutkan mt-2"
            onClick={validateEmail}
            style={{
              backgroundColor: dataApp?.primary_color ?? "#B92362",
              color: dataApp?.secondary_color ?? "#000",
            }}
          >
            Lanjutkan
          </button>
          {showSkipBtn ? (
            <button
              className="btn-lewati"
              onClick={() => setDataRegister()}
              style={{
                backgroundColor: dataApp?.secondary_color ?? "#000",
                color: dataApp?.primary_color ?? "#B92362",
                borderColor: dataApp?.primary_color ?? "#B92362",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              Lewati
            </button>
          ) : null}
          <div
            style={{ flexDirection: "row", display: "flex", marginBottom: 20 }}
          >
            <NormalText
              fontSize="14px"
              color={"black"}
              margin={"10px 0px 0px 5px"}
            >
              Sudah jadi member ?
            </NormalText>
            <div onClick={() => navigate("/check-member")}>
              <NormalText
                fontSize="14px"
                color={dataApp?.primary_color ?? "#B92362"}
                margin={"10px 0px 0px 5px"}
                fontWeight="bold"
              >
                Pesan disini
              </NormalText>
            </div>
          </div>
        </div>
      </Stack>
      <Footer>
        <div className="text-center mt-5">
          <img src={LogoPowerdBy} alt="powerdBy" className="powered-image" />
        </div>
      </Footer>
    </MainLayout>
  );
};

export default RegisterPage;
