import { ResponseProps, TableState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { listAllTable } from './table.services';

export const initialState: TableState = {
    data: {} as ResponseProps,
    status: "loading",
    error: {}
}

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listAllTable.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(listAllTable.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(listAllTable.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })

    }
})

export const BannerReducer = bannerSlice.reducer