import { Navigate } from "react-router-dom";
import { StoreInfoState } from "redux/storeInfo";

interface ProtectedStoreRouteProps {
  data: StoreInfoState;
  children: any;
}

const ProtectedStoreRoute = ({ data, children }: ProtectedStoreRouteProps) => {
  if(data && data.data && data.data.data){
    return children
  } 
  return <Navigate to="/404" replace/>
};

export default ProtectedStoreRoute;
