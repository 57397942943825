import { createAsyncThunk } from "@reduxjs/toolkit";
import { QrGroupInfoState } from "./types";
import axios from "axios";

interface QrGroupInfoProps {
  group_code: string;
  limit?: string | number;
  offset?: string | number;
  filter?: string;
}

export const qrGroupInfo = createAsyncThunk(
  "qrGroup/info",
  async ({ group_code, limit, offset, filter }: QrGroupInfoProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/group/${group_code}/detail?limit=${limit ? limit : 0}${
          offset ? `&offset=${offset}` : ""
        }${filter ? `&filter=${filter}` : ""}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as QrGroupInfoState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as QrGroupInfoState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as QrGroupInfoState;
    }
  }
);
