import { Box, Avatar } from "@mui/material";
import SandboxLogo from "assets/images/sandbox.svg";
import { NormalText } from "../../components/styled/text.styled";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  storeLogo?: string;
  appLogo?: string;
  storeName: string;
  color: string;
}

const HeaderBigV1 = ({ storeLogo, appLogo, storeName, color }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0.75rem 0",
        position: "relative",
      }}
    >
      <Avatar
        sx={{
          backgroundColor: color,
          fontSize: "unset",
          width: "25px",
          height: "25px",
          boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
          position: "absolute",
          top: "10px",
          left: "10px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <ArrowBackIosNewIcon
          sx={{ color: "#ffffff", height: "12px", width: "auto" }}
        />
      </Avatar>
      <Avatar
        sx={{
          marginRight: "0.25rem",
          width: "70px",
          height: "70px",
          fontSize: "12px",
        }}
      >
        <img
          src={storeLogo ? storeLogo : appLogo ? appLogo : SandboxLogo}
          alt="logo toko"
          className="w-100"
        />
      </Avatar>
      <NormalText
        fontSize="16px"
        fontWeight="bold"
        margin="0 0 0 10px"
        fontFamily="D-DIN Exp"
      >
        {storeName}
      </NormalText>
    </Box>
  );
};

export default HeaderBigV1;
