import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import HomePage from "./views/Home/home";
import RegisterPage from "./views/Register/register";
import RegisterMemberPage from "./views/Register/registerMember";
import RegisterPesananPage from "./views/Register/registerPesanan";
import RegisterAddressPage from "views/Register/registerAddress";
import ForbiddenPage from "./views/Blocker/forbidden";
import LandingPage from "./views/Landing/main.page";
import BrandDetail from "./views/Brand/detail.page";
import CategoryDetail from "./views/Category/detail.page";
import ProductDetail from "./views/Product/product.detail";
import ProductDetailByQr from "views/Product/product.detail.by.qr";
import CartPage from "views/Cart/cart.page";
import TablePage from "views/Table/table.page";
import CheckoutPage from "views/Checkout/checkout.page";
import CheckoutSuccessPage from "views/Checkout/Information/success";
//order
import OrderListPage from "views/Order/order.list";
import OrderDetailPage from "views/Order/order.detail";
import OrdersCheck from "views/Order/orders.check";
//pickup point
import PickupPointPage from "views/PickupPoint/pickuppoint.page";
//customer
import CustomerPage from "views/Customer/customer.page";
//queue
import QueuePage from "views/Queue/queue.page";
import QueueForbiddenPage from "./views/Queue/forbidden";
import ProfilePage from "./views/Profile";
// loyalty
import CheckLoyaltyPage from "views/Loyalty/check.loyalty";
import DetailLoyaltyPage from "views/Loyalty/detail.loyalty";
import OrderCekInput from "views/Order/order.cek.input";
//QR Group Page
import QrGroupMainPage from "views/Landing/qrgroup.main.page";
import StorePage from "views/Store/store.page";
import QrGroupProductDetail from "views/Product/qrgroup.product.detail";

//Protected Route
import ProtectedStoreRoute from "components/ProtectedRoute/ProtectedRouteStore";
import ProtectedQrGroupRoute from "components/ProtectedRoute/ProtectedRouteQrGroup";
import useRedux from "redux/useRedux";

const App = () => {
  const {
    storeState: { QrGroupInfo, StoreInfo },
  } = useRedux();

  return (
    <Routes>
      <Route path="*" element={<Navigate to={"/404"} replace={false} />} />
      <Route
        path="/"
        element={
          <ProtectedStoreRoute data={StoreInfo}>
            <HomePage />
          </ProtectedStoreRoute>
        }
      />
      <Route path="/:key" element={<HomePage />} />
      <Route path="/:key/:order_number" element={<HomePage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/check-member" element={<RegisterMemberPage />} />
      <Route path="/register-pesanan" element={<RegisterPesananPage />} />
      <Route path="/register-address" element={<RegisterAddressPage />} />
      <Route path="/access-forbidden" element={<ForbiddenPage />} />
      <Route
        path="/home"
        element={
          <ProtectedStoreRoute data={StoreInfo}>
            <LandingPage />
          </ProtectedStoreRoute>
        }
      />
      <Route
        path="/home/:store_key/:store_id/:merchant_id/:product_id/detail"
        element={<LandingPage />}
      />
      <Route path="/qr-group/:qr_group_key" element={<QrGroupMainPage />} />
      <Route
        path="/qr-group"
        element={
          <ProtectedQrGroupRoute data={QrGroupInfo}>
            <QrGroupMainPage />
          </ProtectedQrGroupRoute>
        }
      />
      <Route
        path="/qr-group/store/:storeName"
        element={
          <ProtectedQrGroupRoute data={QrGroupInfo}>
            <StorePage />
          </ProtectedQrGroupRoute>
        }
      />
      <Route
        path="/qr-group/store/:storeName/:productName"
        element={
          <ProtectedQrGroupRoute data={QrGroupInfo}>
            <QrGroupProductDetail />
          </ProtectedQrGroupRoute>
        }
      />
      <Route
        path="/brand/:brandId/detail"
        element={
          <ProtectedStoreRoute data={StoreInfo}>
            <BrandDetail />
          </ProtectedStoreRoute>
        }
      />
      <Route
        path="/product/:productId/detail"
        element={
          <ProtectedStoreRoute data={StoreInfo}>
            <ProductDetail />
          </ProtectedStoreRoute>
        }
      />
      <Route
        path="/:key/:storeId/:merchantId/product/:productId/detail"
        element={<ProductDetailByQr />}
      />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/table" element={<TablePage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/checkout/success" element={<CheckoutSuccessPage />} />
      {/* order */}
      <Route path="/order-list" element={<OrderListPage />} />
      <Route path="/order-detail/:order_number" element={<OrderDetailPage />} />
      <Route path="/order-detail-status" element={<OrderCekInput />} />
      <Route path="/order-check" element={<OrdersCheck />} />
      {/* pickup point */}
      <Route path="/pickup-point" element={<PickupPointPage />} />
      {/* customer */}
      <Route path="/customer-page" element={<CustomerPage />} />
      {/* queue */}
      <Route path="/:storeid/queue" element={<QueuePage />} />
      <Route path="/queue/access-forbidden" element={<QueueForbiddenPage />} />
      <Route path="/profile-customer" element={<ProfilePage />} />
      <Route path="/:storecode/loyalty" element={<CheckLoyaltyPage />} />
      <Route path="/customer/loyalty" element={<DetailLoyaltyPage />} />
      <Route
        path="/category/:categoryLevel/:categoryId/detail"
        element={
          <ProtectedStoreRoute data={StoreInfo}>
            <CategoryDetail />
          </ProtectedStoreRoute>
        }
      />
    </Routes>
  );
};

export default App;
