import React, { ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Modal,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Collapse,
} from "@mui/material";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import styled from "styled-components";
import "./category-filter.css";

// Import Redux
import { BrandsType } from "redux/brand";
import { CategoryInterface } from "redux/category";

// Import Assets
//import ExpandDown from "assets/icons/ic_expand_down.svg";
//import ExpandUp from "assets/icons/ic_expand_up.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ExpandLess from "@mui/icons-material/ExpandLess";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ExpandMore from "@mui/icons-material/ExpandMore";

export const Tab = styled(TabUnstyled)`
  color: #0a2942;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background-color: transparent;
  width: 100%;
  height: 35px;
  padding: 10px 12px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin: 0 -1px;

  &:focus {
    color: #0a2942;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #0a2942;
    font-weight: 700;
  }
`;

interface TabsListBg {
  background?: string;
}

export const TabsList = styled(TabsListUnstyled)<TabsListBg>`
  height: 35px;
  background-color: ${(props) => props.background};
  border-radius: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
`;

interface Props {
  open: boolean;
  //handleClose?: React.MouseEventHandler<HTMLDivElement>;
  handleClose?: any;
  children?: ReactNode;
  background?: string;
  storeInfo?: any;
  brands?: BrandsType[];
  categories?: CategoryInterface[];
  categories_brand?: Array<any>[];
  page?: string;
  onClick?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 320,
  width: 320,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "21px 14px 25px 14px",
  outline: "unset!important",
};

// Icon For Expand Category List
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExpandIcon = styled.img`
  cursor: pointer;
  height: 7.18px;
  width: 12.47px;
`;

const CategoryFillter = ({
  open,
  handleClose,
  children,
  background,
  brands,
  storeInfo,
  categories,
  categories_brand,
  page,
  onClick,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCol, setOpenCol] = React.useState();
  const [openSubCol, setOpenSubCol] = React.useState();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (itemId: any) => {
    if (openCol !== itemId) {
      setOpenCol(itemId);
    } else {
      setOpenCol(undefined);
    }
  };

  const handleClickSubCategory = (itemId: any) => {
    if (openSubCol !== itemId) {
      setOpenSubCol(itemId);
    } else {
      setOpenSubCol(undefined);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      {page === "brand" ? (
        <Box sx={style}>
          <TabsUnstyled defaultValue={0}>
            <TabsList background={background}>
              <Tab>Kategori</Tab>
              <Tab>Brand</Tab>
            </TabsList>

            {/* Category */}
            <TabPanelUnstyled value={0}>
              {/* Category List */}
              <Box className="filter-container">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 320,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {/* {console.log(listCategory)} */}
                  {/* List Test Level 1 & 2*/}
                  {categories_brand && categories_brand.length > 0
                    ? categories_brand.map((item, index) => (
                        <>
                          <ListItem key={"list" + index} sx={{ padding: 0 }}>
                            <ListItemButton
                              sx={{
                                paddingX: 1,
                                borderBottom: "1px solid #f1f1f1",
                              }}
                            >
                              <ListItemText
                                primary={item}
                                onClick={() => {
                                  onClick(index);
                                  handleClose();
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </>
                      ))
                    : null}
                </List>
              </Box>
            </TabPanelUnstyled>

            {/* Brand */}
            <TabPanelUnstyled value={1}>
              <Box className="filter-container">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 320,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {brands &&
                    brands.length &&
                    brands
                      .filter((brand) => brand.is_active === true)
                      .filter((brand) => brand.total_product > 0)
                      .map((item, index) => (
                        <ListItem key={"brand" + index} sx={{ padding: 0 }}>
                          <ListItemButton
                            sx={{
                              paddingX: 1,
                              borderBottom: "1px solid #f1f1f1",
                            }}
                            onClick={() => {
                              handleClose();
                              navigate(`/brand/${item.id}/detail`, {
                                state: {
                                  prev_url: location.pathname,
                                },
                              });
                            }}
                          >
                            <ListItemText primary={item.brand_name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                </List>
              </Box>
            </TabPanelUnstyled>
          </TabsUnstyled>
        </Box>
      ) : (
        <Box sx={style}>
          <TabsUnstyled defaultValue={0}>
            <TabsList background={background}>
              <Tab>Kategori</Tab>
              <Tab>Brand</Tab>
            </TabsList>

            {/* Category */}
            <TabPanelUnstyled value={0}>
              {/* Category List */}
              <Box className="filter-container">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 320,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {/* {console.log(listCategory)} */}
                  {/* List Test Level 1 & 2*/}
                  {storeInfo?.data?.data?.merchant_type === "RETAIL" &&
                  categories &&
                  categories.length > 0
                    ? categories
                        .filter((category) => category.is_active === true)
                        .filter((category) => category.total_product > 0)
                        .map((item, index) => (
                          <>
                            <ListItem key={"list" + index} sx={{ padding: 0 }}>
                              <ListItemButton
                                sx={{
                                  paddingX: 1,
                                  borderBottom: "1px solid #f1f1f1",
                                }}
                              >
                                <ListItemText
                                  primary={item.category_name}
                                  onClick={() => {
                                    navigate(`/category/1/${item.id}/detail`, {
                                      state: {
                                        prev_url: location.pathname,
                                      },
                                    });
                                    handleClose();
                                  }}
                                />
                                {item.subCategory &&
                                item.subCategory.length > 0 &&
                                item.subCategory
                                  ?.filter(
                                    (subCategory) =>
                                      subCategory.is_active === true
                                  )
                                  ?.filter(
                                    (subCategory) =>
                                      subCategory.total_product > 0
                                  ).length > 0 ? (
                                  openCol === item.id ? (
                                    <ExpandLess
                                      onClick={() => handleClick(item.id)}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(item.id)}
                                    />
                                  )
                                ) : null}
                              </ListItemButton>
                            </ListItem>
                            <Collapse
                              in={openCol === item.id}
                              timeout="auto"
                              unmountOnExit
                              key={"collapse" + index}
                            >
                              <List component="div" disablePadding>
                                {item.subCategory &&
                                item.subCategory.filter(
                                  (subCategoryItem) =>
                                    subCategoryItem.is_active === true
                                ).length > 0
                                  ? item.subCategory
                                      .filter(
                                        (subCategoryItem) =>
                                          subCategoryItem.is_active === true
                                      )
                                      .filter(
                                        (subCategoryItem) =>
                                          subCategoryItem.total_product > 0
                                      )
                                      .map((subItem, subIndex) => {
                                        return (
                                          <>
                                            <ListItem
                                              sx={{ py: 0, px: 0 }}
                                              key={"subcategory-" + subItem.id}
                                            >
                                              <ListItemButton
                                                sx={{ pl: 3, pr: 1 }}
                                              >
                                                <ListItemText
                                                  primary={
                                                    subItem.subcategory_name
                                                  }
                                                  onClick={() => {
                                                    navigate(
                                                      `/category/2/${subItem.id}/detail`,
                                                      {
                                                        state: {
                                                          prev_url:
                                                            location.pathname,
                                                        },
                                                      }
                                                    );
                                                    handleClose();
                                                  }}
                                                />

                                                {subItem.subsCategory &&
                                                subItem.subcategory_name
                                                  .length > 0 &&
                                                subItem.subsCategory.filter(
                                                  (i) => i.is_active
                                                ).length > 0 &&
                                                subItem.subsCategory
                                                  .filter((i) => i.is_active)
                                                  .filter(
                                                    (i) => i.total_product > 0
                                                  ).length > 0 ? (
                                                  openSubCol === subItem.id ? (
                                                    <ExpandLess
                                                      onClick={() =>
                                                        handleClickSubCategory(
                                                          subItem.id
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <ExpandMore
                                                      onClick={() =>
                                                        handleClickSubCategory(
                                                          subItem.id
                                                        )
                                                      }
                                                    />
                                                  )
                                                ) : null}
                                              </ListItemButton>
                                            </ListItem>
                                            <Collapse
                                              in={openSubCol === subItem.id}
                                              timeout="auto"
                                              unmountOnExit
                                              key={"collapse" + subIndex}
                                            >
                                              <List
                                                component="div"
                                                disablePadding
                                              >
                                                {subItem.subsCategory &&
                                                subItem.subsCategory.length >
                                                  0 &&
                                                subItem.subsCategory.filter(
                                                  (i) => i.is_active === true
                                                ).length > 0 &&
                                                subItem.subsCategory
                                                  .filter(
                                                    (i) => i.is_active === true
                                                  )
                                                  .filter(
                                                    (i) => i.total_product > 0
                                                  ).length > 0
                                                  ? subItem.subsCategory
                                                      .filter(
                                                        (i) =>
                                                          i.is_active === true
                                                      )
                                                      .filter(
                                                        (i) =>
                                                          i.total_product > 0
                                                      )
                                                      .map(
                                                        (
                                                          subsItem,
                                                          subsIndex
                                                        ) => {
                                                          return (
                                                            <>
                                                              <ListItem
                                                                sx={{
                                                                  py: 0,
                                                                  px: 0,
                                                                }}
                                                                key={
                                                                  "subscategory-" +
                                                                  subsItem.id
                                                                }
                                                              >
                                                                <ListItemButton
                                                                  sx={{ pl: 4 }}
                                                                  onClick={() => {
                                                                    navigate(
                                                                      `/category/3/${subsItem.id}/detail`,
                                                                      {
                                                                        state: {
                                                                          prev_url:
                                                                            location.pathname,
                                                                        },
                                                                      }
                                                                    );
                                                                    handleClose();
                                                                  }}
                                                                >
                                                                  <ListItemText
                                                                    primary={
                                                                      subsItem.subscategory_name
                                                                    }
                                                                  />
                                                                </ListItemButton>
                                                              </ListItem>
                                                            </>
                                                          );
                                                        }
                                                      )
                                                  : null}
                                              </List>
                                            </Collapse>
                                          </>
                                        );
                                      })
                                  : null}
                              </List>
                            </Collapse>
                          </>
                        ))
                    : null}

                  {/* List Test Level 3 */}
                  {storeInfo?.data?.data?.merchant_type === "FOOD" &&
                  categories &&
                  categories.length
                    ? categories
                        .filter((category) => category.is_active === true)
                        .map((subCat) =>
                          subCat.subCategory
                            ?.filter(
                              (subActive) => subActive.is_active === true
                            )
                            .map((subsCat) =>
                              subsCat.subsCategory
                                ?.filter(
                                  (subsActive) => subsActive.is_active === true
                                )
                                .filter(
                                  (subsActive) => subsActive.total_product > 0
                                )
                                .map((subsCategoryItem) => {
                                  return (
                                    <ListItem
                                      sx={{ padding: 0 }}
                                      key={"subsCategory" + subsCategoryItem.id}
                                      onClick={() => {
                                        navigate(
                                          `/category/3/${subsCategoryItem.id}/detail`,
                                          {
                                            state: {
                                              prev_url: location.pathname,
                                            },
                                          }
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <ListItemButton
                                        sx={{
                                          paddingX: 1,
                                          borderBottom: "1px solid #f1f1f1",
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            subsCategoryItem.subscategory_name
                                          }
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })
                            )
                        )
                    : null}
                </List>
              </Box>
            </TabPanelUnstyled>

            {/* Brand */}
            <TabPanelUnstyled value={1}>
              <Box className="filter-container">
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 320,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {brands &&
                    brands.length &&
                    brands
                      .filter((brand) => brand.is_active === true)
                      .filter((brand) => brand.total_product > 0)
                      .map((item, index) => (
                        <ListItem key={"brand" + index} sx={{ padding: 0 }}>
                          <ListItemButton
                            sx={{
                              paddingX: 1,
                              borderBottom: "1px solid #f1f1f1",
                            }}
                            onClick={() =>
                              navigate(`/brand/${item.id}/detail`, {
                                state: {
                                  prev_url: location.pathname,
                                },
                              })
                            }
                          >
                            <ListItemText primary={item.brand_name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                </List>
              </Box>
            </TabPanelUnstyled>
          </TabsUnstyled>
        </Box>
      )}
    </Modal>
  );
};

export default CategoryFillter;
