import { AppSettingState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getAppSetting } from './appsetting.services';
import SandboxImage from 'assets/images/sandbox.svg'

export const initialState: AppSettingState = {
    data: {
        data: {
            primary_color: '#FFC535',
            merchant_name: 'Sandbox',
            secondary_color: '#ffffff',
            app_logo: SandboxImage,
            favicon: '',
            id: -1,
            is_deleted: false,
            isDeleted: false,
            merchant_id: -1,
            threshold: 0
        },
        message: '',
        meta: {
            limit: -1,
            offset: - 1,
            total: 0
        }
    },
    status: "loading",
    error: {}
}

export const appSettingSlice = createSlice({
    name: 'appSetting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAppSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getAppSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getAppSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })

    }
})

export const AppSettingReducer = appSettingSlice.reducer