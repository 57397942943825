import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainLayout from "components/Layouts/MainLayout";
import { QrGroupProductType } from "redux/product";

import { NormalText } from "../../components/styled/text.styled";
import styled from "styled-components";
import { Box, Stack, IconButton } from "@mui/material";

import Checkbox from "components/Forms/Checkbox";
import InputField from "components/Forms/InputField";

import useRedux from "../../../src/redux/useRedux";
import { AppSettingData } from "redux/appsetting";
import { detailProduct, ProductsDetail, addOnProduct } from "redux/product";
import { ChekoutButton } from "components/BottomSheet";
import CounterButton from "components/Button/counter";

import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addToCart,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ProductsTypes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ProductAdditional,
} from "redux/cart/cart.reducer";
import history from "helper/history";
import { groupBy, formatCurrency } from "helper/general";
import NoPhoto from "assets/images/NoPhotoDetail.svg";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Splide as SplideTypes,
  Components as ComponentsTypes,
} from "@splidejs/splide";
import "@splidejs/react-splide/css";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export function SlideNumber(Splide: SplideTypes, Components: ComponentsTypes) {
  const { track } = Components.Elements;

  let elm: any;

  let elmOptions = {
    textAlign: "left",
    position: "absolute",
    bottom: "0",
    left: "1rem",
    padding: "0.2rem 0.5rem",
    borderRadius: "3px",
    background: "rgba(0,0,0,0.7)",
    color: "#ffffff",
    fontSize: "0.9rem",
    fontWeight: "800",
  };

  function mount() {
    elm = document.createElement("div");
    const {
      textAlign,
      position,
      padding,
      borderRadius,
      bottom,
      background,
      left,
      color,
      fontSize,
      fontWeight,
    } = elmOptions;
    elm.style.textAlign = textAlign;
    elm.style.position = position;
    elm.style.bottom = bottom;
    elm.style.left = left;
    elm.style.padding = padding;
    elm.style.background = background;
    elm.style.color = color;
    elm.style.borderRadius = borderRadius;
    elm.style.fontSize = fontSize;
    elm.style.fontWeight = fontWeight;

    track?.parentElement?.insertBefore(elm, track.nextSibling);

    update();
    Splide.on("move", update);
  }

  function update() {
    elm.textContent = `${Splide.index + 1}/${Splide.length}`;
  }

  return {
    mount,
  };
}

const slideProperties = {
  perPage: 1,
  start: 0,
  arrows: false,
  isNavigation: false,
  pagination: false,
};

interface StateProps {
  productDetail: QrGroupProductType;
  prevUrl: string;
}

const QrGroupProductDetail = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const [loading, setLoading] = useState(false);

  const [addons, setAddons] = useState<any[]>([]);
  const [keyAddOn, setKeyAddons] = useState<any>([]);
  const [product, setProduct] = useState<ProductsDetail>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notes, setNotes] = useState("");
  const [qty, setQty] = useState(1);

  const {
    thunkDispatch,
    storeState: { AppSetting },
  } = useRedux();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  const getAddOn = () => {
    if (state && state.productDetail) {
      let data = {
        product_id: state.productDetail.product_id,
        merchant_id: state.productDetail.merchant_id,
        store_id: state.productDetail.productStore?.store_id
          ? state.productDetail.productStore.store_id
          : state.productDetail.store_id,
      };
      setLoading(true);
      thunkDispatch(addOnProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            if (
              res.data.data &&
              res.data.data.productAddOn &&
              res.data.data.productAddOn.length !== 0
            ) {
              let resTemp = groupBy(res.data.data.productAddOn, "product_type");
              setAddons(resTemp);
              setKeyAddons(Object.keys(resTemp));
            }
          }
        });
    }
  };

  useEffect(() => {
    if (state && state.productDetail) {
      let data = {
        product_id: state.productDetail.product_id,
        merchant_id: state.productDetail.merchant_id,
        store_id: state.productDetail.productStore?.store_id
          ? state.productDetail.productStore.store_id
          : state.productDetail.store_id,
      };
      setLoading(true);
      thunkDispatch(detailProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            // console.log(res.data.data);
            setLoading(false);
            setProduct(res.data.data);
            if (res.data.data?.product_detail?.is_customizable) {
              getAddOn();
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const productToCart = (props: ProductsTypes) => {
  //   if (keyAddOn && keyAddOn.length > 0) {
  //     let adds: ProductAdditional[] = [];
  //     keyAddOn.forEach((key: any) => {
  //       // eslint-disable-next-line
  //       addons[key].map((item: any) => {
  //         if (item.value) {
  //           adds.push({
  //             id: item.product_assign_id,
  //             name: item.product_name,
  //             qty: 1,
  //             price:
  //               Number(item.product_price_after_discount) > 0
  //                 ? item.product_price_after_discount
  //                 : item.product_price,
  //             product_id: item.product_id,
  //           } as ProductAdditional);
  //         }
  //       });
  //     });
  //     props.additionals = adds;
  //   }
  //   thunkDispatch(addToCart(props)).then(() => {
  //     history.back();
  //   });
  // };

  // useEffect(()=>{},[])

  return (
    <MainLayout
      background={"white"}
      favIcon={dataApp?.favicon ?? ""}
      title={"Detail Produk | " + dataApp?.merchant_name}
    >
      <HeaderContainer>
        <IconButton
          sx={{
            background: "#888888",
            color: "#ffffff",
            position: "absolute",
            top: "11px",
            left: "8px",
            zIndex: 100,
          }}
          size="small"
          onClick={() => {
            history.back();
          }}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Splide
          extensions={{ SlideNumber }}
          options={slideProperties}
          tag="section"
          aria-label="Product Images"
          className="w-100"
        >
          <SplideSlide
            key="splide-slide-1"
            className="d-flex justify-content-center"
          >
            <img
              src={
                product?.product_detail?.product_image_main &&
                product?.product_detail?.product_image_main.length > 0
                  ? product?.product_detail?.product_image_main
                  : NoPhoto
              }
              alt="gambar 1"
              style={{ width: "auto", height: "221px", objectFit: "contain" }}
              data-splide-lazy="path-to-the-image"
            />
          </SplideSlide>
          <SplideSlide
            key="splide-slide-2"
            className="d-flex justify-content-center"
          >
            <img
              src={
                product?.product_detail?.product_image_1 &&
                product?.product_detail?.product_image_1.length > 0
                  ? product?.product_detail?.product_image_1
                  : NoPhoto
              }
              alt="gambar 2"
              style={{ width: "auto", height: "221px", objectFit: "contain" }}
              data-splide-lazy="path-to-the-image"
            />
          </SplideSlide>
          <SplideSlide
            key="splide-slide-3"
            className="d-flex justify-content-center"
          >
            <img
              src={
                product?.product_detail?.product_image_2 &&
                product?.product_detail?.product_image_2.length > 0
                  ? product?.product_detail?.product_image_2
                  : NoPhoto
              }
              alt="gambar 3"
              style={{ width: "auto", height: "221px", objectFit: "contain" }}
              data-splide-lazy="path-to-the-image"
            />
          </SplideSlide>
          <SplideSlide
            key="splide-slide-4"
            className="d-flex justify-content-center"
          >
            <img
              src={
                product?.product_detail?.product_image_3 &&
                product?.product_detail?.product_image_3.length > 0
                  ? product?.product_detail?.product_image_3
                  : NoPhoto
              }
              alt="gambar 4"
              style={{ width: "auto", height: "221px", objectFit: "contain" }}
              data-splide-lazy="path-to-the-image"
            />
          </SplideSlide>
          <SplideSlide
            key="splide-slide-5"
            className="d-flex justify-content-center"
          >
            <img
              src={
                product?.product_detail?.product_image_4 &&
                product?.product_detail?.product_image_4.length > 0
                  ? product?.product_detail?.product_image_4
                  : NoPhoto
              }
              alt="gambar 5"
              style={{ width: "auto", height: "221px", objectFit: "contain" }}
              data-splide-lazy="path-to-the-image"
            />
          </SplideSlide>
        </Splide>
      </HeaderContainer>
      <Box padding={"10px 20px 10px 20px"}>
        <NormalText fontSize={"14px"} fontFamily="Open Sans" fontWeight="bold">
          {product?.product_name ?? ""}
        </NormalText>
        <NormalText fontSize={"12px"} fontFamily="Open Sans">
          {product?.product_description?.short_description ?? ""}
        </NormalText>
        {product &&
          product.product_detail &&
          product.product_detail.is_customizable &&
          keyAddOn &&
          keyAddOn.length !== 0 && (
            <div>
              <NormalText
                fontSize={"22px"}
                fontFamily="Open Sans"
                fontWeight="bold"
              >
                {formatCurrency(
                  Number(
                    product?.product_detail?.product_price_after_discount
                  ) > 0
                    ? Number(
                        product?.product_detail?.product_price_after_discount
                      )
                    : Number(product?.product_detail?.product_price)
                )}
              </NormalText>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {product &&
                product.product_detail &&
                product.product_detail.discount_type === "percent" ? (
                  <BoxDiscount>
                    <NormalText
                      fontSize={"10px"}
                      fontFamily="Open Sans"
                      fontWeight="bold"
                      color="#B92362"
                    >
                      {product?.product_detail?.discount ?? ""}%
                    </NormalText>
                  </BoxDiscount>
                ) : null}
                {product &&
                  product.product_detail &&
                  product.product_detail.product_price && (
                    <NormalText
                      fontSize={"12px"}
                      fontFamily="Open Sans"
                      fontWeight="bold"
                      color="#A1A6AD"
                      textDecoration="line-through"
                    >
                      {formatCurrency(
                        Number(product?.product_detail?.product_price ?? 0)
                      )}
                    </NormalText>
                  )}
              </div>
            </div>
          )}
      </Box>
      <Box margin={"10px 0 50px 0"}>
        {product &&
          product.product_detail &&
          product.product_detail.is_customizable &&
          keyAddOn &&
          keyAddOn.length !== 0 &&
          keyAddOn.map((x: any, index: number) => (
            <Box key={index + "-content"}>
              <CategoryLabel
                style={{
                  backgroundColor: dataApp?.primary_color ?? "#B92362",
                }}
              >
                <NormalText
                  fontSize={"12px"}
                  color={dataApp?.secondary_color ?? "#000"}
                  fontFamily="D-DIN Exp"
                  fontWeight="bold"
                  margin="0 0 0 24px"
                >
                  {x}
                </NormalText>
              </CategoryLabel>
              <Box margin={"10px 20px"}>
                <Stack direction={"column"} spacing={2}>
                  {addons[x] &&
                    addons[x].length &&
                    addons[x].map((item: any, i: number) => (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Checkbox
                          background={dataApp?.primary_color ?? "#B92362"}
                          key={i}
                          checked={item.value}
                          onClick={() => {
                            let temp = [...addons[x]];
                            temp[i].value = !temp[i].value;
                            setAddons({
                              ...addons,
                              [x]: temp,
                            });
                          }}
                          onChange={(e) => console.log(e)}
                          label={item.product_name}
                        />
                        <NormalText
                          fontSize={"14px"}
                          color={"#010101"}
                          fontFamily="D-DIN Exp"
                          margin="0 0 0 24px"
                        >
                          {formatCurrency(
                            Number(item.product_price_after_discount) > 0
                              ? Number(item.product_price_after_discount)
                              : Number(item.product_price)
                          ) ?? ""}
                        </NormalText>
                      </div>
                    ))}
                </Stack>
              </Box>
            </Box>
          ))}
        <CategoryLabel
          style={{
            backgroundColor: dataApp?.primary_color ?? "#B92362",
          }}
        >
          <NormalText
            fontSize={"12px"}
            color={dataApp?.secondary_color ?? "#FFF4F8"}
            fontFamily="D-DIN Exp"
            fontWeight="bold"
            margin="0 0 0 24px"
          >
            Notes
          </NormalText>
        </CategoryLabel>
        <Box margin={"10px 20px"}>
          <InputField
            placeholder={"Notes"}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Box>
      </Box>
      <br />
      {!loading && (
        <>
          <FooterContainer
            display={"flex"}
            sx={{
              width: "100%",
              placeContent: "center",
              top: "85%",
              position: "fixed",
            }}
          >
            <CounterButton
              value={qty}
              background={dataApp?.primary_color ?? "black"}
              onIncrease={() => setQty(qty + 1)}
              onDecrease={() => {
                if (qty === 1) {
                  setQty(1);
                } else {
                  setQty(qty - 1);
                }
              }}
            />
          </FooterContainer>
          <FooterContainer
            sx={{
              padding: "20px",
              bottom: 0,
              display: "flex",
              width: "100%",
              position: "fixed",
            }}
          >
            <ChekoutButton
              background={dataApp?.primary_color ?? "black"}
              onClick={() => {
                if (product) {
                  // productToCart({
                  //   is_customizable: product.product_detail.is_customizable,
                  //   product_id: product.product_id,
                  //   qty: qty,
                  //   product_name: product.product_name,
                  //   product_image: "",
                  //   notes: notes,
                  //   brand_id: product.brand.id ?? "",
                  //   brand_name: product.brand.brand_name ?? "",
                  //   additionals: [],
                  //   price:
                  //     Number(
                  //       product.product_detail.product_price_after_discount
                  //     ) > 0
                  //       ? product.product_detail.product_price_after_discount
                  //       : product.product_detail.product_price,
                  // });
                }
              }}
            >
              Tambah Ke Keranjang
            </ChekoutButton>
          </FooterContainer>
        </>
      )}
    </MainLayout>
  );
};

interface HeaderProps {
  url?: string;
}

const FooterContainer = styled(Box)`
  @media only screen and (min-width: 768px) {
    max-width: 360px;
  }

  @media only screen and (max-width: 320px) {
    position: relative !important;
  }
`;

const HeaderContainer = styled.div<HeaderProps>`
  height: 221px;
  width: 100%;
  top: 0;
  position: relative;
  background: ${(props) => "url(" + props.url + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 0px;
`;

const CategoryLabel = styled.div`
  width: 100%;
  height: 35px;
  align-items: center;
  display: flex;
  background: #fff4f8;
`;

const BoxDiscount = styled.div`
  width: 26px;
  height: 18px;
  color: #b92362;
  justify-content: center;
  background-color: #fff4f8;
  padding: 2px;
  margin-right: 5px;
`;

export default QrGroupProductDetail;
