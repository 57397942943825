import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderState, OrderDetailState } from './types';
import axios from 'axios';

interface getList {
  limit?: number;
  offset?: number;
  customer_id?: any;
  store_id?: any;
}

export const orderList = createAsyncThunk(
  'order/list',
  async (props: getList) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${props.customer_id}/order-customer/${props.store_id}/store?offset=${props.offset}&limit=${props.limit}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as OrderState;
    }
  },
);

export interface getDetail {
  order_number?: any;
}

export const orderDetail = createAsyncThunk(
  'order/detail',
  async (props: getDetail) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/status?order_number=${props.order_number}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as OrderDetailState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as OrderDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as OrderDetailState;
    }
  },
);

interface list {
  limit?: number;
  offset?: number;
  customer_id?: any;
  store_id?: any;
  filter?: any;
  status_order?: string;
}

export const showList = createAsyncThunk(
  'order/list',
  async (props: list) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/order/history?store_id=${props.store_id}&status_order=${props.status_order}&offset=${props.offset}&limit=${props.limit}${props.filter ? `&filter=${props.filter}`:''}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as OrderState;
    }
  },
);
