import Skeleton from "@mui/material/Skeleton";
import { Box, Grid, Stack } from "@mui/material";

const HomePageSkeleton = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0.75rem 0",
          position: "relative",
        }}
      >
        <Skeleton
          sx={{
            fontSize: "unset",
            width: "25px",
            height: "25px",
            boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer",
          }}
          variant="circular"
        ></Skeleton>
        <Skeleton
          sx={{
            marginRight: "0.25rem",
            width: "70px",
            height: "70px",
            fontSize: "12px",
          }}
          variant="circular"
        ></Skeleton>

        <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
      </Box>

      {/* search bar */}
      <Box
        sx={{
          background: "#ffffff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        width={"100%"}
        padding={"0 10px 20.5px"}
        margin={"0 0 0 0"}
      >
        <Skeleton
          sx={{
            width: "100%",
            height: "35px",
          }}
          variant="rectangular"
        ></Skeleton>
      </Box>

      {/* banner */}
      <Skeleton
        sx={{
          width: "100%",
          height: "120px",
        }}
        variant="rectangular"
      ></Skeleton>

      {/* category */}
      <Box
        sx={{
          padding: "20px 10px 0 10px",
          width: "100%",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        </Stack>
        <Grid
          container
          sx={{
            margin: "10px 0 0 0",
            width: "100%",
          }}
          rowSpacing={1}
        >
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{
                width: "75px",
                height: "75px",
                margin: "0 auto",
              }}
              variant="rectangular"
            ></Skeleton>
          </Grid>
        </Grid>
      </Box>

      {/* new products */}
      <Box
        sx={{
          padding: "20px 10px 0 10px",
          width: "100%",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
        </Stack>
      </Box>

      <Grid
        container
        sx={{
          margin: "10px 0 0 0",
          width: "100%",
        }}
        spacing={2}
      >
        <Grid item xs={6}>
          <Skeleton
            sx={{
              width: "100%",
              height: "120px",
              margin: "0 auto",
            }}
            variant="rectangular"
          ></Skeleton>
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            sx={{
              width: "100%",
              height: "120px",
              margin: "0 auto",
            }}
            variant="rectangular"
          ></Skeleton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePageSkeleton;
