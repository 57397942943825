import React, { useState } from "react";
import { AppContainer } from "../../components/styled/layout.styled";
import { NormalText } from "../../components/styled/text.styled";
import { Stack } from "../../components/styled/layout.styled";
import MainLayout from "components/Layouts/MainLayout";
import Footer from "components/Layouts/Footer";
import LogoPowerdBy from "../../assets/logo/sandbox-powered-logo.svg";
import "./styles.css";
import useRedux from "../../redux/useRedux";
import { useNavigate } from "react-router-dom";
import { AppSettingData } from "redux/appsetting";
import { ReactComponent as IcLoyalty } from "../../assets/images/icLoyalty.svg";
import { ReactComponent as IcProfile } from "../../assets/images/icProfile.svg";
import { ReactComponent as IcBag } from "../../assets/images/icBag.svg";
import KembaliIcon from "../../assets/icons/kembali_ic.svg";
import { ReactComponent as KeluarIcon } from "../../assets/icons/icon-keluar.svg";
import { removeCustomerData } from "redux/checkCustomer";
import { removeRegisterdata } from "redux/userRegister";

const CustomerPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { AppSetting },
  } = useRedux();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  return (
    <MainLayout
      background={"white"}
      favIcon={dataApp?.favicon ?? ""}
      title={dataApp?.merchant_name ?? ""}
    >
      <AppContainer>
        <Stack direction="column">
          <div
            className="container"
            style={{
              width: "100%",
              height: "158px",
              backgroundColor: dataApp?.primary_color ?? "#B92362",
            }}
          >
            <div
              onClick={() => navigate("/register-pesanan")}
              style={{ flexDirection: "row", display: "flex" }}
            >
              <img
                src={KembaliIcon}
                alt={"Sandbox Logo"}
                style={{ marginTop: "15px", width: "14px", height: "14px" }}
              />
              <NormalText fontSize="16px" color="white" margin="10px 0 0 5px">
                Kembali
              </NormalText>
            </div>
            <NormalText
              fontSize="18px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
              color={dataApp?.secondary_color ?? "#000"}
              margin={"25px 0px 0px 0px"}
            >
              Hi..
            </NormalText>
            <NormalText
              fontSize="18px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
              color={dataApp?.secondary_color ?? "#000"}
              margin={"0px 0px 20px 0px"}
            >
              Selamat datang di {dataApp?.merchant_name ?? ""}
            </NormalText>
          </div>
          <div
            style={{
              width: "90%",
              marginTop: "120px",
              marginLeft: "22px",
              flexDirection: "column",
            }}
          >
            <button
              className="btn-lanjutkan mt-2"
              style={{
                backgroundColor: dataApp?.primary_color ?? "#B92362",
                color: dataApp?.secondary_color ?? "#000",
              }}
              onClick={() => navigate("/order-list")}
            >
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <IcBag
                  fill={dataApp?.secondary_color ?? "#000"}
                  style={{
                    height: 25,
                    width: 25,
                    marginRight: "30px",
                  }}
                />
                <div
                  style={{
                    width: "0px",
                    height: "30px",
                    borderWidth: "1px",
                    borderColor: dataApp?.secondary_color ?? "#000",
                    borderStyle: "solid",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
                Cek Pesanan
              </div>
            </button>
            <button
              className="btn-lanjutkan mt-2"
              style={{
                backgroundColor: dataApp?.primary_color ?? "#B92362",
                color: dataApp?.secondary_color ?? "#000",
              }}
              onClick={() => navigate("/customer/loyalty")}
            >
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <IcLoyalty
                  fill={dataApp?.secondary_color ?? "#000"}
                  style={{
                    height: 25,
                    width: 25,
                    marginRight: "30px",
                  }}
                />
                <div
                  style={{
                    width: "0px",
                    height: "30px",
                    borderWidth: "1px",
                    borderColor: dataApp?.secondary_color ?? "#000",
                    borderStyle: "solid",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
                Cek Loyalty Point
              </div>
            </button>
            <button
              className="btn-lanjutkan mt-2"
              style={{
                backgroundColor: dataApp?.primary_color ?? "#B92362",
                color: dataApp?.secondary_color ?? "#000",
              }}
              onClick={() => navigate("/profile-customer")}
            >
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <IcProfile
                  fill={dataApp?.secondary_color ?? "#000"}
                  style={{
                    height: 25,
                    width: 25,
                    marginRight: "30px",
                  }}
                />
                <div
                  style={{
                    width: "0px",
                    height: "30px",
                    borderWidth: "1px",
                    borderColor: dataApp?.secondary_color ?? "#000",
                    borderStyle: "solid",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
                Profil
              </div>
            </button>
            <button
              className="btn-lanjutkan mt-2"
              style={{
                backgroundColor: dataApp?.primary_color ?? "#B92362",
                color: dataApp?.secondary_color ?? "#000",
              }}
              onClick={() => {
                thunkDispatch(removeCustomerData()).unwrap().then(() => {
                  thunkDispatch(removeRegisterdata()).unwrap().then(() => {
                    navigate("/register-pesanan")
                  }).catch()
                }).catch()
              }}
            >
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <KeluarIcon
                  fill={dataApp?.secondary_color ?? "#000"}
                  style={{
                    height: 25,
                    width: 25,
                    marginRight: "30px",
                  }}
                />
                <div
                  style={{
                    width: "0px",
                    height: "30px",
                    borderWidth: "1px",
                    borderColor: dataApp?.secondary_color ?? "#000",
                    borderStyle: "solid",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                />
                Keluar
              </div>
            </button>
          </div>
        </Stack>
      </AppContainer>
      <Stack direction="row" align="center">
        <Footer>
          <div className="text-center mt-5">
            <img src={LogoPowerdBy} alt="powerdBy" />
          </div>
        </Footer>
      </Stack>
    </MainLayout>
  );
};

export default CustomerPage;
