import { Stack, Typography } from "@mui/material";
import { ProductType } from "redux/product";
import ProductListItem from "components/List/product.list.item";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreQrGroupInfoTypes } from "redux/storeInfo";

interface StoreProductSectionProps {
  products?: ProductType[];
  className?: string;
  getDetailProduct?: any;
  storeData?: StoreQrGroupInfoTypes;
}

const StoreProductSection = ({
  className,
  products,
  getDetailProduct,
  storeData,
}: StoreProductSectionProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Stack className={className} direction={"column"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="ps-3 pe-2"
        width={"100%"}
      >
        <Typography
          variant="h6"
          sx={{
            textTransform: "none !important",
            fontFamily: "D-DIN Exp, sans-serif !important",
            fontSize: "0.8rem !important",
            fontWeight: "bold !important",
            letterSpacing: "0.02rem",
          }}
        >
          Semua Produk
        </Typography>
      </Stack>
      <Stack direction={"row"} className="scroll-box mt-3">
        {products && products.length > 0
          ? products.map((product, index) => {
              return (
                <ProductListItem
                  image={product.product_detail.product_image_main}
                  name={product.product_name}
                  key={index + "-product"}
                  discount={product.product_detail.discount}
                  discount_type={product.product_detail.discount_type}
                  price={product.product_detail.product_price}
                  price_after_discount={
                    product.product_detail.product_price_after_discount
                  }
                  type="row"
                  onClick={() => {
                    product.product_detail.is_customizable
                      ? navigate(
                          `/qr-group/store/${storeData?.store_name
                            ?.toLowerCase()
                            .replace(/ /g, "")}/${product?.product_name
                            ?.toLowerCase()
                            .replace(/ /g, "-")}`,
                          {
                            state: {
                              prevUrl: location.pathname,
                              productDetail: {
                                ...product,
                                store_id: storeData?.store_id,
                              },
                            },
                          }
                        )
                      : getDetailProduct({
                          productId: product.product_id,
                          merchantId: product.merchant_id,
                          storeId: storeData?.store_id,
                        });
                  }}
                />
              );
            })
          : null}
      </Stack>
    </Stack>
  );
};

export default StoreProductSection;
