import React, { useState } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import styled from "styled-components";
import { Box, Stack, Grid } from "@mui/material";
import useRedux from "../../redux/useRedux";
import { AppSettingData } from "redux/appsetting";
import { formatCurrency } from "helper/general";
import ImageLogo from "../../assets/images/GarpuSendok.svg";
import { orderList, OrderTypes } from "redux/order";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component"; //eslint-disable-line
import KembaliIcon from "../../assets/icons/kembali_ic_black.svg";

const ProductDetail = () => {
  const [data, setData] = React.useState<OrderTypes[]>([]);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting, CheckCustomer },
  } = useRedux();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  const fetchMoreData = () => {
    setTimeout(() => {
      setLimit(limit + 10);
    }, 1500);
  };

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.id
    ) {
      let data = {
        customer_id: CheckCustomer?.data?.data?.member_id ?? "",
        limit: limit,
        offset: 0,
        store_id: StoreInfo.data.data.id,
      };
      thunkDispatch(orderList(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            console.log(res.data.data);
            setData(res.data.data);
          }
        });
    }
  }, [thunkDispatch, StoreInfo, limit]); //eslint-disable-line

  return (
    <MainLayout
      background={"white"}
      favIcon={dataApp?.favicon ?? ""}
      title={"Detail Produk | " + dataApp?.merchant_name}
    >
      <Stack direction={"column"} alignItems={"center"}>
        <Box
          padding={"10px 20px 10px 20px"}
          style={{
            backgroundColor: "white",
            marginBottom: "20px",
            width: "100%",
            height: "60px",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              textAlign: "center",
            }}
          >
            <div onClick={() => navigate("/customer-page")}>
              <img
                src={KembaliIcon}
                alt={"Sandbox Logo"}
                style={{ marginTop: "15px", width: "14px", height: "14px" }}
              />
            </div>
            <NormalText
              fontSize={"14px"}
              fontFamily="Open Sans"
              fontWeight="bold"
              margin="10px 0 0 80px"
            >
              Daftar Transaksi
            </NormalText>
          </div>
        </Box>
        <Navigation>
          {data && data.length !== 0 ? (
            <InfiniteScroll
              dataLength={limit}
              next={() => fetchMoreData()}
              hasMore={true}
              loader={
                <NormalText
                  textAlign={"center"}
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 40px 0"}
                >
                  Load More...
                </NormalText>
              }
            >
              {data.map((item, i) => (
                <Box
                  padding={"10px 20px 10px 20px"}
                  style={{
                    backgroundColor: "white",
                    width: "95%",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    onClick={() =>
                      navigate(`/order-detail/${item.order_number}`)
                    }
                  >
                    <Grid container direction={"row"}>
                      <Grid item sm={2}>
                        <img
                          src={ImageLogo}
                          alt="lgo"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <NormalText
                          fontSize={"14px"}
                          fontFamily="Open Sans"
                          fontWeight="bold"
                        >
                          {item.order_number ?? ""}
                        </NormalText>
                        <NormalText fontSize={"14px"} fontFamily="Open Sans">
                          {item.store_name ?? ""}
                        </NormalText>
                      </Grid>
                      <Grid item sm={4}>
                        <NormalText
                          fontSize={"12px"}
                          fontFamily="Open Sans"
                          textAlign="end"
                          color="#ccc"
                        >
                          {item.order_date ?? ""}
                        </NormalText>
                        <NormalText
                          fontSize={"13px"}
                          fontFamily="Open Sans"
                          textAlign="end"
                        >
                          {item.total_price
                            ? formatCurrency(Number(item.total_price))
                            : ""}
                        </NormalText>
                        <NormalText
                          fontSize={"12px"}
                          fontFamily="Open Sans"
                          textAlign="end"
                          color="#81D678"
                        >
                          {item && item.order_status
                            ? item.order_status.replace("_", " ")
                            : ""}
                        </NormalText>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              ))}
            </InfiniteScroll>
          ) : null}
        </Navigation>
      </Stack>
    </MainLayout>
  );
};

const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  height: 100%;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-x: auto;
  @media only screen and (max-width: 768px) {
    width: 360px;
  }
`;

export default ProductDetail;
