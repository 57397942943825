import { useState } from 'react';
import { Stack } from '../../components/styled/layout.styled';
import MainLayout from 'components/Layouts/MainLayout';
import Footer from 'components/Layouts/Footer';
import LogoGotBeef from '../../assets/logo/ic-got-beef.svg';
import LogoPowerdBy from '../../assets/logo/sandbox-powered-logo.svg';
import './styles.css';
import { NormalText } from 'components/styled/text.styled';
import { AppSettingData } from 'redux/appsetting';
import useRedux from '../../redux/useRedux';
import { useNavigate } from 'react-router-dom';
import { checkCustomer } from 'redux/checkCustomer';
import { removeRegisterdata } from 'redux/userRegister';
import ConfirmationModal from 'components/Modal/confirmation';

const RegisterMemberPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [data, setData] = useState('');
  const {
    thunkDispatch,
    storeState: { AppSetting, CheckCustomer, StoreInfo },
  } = useRedux();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null,
  );

  const handleChange = (event: any) => {
    // use spread operator
    setData(event.target.value);
  };

  const validateEmail = () => {
    /* eslint-disable */
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!data || regex.test(data) === false) {
      setError(true);
      setErrorText(
        !data
          ? 'Wajib diisi'
          : 'Format data harus berupa email (contoh: email@sandbox.co.id)',
      );
    } else if (data.length > 255) {
      setError(data.length > 255 ? true : false);
      setErrorText(
        data.length > 255 ? 'Maksimal karakter adalah 255 Karakter' : '',
      );
    } else {
      handleAddUser();
    }
  };

  const handleAddUser = () => {
    let dataObject = {
      email: data,
      merchant_id: StoreInfo?.data?.data?.id ?? '',
    };
    thunkDispatch(checkCustomer(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          thunkDispatch(removeRegisterdata()).unwrap().catch()
          setStatusModal('success');
          setOpenModal(true);
        } else {
          setStatusModal('failed');
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <MainLayout
      background={dataApp?.secondary_color ?? '#FFF4F8'}
      favIcon={dataApp?.favicon ?? ''}
      title={dataApp?.merchant_name ?? ''}
    >
      <ConfirmationModal backgroundButton={AppSetting?.data?.data?.primary_color} hasCancel={false} cancelText={'Okay'} open={openModal} handleConfirm={() => {
        setOpenModal(!openModal);
        statusModal === 'success' ? navigate('/home') : null;
      }} handleClose={() => setOpenModal(!openModal)} title={statusModal === 'success' ? 'Sukses' : 'Gagal'} description={statusModal === 'success' ? 'Sukses User terdaftar' : CheckCustomer?.error?.response?.data?.message ? CheckCustomer.error.response.data.message : 'User tidak terdaftar'} />
      <Stack direction="row" align="start">
        <div className="container mt-3">
          <img src={dataApp?.app_logo ?? LogoGotBeef} alt="logo" className="logo" width={'100px'} />
          <h3
            className="font-16 mt-3"
            style={{ color: dataApp?.primary_color ?? '#B92362' }}
          >
            Silahkan untuk mengisi email yang terdaftar sebagai member
          </h3>
          <div className="form-group mb-3 mt-3">
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? '#B92362' }}
            >
              Email *
            </label>
            <input
              type="text"
              placeholder="Masukan Email Anda"
              className={`form-control w-100  ${error ? 'error' : ''}`}
              onChange={handleChange}
            />
            <NormalText fontSize="12px" color="red">
              {errorText}
            </NormalText>
          </div>
          <button
            className="btn-lanjutkan mt-2"
            onClick={validateEmail}
            style={{
              backgroundColor: dataApp?.primary_color ?? '#B92362',
              color: dataApp?.secondary_color ?? '#000',
            }}
          >
            Lanjutkan
          </button>
          <button
            className="btn-lanjutkan mt-2"
            onClick={() => navigate('/register')}
            style={{
              color: dataApp?.primary_color ?? '#B92362',
              borderColor: dataApp?.primary_color ?? '#B92362',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: dataApp?.secondary_color ?? '#000',
            }}
          >
            Kembali ke Halaman Register
          </button>
        </div>
      </Stack>
      <Footer>
        <div className="text-center mt-5">
          <img src={LogoPowerdBy} alt="powerdBy" className='powered-image' />
        </div>
      </Footer>
    </MainLayout>
  );
};

export default RegisterMemberPage;
