import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import ArrowRightIcons from "assets/icons/ic_arrow_right.svg";
import TakeAwayIcon from "assets/icons/TAKEAWAY-V2.svg";
import DineInIcon from "assets/icons/DINEIN-V2.svg";
import DeliveryIcon from "assets/icons/DELIVERY-V2.svg";
import PickupPointIcon from "assets/icons/PICKUPPOINT-V2.svg";
import InStorePickUpIcon from "assets/icons/INSTOREPICKUP-V2.svg";

interface ServiceTypeComponentProps {
  service:
    | "DINE IN"
    | "TAKE AWAY"
    | "DELIVERY"
    | "PICKUP POINT"
    | "IN STORE PICKUP";
  onClick?: any;
}

const ServiceTypeComponent = ({
  service,
  onClick,
}: ServiceTypeComponentProps) => {
  return (
    <Box padding={"20px 20px"} onClick={onClick}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Box
            sx={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 4px 4px 0px #cccccc",
            }}
          >
            <img
              src={
                service === "TAKE AWAY"
                  ? TakeAwayIcon
                  : service === "DINE IN"
                  ? DineInIcon
                  : service === "DELIVERY"
                  ? DeliveryIcon
                  : service === "IN STORE PICKUP"
                  ? InStorePickUpIcon
                  : service === "PICKUP POINT"
                  ? PickupPointIcon
                  : DineInIcon
              }
              alt={
                service === "TAKE AWAY"
                  ? "Bawa Pulang Ikon"
                  : service === "DINE IN"
                  ? "Santap di Tempat Ikon"
                  : service === "DELIVERY"
                  ? "Pesan Antar Ikon"
                  : service === "IN STORE PICKUP"
                  ? "Ambil di Toko Ikon"
                  : service === "PICKUP POINT"
                  ? "Ambil di Titik Pengambilan Ikon"
                  : "Pilih tipe pesanan Ikon"
              }
              className="w-100"
            />
          </Box>

          <Stack direction={"column"}>
            <NormalText
              fontFamily="D-DIN"
              fontSize={"14px"}
              fontWeight={"bold"}
            >
              {service === "TAKE AWAY"
                ? "Bawa Pulang"
                : service === "DINE IN"
                ? "Santap di Tempat"
                : service === "DELIVERY"
                ? "Pesan Antar"
                : service === "IN STORE PICKUP"
                ? "Ambil di Toko"
                : service === "PICKUP POINT"
                ? "Ambil di Titik Pengambilan"
                : "Pilih tipe pesanan"}
            </NormalText>
          </Stack>
        </Stack>
        {/* {service === "TAKE AWAY" ? (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={TakeAwayIcon} alt={"take away icon"} />
            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Bawa Pulang
              </NormalText>
            </Stack>
          </Stack>
        ) : service === "DINE IN" ? (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={DineInIcon} alt={"take away icon"} />
            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Santap di Tempat
              </NormalText>
            </Stack>
          </Stack>
        ) : service === "DELIVERY" ? (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                borderRadius: "50%",
                width: "38px",
                height: "38px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFEE8E",
              }}
            >
              <img
                src={DeliveryIcon}
                alt={"delivery icon"}
                className="w-75"
              />
            </Box>

            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Pesan Antar
              </NormalText>
            </Stack>
          </Stack>
        ) : service === "IN STORE PICKUP" ? (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={DineInIcon} alt={"in store pickup icon"} />
            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Ambil di Toko
              </NormalText>
            </Stack>
          </Stack>
        ) :
          service === "PICKUP POINT" ?
            (<Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src={DineInIcon} alt={"pickup point icon"} />
              <Stack direction={"column"}>
                <NormalText
                  fontFamily="D-DIN"
                  fontSize={"14px"}
                  fontWeight={"bold"}
                >
                  Ambil di Titik Pengambilan
                </NormalText>
              </Stack>
            </Stack>)
            :
            (
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <img src={DineInIcon} alt={"take away icon"} />
                <Stack direction={"column"}>
                  <NormalText
                    fontFamily="D-DIN"
                    fontSize={"14px"}
                    fontWeight={"bold"}
                  >
                    Pilih tipe pesanan
                  </NormalText>
                </Stack>
              </Stack>

            )} */}
        <img src={ArrowRightIcons} alt={"arrow away icon"} />
      </Stack>
    </Box>
  );
};

export default ServiceTypeComponent;
