import MainLayout from "components/Layouts/MainLayout";
import { useState, useEffect } from "react";
import useRedux from "redux/useRedux";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import InputSearch from "components/Forms/InputSearch";
import StoreTagSection from "./store.tag.section";
import { useLocation } from "react-router-dom";
import { StoreQrGroupInfoTypes } from "redux/storeInfo";
import history from "helper/history";
import { ProductType, listSearchProductResult } from "redux/product";
import StoreProductSection from "./store.product.section";
import ProductListFull from "components/List/product.list.full";
import ProductDrawer from "components/Drawer/product.drawer";
import { detailProduct, ProductsDetail } from "redux/product";

interface LocationState {
  state: StoreQrGroupInfoTypes;
}

const StorePage = () => {
  const location = useLocation();
  const { state } = location as LocationState;
  //const { storeName } = useParams();
  const {
    thunkDispatch,
    storeState: { QrGroupInfo, AppSetting },
  } = useRedux();
  const [search, setSearch] = useState("");
  //const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState<ProductType[]>([]);

  const [openProduct, setOpenProduct] = useState(false);
  const [product, setProduct] = useState<ProductsDetail>({
    product_id: 0,
    product_name: "",
    category: {
      id: 0,
      category_name: "",
    },
    sub_category: {
      id: 0,
      sub_category_name: "",
    },
    subs_category: {
      id: 0,
      subs_category_name: "",
    },
    brand: {
      id: 0,
      brand_name: "",
    },
    status: false,
    merchant_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: false,
      product_price: "",
      discount_type: "",
      discount: 0,
      product_price_after_discount: "",
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
      long_description_html: "",
    },
  });

  const handleProductDrawer = (open: boolean) => {
    setOpenProduct(open);
  };

  const handleBackButton = () => {
    history.back();
  };

  const fetchProduct = () => {
    thunkDispatch(
      listSearchProductResult({
        keyword: search.toLowerCase(),
        merchant_id: state.merchant_id,
        store_id: state.store_id,
        limit: 5,
      })
    )
      .unwrap()
      .then((res: any) => {
        setProducts(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const productToCart = (props: any) => {
    // thunkDispatch(addToCart(props)).then(() => {
    //   handleProductDrawer(false);
    // });
  };

  const getDetailProduct = ({ productId, merchantId, storeId }: any) => {
    let data = {
      product_id: productId,
      merchant_id: merchantId,
      store_id: storeId,
    };
    thunkDispatch(detailProduct(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          //setLoading(false);
          let detailProduct: ProductsDetail = res.data.data;

          let tempDivElement: any = document.createElement("div");
          detailProduct.product_description.long_description_html =
            detailProduct.product_description.long_description;

          tempDivElement.innerHTML =
            detailProduct?.product_description?.long_description;

          detailProduct.product_description.long_description =
            tempDivElement?.innerText;

          setProduct(detailProduct);

          if (res.data.data?.product_detail?.is_customizable) {
            // navigate(`/product/${product_id}/detail`, {
            //   state: { previousPath: pathName },
            // });
            console.log(product);
          } else {
            handleProductDrawer(true);
          }
        }
      });
  };

  // console.log(state);
  useEffect(() => {
    fetchProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={AppSetting?.data?.data?.favicon ?? ""}
      title={QrGroupInfo?.data?.data?.group_name ?? ""}
    >
      <Box className="pb-5">
        <header className="w-100 mt-3 px-3">
          {/* Title & Back button */}
          <Stack
            direction="row"
            width={"100%"}
            className="my-3 position-relative"
            justifyContent={"center"}
            alignContent={"center"}
          >
            <IconButton
              sx={{
                background: "#888888",
                color: "#ffffff",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              size="small"
              onClick={handleBackButton}
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <Typography
              component={"h1"}
              sx={{
                textAlign: "center",
                fontFamily: "D-DIN Exp, sans-serif",
                fontWeight: "400",
              }}
            >
              {state.store_name}
            </Typography>
          </Stack>

          {/* Search Bar */}
          <Box width={"100%"}>
            <InputSearch
              placeholder="Cari produk pilihanmu"
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </Box>
        </header>

        {search && search.length > 0 ? (
          <Box
            sx={{
              backgroundColor: "white",
              height: "100vh",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {products && products.length > 0 ? (
              <ProductListFull
                productList={products}
                className="mt-3 px-3 pb-5"
                storeData={state}
                getDetailProduct={getDetailProduct}
              />
            ) : null}

            {products && products.length === 0 ? (
              <Box
                sx={{
                  wordBreak: "break-word",
                  height: "calc(100vh - 280px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack>
                  <Typography
                    fontSize={"1rem"}
                    variant="h6"
                    textAlign={"center"}
                    fontWeight={"bold"}
                  >
                    Oops!
                  </Typography>
                  <Typography textAlign="center" fontSize="12px">
                    Tidak ada produk ditemukan{" "}
                    {search ? `dengan kata kunci "${search}"` : ""}
                  </Typography>
                </Stack>
              </Box>
            ) : null}
          </Box>
        ) : (
          <>
            <StoreTagSection
              logo={state?.store_logo}
              name={state?.store_name}
              location={state?.city?.city_name}
              className="w-100 mt-3 px-3"
            />
            <StoreProductSection
              className="mt-3"
              products={products}
              storeData={state}
              getDetailProduct={getDetailProduct}
            />
          </>
        )}
      </Box>
      <ProductDrawer
        open={openProduct}
        onClose={handleProductDrawer}
        onOpen={handleProductDrawer}
        product={product}
        color={AppSetting?.data?.data?.primary_color}
        productToCart={productToCart}
      />
    </MainLayout>
  );
};

export default StorePage;
