import React from "react";
import { Box, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import { Button } from "../styled/button.styled";

interface Props {
  open: boolean;
  handleClose?: () => void;
  description?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  handleConfirm: () => void;
  backgroundButton?: string;
  hasCancel?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  description,
  confirmText,
  cancelText,
  titleStyle,
  descriptionStyle,
  backgroundButton,
  handleConfirm,
  hasCancel,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {title && <NormalText fontWeight="bold" style={titleStyle}>{title}</NormalText>}
        {description && (
          <NormalText style={descriptionStyle}>{description}</NormalText>
        )}
        <Stack direction={"row"} spacing={1} margin={"10px 0 0 0"} justifyContent={'center'}>
          <Button
            background={backgroundButton ?? '#035292'}
            style={{ color: "white", fontSize: "12px" }}
            onClick={handleConfirm}
          >
            {confirmText ?? "Yes"}
          </Button>
          {hasCancel && (
            <Button
              variant="secondary"
              style={{ color: "white", fontSize: "12px" }}
              onClick={handleClose}
            >
              {cancelText ?? "No"}
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
