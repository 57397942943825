import React from "react";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import CounterButton from "components/Button/counter";
import { formatCurrency } from "helper/general";
import { ProductAdditional } from "redux/cart/cart.reducer";

export interface CardProductProps {
  product_name?: string;
  product_price?: number;
  qty: number;
  customizable?: ProductAdditional[];
  background?: string;
  onQtyIncrease?: React.MouseEventHandler<HTMLButtonElement>;
  onQtyDecrease?: React.MouseEventHandler<HTMLButtonElement>;
  notes?: string;
}

export interface ProductAddOnProps {
  name?: string;
}

const CartProduct = ({
  product_name,
  product_price,
  qty,
  customizable,
  onQtyIncrease,
  onQtyDecrease,
  background,
  notes
}: CardProductProps) => {
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let temp = Number(product_price) ?? 0;
    if (customizable && customizable.length) {
      customizable.forEach((customer) => {
        temp += Number(customer.price);
      });
      setTotal(temp);
    } else {
      setTotal(temp);
    }
  }, [customizable, product_price]);

  return (
    <>
      <Box
        sx={{ background: "white", margin: "0 0 3px 0" }}
        padding={"20px 20px"}
        minHeight={"80px"}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>
            <NormalText
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Open Sans"
            >
              {product_name}
            </NormalText>
            {customizable &&
              customizable.length > 0 &&
              customizable.map((item, index) => (
                <NormalText
                  fontSize="12px"
                  fontFamily="Open Sans"
                  key={"cartproduct-" + index}
                >
                  {item.name}
                </NormalText>
              ))}
            <NormalText
              fontSize="11px"
              fontFamily="Open Sans"
              margin="10px 0 0 0"
            >
              Catatan:
            </NormalText>
            <NormalText
              fontSize="11px"
              fontFamily="Open Sans"
            >
              {notes && notes.length > 0 ? notes : "-"}
            </NormalText>
          </Box>
          <Box>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"right"}
            >
              <NormalText
                textAlign={"right"}
                fontSize="12px"
                fontWeight="bold"
                margin="0 0 0 20px 0"
                fontFamily="Open Sans"
              >
                {formatCurrency(total)}
              </NormalText>
              <CounterButton
                background={background}
                value={qty}
                onDecrease={onQtyDecrease}
                onIncrease={onQtyIncrease}
              />
            </Stack>
          </Box>
        </Stack>
      </Box>
      <SpacerLine />
    </>
  );
};

export default CartProduct;
