import React from "react";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import QrisIcon from "assets/icons/ic_qris.svg";
import CreditIcon from "assets/icons/ic_credit_card.svg";
import CashIcon from "assets/icons/ic_cash.svg";

interface PaymentItemProps {
  onChange: any;
  name: string;
  selected: boolean;
  channel: string;
  background?: string;
}

const PaymentItem = ({
  onChange,
  name,
  channel,
  selected,
  background,
}: PaymentItemProps) => {
  return (
    <Box
      padding={"15px 20px"}
      onClick={onChange}
      sx={{ cursor: "pointer" }}
      width={"100%"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <div style={{ width: "50px" }}>
            <img
              style={{ width: channel === "qr_code" ? "50px" : "40px" }}
              src={
                channel === "qr_code"
                  ? QrisIcon
                  : channel === "virtual_account" || channel === "debit_credit"
                  ? CreditIcon
                  : CashIcon
              }
              alt={channel}
            />
          </div>
          <Stack direction={"column"}>
            <NormalText
              fontFamily="D-DIN"
              fontSize={"14px"}
              textAlign={"left"}
              fontWeight={"bold"}
            >
              {name}
            </NormalText>
          </Stack>
        </Stack>
        <Item>
          <RadioButton
            checkedBackground={background ?? "#f8e71c"}
            type="radio"
            name="payment"
            value="Virtual Account"
            checked={selected}
            onChange={onChange}
          />
          <RadioButtonLabel />
        </Item>
      </Stack>
    </Box>
  );
};

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-sizing: border-box;
  border: 4px solid #ccc;
`;

interface RadioButtonProps {
  checkedBackground?: string;
}

export const RadioButton = styled.input<RadioButtonProps>`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  box-sizing: border-box;
  &:hover ~ ${RadioButtonLabel} {
    &::after {
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
  }
  &:checked + ${RadioButtonLabel} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &::after {
      content: "";
      font-family: "FontAwesome";
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

export default PaymentItem;
