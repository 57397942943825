import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueueState } from './types';
import axios from 'axios';

export interface OrderPropsList {
  limit?: number;
  offset?: number;
  store_id?: any;
}

export const queueList = createAsyncThunk(
  'queue/list',
  async (props: OrderPropsList) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${props.store_id}/order-queue?offset=${props.offset}&limit=${props.limit}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as QueueState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as QueueState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as QueueState;
    }
  },
);
