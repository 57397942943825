import {
  Box,
  Modal,
} from "@mui/material";

import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import styled from "styled-components";
import BrandListFilter from "./brand.list.filter";
import CategoryListFilter from "./category.list.filter";

import { CategoryInterface } from "redux/category";
import { BrandsType } from "redux/brand";

interface CategoryAndBrandListFilterProps {
  brandList?: BrandsType[];
  categoryList?: CategoryInterface[];
  openModal: boolean;
  handleClose: any;
  page?: string;
  background?: string;
}

interface TabsListBg {
  background?: string;
}

export const Tab = styled(TabUnstyled)`
  color: #0a2942;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background-color: transparent;
  width: 100%;
  height: 35px;
  padding: 10px 12px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin: 0 -1px;

  &:focus {
    color: #0a2942;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #0a2942;
    font-weight: 700;
  }
`;

export const TabsList = styled(TabsListUnstyled)<TabsListBg>`
  height: 35px;
  background-color: ${(props) => props.background};
  border-radius: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
`;

const CategoryAndBrandListFilter = ({
  categoryList,
  brandList,
  openModal,
  handleClose,
  page,
  background,
}: CategoryAndBrandListFilterProps) => {
  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 320,
          width: 320,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          borderRadius: "20px",
          boxShadow: 24,
          padding: "21px 14px 25px 14px",
          outline: "unset!important",
        }}
      >
        <TabsUnstyled defaultValue={page === "brand" ? 1 : 0}>
          <TabsList background={background}>
            <Tab>Kategori</Tab>
            <Tab>Brand</Tab>
          </TabsList>

          {/* Category */}
          <TabPanelUnstyled value={0}>
            <CategoryListFilter
              list={categoryList && categoryList.length > 0 ? categoryList : []} type={""}  
              handleClose={handleClose}          />
          </TabPanelUnstyled>

          {/* Brand */}
          <TabPanelUnstyled value={1}>
            <BrandListFilter
              list={brandList && brandList.length > 0 ? brandList : []}
              handleClose={handleClose}
            />
          </TabPanelUnstyled>
        </TabsUnstyled>
      </Box>
    </Modal>
  );
};

export default CategoryAndBrandListFilter;
