//import * as React from 'react';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
//import Stack from '@mui/material/Stack';

const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  100: '#eaeef2',
  300: '#afb8c1',
  900: '#24292f',
};

const ChangeNotesButton = styled(ButtonUnstyled)(
  ({ theme }) => `
  font-family: sans-serif;
  font-weight: 600;
  font-size: 0.7rem;
  background-color: transparent;
  padding: 0px 5px;
  border-radius: 0;
  color: #636e72;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  //box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};

  // &:hover {
  //   background-color: ${blue[600]};
  // }

  // &.${buttonUnstyledClasses.active} {
  //   background-color: ${blue[700]};
  // }

  // &.${buttonUnstyledClasses.focusVisible} {
  //   box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
  //   outline: none;
  // }

  // &.${buttonUnstyledClasses.disabled} {
  //   opacity: 0.5;
  //   cursor: not-allowed;
  // }
  `,
);

export default ChangeNotesButton
// function UnstyledButtonIntroduction() {
//   return (
//     <Stack spacing={2} direction="row">
//       <CustomButton>Button</CustomButton>
//       <CustomButton disabled>Disabled</CustomButton>
//     </Stack>
//   );
// }