import { ResponseProps, QrGroupInfoState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { qrGroupInfo } from './qrgroup.info.services';

export const initialState: QrGroupInfoState = {
  data: {} as ResponseProps,
  status: "loading",
  error: {}
}

export const qrGroupInfoSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
      .addCase(qrGroupInfo.pending, (state) => {
          state.status = 'loading'
      })
      .addCase(qrGroupInfo.fulfilled, (state, action) => {
          if (action.payload) {
              state.data = action.payload.data;
              state.status = action.payload.status;
              state.error = action.payload.error
          }
      })
      .addCase(qrGroupInfo.rejected, (state, action) => {
          state.status = 'error';
          state.error = action.error
      })
  }
})

export const QrGroupInfoReducer = qrGroupInfoSlice.reducer