import { Stack, Button } from "@mui/material";
import NewProductListItem from "./newproduct.list.item";
import { useNavigate, useLocation } from "react-router-dom";

import { QrGroupProductType } from "redux/product";

interface NewProductListSectionProps {
  className?: string;
  newProductList?: QrGroupProductType[];
  primaryColor?: string;
  showAllTrigger?: any;
  getDetail?: any;
}

const NewProductListSection = ({
  className,
  newProductList,
  showAllTrigger,
  primaryColor,
  getDetail,
}: NewProductListSectionProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Stack className={className} direction={"column"} width={"100%"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="ps-3 pe-2"
        width={"100%"}
      >
        <h6 className="store-section-title mb-0">Produk Terbaru</h6>

        <Button
          className="store-section-show-all-btn"
          sx={{ color: `${primaryColor ? `${primaryColor}` : "#FFC535"}` }}
          onClick={showAllTrigger}
        >
          Lihat Semua
        </Button>
      </Stack>
      <Stack direction={"row"} className="scroll-box mt-3">
        {newProductList && newProductList.length > 0
          ? newProductList.map((product, index) => {
              return (
                <NewProductListItem
                  image={product.product_detail.product_image_main}
                  name={product.product_name}
                  storeName={product.productStore.store_name}
                  key={index + "-product"}
                  discount={product.product_detail.discount}
                  discount_type={product.product_detail.discount_type}
                  price={product.product_detail.product_price}
                  price_after_discount={
                    product.product_detail.product_price_after_discount
                  }
                  type="row"
                  onClick={() => {
                    product.product_detail.is_customizable
                      ? navigate(
                          `/qr-group/store/${product?.productStore?.store_name
                            ?.toLowerCase()
                            .replace(/ /g, "")}/${product?.product_name
                            ?.toLowerCase()
                            .replace(/ /g, "-")}`,
                          {
                            state: {
                              prevUrl: location.pathname,
                              productDetail: product,
                            },
                          }
                        )
                      : getDetail({
                          productId: product.product_id,
                          merchantId: product.merchant_id,
                          storeId: product.productStore.store_id,
                        });
                  }}
                />
              );
            })
          : null}
      </Stack>
    </Stack>
  );
};

export default NewProductListSection;
