import React from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
// import GotBeef from '../../assets/images/gotbeef_primary.svg';
// import FooterImage from '../../assets/images/footer_queue.png';
import useRedux from 'redux/useRedux';
import { queueList, QueueTypes } from 'redux/queue';
import { useParams } from 'react-router-dom';
import { getAppSetting, getStoreInfo } from './getStoreApps';
import { AppSettingData } from 'redux/appsetting';
// import 
const LIMIT_PER_PAGE = 0

const QueuePage = () => {
  const {
    thunkDispatch,
    // storeState: { AppSetting },
  } = useRedux();
  const { storeid } = useParams();
  const [page, setPage] = React.useState(0);
  const [show, setShow] = React.useState<QueueTypes[]>();
  const [allData, setAllData] = React.useState<QueueTypes[]>([]); //eslint-disable-line
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotalData] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataSetting, setDataSetting] = React.useState<AppSettingData>();
  const [id, setId] = React.useState();

  const getSettingApp = async (merchant_id: any) => {
    await getAppSetting(merchant_id)
      .then((res) => {
        if (res.status === 200) {
          setDataSetting(res.data.data);
        } else {
          window.location.replace('/queue/access-forbidden');
        }
      })
      .catch((err) => {
        window.location.replace('/queue/access-forbidden');
      });
  };

  const getQueueData = () => {
    if (id) {
      let dataObject = {
        limit: LIMIT_PER_PAGE,
        offset: page,
        store_id: id ?? '',
      };
      thunkDispatch(queueList(dataObject))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            if (res.data.meta.total && res.data.meta.total !== 0) {
              if (res.data.meta.total % (page * LIMIT_PER_PAGE ?? 1) > 0) {
                setTimeout(() => {
                  setPage(0)
                }, 5000);
              } else {
                setTimeout(() => {
                  setPage(page + 1);
                }, 5000);
              }
              setShow(res.data.data);

            } else {
              if (page === 0) {
                setTimeout(() => {
                  getQueueData()
                }, 5000);
              } else {
                setPage(0)
              }
            }
            setAllData(res.data.data);
            setTotalData(res.data.meta.total);
          }
        })
        .catch((err) => {
          window.location.replace('/queue/access-forbidden');
        });
    }
  };

  const getStore = async () => {
    await getStoreInfo(storeid)
      .then((res) => {
        if (res.status === 200) {
          setId(res.data?.data?.id ?? '');
          getSettingApp(res.data?.data?.merchant_id ?? '');

        } else {
          window.location.replace('/queue/access-forbidden');
        }
      })
      .catch((err) => {
        window.location.replace('/queue/access-forbidden');
      });
  };

  React.useEffect(() => {
    if (storeid) {
      getStore();
    } else {
      window.location.replace('/queue/access-forbidden');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeid]);

  React.useEffect(() => {
    getQueueData()
  // }, []); //eslint-disable-line
  }, [id, total, page]); //eslint-disable-line
  // React.useEffect(() => {
  //   if (total && total !== 0) {
  //     if (page * LIMIT_PER_PAGE <= total) {
  //       setTimeout(() => {
  //         setPage(page + 1);
  //       }, 10000);
  //     } else {
  //       setTimeout(() => {
  //         setPage(0)
  //       }, 10000);
  //     }
  //   }
  // }, [page, total]);

  // React.useEffect(() => {
  //   getQueueData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id, page]);


  return (
    <Container image={null}>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Queue Display</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.cdnfonts.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
      </Helmet>
      <Box padding={'40px'}>
        <Box sx={{ background: 'rgba(255, 255, 255, 0.8)' }}>
          <Table>
            <thead>
              <th>Antrian</th>
              <th>No Bon</th>
              <th>Item</th>
              <th>Qty</th>
              <th>Jam Order</th>
              <th>Status</th>
            </thead>
            <tbody>
              {show && show.length !== 0
                ? show.filter((item) => item.status !== "CLOSED")
                  .map((item, index) => (
                  <tr>
                    <td>{item.order_queue}</td>
                    <td>{item.no_transaction} - {item.customer_name}</td>
                    <td>
                      { item.item.length !== 0 
                        ? item.item.map ((data, index) => (
                            <p style={{
                              borderTop:index > 0 ? '1px solid black' : '0'}
                            }>
                              {data.product_name}
                            </p>
                        ))
                        : null
                      }
                    </td>
                    <td>
                      { item.item.length !== 0 
                        ? item.item.map ((data, index) => (
                            <p style={{
                              borderTop:index > 0 ? '1px solid black' : '0'}
                            }>{data.qty}</p>
                        ))
                        : null
                      }
                    </td>
                    <td>{item.order_hour}</td>
                    <td>
                      <Badge variant={item?.status ?? 'PREPARING'}>
                        {item.status === 'PREPARING'
                          ? 'Preparing'
                          : item.status === 'ON PROCESS'
                            ? 'On Process'
                            : item.status === 'READY'
                              ? 'Ready'
                              : 'Serving'}
                      </Badge>
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

interface ContainerProps {
  image: any;
}

const Container = styled.div<ContainerProps>`
  margin: 0;
  padding: 0;
  background: #ffffff;
  height: 100vh;
  background-size: 100%;
  background-image: ${(props) => (props.image ? `url(${props.image})` : '')};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
`;

const Table = styled.table`
  font-family: 'Open Sans';
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  animation: ease-in-out 0.2s;

  td {
    padding: 15px 0;
    font-size: 24px;
  }

  td,
  th {
    border: 1px solid black;
  }
  :first-child th {
    border-top: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child,
  th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  th:last-child {
    border-right: 0;
  }

  th {
    padding: 10px 0;
    font-size: 32px;
    font-weight: 600;
  }
`;

interface BadgeProps {
  variant?: string;
}

const Badge = styled.div<BadgeProps>`
  background: ${({ variant }) =>
    variant === 'PREPARING'
      ? '#FF6B00'
      : variant === 'ON PROCESS'
        ? '#ffbd39'
        : '#039912'};
  color: white;
  border-radius: 50px;
  margin: 0 10%;
  padding: 10px 5px ;
  text-align: center;
  font-family: 'Open sans';
  font-size: 24px;
  font-weight: 600;
  width: 100%
`;


export default QueuePage;
