import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { Box } from "@mui/material";
//import IconCategory from "assets/icons/ic_default_category.svg";
import NoIcon from 'assets/images/NoIcon.svg'

interface Category {
  image?: any;
  name?: string;
  onClick?: any;
}

const ProductsCategory = ({ image, name, onClick }: Category) => {
  return (
    <Container onClick={onClick}>
      <Box
        margin={"5px auto 0 auto"}
        sx={{
          background: "#FFFFFF",
          width: "75px",
          height: "75px",
          borderRadius: "5px",
          boxShadow: "0 8px 10px rgba(0, 0, 0, 0.12)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <CategoryImage src={image && image.length ? image : NoIcon} alt={name} />
      </Box>
      <NormalText
        fontFamily="D-DIN Exp"
        fontStyle="normal"
        fontWeight="400"
        fontSize="10px"
        lineHeight="11px"
        textAlign="center"
        margin="15px 0 0 0"
      >
        {name && name.length > 18 ? name.substring(0, 18) + "..." : name}
      </NormalText>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
  /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08); */
  border-radius: 10px;
  // &:hover {
  //   box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  // }
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  // margin: 15px;
  object-fit: cover;
`;

export default ProductsCategory;
