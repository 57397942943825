import React, { useState } from "react";
import { NormalText } from "../../components/styled/text.styled";
import { Stack } from "../../components/styled/layout.styled";
import MainLayout from "components/Layouts/MainLayout";
import "./styles.css";

import useRedux from "../../../src/redux/useRedux";
import { useNavigate } from "react-router-dom";
import { AppSettingData } from "redux/appsetting";

import { ProvincesData, getAllProvinces } from "redux/regions/province";
import { CityData, getAllCity } from "redux/regions/city";
import { getAllSubUrban, SubUrbanData } from "redux/regions/suburban";
import { getAllArea, AreaData } from "redux/regions/area";
import { RegisterAddressProps, registerSetData } from "redux/registerAddress";

import { back } from "../../helper/history";
import { notify } from "helper/general";

interface Props {
  showSkipBtn?: boolean;
}

const RegisterAddressPage = ({ showSkipBtn = true }: Props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [service, setService] = useState(
    window.localStorage.getItem("MQR_USER_SERVICE") ?? ""
  );
  const [provinces, setProvinces] = useState<ProvincesData[]>();
  const [cities, setCities] = useState<CityData[]>();
  const [listSubUrban, setListSubUrban] = useState<SubUrbanData[]>();
  const [areas, setAreas] = useState<AreaData[]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorNama, setErrorNama] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorProvince, setErrorProvince] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorSuburb, setErrorSuburb] = useState("");
  const [errorArea, setErrorArea] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    full_name: "",
    address: "",
    province_id: 0,
    city_id: 0,
    suburb_id: 0,
    area_id: 0,
    email: "",
    phone: "",
  });

  const {
    thunkDispatch,
    storeState: { AppSetting, RegisterAddress },
  } = useRedux();
  // eslint-disable-next-line

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  const fetchCities = (prov_id: any) => {
    thunkDispatch(getAllCity(prov_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setCities(res.data.data.cities);
        }
      });
  };

  const fetchSuburban = (city_id: any) => {
    thunkDispatch(getAllSubUrban(city_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setListSubUrban(res.data.data.suburbs);
        }
      });
  };

  const fetchArea = (suburb_id: any) => {
    thunkDispatch(getAllArea(suburb_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAreas(res.data.data.areas);
        }
      });
  };

  const handleChange = (event: any) => {
    const numberOnly = /^[0-9\b]+$/;
    if (event.target.name === "phone") {
      if (event.target.value === "" || numberOnly.test(event.target.value)) {
        setData({ ...data, [event.target.name]: event.target.value });
        return;
      }
      return;
    }

    // use spread operator

    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  function inputAddress(data: RegisterAddressProps) {
    thunkDispatch(registerSetData(data)).unwrap();
    notify("success", "Data berhasil disubmit");

    setTimeout(() => {
      navigate("/checkout");
    }, 1500);
  }

  const handleAddAddress = () => {
    let dataObject = {
      full_name: data.full_name ? data.full_name : "",
      address: data.address ? data.address : "",
      email: data.email ? data.email : "",
      phone: data.phone ? data.phone : "",
      province_id: data.province_id ? data.province_id : 0,
      city_id: data.city_id ? data.city_id : 0,
      suburb_id: data.suburb_id ? data.suburb_id : 0,
      area_id: data.area_id ? data.area_id : 0,
    };
    inputAddress(dataObject);
  };

  const validateEmail = () => {
    setIsSubmit(true);
    const regex =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const regexNumberOnly = /[0-9]/;

    if (service && service === "DELIVERY") {
      if (
        !data.email ||
        regex.test(data.email) === false ||
        !data.full_name ||
        data.full_name.length > 50 ||
        !data.phone ||
        regexNumberOnly.test(data.phone) === false ||
        data.phone.length < 9 ||
        data.phone.length > 13 ||
        !data.address ||
        data.address.length > 100 ||
        !data.province_id ||
        !data.city_id ||
        !data.suburb_id ||
        !data.area_id
      ) {
        setError(true);
        setErrorText(
          !data.email
            ? "Email wajib diisi"
            : regex.test(data.email) === false
            ? "Format data harus berupa email (contoh: email@sandbox.co.id)"
            : ""
        );
        setErrorPhone(
          !data.phone
            ? "Nomor Telepon Wajib diisi"
            : data.phone.length < 9
            ? "Nomor Telepon Minimal 9 angka"
            : data.phone.length > 13
            ? "Nomor Telepon Maksimal 13 angka"
            : regexNumberOnly.test(data.phone) === false
            ? "Hanya angka saja"
            : ""
        );
        setErrorNama(
          !data.full_name
            ? "Nama Wajib diisi"
            : data.full_name.length > 50
            ? "Nama Maksimal 50 karakter"
            : ""
        );
        setErrorAddress(
          !data.address
            ? "Alamat Wajib diisi"
            : data.address.length > 100
            ? "Alamat Maksimal 100 karakter"
            : ""
        );
        setErrorProvince(!data.province_id ? "Provinsi Wajib diisi" : "");
        setErrorCity(!data.city_id ? "Kota/Kabupaten Wajib diisi" : "");
        setErrorSuburb(!data.suburb_id ? "Kecamatan Wajib diisi" : "");
        setErrorArea(!data.area_id ? "Kelurahan Wajib diisi" : "");
        setIsSubmit(false);
      } else {
        setError(false);
        setErrorText("");
        setErrorPhone("");
        setErrorAddress("");
        setErrorProvince("");
        setErrorCity("");
        setErrorSuburb("");
        setErrorArea("");
        handleAddAddress();
      }
    } else {
      if (
        !data.email ||
        regex.test(data.email) === false ||
        !data.full_name ||
        data.full_name.length > 50 ||
        !data.phone ||
        regexNumberOnly.test(data.phone) === false ||
        data.phone.length < 9 ||
        data.phone.length > 13
      ) {
        setError(true);
        setErrorText(
          !data.email
            ? "Email wajib diisi"
            : regex.test(data.email) === false
            ? "Format data harus berupa email (contoh: email@sandbox.co.id)"
            : ""
        );
        setErrorPhone(
          !data.phone
            ? "Nomor Telepon Wajib diisi"
            : data.phone.length < 9
            ? "Nomor Telepon Minimal 9 angka"
            : data.phone.length > 13
            ? "Nomor Telepon Maksimal 13 angka"
            : regexNumberOnly.test(data.phone) === false
            ? "Hanya angka saja "
            : ""
        );
        setErrorNama(
          !data.full_name
            ? "Nama Wajib diisi"
            : data.full_name.length > 50
            ? "Nama Maksimal 50 karakter"
            : ""
        );
        setIsSubmit(false);
      } else {
        setError(false);
        setErrorText("");
        setErrorPhone("");
        handleAddAddress();
      }
    }
  };

  React.useEffect(() => {
    if (service === "DELIVERY") {
      thunkDispatch(getAllProvinces())
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setProvinces(res.data.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (RegisterAddress?.data && Object.keys(RegisterAddress.data).length > 0) {
      setData((prevState) => {
        return {
          ...prevState,
          ...RegisterAddress.data,
        };
      });
      if (RegisterAddress.data.province_id !== 0) {
        fetchCities(RegisterAddress.data.province_id);
      }
      if (RegisterAddress.data.city_id !== 0) {
        fetchSuburban(RegisterAddress.data.city_id);
      }
      if (RegisterAddress.data.suburb_id !== 0) {
        fetchArea(RegisterAddress.data.suburb_id);
      }
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      background={dataApp?.secondary_color ?? "#FFF4F8"}
      favIcon={dataApp?.favicon ?? ""}
      title={dataApp?.merchant_name ?? ""}
    >
      <Stack direction="row" align="start">
        <div className="container mt-3">
          <h3
            className="font-16 mt-3"
            style={{ color: dataApp?.primary_color ?? "#B92362" }}
          >
            Silahkan isi Form dibawah ini.
          </h3>

          <div className="form-group mb-3 mt-3">
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Nama *
            </label>
            <input
              type="text"
              name="full_name"
              placeholder="Masukan Nama Anda"
              className={`form-control w-100  ${errorNama ? "error" : ""}`}
              value={data.full_name}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />

            <NormalText fontSize="12px" color="red">
              {errorNama}
            </NormalText>
          </div>
          {service && service === "DELIVERY" ? (
            <>
              <div className="form-group mb-3 mt-3">
                <label
                  className="mb-1"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                >
                  Alamat *
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder="Masukan Alamat"
                  className={`form-control w-100  ${
                    errorAddress ? "error" : ""
                  }`}
                  value={data.address}
                  onChange={handleChange}
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                />

                <NormalText fontSize="12px" color="red">
                  {errorAddress}
                </NormalText>
              </div>
              <div className="form-group mb-3 mt-3">
                <label
                  className="mb-1"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                >
                  Provinsi *
                </label>
                <select
                  className={`form-select w-100  ${
                    errorProvince ? "error" : ""
                  }`}
                  aria-label="Default select example"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                  value={data.province_id}
                  onChange={(event) => {
                    fetchCities(event.target.value);
                    setListSubUrban([]);
                    setAreas([]);
                    setData({
                      ...data,
                      province_id: Number(event.target.value),
                      city_id: 0,
                      suburb_id: 0,
                      area_id: 0,
                    });
                  }}
                >
                  <option value="" defaultChecked>
                    Pilih Provinsi
                  </option>
                  {provinces &&
                    provinces.length &&
                    provinces.map((province, index) => {
                      return (
                        <option key={index} value={province.id}>
                          {province.province_name}
                        </option>
                      );
                    })}
                </select>
                <NormalText fontSize="12px" color="red">
                  {errorProvince}
                </NormalText>
              </div>
              <div className="form-group mb-3 mt-3">
                <label
                  className="mb-1"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                >
                  Kota/Kab *
                </label>
                <select
                  className={`form-select w-100  ${errorCity ? "error" : ""}`}
                  aria-label="Default select example"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                  value={data.city_id}
                  onChange={(event) => {
                    fetchSuburban(event.target.value);
                    setAreas([]);
                    setData({
                      ...data,
                      city_id: Number(event.target.value),
                      suburb_id: 0,
                      area_id: 0,
                    });
                  }}
                >
                  <option value="" defaultChecked>
                    Pilih Kota
                  </option>
                  {cities &&
                    cities.length &&
                    cities.map((city, index) => {
                      return (
                        <option key={index} value={city.id}>
                          {city.city_name}
                        </option>
                      );
                    })}
                </select>
                <NormalText fontSize="12px" color="red">
                  {errorCity}
                </NormalText>
              </div>
              <div className="form-group mb-3 mt-3">
                <label
                  className="mb-1"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                >
                  Kecamatan *
                </label>
                <select
                  className={`form-select w-100  ${errorSuburb ? "error" : ""}`}
                  aria-label="Default select example"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                  value={data.suburb_id}
                  onChange={(event) => {
                    setData({ ...data, suburb_id: Number(event.target.value) });
                    fetchArea(event.target.value);
                  }}
                >
                  <option value="" defaultChecked>
                    Pilih Kecamatan
                  </option>
                  {listSubUrban &&
                    listSubUrban.length &&
                    listSubUrban.map((subUrban, index) => {
                      return (
                        <option key={index} value={subUrban.id}>
                          {subUrban.suburbName}
                        </option>
                      );
                    })}
                </select>
                <NormalText fontSize="12px" color="red">
                  {errorSuburb}
                </NormalText>
              </div>
              <div className="form-group mb-3 mt-3">
                <label
                  className="mb-1"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                >
                  Kelurahan *
                </label>
                <select
                  className={`form-select w-100  ${errorArea ? "error" : ""}`}
                  aria-label="Default select example"
                  style={{ color: dataApp?.primary_color ?? "#B92362" }}
                  value={data.area_id}
                  onChange={(event) => {
                    setData({ ...data, area_id: Number(event.target.value) });
                  }}
                >
                  <option value="" defaultChecked>
                    Pilih Kelurahan
                  </option>
                  {areas &&
                    areas.length &&
                    areas.map((area, index) => {
                      return (
                        <option key={index} value={area.id}>
                          {area.area_name}
                        </option>
                      );
                    })}
                </select>
                <NormalText fontSize="12px" color="red">
                  {errorArea}
                </NormalText>
              </div>
            </>
          ) : null}

          <div className={`form-group mb-3 mt-3`}>
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Email *
            </label>
            <input
              type="text"
              name="email"
              placeholder="Masukan Email Anda"
              className={`form-control w-100  ${errorText ? "error" : ""}`}
              value={data.email}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />
            <NormalText fontSize="12px" color="red">
              {errorText}
            </NormalText>
          </div>
          <div className="form-group mb-3 mt-3">
            <label
              className="mb-1"
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            >
              Nomor Telepon *
            </label>
            <input
              type="text"
              name="phone"
              placeholder="Masukan Nomor Telepon Anda"
              className={`form-control w-100  ${errorPhone ? "error" : ""}`}
              value={data.phone}
              onChange={handleChange}
              style={{ color: dataApp?.primary_color ?? "#B92362" }}
            />

            <NormalText fontSize="12px" color="red">
              {errorPhone}
            </NormalText>
          </div>

          <button
            className="btn-lanjutkan mt-2"
            onClick={validateEmail}
            style={{
              backgroundColor: dataApp?.primary_color ?? "#B92362",
              color: dataApp?.secondary_color ?? "#000",
            }}
            disabled={isSubmit}
          >
            {isEdit ? "Simpan Perubahan" : "Submit"}
          </button>
          <button
            className="btn-lanjutkan mt-2 mb-4"
            style={{
              backgroundColor: dataApp?.secondary_color ?? "#B92362",
              color: dataApp?.primary_color ?? "#000",
            }}
            onClick={() => back()}
          >
            Kembali
          </button>
        </div>
      </Stack>
      {/* <Footer>
        <div className="text-center mt-5">
          <img src={LogoPowerdBy} alt="powerdBy" className="powered-image" />
        </div>
      </Footer> */}
    </MainLayout>
  );
};

export default RegisterAddressPage;
