import { ResponseProps, QueueState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { queueList } from './queue.services';

export const initialState: QueueState = {
    data: {} as ResponseProps,
    status: "loading",
    error: {}
}

export const QueueSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(queueList.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(queueList.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(queueList.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const QueueReducer = QueueSlice.reducer