import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface BottomSheetProps {
  children?: React.ReactNode;
  borderradius?: string;
  height?: string;
  padding?: string;
  zindex?: string;
  minheight?: string;
}

const BottomSheet = ({
  children,
  borderradius,
  height,
  padding,
  zindex,
  minheight,
}: BottomSheetProps) => {
  return (
    <>
      <BottomModal
        animate={{ y: [0, -5, 0] }}
        transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 1 }}
        borderradius={borderradius}
        height={height}
        padding={padding}
        zindex={zindex}
        minheight={minheight}
      >
        <Content padding={padding}>{children}</Content>
      </BottomModal>
    </>
  );
};

export const BottomModal = styled(motion.div) <BottomSheetProps>`
  position: fixed;
  height: ${(props) => props.height ?? '90px'};
  min-height: ${(props) => props.minheight ?? ''};
  z-index: ${(props) => props.zindex ?? '2'};
  width: 100%;
  background: white;
  border: 1px solid #c4c4c4;
  border-width: 1px 0 0 0;
  bottom: 0;
  border-radius: ${(props) => props.borderradius ?? 'none'};

  @media only screen and (min-width: 768px) {
    max-width: 360px;
  }
`;

interface ChekoutButtonProps {
  background?: string;
  color?: string;
  border?: string;
  borderRadius?: string;
}

export const ChekoutButton = styled.button<ChekoutButtonProps>`
  background: ${(props) => props.background ?? "rgba(185, 35, 98, 1)"};
  border: ${(props) => props.border ?? "none"};
  border-radius: ${(props) => props.borderRadius ?? "none"};
  width: 100%;
  color: ${(props) => props.color ?? "#ffffff"};
  height: 50px;

  :focus,
  :hover {
    opacity: 0.8;
  }
`;

export const Content = styled.div<BottomSheetProps>`
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.padding ?? '20px'};
`;

// Export Styled Components for Checkout Bottom Modal
export const CheckoutBottomModal = styled(motion.div) <BottomSheetProps>`
  position: fixed;
  height: ${(props) => props.height ?? '90px'};
  min-height: ${(props) => props.minheight ?? ''};
  z-index: ${(props) => props.zindex ?? '2'};
  width: 100%;
  bottom: 0;

  :focus,
  :hover {
    filter: brightness(85%);
  }
  
  @media only screen and (min-width: 768px) {
    max-width: 360px;
  }
`;

// Export Styled Component for Cart Checkout Button
export const CartChekoutButton = styled.button<ChekoutButtonProps>`
  background: ${(props) => props.background ?? 'rgba(185, 35, 98, 1)'};
  border: none;
  width: 100%;
  color: white;
  height: 50px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;

export const CheckoutIcon = styled.img`
  cursor: pointer;
  height: 12px;
  width: 12px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;


export default BottomSheet;