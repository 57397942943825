import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";

import { PricingsProps, CourierProps } from "redux/delivery";
import { formatCurrency } from "helper/general";

interface CourierItemProps {
  onChange: any;
  courier: CourierProps | null;
  selected?: boolean;
  background?: string;
  data: PricingsProps;
}

const CourierItem = ({
  onChange,
  courier,
  selected,
  background,
  data,
}: CourierItemProps) => {
  return (
    <Box
      padding={"10px 20px"}
      onClick={onChange}
      sx={{ cursor: "pointer" }}
      width={"100%"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Stack direction={"column"}>
            <NormalText
              fontFamily="D-DIN"
              fontSize={"14px"}
              textAlign={"left"}
              fontWeight={"bold"}
            >
              {`${data.logistic.name} ${data.rate.name} (${formatCurrency(
                data.final_price
              )})`}
            </NormalText>
          </Stack>
        </Stack>
        <Item>
          <RadioButton
            checkedBackground={background ?? "#f8e71c"}
            type="radio"
            name="delivery-type"
            checked={selected}
            onChange={onChange}
          />
          <RadioButtonLabel />
        </Item>
      </Stack>
    </Box>
  );
};

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-sizing: border-box;
  border: 4px solid #ccc;
`;

interface RadioButtonProps {
  checkedBackground?: string;
}

export const RadioButton = styled.input<RadioButtonProps>`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  box-sizing: border-box;
  &:hover ~ ${RadioButtonLabel} {
    &::after {
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
  }
  &:checked + ${RadioButtonLabel} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &::after {
      content: "";
      font-family: "FontAwesome";
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

export default CourierItem;
