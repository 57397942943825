import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppSettingState } from "./types";
import axios from "axios";
import { initialState } from './appsetting.reducers'

interface getApp {
    merchant_id: number
}

export const getAppSetting = createAsyncThunk('appSetting/list', async ({ merchant_id }: getApp) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/merchant/setting/getsetting?merchant_id=${merchant_id}&device_type=MOBILEQR`,
            config
        );
        if (response && response.data && response.data.data !== null) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as AppSettingState;
        } else {
            return {
                ...initialState, status: 'success'
            } as AppSettingState;
        }
    } catch (err) {
        if (err)
            return {
                ...initialState, status: 'success'
            } as AppSettingState;
    }
})
