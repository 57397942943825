import { useState, useEffect } from "react";
import MainLayout from "components/Layouts/MainLayout";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Stack, Grid } from "@mui/material";
//import { NormalText } from "components/styled/text.styled";
import IlustrasiList from "assets/images/cek-pesanan-ilustrasi.svg";
//import { getStatus, ShipmentStatus } from "redux/delivery";
import useRedux from "redux/useRedux";
import { ChekoutButton } from "components/BottomSheet";
//import { getOrderInvoice } from "redux/checkout";
//import moment from "moment";
//import { notify } from "helper/general";
import { NormalText } from "components/styled/text.styled";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import KembaliIcon from "../../assets/icons/kembali_ic_black.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import ImageLogo from "../../assets/images/GarpuSendok.svg";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import InfiniteScroll from "react-infinite-scroll-component";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import { formatCurrency } from "helper/general";

const OrderCekInput = () => {
  const navigate = useNavigate();
  // const [dataStatus, setDataStatus] = useState<ShipmentStatus>({
  //   code: 0,
  //   created_date: "",
  //   description: "",
  //   name: "",
  //   reason: "",
  //   track_url: "",
  //   updated_by: "",
  //   updated_date: "",
  // });
  const [orderNumber, setOrderNumber] = useState("");
  const [errorOrderNumber, setErrorOrderNumber] = useState("");
  const [orderName, setOrderName] = useState("");
  const [errorOrderName, setErrorOrderName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useState([]);

  const {
    storeState: { AppSetting, RegisterAddress },
  } = useRedux();

  async function getDetailOrder(event: any) {
    event.preventDefault();
    setSubmitted(true);
    if (!orderNumber || orderNumber.length === 0) {
      setErrorOrderNumber("Nomer Telephone wajib diisi!");
    } else {
      setErrorOrderNumber("");
    }

    if (!orderName || orderName.length === 0) {
      setErrorOrderName("Nama wajib diisi!");
    } else {
      setErrorOrderName("");
    }

    if (!orderName || !orderNumber) {
      setSubmitted(false);
      return false;
    }
    localStorage.setItem('user', JSON.stringify({orderName,orderNumber}))
    // navigate(`/order-detail/${orderNumber}`);
    navigate(`/order-check/`);

    // try {
    //   const response = await thunkDispatch(
    //     getOrderInvoice({ order_number: orderNumber })
    //   ).unwrap();
    //   console.log(response);
    //   if (response?.status === "error") {
    //     console.log("error");
    //     notify("error", "Terjadi kesalahan server!");
    //   } else {
    //     if (!response?.data?.data?.shipper_order_id) {
    //       notify("error", "Data tidak ditemukan!");
    //     } else {
    //       getShipmentStatus(response?.data?.data?.shipper_order_id);
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    //   notify("error", "Terjadi kesalahan server!");
    // }
  }

  useEffect(() => {
    setOrderName(RegisterAddress.data.full_name ?? '')
    setOrderNumber(RegisterAddress.data.phone ?? '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  // async function getShipmentStatus(orderShipId: any) {
  //   try {
  //     const responseShipment = await thunkDispatch(
  //       getStatus(orderShipId)
  //     ).unwrap();

  //     //console.log(responseShipment.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // let findOrderNumber = Delivery.orderList.find((order: any) => {
  //   //   return order.order_number === orderNumber;
  //   // });

  //   // thunkDispatch(getStatus(findOrderNumber.shipper_order_id))
  //   //   .unwrap()
  //   //   .then((res) => {
  //   //     console.log(res);
  //   //     setDataStatus(res.data.data.order.detail.shipmentStatus);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  // }
  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={AppSetting?.data?.data?.favicon ?? ""}
      title={AppSetting?.data?.data?.merchant_name ?? ""}
    >
      {/* {Delivery && Delivery.orderList.length > 0 ? (
        <Stack direction={"column"} alignItems={"center"}>
          <Box
            padding={"10px 20px 10px 20px"}
            style={{
              backgroundColor: "white",
              marginBottom: "20px",
              width: "100%",
              height: "60px",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                textAlign: "center",
              }}
            >
              <div onClick={() => navigate("/")}>
                <img
                  src={KembaliIcon}
                  alt={"Sandbox Logo"}
                  style={{
                    marginTop: "15px",
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <NormalText
                fontSize={"14px"}
                fontFamily="Open Sans"
                fontWeight="bold"
                margin="10px 0 0 80px"
              >
                Daftar Transaksi
              </NormalText>
            </div>
          </Box>
          <Navigation>
            {Delivery && Delivery.orderList.length !== 0 ? (
              <InfiniteScroll
                dataLength={5}
                next={() => console.log("fetchMoreData()")}
                hasMore={true}
                loader={
                  <NormalText
                    textAlign={"center"}
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    lineHeight={"24px"}
                    margin={"0px 0 40px 0"}
                  >
                    Load More...
                  </NormalText>
                }
              >
                {Delivery.orderList.map((item: any, i: any) => (
                  <Box
                    padding={"10px 20px 10px 20px"}
                    style={{
                      backgroundColor: "white",
                      width: "95%",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      onClick={() =>
                        navigate(`/order-detail/${item.order_number}`)
                      }
                    >
                      <Grid container direction={"row"}>
                        <Grid item sm={2}>
                          <img
                            src={ImageLogo}
                            alt="lgo"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <NormalText
                            fontSize={"14px"}
                            fontFamily="Open Sans"
                            fontWeight="bold"
                          >
                            {item.order_number ?? ""}
                          </NormalText>
                          <NormalText fontSize={"14px"} fontFamily="Open Sans">
                            {item.store_name ?? ""}
                          </NormalText>
                        </Grid>
                        <Grid item sm={4}>
                          <NormalText
                            fontSize={"12px"}
                            fontFamily="Open Sans"
                            textAlign="end"
                            color="#ccc"
                          >
                            {item.order_date ?? ""}
                          </NormalText>
                          <NormalText
                            fontSize={"13px"}
                            fontFamily="Open Sans"
                            textAlign="end"
                          >
                            {item.total_price
                              ? formatCurrency(Number(item.total_price))
                              : ""}
                          </NormalText>
                          <NormalText
                            fontSize={"12px"}
                            fontFamily="Open Sans"
                            textAlign="end"
                            color="#81D678"
                          >
                            {item && item.order_status
                              ? item.order_status.replace("_", " ")
                              : ""}
                          </NormalText>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                ))}
              </InfiniteScroll>
            ) : null}
          </Navigation>
        </Stack>
      )} */}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column",
        }}
      >
        <Box>
          <img
            src={IlustrasiList}
            alt="ilustrasi list pesanan"
            loading="lazy"
          />
        </Box>
        <NormalText
          fontSize="18px"
          fontWeight="bold"
          letterSpacing="1px"
          lineHeight="32px"
          margin="30px 0 0 0"
          color={"000000"}
          className={"intro-text-1"}
        >
          Cek Pesanan Saya
        </NormalText>
        <form onSubmit={getDetailOrder} className="w-100 mt-5 px-3">
          <div className="form-group w-100">
            <label>
              <NormalText
                fontSize="14px"
                fontWeight="bold"
                letterSpacing="0px"
                lineHeight="1"
                margin="0px 0 0 0"
                color={"000000"}
                className={"intro-text-1"}
              >
                Masukan Nama Anda
              </NormalText>
            </label>
            <input
              type="text"
              value={orderName}
              className={`form-control w-100  ${errorOrderName ? "error" : ""
                }`}
              placeholder="Masukan nama"
              onChange={(event) => {
                setOrderName(event.target.value);
              }}
            />
            <NormalText fontSize="12px" color="red">
              {errorOrderName}
            </NormalText>
            <label>
              <NormalText
                fontSize="14px"
                fontWeight="bold"
                letterSpacing="0px"
                lineHeight="1"
                margin="15px 0 0 0"
                color={"000000"}
                className={"intro-text-1"}
              >
                Masukan Nomer Handphone Anda
              </NormalText>
            </label>
            <InputTelephone
              type="number"
              value={orderNumber}
              className={`form-control w-100  ${errorOrderNumber ? "error" : ""
                }`}
              placeholder="Masukan nomer handphone"
              onChange={(event) => {
                setOrderNumber(event.target.value);
              }}
            />
            <NormalText fontSize="12px" color="red">
              {errorOrderNumber}
            </NormalText>
          </div>
          <div className="form-group w-100">
            <ChekoutButton
              type="submit"
              className="mt-3"
              color="#ffffff"
              background={AppSetting?.data?.data?.primary_color}
              borderRadius={"5px"}
              disabled={submitted}
            >
              Cek Pesanan
            </ChekoutButton>
            <ChekoutButton
              onClick={() => {
                navigate("/");
              }}
              type="button"
              className="mt-3"
              color={AppSetting?.data?.data?.primary_color}
              background="#ffffff"
              border={`2px solid ${AppSetting?.data?.data?.primary_color}`}
              borderRadius={"5px"}
            >
              Kembali
            </ChekoutButton>
          </div>
        </form>
      </Box>
    </MainLayout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  height: 100%;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-x: auto;
  @media only screen and (max-width: 768px) {
    width: 360px;
  }
`;

const InputTelephone = styled.input`
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

export default OrderCekInput;
