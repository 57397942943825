import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import SearchIcon from "assets/icons/search_ic.svg";

interface InputSearchType {
  placeholder?: string;
  value?: string;
  onChange?: any;
  border?: any;
  background?: string;
  onFocus?: any;
}

const InputSearch = ({
  placeholder,
  value,
  onChange,
  onFocus,
  background,
}: InputSearchType) => {
  return (
    <Box>
      <Icon background={background}>
        <img
          src={SearchIcon}
          alt={"search_icon"}
          style={{
            width: "14.06px",
            height: "14.06px",
          }}
        />
      </Icon>
      <Search
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        background={background}
        onFocus={onFocus}
      />
    </Box>
  );
};

const Icon = styled.i<InputSearchType>`
  position: absolute;
  height: 35px;
  width: 34.6px;
  background: ${(props) => props.background || "#FFC535"};
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  padding: 5px 0;
`;

const Search = styled.input<InputSearchType>`
  width: 100%;
  border: none;
  padding: 0 10px 0 43px;
  border: 1px solid ${(props) => props.background || "#FFC535"};
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 35px;
  font-family: D-DIN, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13.03px;
  outline: none;
  letter-spacing: 0.5px;

  :focus,
  :hover {
    border: 1px solid ${(props) => props.background || "#FFC535"};
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #bababa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bababa;
  }

  ::placeholder {
    color: #bababa;
  }
`;

export default InputSearch;
