import { ResponseProps, OrderState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { orderList } from './orderservices';

export const initialState: OrderState = {
    data: {} as ResponseProps,
    status: "loading",
    error: {}
}

export const orderSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(orderList.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(orderList.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(orderList.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const OrderReducer = orderSlice.reducer