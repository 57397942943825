import React from "react";
import { Box, Stack } from "@mui/material";
import { ReactComponent as CheckedImage } from "../../../assets/images/Icchecked.svg";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "components/styled/text.styled";
import useRedux from "redux/useRedux";
import { CategoryLabel } from "views/Brand/detail.page";
import CartProduct from "views/Cart/cart.product";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "helper/general";
import { Button } from "components/styled/button.styled";
import { resetCart } from "redux/cart/cart.reducer";
import { SELECTED_TABLE_KEY, SELECTED_TABLE_NAME_KEY } from "helper/constant";

const SuccessPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      Cart,
      Invoice,
      AppSetting: {
        data: {
          data: { primary_color, secondary_color, app_logo },
        },
      },
    },
  } = useRedux();

  const handleCheckOrder = () => {
    localStorage.removeItem(SELECTED_TABLE_KEY);
    localStorage.removeItem(SELECTED_TABLE_NAME_KEY);
    thunkDispatch(resetCart())
      .unwrap()
      .then((res) => {
        navigate(`/order-detail/${Invoice.data.data.order_number}`);
      })
      .catch();
  };

  React.useEffect(() => {
    console.log(Invoice);
    if (Invoice.status !== "success") {
      navigate("/home");
    }
  }, [Invoice, navigate]);

  return (
    <MainLayout
      background={"#f6f6f699"}
      title="Order Success"
      favIcon={app_logo}
    >
      {Invoice.status === "success" ? (
        <>
          <div className="container text-center" style={{ margin: "0 0 0 0" }}>
            <CheckedImage
              fill={primary_color ?? "#B92362"}
              style={{
                height: 60,
                width: 60,
                margin: "150px 0 0 0",
                userSelect: "none",
              }}
            />
            <NormalText
              fontSize="18px"
              margin="20px"
              fontWeight="600"
              fontFamily="D-DIN EXP"
            >
              {Invoice &&
              Invoice.data &&
              Invoice.data.data &&
              Invoice.data.data.payment_status === "PENDING"
                ? "MENUNGGU PEMBAYARAN"
                : "PEMBAYARAN BERHASIL"}
            </NormalText>
            {Invoice.data.data.order_number && (
              <>
                <NormalText margin={"15px 0 0 0"} fontWeight="bold">
                  Nomor Order
                </NormalText>
                <NormalText>{Invoice.data.data.order_number}</NormalText>
              </>
            )}
            {Invoice.data.data.payment_status === "PAID" && (
              <>
                <NormalText margin={"15px 0 0 0"} fontWeight="bold">
                  Nomor Antrian
                </NormalText>
                <NormalText>{Invoice.data.data.order_queue}</NormalText>
              </>
            )}
            <NormalText
              wordWrap="break-word"
              color="#000000"
              fontSize="12px"
              fontFamily="Open Sans"
              margin={"15px 0 0 0"}
            >
              {Invoice &&
              Invoice.data &&
              Invoice.data.data &&
              Invoice.data.data.payment_status === "PENDING" ? (
                <span>
                  Silahkan mendatangi kasir{" "}
                  <strong>
                    Toko{" "}
                    {Invoice &&
                    Invoice.data &&
                    Invoice.data.data &&
                    Invoice.data.data.store_name
                      ? Invoice.data.data.store_name
                      : ""}
                  </strong>{" "}
                  untuk melanjutkan pembayaran.
                </span>
              ) : (
                "Pesanan Anda sedang kami proses. Silahkan tunggu nomor pesanan anda dipanggil"
              )}
            </NormalText>
            <NormalText
              wordWrap="break-word"
              color="#000000"
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Open Sans"
              margin={"15px 0 0 0"}
            >
              {Invoice &&
              Invoice.data &&
              Invoice.data.data &&
              Invoice.data.data.store_address
                ? Invoice.data.data.store_address
                : ""}
            </NormalText>

            <Button
              background={primary_color}
              onClick={() => handleCheckOrder()}
              style={{
                color: "white",
                borderRadius: "0",
                width: "80%",
                minWidth: 200,
                margin: "20px 0 0 0",
              }}
            >
              Cek Pesanan
            </Button>
            <Button
              background={primary_color}
              onClick={() => navigate('/')}
              style={{
                color: "white",
                borderRadius: "0",
                width: "80%",
                minWidth: 200,
                margin: "20px 0 0 0",
              }}
            >
              Kembali ke halaman depan
            </Button>
          </div>
          <div
            style={{
              margin: "80px 0 0 0",
              background: "white",
              padding: "20px 0",
            }}
          >
            <NormalText
              margin="0 0 15px 20px"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
            >
              Order Saya
            </NormalText>
            {Cart.items &&
              Cart.items.length > 0 &&
              Cart.items.map((brand, prodItem) => (
                <div key={"cartcontainer-" + prodItem}>
                  <CategoryLabel background={primary_color ?? "#fff4f8"}>
                    <NormalText
                      fontSize={"12px"}
                      color={secondary_color ?? "#B92362"}
                      fontFamily="D-DIN Exp"
                      fontWeight="bold"
                      margin="0 0 0 20px"
                    >
                      {brand.brand_name}
                    </NormalText>
                  </CategoryLabel>
                  {brand.products &&
                    brand.products.length &&
                    brand.products.map((item, index: number) => (
                      <CartProduct
                        key={"cartitem-" + index}
                        background={primary_color ?? "#B92362"}
                        qty={item.qty}
                        showCounter={false}
                        customizable={item.additionals}
                        product_name={item.product_name}
                        product_price={Number(item.price)}
                        editNote={false}
                      />
                    ))}
                </div>
              ))}
            <CategoryLabel background={primary_color ?? "#fff4f8"}>
              <NormalText
                fontSize={"12px"}
                color={secondary_color ?? "#B92362"}
                fontFamily="D-DIN Exp"
                fontWeight="bold"
                margin="0 0 0 20px"
              >
                Payment Detail
              </NormalText>
            </CategoryLabel>
            <Box sx={{ background: "#f6f6f699" }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"5px 20px"}
              >
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  Order Fee
                </NormalText>
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  {formatCurrency(
                    Number(Invoice.data.data.payment_fee_customer)
                  )}
                </NormalText>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"5px 20px"}
              >
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  Tax & Service
                </NormalText>
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  {formatCurrency(
                    Number(Invoice.data.data.service_fee ?? 0) +
                      Number(Invoice.data.data.tax)
                  )}
                </NormalText>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"5px 20px"}
              >
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  Sub Total
                </NormalText>
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                >
                  {formatCurrency(Number(Invoice.data.data.sub_total))}
                </NormalText>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"10px 10px"}
              >
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                  fontWeight={"bold"}
                >
                  Total Payment
                </NormalText>
                <NormalText
                  fontSize={"12px"}
                  color={"black"}
                  fontFamily="D-DIN Exp"
                  fontWeight={"bold"}
                >
                  {formatCurrency(Number(Invoice.data.data.total))}
                </NormalText>
              </Stack>
            </Box>
          </div>
        </>
      ) : (
        <p>Not Valid</p>
      )}
    </MainLayout>
  );
};

export default SuccessPage;
