import { SubUrbanState, SubUrbanResponseProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getAllSubUrban } from "./suburban.services";

export const initialState: SubUrbanState = {
  data: {} as SubUrbanResponseProps,
  status: "loading",
  error: {},
};

export const SuburbanSlice = createSlice({
  name: "suburban",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubUrban.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllSubUrban.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getAllSubUrban.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const SubUrbanReducer = SuburbanSlice.reducer;
