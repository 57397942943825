import React, { ReactNode } from "react";
import * as S from './styles';

interface Props {
  children: ReactNode;
}

const Footer = ({ children }: Props) => {
  return (
    <>
      <S.Footer>
        {children}
      </S.Footer>
    </>
  );
};

export default Footer;
