import React, { useState } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import BackButtonToHome from "../../components/Button/back.button.to.home";
import Checkbox from "components/Forms/Checkbox";
import InputField from "components/Forms/InputField";
import { useParams, useNavigate } from "react-router-dom";
import useRedux from "../../../src/redux/useRedux";
import { getAppSetting, AppSettingData } from "redux/appsetting";
import { detailProduct, ProductsDetail, addOnProduct } from "redux/product";
import { ChekoutButton } from "components/BottomSheet";
import CounterButton from "components/Button/counter";
import {
  addToCart,
  ProductsTypes,
  ProductAdditional,
} from "redux/cart/cart.reducer";

import { groupBy, formatCurrency } from "helper/general";
import NoPhoto from 'assets/images/NoPhotoDetail.svg';

const ProductDetailByQr = () => {
  const navigate = useNavigate();
  const [dataApp, setDataApp] = useState<AppSettingData>();
  const [addons, setAddons] = React.useState<any[]>([]);
  const [keyAddOn, setKeyAddons] = React.useState<any>([]);
  const [product, setProduct] = React.useState<ProductsDetail>();
  const [notes, setNotes] = React.useState("")
  const { key, storeId, merchantId, productId } = useParams();

  const [qty, setQty] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const { thunkDispatch } = useRedux();
  // eslint-disable-next-line

  React.useEffect(() => {
    if (merchantId) {
      //console.log("GETT APP SETTING 1");
      thunkDispatch(
        getAppSetting({
          merchant_id: Number(merchantId),
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setDataApp(res.data.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useState(() => {
    if (key && storeId && merchantId && productId) {
      let data = {
        product_id: productId ? productId : 0,
        merchant_id: merchantId ? merchantId : 0,
        store_id: storeId ? storeId : 0,
      };
      setLoading(true);
      thunkDispatch(
        getAppSetting({
          merchant_id: Number(merchantId),
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setDataApp(res.data.data);
          }
        });

      thunkDispatch(detailProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            console.log(res.data.data);
            setLoading(false);
            setProduct(res.data.data);
            if (res.data.data?.product_detail?.is_customizable) {
              getAddOn();
            }
          }
        });
    }
  });

  const getAddOn = () => {
    if (storeId || merchantId) {
      let data = {
        product_id: productId,
        merchant_id: merchantId,
        store_id: storeId,
      };
      setLoading(true);
      thunkDispatch(addOnProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            if (
              res.data.data &&
              res.data.data.productAddOn &&
              res.data.data.productAddOn.length !== 0
            ) {
              let resTemp = groupBy(res.data.data.productAddOn, "product_type");
              setAddons(resTemp);
              setKeyAddons(Object.keys(resTemp));
            }
          }
        });
    }
  };

  const productToCart = (props: ProductsTypes) => {
    if (keyAddOn && keyAddOn.length > 0) {
      let adds: ProductAdditional[] = [];
      keyAddOn.forEach((key: any) => {
        // eslint-disable-next-line
        addons[key].map((item: any) => {
          if (item.value) {
            adds.push({
              id: item.product_assign_id,
              name: item.product_name,
              qty: 1,
              price:
                Number(item.product_price_after_discount) > 0
                  ? item.product_price_after_discount
                  : item.product_price,
              product_id: item.product_id,
            } as ProductAdditional);
          }
        });
      });
      props.additionals = adds;
    }
    thunkDispatch(addToCart(props)).then(() => {
      navigate("/home");
    });
  };

  return (
    <MainLayout background={"white"} favIcon={""} title={""}>
      <HeaderContainer url={product?.product_detail?.product_image_main && product?.product_detail?.product_image_main.length > 0 ? product?.product_detail?.product_image_main : NoPhoto}>
        <BackButtonToHome />
      </HeaderContainer>
      <Box padding={"10px 20px 10px 20px"}>
        <NormalText fontSize={"14px"} fontFamily="Open Sans" fontWeight="bold">
          {product?.product_name ?? ""}
        </NormalText>
        <NormalText fontSize={"12px"} fontFamily="Open Sans">
          {product?.product_description?.short_description ?? ""}
        </NormalText>
        {product &&
          product.product_detail &&
          product.product_detail.is_customizable &&
          keyAddOn &&
          keyAddOn.length !== 0 && (
            <div>
              <NormalText
                fontSize={"22px"}
                fontFamily="Open Sans"
                fontWeight="bold"
              >
                {formatCurrency(
                  Number(
                    product?.product_detail?.product_price_after_discount
                  ) > 0
                    ? Number(
                      product?.product_detail?.product_price_after_discount
                    )
                    : Number(product?.product_detail?.product_price)
                )}
              </NormalText>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {product &&
                  product.product_detail &&
                  product.product_detail.discount_type === "percent" ? (
                  <BoxDiscount>
                    <NormalText
                      fontSize={"10px"}
                      fontFamily="Open Sans"
                      fontWeight="bold"
                      color="#B92362"
                    >
                      {product?.product_detail?.discount ?? ""}%
                    </NormalText>
                  </BoxDiscount>
                ) : null}
                {product &&
                  product.product_detail &&
                  product.product_detail.product_price && (
                    <NormalText
                      fontSize={"12px"}
                      fontFamily="Open Sans"
                      fontWeight="bold"
                      color="#A1A6AD"
                      textDecoration="line-through"
                    >
                      {formatCurrency(
                        Number(product?.product_detail?.product_price ?? 0)
                      )}
                    </NormalText>
                  )}
              </div>
            </div>
          )}
      </Box>
      <Box margin={"10px 0 50px 0"}>
        {product &&
          product.product_detail &&
          product.product_detail.is_customizable &&
          keyAddOn &&
          keyAddOn.length !== 0 &&
          keyAddOn.map((x: any, index: number) => (
            <Box key={index + "-content"}>
              <CategoryLabel
                style={{
                  backgroundColor: dataApp?.primary_color ?? "#B92362",
                }}
              >
                <NormalText
                  fontSize={"12px"}
                  color={dataApp?.secondary_color ?? "#000"}
                  fontFamily="D-DIN Exp"
                  fontWeight="bold"
                  margin="0 0 0 24px"
                >
                  {x}
                </NormalText>
              </CategoryLabel>
              <Box margin={"10px 20px"}>
                <Stack direction={"column"} spacing={2}>
                  {addons[x] &&
                    addons[x].length &&
                    addons[x].map((item: any, i: number) => (
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Checkbox
                          background={dataApp?.primary_color ?? "#B92362"}
                          key={i}
                          checked={item.value}
                          onClick={() => {
                            let temp = [...addons[x]];
                            temp[i].value = !temp[i].value;
                            setAddons({
                              ...addons,
                              [x]: temp,
                            });
                          }}
                          onChange={(e) => console.log(e)}
                          label={item.product_name}
                        />
                        <NormalText
                          fontSize={"14px"}
                          color={"#010101"}
                          fontFamily="D-DIN Exp"
                          margin="0 0 0 24px"
                        >
                          {formatCurrency(
                            Number(item.product_price_after_discount) > 0
                              ? Number(item.product_price_after_discount)
                              : Number(item.product_price)
                          ) ?? ""}
                        </NormalText>
                      </div>
                    ))}
                </Stack>
              </Box>
            </Box>
          ))}
        <CategoryLabel
          style={{
            backgroundColor: dataApp?.primary_color ?? "#B92362",
          }}
        >
          <NormalText
            fontSize={"12px"}
            color={dataApp?.secondary_color ?? "#FFF4F8"}
            fontFamily="D-DIN Exp"
            fontWeight="bold"
            margin="0 0 0 24px"
          >
            Notes
          </NormalText>
        </CategoryLabel>
        <Box margin={"10px 20px"}>
          <InputField placeholder={"Notes"} onChange={(e) => setNotes(e.target.value)} />
        </Box>
      </Box>
      <br />
      {!loading && (
        <>
          <FooterContainer
            display={"flex"}
            sx={{
              width: "100%",
              placeContent: "center",
              top: "85%",
              position: "fixed",
            }}
          >
            <CounterButton
              value={qty}
              background={dataApp?.primary_color ?? "black"}
              onIncrease={() => setQty(qty + 1)}
              onDecrease={() => {
                if (qty === 1) {
                  setQty(1);
                } else {
                  setQty(qty - 1);
                }
              }}
            />
          </FooterContainer>
          <FooterContainer
            sx={{
              padding: "20px",
              bottom: 0,
              display: "flex",
              width: "100%",
              position: "fixed",
            }}
          >
            <ChekoutButton
              background={dataApp?.primary_color ?? "black"}
              onClick={() => {
                if (product) {
                  productToCart({
                    is_customizable: product.product_detail.is_customizable,
                    product_id: product.product_id,
                    qty: qty,
                    product_name: product.product_name,
                    product_image: "",
                    brand_id: product.brand.id ?? "",
                    brand_name: product.brand.brand_name ?? "",
                    notes: notes,
                    additionals: [],
                    price:
                      Number(product.product_detail.product_price_after_discount) > 0 ? product.product_detail.product_price_after_discount :
                        product.product_detail.product_price,
                  });
                }
              }}
            >
              Tambah Ke Keranjang
            </ChekoutButton>
          </FooterContainer>
        </>
      )}
    </MainLayout>
  );
};

interface HeaderProps {
  url: string;
}

const FooterContainer = styled(Box)`
  @media only screen and (min-width: 768px) {
    max-width: 360px;
  }

  @media only screen and (max-width: 320px) {
    position: relative !important;
  }
`;

const HeaderContainer = styled.div<HeaderProps>`
  height: 221px;
  width: 100%;
  top: 0;
  position: relative;
  background: ${(props) => "url(" + props.url + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
`;

const CategoryLabel = styled.div`
  width: 100%;
  height: 35px;
  align-items: center;
  display: flex;

  background: #fff4f8;
`;

const BoxDiscount = styled.div`
  width: 26px;
  height: 18px;
  color: #b92362;
  justify-content: center;
  background-color: #fff4f8;
  padding: 2px;
  margin-right: 5px;
`;

export default ProductDetailByQr;
