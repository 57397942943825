import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRate = createAsyncThunk("getRate", async (props: any) => {
  const config = {
    headers: {
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/master/domesticrates?storeId=${props.storeId}&destinationAreaId=${props.destinationAreaId}&price=${props.price}&weight=${props.weight}&length=${props.length}&wide=${props.wide}&height=${props.height}&storeType=${props.storeType}&deliveryType=${props.deliveryType}&rateType=${props.rateType}`,
      config
    );
    if (response && response.data) {
      return {
        data: response.data,
        status: "success",
        error: null,
      };
    } else {
      return {
        status: "error",
        error: null,
      };
    }
  } catch (error) {
    return {
      data: "Something went wrong!",
      status: "error",
      error: error,
    };
  }
});

export const getStatus = createAsyncThunk("getStatus", async (props: any) => {
  const config = {
    headers: {
      api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shipper/tracking-shipment-status?id=${props}`,
      config
    );

    if (response && response.data) {
      return response;
    } else {
      return {
        status: "error",
        error: null,
      };
    }
  } catch (err) {
    return {
      data: "Something went wrong!",
      status: "error",
      error: err,
    };
  }
});

export const orderSetData = createAsyncThunk(
  "orderSetData",
  async (props: any) => {
    return {
      ...props,
    };
  }
);
