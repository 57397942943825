import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { NormalText } from "../styled/text.styled";

interface CheckboxTypes {
  className?: string;
  label?: string;
  background?: string;
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> & (() => void);
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  className,
  label,
  checked,
  onChange,
  onClick,
  background,
}: CheckboxTypes) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      alignItems={"center"}
      sx={{ cursor: "pointer" }}
    >
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} onChange={onChange} />
        <StyledCheckbox
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          background={background}
        >
          <Icon viewBox="0 0 24 24" checked={checked}>
            <polyline points="20 5 10 15 5 9" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      {label && (
        <NormalText
          fontFamily="Open Sans"
          fontSize="12px"
          fontWeight={"400"}
          onClick={onClick}
        >
          {label}
        </NormalText>
      )}
    </Stack>
  );
};

const CheckboxContainer = styled.div`
  display: inline-block;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.svg<CheckboxTypes>`
  fill: none;
  stroke: ${(props) => (props.checked ? "white" : "#A3A3A3")};
  stroke-width: 1px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<CheckboxTypes>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked ? `${props.background ?? "black"}` : "transparent"};
  border: ${(props) =>
    props.checked ? "1px solid #B92362" : "1px solid #CECECE"};
  border-radius: 3px;
  transition: all 150ms;

  ${Icon} {
    visibility: visible;
  }
`;

export default Checkbox;
