import { Stack } from "@mui/material";
import NoPhoto from "assets/images/NoPhotoDetail.svg";
import IconStore from "assets/icons/icon store.svg";
import { formatCurrency } from "helper/general";

interface NewProductListItemProps {
  name?: string;
  image?: string;
  storeName?: string;
  discount?: any;
  discount_type?: any;
  price?: string;
  price_after_discount?: any;
  type?: "row" | "column";
  onClick?:any;
}

const NewProductListItem = ({
  name,
  image,
  storeName,
  discount,
  discount_type,
  price,
  price_after_discount,
  type,
  onClick
}: NewProductListItemProps) => {
  return type === "row" ? (
    <Stack
      className="mx-3 product-item-container"
      direction={"column"}
      justifyContent={"space-between"}
      height=" 240px"
      onClick={onClick}
    >
      <div>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          className="product-image mt-1"
        >
          {discount && discount_type === "percent" ? (
            <div className="product-discount-tag">
              <div className="product-discount-tag__label">Diskon</div>
              <div className="product-discount-tag__discount">{discount}%</div>
            </div>
          ) : null}

          <img
            src={image ? image : NoPhoto}
            alt="store-logo"
            className="product-image__img"
            loading="lazy"
          />
        </Stack>
        <p className="product-name mt-3 mb-0">
          {name && name.length > 35
            ? name?.toUpperCase().substring(0, 35) + "..."
            : name?.toUpperCase()}
        </p>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"20px"}
            height={"20px"}
            sx={{ borderRadius: "50%", overflow: "hidden" }}
          >
            <img src={IconStore} className="w-100" alt="icon store" />
          </Stack>
          <p className="product-store-name ml-1 mb-0">
            {storeName && storeName?.length > 20
              ? storeName?.slice(0, 20) + "..."
              : storeName}
          </p>
        </Stack>
      </div>

      <div>
        {discount || Number(discount) > 0 ? (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={0}
            width={"100%"}
            className="m-0"
          >
            <p className="product-price-before-discount mb-0">
              {formatCurrency(Number(price))}
            </p>
          </Stack>
        ) : null}

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={0}
          className="mt-1"
        >
          <p className="product-price-label mb-0">Harga</p>

          <p className="product-price mb-0">
            {price_after_discount && Number(price_after_discount) > 0
              ? formatCurrency(Number(price_after_discount))
              : formatCurrency(Number(price))}
          </p>
        </Stack>
      </div>
    </Stack>
  ) : (
    <Stack
      className="product-item-container-v2 px-2 pt-2 pb-3"
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      height=" 210px"
      onClick={onClick}
    >
      <div className="w-100">
        <Stack direction={"row"} alignItems={"center"}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"20px"}
            height={"20px"}
            sx={{ borderRadius: "50%", overflow: "hidden" }}
          >
            <img src={IconStore} className="w-100" alt="icon store" />
          </Stack>
          <p className="product-store-name ml-1 mb-0">
            {storeName && storeName?.length > 20
              ? storeName?.slice(0, 20) + "..."
              : storeName}
          </p>
        </Stack>

        <Stack
          className="product-image-v2 mt-2"
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          {discount && discount_type === "percent" ? (
            <div className="product-discount-tag">
              <div className="product-discount-tag__label">Diskon</div>
              <div className="product-discount-tag__discount">{discount}%</div>
            </div>
          ) : null}

          <img
            src={image ? image : NoPhoto}
            alt="store-logo"
            className="product-image-v2__img"
            loading="lazy"
          />
        </Stack>
        <p className="product-name mt-2 mb-0">
          {name && name.length > 35
            ? name?.toUpperCase().substring(0, 35) + "..."
            : name?.toUpperCase()}
        </p>
      </div>

      <div className="w-100">
        {discount || Number(discount) > 0 ? (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={0}
            width={"100%"}
            className="m-0"
          >
            <p className="product-price-before-discount mb-0">
              {formatCurrency(Number(price))}
            </p>
          </Stack>
        ) : null}

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={0}
          className="mt-1"
        >
          <p className="product-price-label mb-0">Harga</p>

          <p className="product-price mb-0">
            {price_after_discount && Number(price_after_discount) > 0
              ? formatCurrency(Number(price_after_discount))
              : formatCurrency(Number(price))}
          </p>
        </Stack>
      </div>
    </Stack>
  );
};

export default NewProductListItem;
