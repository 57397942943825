import { RegisterAddressState, RegisterAddressProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { registerSetData, registerClearData } from "./index";

export const initialState: RegisterAddressState = {
  data: {} as RegisterAddressProps,
  status: "loading",
  error: {},
};

export const registerAddressSlice = createSlice({
  name: "register-address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerSetData.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = {
            ...action.payload,
          };
          state.status = "success";
          state.error = "";
        }
      })
      .addCase(registerSetData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(registerSetData.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(registerClearData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = "success";
        state.error = action.payload.error;
      })
  },
});
export const RegisterAddressReducer = registerAddressSlice.reducer;
