// import { useState } from "react";
import * as React from "react";
import MainLayout from "components/Layouts/MainLayout";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Avatar, Stack, Grid } from "@mui/material";
//import { NormalText } from "components/styled/text.styled";
//import { getStatus, ShipmentStatus } from "redux/delivery";
import useRedux from "redux/useRedux";
//import { getOrderInvoice } from "redux/checkout";
import moment from "moment";
import 'moment/locale/id';
//import { notify } from "helper/general";
import { NormalText } from "components/styled/text.styled";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import IconBack from "assets/icons/ic_back-button.svg";
import IconYourOrder from "assets/icons/ic_your_order.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import IconPending from "assets/icons/ic_pending.svg";
import IconBaru from "assets/icons/ic_baru.svg";
import IconProses from "assets/icons/ic_proses.svg";
import IconSiapPickup from "assets/icons/ic_siap_pickup.svg";
import IconDikirim from "assets/icons/ic_dikirim.svg";
import IconBatal from "assets/icons/ic_batal.svg";
import IconSelesai from "assets/icons/ic_selesai.svg";
import styled from "styled-components";
import { showList } from 'redux/order';
import { formatCurrency } from 'helper/general';
import NoPhoto from "assets/images/NoPhotoList.svg";



// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import InfiniteScroll from "react-infinite-scroll-component";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import { formatCurrency } from "helper/general";

const OrderCekInput = () => {
  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = React.useState("ALL");
  const [indexStatus, setIndexStatus] = React.useState(0);
  const [listOrder, setlistOrder] = React.useState<any[]>();
  const [listOrderPending, setlistOrderPending] = React.useState<any[]>();
  moment.locale('id');
  const [user, setUser] = React.useState<any>([]);


  const {
    thunkDispatch,
    storeState: { AppSetting, StoreInfo },
  } = useRedux();

  const listStatus = [
    { 
      name: "Semua",
      status:'ALL',
      icon:'', 
    },
    { 
      name:"Baru",
      status:'NEW_ORDER',
      icon: IconBaru, 
    },
    { 
      name:"Proses",
      status:'PROCESS',
      icon: IconProses, 
    },
    { 
      name:"Siap dipickup",
      status:'READY_TO_PICKUP', 
      icon: IconSiapPickup,
    },
    { 
      name:"Dikirim",
      status:'DELIVERY', 
      icon: IconDikirim,
    },
    { 
      name:"Selesai",
      status:'CLOSED',
      icon: IconSelesai,
    },
    { 
      name:"Batal",
      status:'CANCELED', 
      icon: IconBatal,
    },
    { 
      name:"Tertunda",
      status:'PENDING', 
      icon: IconPending,
    },
  ];
 
  const listOrderType = [
    {
      order_type:"TAKE AWAY",
      nama:"Bawa Pulang"
    },
    {
      order_type:"DINE IN",
      nama:"Makan Di Tempat"
    },
    {
      order_type:"DELIVERY",
      nama:"Pesan Antar"
    },
    {
      order_type:"PICKUP POINT",
      nama:"Ambil Di Titik Pengambilan"
    },
    {
      order_type:"IN STORE PICKUP",
      nama:"Ambil Di Toko"
    },
  ]

  React.useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);


  // React.useEffect(() => {
  //   let data = {
  //     store_id: 47,
  //   };
  //   thunkDispatch(myOrderList(data))
  //     .unwrap()
  //     .then((res: any) => {
  //       if (res && res.status === 'success') {
  //         console.log(res)
  //       }
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [thunkDispatch]);
  
  // get list order status pending
  React.useEffect(() => {
    let name = typeof user.orderName !== 'undefined' ? user.orderName : '' ;
    let data = {
      offset: 0,
      limit: 10,
      store_id: StoreInfo.data.data.id,
      status_order: "PENDING",
      filter: name,
    };
    thunkDispatch(showList(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          setlistOrderPending(res.data.data)
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thunkDispatch, user]);

  // get data list order
  React.useEffect(() => {
    let name = typeof user.orderName !== 'undefined' ? user.orderName : '' ;
    let data = {
      offset: 0,
      limit: 10,
      store_id: StoreInfo.data.data.id,
      status_order: activeStatus,
      filter: name,
    };
    thunkDispatch(showList(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          setlistOrder(res.data.data)
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thunkDispatch, activeStatus, user]);




  // console.log(listOrderPending)
  // console.log(listOrderPending?.length)
  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={AppSetting?.data?.data?.favicon ?? ""}
      title={AppSetting?.data?.data?.merchant_name ?? ""}
    >
      <Box
        sx={{
          width: "100%",
          height: "125px",
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          background: AppSetting?.data?.data?.primary_color ?? "#FFCE31",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "white",
            fontSize: "unset",
            width: "25px",
            height: "25px",
            boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
            position:'absolute',
            left:'20px',
            top:'45px',

          }}
          onClick={() => navigate('/order-detail-status')}
        >
          <BackButtonIcon src={IconBack} alt={"back_icon"} />
        </Avatar>
        <NormalText
          fontSize="22px"
          fontWeight="bold"
          letterSpacing="1px"
          lineHeight="32px"
          margin="45px 0 0 0"
          color={"000000"}
          className={"intro-text-1"}
        >
          Cek Status Pesanan
        </NormalText>
        <YourOrderIcon src={IconYourOrder}/>
      </Box>
      <Box
        sx={{
          bgcolor:"white",
          width: "100%",
          height: "100%",
          background: 'white',
        }}
      >
         <Box margin={"0 0 0 0"}>
            <Navigation>
              <UnorderList>
                {listStatus &&
                  listStatus.map((item, index) => (
                    <ListItem
                      key={index}
                      active={item.status === activeStatus}
                      color={AppSetting?.data?.data?.primary_color}
                      onClick={() => {
                        setActiveStatus(item.status);
                        setIndexStatus(index);
                      }}
                    >
                      <NormalText fontSize="12px">
                        {item.name}
                      </NormalText>
                    </ListItem>
                  ))}
              </UnorderList>
            </Navigation>
          </Box>
          <Box>
          {listOrder && 
           listOrder.length > 0 && 
           listOrder.filter((data) => data.customer_phone === user.orderNumber)
           .map((item:any, index) => {

            // search total of another product
            let totalProduct = 0;
            item.order_detail.map((data:any) => {return totalProduct += data.product_qty});
            let otherTotal = totalProduct - item.order_detail[0].product_qty;

            return (
              <ListOrder 
                onClick={() => navigate(`/order-detail/${item.order_number}`)}
                color={AppSetting?.data?.data?.primary_color} 
              >
                <Box sx={{ margin:"20px"}}/>
                <Box sx={{cursor:'pointer'}}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box 
                      sx={{
                        margin:"0 0 0 15px",
                      }}
                      >
                      <Stack direction={"column"}>
                        <NormalText 
                          fontSize="10px" 
                          fontWeight="700"
                          >
                          {listStatus.filter((data) => data.status === item.status_order).map(data => data.name)}
                        </NormalText>
                        <NormalText
                          fontSize="14px" 
                          fontWeight="700"
                          >
                          {listOrderType.filter((data) => data.order_type === item.order_type).map(data => data.nama)}
                        </NormalText>
                        <NormalText
                          fontSize="12px" 
                          fontWeight="400"
                        >
                          {moment(item.payment_date).format('dddd, ll - LTS')}
                        </NormalText>
                      </Stack>
                    </Box>

                    {/* Show icon status */}
                    <Box
                      sx={{ margin:"10px 20px 0 15px" }}
                    >
                      { listStatus[indexStatus].status === "ALL"
                        ? listOrderPending && 
                          listOrderPending?.length > 0 &&
                          listOrderPending.find( data => data.order_number === item.order_number ) 

                          // show icon pending and another it
                          ? <IconImage src={IconPending} alt={"Icon"}/>   
                          : listStatus.filter((data) => data.status === item.status)
                            .map((status, index) => {
                              return (
                                status.icon && 
                                <IconImage src={status.icon} alt={"Icon"}/>
                              )
                            }) 
                        : ''
                      }

                      {listStatus &&
                        listStatus.filter((item) => item.status === listStatus[indexStatus].status)
                        .map((status, index) => {
                          return (
                            status.icon && 
                            <IconImage src={status.icon} alt={"Icon"}/>
                          )
                        }) 
                      }
                    </Box>

                  </Stack>
                  <Box 
                    sx={{
                      width:"308px",
                      border:"1px solid #CCCCCC",
                      borderRadius:"2px",
                      marginLeft:"15px",
                      marginTop:"5px",                  
                    }}
                  />
                  <Stack direction={"row"} justifyContent={"start"}>
                    <Box
                      sx={{
                        margin:"10px 10px 10px 15px",
                      }}
                      >
                      {/* <ProductImage src={IconYourOrder}/> */}
                      <ProductImage 
                        src={ 
                          item.order_detail[0].product_image.length === 0
                            ? NoPhoto
                            : item.order_detail[0].product_image
                        }
                      />
                    </Box>
                    <Stack direction={"column"}>
                        <NormalText 
                          fontSize="14px" 
                          fontWeight="700"
                          margin="10px 0 0 0"
                          >
                          {item.order_detail[0].product_name &&
                            item.order_detail[0].product_name.length > 31 
                              ? item.order_detail[0].product_name.substring(0, 31) + "..." 
                              : item.order_detail[0].product_name
                          }
                        </NormalText>
                        <NormalText
                          fontSize="12px" 
                          fontWeight="400"
                          >
                          {item.order_detail[0].product_qty} Barang
                          {otherTotal === 0
                            ? ''
                            : `, (+${otherTotal} Produk lainnya)`
                          }
                        </NormalText>
                        <NormalText
                          fontSize="14px" 
                          fontWeight="700"
                          >
                          {formatCurrency(Number(item.total_amount))}
                        </NormalText>
                      </Stack>
                  </Stack>
                </Box>
              </ListOrder>
            )})
          }
          {listOrder && 
            listOrder.length === 0 && (
              <Box
                sx={{
                  width: "100%",
                  height: "125px",
                  display: "flex",
                  justifyContent: "center",
                  margin:"40px 0",
                }}
              >
                <NormalText>
                  {listStatus[indexStatus].status === "ALL"
                    ? "Belum ada pesanan"
                    : `Belum ada pesanan berstatus ${listStatus[indexStatus].name}`
                  }
                  
                </NormalText>
              </Box>
            )
          }
          </Box>
      </Box>

    </MainLayout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  height: 100%;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-x: auto;
  @media only screen and (max-width: 768px) {
    width: 360px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const BackButtonIcon = styled.img`
  cursor: pointer;
  width: 12px;
  height: auto;
`;
const YourOrderIcon = styled.img`
  width: 170px;
  height: auto;
  position: absolute;
  right: -1px;
  top: 22px;

`;

const UnorderList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  width: 100%;
  margin-left: 8px;
  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

interface ListProps {
  active?: boolean;
  background?: string;
}

const ListItem = styled.li<ListProps>`
  transition: ease-in-out 0.2s;
  display: block;
  max-width: 100%;
  margin-right: 5px;
  margin-top: 15px;
  margin-left: 2px;
  font-family: "D-DIN EXP";
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: black;
  text-underline-offset: 6px;
  font-size: 14px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  min-width: 90px;
  border: 1.5px solid ${(props) => (props.color ? props.color + "60" : "#FFEE8E")};
  border-radius: 30px;
  background-color: ${(props) => (props.active ? props.color + "70" : "#FFFFFF")};
  padding: 4px 2px;

  :hover {
    font-weight: bold;
  }
`;

const ListOrder = styled.div`
  width: 340px;
  height: 159.74px;
  align-items: center;  
  position: relative;
  border: 1px solid ${(props) => (props.color ? props.color : "#FFEE8E")}  ;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
`;

const ProductImage = styled.img`
  width: 58px;
  height: 58px;
`;

const IconImage = styled.img`
  width: 30px;
  height: 30px;
`;



export default OrderCekInput;
