import { Grid, Box } from "@mui/material";
import StoreListItem from "./store.list.item";
import { StoreQrGroupInfoTypes } from "redux/storeInfo";
import StoreListFilter from "./store.list.filter";
import { useNavigate } from "react-router-dom";

interface StoreListFullProps {
  storeList?: StoreQrGroupInfoTypes[];
  className?: string;
  type?: number;
  withFilter?: boolean;
  filterAge?: any;
  filterAsc?: any;
  filterDesc?: any;
  handleFilterAge?: any;
  handleFilterAsc?: any;
  handleFilterDesc?: any;
  primaryColor: any;
}

const StoreListFull = ({
  storeList,
  className,
  type,
  filterAge,
  filterAsc,
  filterDesc,
  handleFilterAge,
  handleFilterAsc,
  handleFilterDesc,
  primaryColor,
}: StoreListFullProps) => {
  const navigate = useNavigate();
  const handleNavigate = (data: StoreQrGroupInfoTypes) => {
    navigate(
      `/qr-group/store/${data?.store_name?.toLowerCase().replace(/ /g, "")}`, { state: data }
    );
  };

  return type === 1 ? (
    <Grid container className={className} spacing={4} alignItems={"center"}>
      {storeList && storeList.length > 0
        ? storeList.map((store) => {
            return (
              <Grid item xs={6} key={store.id}>
                <StoreListItem
                  storeLogo={store.store_logo}
                  storeName={store.store_name}
                  storeLocation={store.city.city_name}
                  key={store.id}
                  type="column"
                  primaryColor={primaryColor}
                  onClick={()=>{handleNavigate(store)}}
                />
              </Grid>
            );
          })
        : null}
    </Grid>
  ) : (
    <Box className="mt-4">
      <StoreListFilter
        filterAge={filterAge}
        filterAsc={filterAsc}
        filterDesc={filterDesc}
        handleFilterAge={handleFilterAge}
        handleFilterAsc={handleFilterAsc}
        handleFilterDesc={handleFilterDesc}
        primaryColor={primaryColor}
      />
      <Grid container className="mt-1 px-3" spacing={4} alignItems={"center"}>
        {storeList && storeList.length > 0
          ? storeList.map((store) => {
              return (
                <Grid item xs={6} key={store.id}>
                  <StoreListItem
                    storeLogo={store.store_logo}
                    storeName={store.store_name}
                    storeLocation={store.city.city_name}
                    key={store.id}
                    type="column"
                    primaryColor={primaryColor}
                    onClick={()=>{handleNavigate(store)}}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
    </Box>
  );
};

export default StoreListFull;
