import React, { useState } from 'react';
import { AppContainer } from '../../components/styled/layout.styled';
import { NormalText } from '../../components/styled/text.styled';
import { Stack } from '../../components/styled/layout.styled';
import MainLayout from 'components/Layouts/MainLayout';
import './styles.css';
import { postProfile } from '../../../src/redux/profile';
import useRedux from '../../../src/redux/useRedux';
import PopUp from '../../../src/components/Modal/modal';
import { Button as ButtonMUI, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppSettingData } from 'redux/appsetting';
import styled from 'styled-components';
import KembaliIcon from '../../assets/icons/kembali_ic.svg';

const ProfilePage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { RegisterUser, AppSetting, StoreInfo, CheckCustomer },
  } = useRedux();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorNama, setErrorNama] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [data, setData] = useState({
    nama: CheckCustomer?.data?.data?.name ?? '',
    email: CheckCustomer?.data?.data?.email ?? '',
    phoneNo: CheckCustomer?.data?.data?.phone_number ?? '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null,
  );

  const handleChange = (event: any) => {
    // use spread operator
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddUser = () => {
    let dataObject = {
      name: data.nama,
      email: data.email,
      phone_number: data.phoneNo,
      merchant_id: StoreInfo?.data?.data?.merchant_id ?? '',
    };
    thunkDispatch(postProfile(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setStatusModal('failed');
          setOpenModal(true);
        }
      })
      .catch((err: any) => {
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
  };

  const validateEmail = () => {
    const regex =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      !data.email ||
      regex.test(data.email) === false ||
      !data.nama ||
      !data.phoneNo
    ) {
      setError(true);
      setErrorText(
        !data.email
          ? 'Wajib diisi'
          : regex.test(data.email) === false
          ? 'Format data harus berupa email (contoh: email@sandbox.co.id)'
          : '',
      );
      setErrorPhone(!data.phoneNo ? 'Nomor Telepon Wajib diisi' : '');
      setErrorNama(!data.nama ? 'Nama Wajib diisi' : '');
    } else if (data.email.length > 255 || !data.nama || !data.phoneNo) {
      setError(true);
      setErrorText(
        data.email.length > 255 ? 'Maksimal karakter adalah 255 Karakter' : '',
      );
      setErrorPhone(!data.phoneNo ? 'Nomor Telepon Wajib diisi' : '');
      setErrorNama(!data.nama ? 'Nama Wajib diisi' : '');
    } else {
      setError(false);
      setErrorText('');
      setErrorPhone('');
      handleAddUser();
    }
  };

  return (
    <MainLayout
      background={'white'}
      favIcon={dataApp?.favicon ?? ''}
      title={dataApp?.merchant_name ?? ''}
    >
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}
        >
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            <span>Sukses update data profile </span>
          ) : RegisterUser?.error?.response?.data?.message ? (
            <span>{RegisterUser.error.response.data.message}</span>
          ) : (
            <span>
              Gagal update profile, <br /> Mohon check ulang form data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() => {
              setOpenModal(!openModal);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              statusModal === 'success' ? navigate('/customer-page') : null;
            }}
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <AppContainer>
        <Stack direction="column">
          <StyledBox
            style={{
              backgroundColor: dataApp?.primary_color ?? '#B92362',
              paddingTop: '20px',
            }}
          >
            <div
              onClick={() => navigate('/customer-page')}
              style={{ flexDirection: 'row', display: 'flex' }}
            >
              <img
                src={KembaliIcon}
                alt={'Sandbox Logo'}
                style={{ marginLeft: '20px', width: '24px', height: '24px' }}
              />
              <NormalText fontSize="16px" color="white">
                Kembali
              </NormalText>
            </div>
          </StyledBox>
          <div className="container">
            <h3
              className="font-16 mt-3"
              style={{
                color: dataApp?.primary_color ?? '#B92362',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '20px'
              }}
            >
              UPDATE PROFILE KAMU
            </h3>
            <div className="form-group mb-3 mt-3">
              <label
                className="mb-1"
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
              >
                Nama *
              </label>
              <input
                type="text"
                name="nama"
                placeholder="Masukan Nama Anda"
                className="form-control w-100"
                value={data.nama}
                onChange={handleChange}
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
              />

              <NormalText fontSize="12px" color="red">
                {errorNama}
              </NormalText>
            </div>
            <div className={`form-group mb-3 mt-3`}>
              <label
                className="mb-1"
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
              >
                Email *
              </label>
              <input
                type="text"
                name="email"
                placeholder="Masukan Email Anda"
                className={`form-control w-100  ${error ? 'error' : ''}`}
                value={data.email}
                onChange={handleChange}
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
                disabled
              />
              <NormalText fontSize="12px" color="red">
                {errorText}
              </NormalText>
            </div>
            <div className="form-group mb-3 mt-3">
              <label
                className="mb-1"
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
              >
                Nomor Telepon *
              </label>
              <input
                type="number"
                name="phoneNo"
                placeholder="Masukan Nomor Telepon Anda"
                className="form-control w-100"
                value={data.phoneNo}
                onChange={handleChange}
                style={{ color: dataApp?.primary_color ?? '#B92362' }}
              />
              <NormalText fontSize="12px" color="red">
                {errorPhone}
              </NormalText>
            </div>

            <button
              className="btn-lanjutkan mt-2"
              onClick={validateEmail}
              style={{
                backgroundColor: dataApp?.primary_color ?? '#B92362',
                color: dataApp?.secondary_color ?? '#000',
                textAlign: 'center',
              }}
            >
              Simpan
            </button>
            <button
              className="btn-lewati"
              onClick={() => navigate('/customer-page')}
              style={{
                backgroundColor: 'transparent',
                color: dataApp?.primary_color ?? '#B92362',
                borderColor: dataApp?.primary_color ?? '#B92362',
                borderWidth: '1px',
                borderStyle: 'solid',
                textAlign: 'center',
              }}
            >
              Kembali
            </button>
          </div>
        </Stack>
      </AppContainer>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 180px;
  position: relative;
  display: flex;
`;

export default ProfilePage;
