import React from "react";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import CounterButton from "components/Button/counter";
import { formatCurrency } from "helper/general";
import { ProductAdditional } from "redux/cart/cart.reducer";
import InputField from "components/Forms/InputField";
import ChangeNotesButton from "components/Button/change.notes.button";

export interface CardProductProps {
  product_name?: string;
  product_price?: number;
  qty: number;
  customizable?: ProductAdditional[];
  background?: string;
  onQtyIncrease?: React.MouseEventHandler<HTMLButtonElement>;
  onQtyDecrease?: React.MouseEventHandler<HTMLButtonElement>;
  onChangeNote?: any;
  showCounter?: boolean;
  notes?: string;
  brand_id?: any;
  cart_id?: any;
  editNote?: boolean;
}

export interface ProductAddOnProps {
  name?: string;
}

const CartProduct = ({
  product_name,
  product_price,
  brand_id,
  cart_id,
  qty,
  customizable,
  onQtyIncrease,
  onQtyDecrease,
  background,
  showCounter = true,
  notes,
  onChangeNote,
  editNote,
}: CardProductProps) => {
  const [total, setTotal] = React.useState(0);
  const [openNote, setOpenNote] = React.useState(false);
  const [note, setNote] = React.useState(notes ?? "");

  let brandId = brand_id ?? "";
  let cartId = cart_id ?? "";

  const onHandleOpenNote = () => {
    setOpenNote((prev_url) => !prev_url);
  };

  const onHandleCloseNote = () => {
    if (note.length > 100) {
      return;
    }
    setOpenNote(false);
    onChangeNote({ brand_id: brandId, cart_id: cartId, notes: note });
  };

  React.useEffect(() => {
    let temp = Number(product_price) ?? 0;
    if (customizable && customizable.length) {
      customizable.forEach((customer) => {
        temp += Number(customer.price);
      });
      setTotal(temp);
    } else {
      setTotal(temp);
    }
  }, [customizable, product_price]);
  return (
    <>
      <Box
        sx={{ background: "white", margin: "0 0 3px 0" }}
        padding={"20px 20px"}
        minHeight={"80px"}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box width={"70%"}>
            <NormalText
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Open Sans"
            >
              {product_name}
            </NormalText>
            {customizable &&
              customizable.length > 0 &&
              customizable.map((item, index) => (
                <NormalText
                  fontSize="12px"
                  fontFamily="Open Sans"
                  key={"cartproduct-" + index}
                >
                  {item.name}
                </NormalText>
              ))}
            <Stack direction={"row"} alignItems="center" width={"100%"}>
              <NormalText
                fontSize="11px"
                fontFamily="Open Sans"
                margin="0 5px 0 0"
              >
                Catatan:
              </NormalText>

              {/* <Button onClick={onHandleOpenNote} >
                <NormalText
                  fontSize="11px"
                  fontFamily="Open Sans"
                  fontWeight="600"
                >
                  {console.log(note)}
                  {!openNote && note && note.length > 0 ? 'Ubah' : null}
                  {!openNote && !note ? 'Tulis Catatan' : null}
                  {openNote ? 'Simpan' : null}
                </NormalText>
              </Button> */}

              {editNote ? (
                <>
                  {!openNote ? (
                    <ChangeNotesButton onClick={onHandleOpenNote}>
                      {!openNote && note && note.length > 0 ? "Ubah" : null}
                      {!openNote && !note ? "Tulis Catatan" : null}
                    </ChangeNotesButton>
                  ) : null}

                  {openNote ? (
                    <ChangeNotesButton onClick={onHandleCloseNote}>
                      Simpan
                    </ChangeNotesButton>
                  ) : null}
                </>
              ) : null}
            </Stack>

            {openNote ? (
              <Box margin={"5px 0 0 0"} width="100%">
                <InputField
                  value={note}
                  placeholder={"Notes"}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Box>
            ) : (
              <Box sx={{ width: "100%", wordBreak: "break-word" }}>
                <NormalText fontSize="11px" fontFamily="Open Sans">
                  {note && note.length > 0
                    ? note
                    : notes && notes.length > 0
                    ? notes
                    : "-"}
                </NormalText>
              </Box>
            )}

            {openNote && note.length > 100 ? (
              <NormalText
                fontSize="11px"
                fontFamily="Open Sans"
                color="#e74c3c"
                fontWeight="600"
              >
                {"Maksimal 100 karakter"}
              </NormalText>
            ) : null}
          </Box>
          <Box>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"right"}
            >
              <NormalText
                textAlign={"right"}
                fontSize="12px"
                fontWeight="bold"
                margin="0 0 0 20px 0"
                fontFamily="Open Sans"
              >
                {formatCurrency(total)}
              </NormalText>
              {showCounter ? (
                <CounterButton
                  background={background}
                  value={qty}
                  onDecrease={onQtyDecrease}
                  onIncrease={onQtyIncrease}
                />
              ) : (
                <NormalText
                  textAlign={"right"}
                  fontSize="12px"
                  fontWeight="bold"
                  margin="0 0 0 20px 0"
                  fontFamily="Open Sans"
                >
                  {qty}X
                </NormalText>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <SpacerLine />
    </>
  );
};

export default CartProduct;
