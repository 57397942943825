import { DeliveryState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getRate } from "./delivery.services";
import { orderSetData } from "./delivery.services";

export const initialState: DeliveryState = {
  data: {},
  status: "loading",
  error: {},
  orderList: [],
};

export const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRate.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = "success";
        state.error = "";
      })
      .addCase(getRate.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getRate.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(orderSetData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(orderSetData.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "success";
          state.error = "";
          state.orderList.push(action.payload);
        }
      });
  },
});

export const DeliveryReducer = deliverySlice.reducer;
