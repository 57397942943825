import styled from "styled-components";

interface InputProps {
  value?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputField = ({ value, placeholder, onChange }: InputProps) => {
  return <StyledInput value={value} placeholder={placeholder} onChange={onChange}/>;
};

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid #e6ecf2;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 12px;
  color: #455a64;
  font-family: "Open Sans";
  padding: 15px 5px;
  width: 100%;

  :focus,
  :active {
    outline: 0;
    border: 0;
    border: 1px solid #e6f3ff;
  }
`;

export default InputField;
