import { ProvinceState, ProvinceResponseProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getAllProvinces } from "./province.services";

export const initialState: ProvinceState = {
  data: {} as ProvinceResponseProps,
  status: "loading",
  error: {},
};

export const ProvinceSlice = createSlice({
  name: "provinces",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProvinces.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllProvinces.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getAllProvinces.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const ProvinceReducer = ProvinceSlice.reducer;
