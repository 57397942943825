import { Stack, Button} from "@mui/material";
import StoreListItem from "./store.list.item";

import { StoreQrGroupInfoTypes } from "redux/storeInfo";
import { useNavigate } from "react-router-dom";

interface StoreListSectionProps {
  className?: string;
  storeList?: StoreQrGroupInfoTypes[];
  primaryColor?: string;
  showAllTrigger?: any;
}

const StoreListSection = ({
  className,
  storeList,
  primaryColor,
  showAllTrigger,
}: StoreListSectionProps) => {
  const navigate = useNavigate();
  const handleNavigate = (data: StoreQrGroupInfoTypes) => {
    navigate(
      `/qr-group/store/${data?.store_name?.toLowerCase().replace(/ /g, "")}`, { state: data }
    );
  };

  return (
    <Stack className={className} direction={"column"} width={"100%"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="ps-3 pe-2"
        width={"100%"}
      >
        <h6 className="store-section-title mb-0">Daftar Toko</h6>
       
        <Button
          className="store-section-show-all-btn"
          sx={{ color: `${primaryColor ? `${primaryColor}` : "#FFC535"}` }}
          onClick={showAllTrigger}
        >
          Lihat Semua
        </Button>
      </Stack>
      <Stack direction={"row"} className="scroll-box mt-3">
        {storeList && storeList.length > 0
          ? storeList.map((store) => {
              return (
                <StoreListItem
                  storeLogo={store.store_logo}
                  storeName={store.store_name}
                  storeLocation={store.city.city_name}
                  key={store.id}
                  type="row"
                  onClick={()=>{handleNavigate(store)}}
                />
              );
            })
          : null}
      </Stack>
    </Stack>
  );
};

export default StoreListSection;
