import { Box, Avatar } from "@mui/material";
import SandboxLogo from "assets/images/sandbox.svg";
import { NormalText } from "../../components/styled/text.styled";

interface HeaderProps {
  storeLogo?: string;
  appLogo?: string;
  storeName: string;
}

const HeaderSmallV1 = ({ storeLogo, appLogo, storeName }: HeaderProps) => {
  return (
    <Box sx={{ background: "white", padding: "10px 20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "0.75rem",
        }}
      >
        <Avatar
          sx={{
            marginRight: "0.25rem",
            width: "25px",
            height: "25px",
            fontSize: "12px",
          }}
        >
          <img
            src={storeLogo ? storeLogo : appLogo ? appLogo : SandboxLogo}
            alt="logo toko"
            className="w-100"
          />
        </Avatar>
        <NormalText
          fontSize="16px"
          fontWeight="bold"
          margin="0 0 0 10px"
          fontFamily="D-DIN Exp"
        >
          {storeName}
        </NormalText>
      </Box>
    </Box>
  );
};

export default HeaderSmallV1;
