import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import SandboxLogo from "assets/images/sandbox.svg";

interface ILoyaltyItem {
  poin: string;
  type: "income" | "expense";
  order_number: string;
}

const LoyaltyItem = ({ poin, type, order_number }: ILoyaltyItem) => {
  return (
    <Wrapper>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Image src={SandboxLogo} alt={"sandbox logo"} />
        <Stack direction={"column"}>
          <NormalText fontWeight="bold">
            Kamu {type === "income" ? "mendapatkan" : "menggunakan"} {poin} poin
          </NormalText>
          <NormalText>
            {type === "income"
              ? "Selamat kamu mendapatkan poin dari"
              : "Penggunaan poin untuk"}{" "}
            nomor transaksi {order_number}.
          </NormalText>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

// eslint-disable-next-line
const DateLabel = styled.div`
  width: 100%;
  height: 35px;
  padding: 0 0 0 10px;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  display: flex;
  background: #ffc635;
`;

export default LoyaltyItem;
