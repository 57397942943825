import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, Button, Avatar, alpha } from "@mui/material";
import styled from "styled-components";
import history from "helper/history";
// Import Component
import { NormalText } from "../../components/styled/text.styled";
import MainLayout from "components/Layouts/MainLayout";
import CategoryFillter from "components/Modal/category-filter";
import CartBottomSheet from "components/CartBottomSheet";
import ConfirmationModal from "components/Modal/confirmation";
import ProductItem from "./product.item";
// Import Redux
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CategoryProps } from "redux/brand";
import {
  getDetailCategory,
  ProductListTypes,
  SubCategoryProps,
} from "redux/category";
import {
  addToCart,
  changeQuantity,
  ProductsTypes,
  ChangeQuantityProps,
} from "redux/cart/cart.reducer";
import { listAllBrand, BrandsType } from "redux/brand";
import { getCategoryList, CategoryInterface } from "redux/category";
// Import Assets
//import BrandCover from "../../assets/images/brandcover.png";
import IcFilter from "assets/icons/ic_filter.svg";
import BackIcon from "assets/icons/ic_back-button.svg";
import ProductDrawer from "components/Drawer/product.drawer";
import { detailProduct, ProductsDetail } from "redux/product";
import DetailPageCategorySkeleton from "components/Skeleton/DetailPageCategorySkeleton";

interface locationStateProps {
  prev_url: string;
}

interface selectedCategoryProps {
  id: number;
  name: string | undefined;
  image_mobile?: any;
  image_web?: any;
}

const CategoryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId, categoryLevel } = useParams();
  const { prev_url } = location.state as locationStateProps;

  // Category state from useNavigate

  const [selectedCategory, setSelectedCategory] =
    React.useState<selectedCategoryProps>({ id: 0, name: "" });

  const [confirm, setConfirm] = React.useState(false);

  const [reducePayload, setReducePayload] =
    React.useState<ChangeQuantityProps>();
  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting, Cart },
  } = useRedux();
  const [products, setProducts] = useState<ProductListTypes[]>();
  const [brands, setBrands] = React.useState<BrandsType[]>();
  const [categories, setCategories] = React.useState<CategoryInterface[]>();
  const [subCategories, setSubCategories] =
    React.useState<SubCategoryProps[]>();
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line
  const [data, setData] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  const [product, setProduct] = React.useState<ProductsDetail>({
    product_id: 0,
    product_name: "",
    category: {
      id: 0,
      category_name: "",
    },
    sub_category: {
      id: 0,
      sub_category_name: "",
    },
    subs_category: {
      id: 0,
      subs_category_name: "",
    },
    brand: {
      id: 0,
      brand_name: "",
    },
    status: false,
    merchant_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: false,
      product_price: "",
      discount_type: "",
      discount: 0,
      product_price_after_discount: "",
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
      long_description_html: "",
    },
  });
  const [openProduct, setOpenProduct] = React.useState(false);
  // State and Even Handler for Category Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleProductDrawer = (open: boolean) => {
    setOpenProduct(open);
  };

  const fetchDetailProduct = (productId: any) => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id &&
      StoreInfo.data.data.id
    ) {
      let data = {
        product_id: productId,
        merchant_id: StoreInfo.data.data.merchant_id,
        store_id: StoreInfo.data.data.id,
      };
      thunkDispatch(detailProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            let detailProduct: ProductsDetail = res.data.data;

            let tempDivElement: any = document.createElement("div");
            detailProduct.product_description.long_description_html =
              detailProduct.product_description.long_description;

            tempDivElement.innerHTML =
              detailProduct?.product_description?.long_description;

            detailProduct.product_description.long_description =
              tempDivElement?.innerText;

            setProduct(detailProduct);

            handleProductDrawer(true);
          }
        });
    }
  };

  const productToCart = (props: ProductsTypes) => {
    thunkDispatch(addToCart(props)).then(() => handleProductDrawer(false));
  };

  const reduceQuantity = (props: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(props))
      .unwrap()
      .then(() => null);
  };

  const productExistOnCart = (brand_id: number, product_id: number) => {
    let brand = Cart.items.find((b) => b.brand_id === brand_id);
    if (brand) {
      let product = brand.products.find((p) => p.product_id === product_id);

      if (product) {
        return {
          qty: product.qty,
          cart_id: product.cart_id,
        };
      }
    }
    return {
      qty: 0,
      cart_id: "",
    };
  };

  const fetchBrand = () => {
    thunkDispatch(
      listAllBrand({
        merchant: StoreInfo.data.data.merchant_id,
        offset: 0,
        store_id: StoreInfo.data.data.id,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setBrands(res.data.data);
        }
      });
  };

  const fetchCategory = () => {
    thunkDispatch(
      getCategoryList({
        merchant: Number(StoreInfo.data.data.merchant_id),
        store_id: Number(StoreInfo.data.data.id),
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setCategories(res?.data?.data ?? []);
          let categories = res?.data?.data;
          if (categoryLevel === "1") {
            let findCategory = categories.find(
              (x) => x.id === Number(categoryId)
            );
            setSelectedCategory({
              id: Number(findCategory?.id),
              name: findCategory?.category_name,
              image_mobile: findCategory?.image_mobile,
              image_web: findCategory?.image_web,
            });

            let subcategories = findCategory?.subCategory
              ?.filter((x) => x.is_active === true)
              .filter((x) => x.total_product > 0);
            setSubCategories(subcategories);
          } else if (categoryLevel === "2") {
            categories.forEach((x: any) => {
              let findSubCategory = x?.subCategory?.find(
                (y: any) => y.id === Number(categoryId)
              );
              //console.log(findSubCategory);
              if (findSubCategory) {
                setSelectedCategory({
                  id: findSubCategory?.id,
                  name: findSubCategory?.subcategory_name,
                  image_mobile: findSubCategory?.image_mobile,
                  image_web: findSubCategory?.image_web,
                });
              }
            });
          } else {
            categories.forEach((x: any) => {
              x?.subCategory?.forEach((y: any) => {
                let findSubsCategory = y?.subsCategory?.find(
                  (z: any) => z.id === Number(categoryId)
                );
                if (findSubsCategory) {
                  setSelectedCategory({
                    id: findSubsCategory?.id,
                    name: findSubsCategory?.subscategory_name,
                    image_mobile: findSubsCategory?.image_mobile,
                    image_web: findSubsCategory?.image_web,
                  });
                }
              });
            });
          }
        }
      });
  };

  useState(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ) {
      setLoading(true);
      fetchBrand();
      fetchCategory();
      if (categoryLevel === "3") {
        thunkDispatch(
          getDetailCategory({
            merchant_id: StoreInfo.data.data.merchant_id,
            store_id: StoreInfo.data.data.id,
            category_id: 0,
            sub_category_id: 0,
            subs_category_id: categoryLevel === "3" ? Number(categoryId) : 0,
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setProducts(res.data.data);
            }
          })
          .then(() => setLoading(false));
      } else {
        thunkDispatch(
          getDetailCategory({
            merchant_id: StoreInfo.data.data.merchant_id,
            store_id: StoreInfo.data.data.id,
            category_id: categoryLevel === "1" ? Number(categoryId) : 0,
            sub_category_id: categoryLevel === "2" ? Number(categoryId) : 0,
            subs_category_id: 0,
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setProducts(res.data.data);
              //console.log(res.data.data);
            }
          })
          .then(() => setLoading(false));
      }
    }
  });

  useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ) {
      fetchBrand();
      fetchCategory();
      if (categoryLevel === "3") {
        thunkDispatch(
          getDetailCategory({
            merchant_id: StoreInfo.data.data.merchant_id,
            store_id: StoreInfo.data.data.id,
            category_id: 0,
            sub_category_id: 0,
            subs_category_id: categoryLevel === "3" ? Number(categoryId) : 0,
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setProducts(res.data.data);
              //console.log(res.data.data);
            }
          });
      } else {
        thunkDispatch(
          getDetailCategory({
            merchant_id: StoreInfo.data.data.merchant_id,
            store_id: StoreInfo.data.data.id,
            category_id: categoryLevel === "1" ? Number(categoryId) : 0,
            sub_category_id: categoryLevel === "2" ? Number(categoryId) : 0,
            subs_category_id: 0,
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setProducts(res.data.data);
              //console.log(res.data.data);
            }
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryLevel, categoryId]);

  return (
    <MainLayout
      background={"#ffffff"}
      favIcon={data?.favicon ?? ""}
      title={data?.merchant_name ?? ""}
    >
      <ConfirmationModal
        handleClose={() => setConfirm(false)}
        handleConfirm={() => {
          if (reducePayload) {
            reduceQuantity(reducePayload);
            setConfirm(false);
          }
        }}
        hasCancel={true}
        backgroundButton={data?.primary_color ?? "#B92362"}
        description={"Apakah anda yakin ingin menghapus item ini?"}
        open={confirm}
      />

      {/* Modal for Category Filter */}
      <CategoryFillter
        handleClose={handleClose}
        open={open}
        background={alpha(AppSetting.data.data.primary_color, 0.4)}
        brands={brands}
        storeInfo={StoreInfo}
        categories={categories}
      />

      <Box
        sx={{
          top: 0,
          zIndex: "2 !important;",
          background: "#F2F2F2",
        }}
      >
        {/* Header */}
        <HeaderContainer url={selectedCategory.image_web}>
          <Avatar
            sx={{
              bgcolor: "white",
              fontSize: "unset",
              width: "25px",
              height: "25px",
              boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
            }}
            onClick={() => {
              if (prev_url?.includes("/home/")) {
                navigate("/home");
              } else {
                history.back();
              }
            }}
          >
            <BackButtonIcon src={BackIcon} alt={"back_icon"} />
          </Avatar>
          <Box sx={{ marginTop: "16.17px" }}>
            <NormalText
              fontFamily="D-DIN Exp"
              fontWeight="700"
              fontSize="25px"
              lineHeight="1"
              color="white"
            >
              {selectedCategory.name && selectedCategory.name.length > 20
                ? `${selectedCategory.name.substring(0, 20)} ... `
                : selectedCategory.name && selectedCategory.name.length < 21
                ? `${selectedCategory.name}`
                : "Kategori"}
            </NormalText>
            <NormalText
              fontFamily="D-DIN Exp"
              fontStyle="normal"
              fontWeight="700"
              fontSize="12px"
              lineHeight="27px"
              color="white"
              margin="0 0 0 0"
            >
              {selectedCategory.name && selectedCategory.name.length > 0
                ? "Kategori"
                : ""}
            </NormalText>
          </Box>
        </HeaderContainer>

        {/* Navigation Filter Button */}
        <Navigation>
          <Button
            variant="contained"
            endIcon={<FilterIcon src={IcFilter} />}
            sx={{
              background: `${alpha(data?.primary_color, 0.4) ?? "#fff4f8"}`,
              width: "80px",
              height: "30px",
              borderRadius: "50px",
              padding: "9px 17px",
              margin: "10px 0px 12.5px 10px",
              fontFamily: "D-DIN Exp, sans-serif",
              fontSize: "12px",
              fontWeight: "400",
              color: "black",
              textTransform: "capitalize",
              outline: "unset!important",
              "&:hover": {
                background: `${alpha(data?.primary_color, 0.6) ?? "#fff4f8"}`,
              },
            }}
            onClick={handleOpen}
          >
            Filter
          </Button>
        </Navigation>
      </Box>

      <Box
        sx={{
          height: "calc(100vh - 190px)",
          overflowY: "auto",
          paddingBottom: `${Cart && Cart.items.length > 0 ? "50px" : "0"}`,
        }}
      >
        {/* Label */}
        {categoryLevel === "1" ? (
          <>
            {loading ? (
              <DetailPageCategorySkeleton />
            ) : (
              <>
                {products && products.length > 0 ? (
                  <>
                    {subCategories && subCategories.length > 0 ? (
                      <>
                        {subCategories?.map((subc, iSubc) => {
                          return (
                            <Box key={iSubc + "-pproduct"}>
                              <CategoryLabel
                                background={data?.primary_color ?? "#fff4f8"}
                              >
                                <NormalText
                                  color="white"
                                  fontFamily="D-DIN Exp"
                                  fontWeight="700"
                                  fontSize="12px"
                                  lineHeight="13.03px"
                                  margin="0 0 0 11px"
                                >
                                  {subc.subcategory_name}
                                </NormalText>
                              </CategoryLabel>

                              {products
                                ?.filter(
                                  (item_product) =>
                                    item_product.subCategory
                                      ?.sub_category_id === subc.id
                                )
                                .map((filtered_product, index_filtered) => {
                                  return (
                                    <>
                                      <Box
                                        sx={{
                                          background: "white",
                                          width: "100%",
                                        }}
                                      >
                                        <ProductItem
                                          isOnCart={
                                            productExistOnCart(
                                              filtered_product?.brand?.brand_id,
                                              filtered_product?.product_id
                                            )?.cart_id !== ""
                                          }
                                          counterProps={{
                                            value:
                                              productExistOnCart(
                                                filtered_product?.brand
                                                  ?.brand_id,
                                                filtered_product.product_id
                                              )?.qty ?? 0,
                                            background:
                                              data?.primary_color ?? "#B92362",
                                            onDecrease: () => {
                                              let quantity = productExistOnCart(
                                                filtered_product?.brand
                                                  ?.brand_id,
                                                filtered_product?.product_id
                                              );

                                              if (
                                                quantity &&
                                                quantity.qty === 1
                                              ) {
                                                setReducePayload({
                                                  brand_id:
                                                    filtered_product?.brand
                                                      ?.brand_id,
                                                  type: "decrease",
                                                  cart_id:
                                                    productExistOnCart(
                                                      filtered_product?.brand
                                                        ?.brand_id,
                                                      filtered_product?.product_id
                                                    )?.cart_id ?? "",
                                                });

                                                setConfirm(true);
                                              } else {
                                                reduceQuantity({
                                                  brand_id:
                                                    filtered_product?.brand
                                                      ?.brand_id,
                                                  type: "decrease",
                                                  cart_id:
                                                    productExistOnCart(
                                                      filtered_product?.brand
                                                        ?.brand_id,
                                                      filtered_product.product_id
                                                    )?.cart_id ?? "",
                                                });
                                              }
                                            },
                                            onIncrease: () => {
                                              reduceQuantity({
                                                brand_id:
                                                  filtered_product?.brand
                                                    ?.brand_id,
                                                type: "increase",
                                                cart_id:
                                                  productExistOnCart(
                                                    filtered_product?.brand
                                                      ?.brand_id,
                                                    filtered_product?.product_id
                                                  )?.cart_id ?? "",
                                              });
                                            },
                                          }}
                                          key={`${index_filtered}/${index_filtered}-product`}
                                          product={
                                            filtered_product?.product_detail
                                          }
                                          productStore={
                                            filtered_product?.productStore
                                          }
                                          name={filtered_product?.product_name}
                                          onClick={() => {
                                            filtered_product?.product_detail
                                              ?.is_customizable
                                              ? navigate(
                                                  `/product/${filtered_product?.product_id}/detail`
                                                )
                                              : fetchDetailProduct(
                                                  filtered_product?.product_id
                                                );
                                          }}
                                          onAddToCart={() => {
                                            if (
                                              filtered_product?.product_detail
                                                .is_customizable
                                            ) {
                                              navigate(
                                                `/product/${filtered_product?.product_id}/detail`
                                              );
                                            } else {
                                              productToCart({
                                                is_customizable:
                                                  filtered_product
                                                    ?.product_detail
                                                    .is_customizable,
                                                product_id:
                                                  filtered_product?.product_id,
                                                qty: 1,
                                                product_name:
                                                  filtered_product?.product_name,
                                                product_image: "",
                                                brand_id:
                                                  filtered_product?.brand
                                                    ?.brand_id ??
                                                  StoreInfo.data.data.id,
                                                brand_name:
                                                  filtered_product?.brand
                                                    ?.brand_name ??
                                                  StoreInfo.data.data
                                                    .store_name,
                                                notes: "",
                                                additionals: [],
                                                price:
                                                  filtered_product?.product_detail &&
                                                  filtered_product
                                                    ?.product_detail
                                                    .product_price &&
                                                  filtered_product
                                                    ?.product_detail
                                                    .product_price_after_discount &&
                                                  Number(
                                                    filtered_product
                                                      ?.product_detail
                                                      .product_price
                                                  ) &&
                                                  Number(
                                                    filtered_product
                                                      ?.product_detail
                                                      .product_price_after_discount
                                                  ) > 0
                                                    ? filtered_product
                                                        ?.product_detail
                                                        .product_price_after_discount
                                                    : filtered_product
                                                        ?.product_detail
                                                        .product_price,
                                              });
                                            }
                                          }}
                                        />
                                      </Box>
                                    </>
                                  );
                                })}
                            </Box>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Tidak ada produk</p>
                  </Box>
                )}
              </>
            )}
          </>
        ) : null}

        {categoryLevel === "2" ? (
          <>
            {loading ? (
              <DetailPageCategorySkeleton />
            ) : (
              <>
                {products && products.length > 0 ? (
                  <>
                    {products.map((item, index) => {
                      return (
                        <Box
                          key={index + "-promolabel"}
                          id={item.product_name.replace(/\s/g, "")}
                          sx={{
                            marginBottom:
                              index === products.length - 1 ? "120px" : "",
                          }}
                        >
                          <Box sx={{ background: "white", width: "100%" }}>
                            <ProductItem
                              isOnCart={
                                productExistOnCart(
                                  item?.brand?.brand_id,
                                  item.product_id
                                )?.cart_id !== ""
                              }
                              counterProps={{
                                value:
                                  productExistOnCart(
                                    item?.brand?.brand_id,
                                    item.product_id
                                  )?.qty ?? 0,
                                background: data?.primary_color ?? "#B92362",
                                onDecrease: () => {
                                  let quantity = productExistOnCart(
                                    item?.brand?.brand_id,
                                    item?.product_id
                                  );

                                  if (quantity && quantity.qty === 1) {
                                    setReducePayload({
                                      brand_id: item?.brand?.brand_id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          item?.brand?.brand_id,
                                          item?.product_id
                                        )?.cart_id ?? "",
                                    });

                                    setConfirm(true);
                                  } else {
                                    reduceQuantity({
                                      brand_id: item?.brand?.brand_id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          item?.brand?.brand_id,
                                          item.product_id
                                        )?.cart_id ?? "",
                                    });
                                  }
                                },
                                onIncrease: () => {
                                  reduceQuantity({
                                    brand_id: item?.brand?.brand_id,
                                    type: "increase",
                                    cart_id:
                                      productExistOnCart(
                                        item?.brand?.brand_id,
                                        item.product_id
                                      )?.cart_id ?? "",
                                  });
                                },
                              }}
                              key={`${index}/${index}-product`}
                              product={item.product_detail}
                              productStore={item.productStore}
                              name={item.product_name}
                              onClick={() => {
                                item?.product_detail?.is_customizable
                                  ? navigate(
                                      `/product/${item.product_id}/detail`
                                    )
                                  : fetchDetailProduct(item.product_id);
                              }}
                              onAddToCart={() => {
                                if (item.product_detail.is_customizable) {
                                  navigate(
                                    `/product/${item.product_id}/detail`
                                  );
                                } else {
                                  productToCart({
                                    is_customizable:
                                      item.product_detail.is_customizable,
                                    product_id: item.product_id,
                                    qty: 1,
                                    product_name: item.product_name,
                                    product_image: "",
                                    brand_id:
                                      item?.brand?.brand_id ??
                                      StoreInfo.data.data.id,
                                    brand_name:
                                      item?.brand?.brand_name ??
                                      StoreInfo.data.data.store_name,
                                    notes: "",
                                    additionals: [],
                                    price:
                                      item.product_detail &&
                                      item.product_detail.product_price &&
                                      item.product_detail
                                        .product_price_after_discount &&
                                      Number(
                                        item.product_detail.product_price
                                      ) &&
                                      Number(
                                        item.product_detail
                                          .product_price_after_discount
                                      ) > 0
                                        ? item.product_detail
                                            .product_price_after_discount
                                        : item.product_detail.product_price,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Tidak ada produk</p>
                  </Box>
                )}
              </>
            )}
          </>
        ) : null}

        {categoryLevel === "3" ? (
          <>
            {loading ? (
              <DetailPageCategorySkeleton />
            ) : (
              <>
                {products && products.length > 0 ? (
                  <>
                    {products.map((item, index) => {
                      return (
                        <Box
                          key={index + "-promolabel"}
                          id={item.product_name.replace(/\s/g, "")}
                          sx={{
                            marginBottom:
                              index === products.length - 1 ? "120px" : "",
                          }}
                        >
                          <Box sx={{ background: "white", width: "100%" }}>
                            <ProductItem
                              isOnCart={
                                productExistOnCart(
                                  item?.brand?.brand_id,
                                  item.product_id
                                )?.cart_id !== ""
                              }
                              counterProps={{
                                value:
                                  productExistOnCart(
                                    item?.brand?.brand_id,
                                    item.product_id
                                  )?.qty ?? 0,
                                background: data?.primary_color ?? "#B92362",
                                onDecrease: () => {
                                  let quantity = productExistOnCart(
                                    item?.brand?.brand_id,
                                    item?.product_id
                                  );

                                  if (quantity && quantity.qty === 1) {
                                    setReducePayload({
                                      brand_id: item?.brand?.brand_id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          item?.brand?.brand_id,
                                          item?.product_id
                                        )?.cart_id ?? "",
                                    });

                                    setConfirm(true);
                                  } else {
                                    reduceQuantity({
                                      brand_id: item?.brand?.brand_id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          item?.brand?.brand_id,
                                          item.product_id
                                        )?.cart_id ?? "",
                                    });
                                  }
                                },
                                onIncrease: () => {
                                  reduceQuantity({
                                    brand_id: item?.brand?.brand_id,
                                    type: "increase",
                                    cart_id:
                                      productExistOnCart(
                                        item?.brand?.brand_id,
                                        item.product_id
                                      )?.cart_id ?? "",
                                  });
                                },
                              }}
                              key={`${index}/${index}-product`}
                              product={item.product_detail}
                              productStore={item.productStore}
                              name={item.product_name}
                              onClick={() => {
                                item?.product_detail?.is_customizable
                                  ? navigate(
                                      `/product/${item.product_id}/detail`
                                    )
                                  : fetchDetailProduct(item.product_id);
                              }}
                              onAddToCart={() => {
                                if (item.product_detail.is_customizable) {
                                  navigate(
                                    `/product/${item.product_id}/detail`
                                  );
                                } else {
                                  productToCart({
                                    is_customizable:
                                      item.product_detail.is_customizable,
                                    product_id: item.product_id,
                                    qty: 1,
                                    product_name: item.product_name,
                                    product_image: "",
                                    brand_id:
                                      item?.brand?.brand_id ??
                                      StoreInfo.data.data.id,
                                    brand_name:
                                      item?.brand?.brand_name ??
                                      StoreInfo.data.data.store_name,
                                    notes: "",
                                    additionals: [],
                                    price:
                                      item.product_detail &&
                                      item.product_detail.product_price &&
                                      item.product_detail
                                        .product_price_after_discount &&
                                      Number(
                                        item.product_detail.product_price
                                      ) &&
                                      Number(
                                        item.product_detail
                                          .product_price_after_discount
                                      ) > 0
                                        ? item.product_detail
                                            .product_price_after_discount
                                        : item.product_detail.product_price,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Tidak ada produk</p>
                  </Box>
                )}
              </>
            )}
          </>
        ) : null}
      </Box>

      {/* Drawer */}
      <ProductDrawer
        open={openProduct}
        onClose={handleProductDrawer}
        onOpen={handleProductDrawer}
        product={product}
        color={AppSetting?.data?.data?.primary_color}
        productToCart={productToCart}
      />

      {Cart && Cart.items.length > 0 && <CartBottomSheet summaries />}
    </MainLayout>
  );
};

// Styled Components
interface HeaderProps {
  url: string;
}

const HeaderContainer = styled.div<HeaderProps>`
  height: 134px;
  width: 100%;
  top: 0;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    ${(props) => "url(" + props.url + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10.83px 8px 51px 8px;
`;

const BackButtonIcon = styled.img`
  cursor: pointer;
  width: 12px;
  height: auto;
`;

const Navigation = styled.div`
  transition: ease-in-out 0.5s;
  background: white;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  /* @media only screen and (max-width: 768px) {
    width: 410px;
  } */
`;

interface CategoryLabelProps {
  background?: string;
}

export const CategoryLabel = styled.div<CategoryLabelProps>`
  width: 100%;
  height: 37px;
  align-items: center;
  display: flex;
  background: ${(props) => props.background ?? "#fff4f8"};
`;

const FilterIcon = styled.img`
  cursor: pointer;
  height: 11.25px;
  width: 11.25px;
  margin-left: -5px;
`;

export default CategoryDetail;
