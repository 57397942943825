import styled from "styled-components";
import { Props as WrapperProps } from './MainLayout'

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "360px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const Wrapper = styled.div<WrapperProps>`
  display: block;
  position: relative;
  background-color: ${(props) => props.background ?? "white"};
  flex-direction: column;
  min-height: 100vh;
  @media ${device.laptop} { 
    max-width: 360px;
  }
  @media ${device.laptopL} { 
    max-width: 360px;
  }

  @media ${device.desktop} {
    max-width: 360px;
  }

  margin: auto;
  font-family: "Ropa Sans";
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 50px;
  @media ${device.mobileS} {
    bottom: 50px;
  }
  width: 100%;
  height: 60px;


  @media only screen and (max-width: 320px) {
    position: relative;
  }
`;
