import React from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { SpacerLine } from 'components/styled/layout.styled';

export interface CardProductProps {
  product_name?: string;
  product_price?: any;
  qty: number;
  customizable?: AddOn[];
}

export interface AddOn {
  product_name?: string;
}

export interface ProductAddOnProps {
  name?: string;
}

const ProductOrder = ({
  product_name,
  product_price,
  qty,
  customizable,
}: CardProductProps) => {
  return (
    <>
      <Box
        sx={{ background: 'white', margin: '0 0 3px 0' }}
        padding={'20px 20px'}
        minHeight={'80px'}
      >
        <Stack direction={'row'}>
          <Grid container>
            <Grid item xs={9}>
              <NormalText
                fontSize="12px"
                fontWeight="bold"
                fontFamily="Open Sans"
              >
                {product_name}
              </NormalText>
              {customizable &&
                customizable.length > 0 &&
                customizable.map((item, index) => (
                  <NormalText
                    fontSize="12px"
                    fontFamily="Open Sans"
                    key={'ProductOrder-' + index}
                  >
                    {item.product_name}
                  </NormalText>
                ))}
            </Grid>
            <Grid item xs={3}>
              <NormalText
                fontSize="12px"
                fontWeight="bold"
                fontFamily="Open Sans"
                textAlign="end"
              >
                {product_price}
              </NormalText>
              <NormalText
                fontSize="12px"
                fontFamily="Open Sans"
                textAlign="end"
              >
                {qty}x
              </NormalText>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <SpacerLine />
    </>
  );
};

export default ProductOrder;
