import React from "react";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const success = (pos: any) => {
  var crd = pos.coords;

  console.log("Your current position is:");
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
};

const errors = (err: any) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
};

export const checkLocation = () => {
  if (navigator.permissions && navigator.permissions.query) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(success);
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "denied") {
          window.location.replace("/access-forbidden");
          navigator.geolocation.getCurrentPosition(success, errors, options);
        }
        result.onchange = function () {
          console.log(result.state);
        };
      });
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, errors, options);
  } else {
    window.location.replace("/access-forbidden");
  }
};

export const useGeoLocation: any = (noGpsCheck: boolean) => {
  const [position, setPosition] = React.useState<GeolocationPosition>();
  const [state, setState] = React.useState<PermissionState>("prompt");
  const [error, setError] = React.useState<any>();

  const getCurrentLocation = () => {
    if (navigator && navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        (p) => {
          setPosition(p);
        },
        (err) => {
          setError(err);
        },
        options
      );
  };

  React.useEffect(() => {
    if (!noGpsCheck && navigator && navigator.geolocation)
      window.navigator.permissions
        .query({ name: "geolocation" })
        .then((res) => {
          setState(res.state);
          if (res.state === "granted" || res.state === "prompt") {
            getCurrentLocation();
          } else {
            // window.location.replace("/access-forbidden");
          }
          res.onchange = () => {
            setState(res.state);
          };
        })
        .catch((err) => {
          setError(err);
        });
  }, [noGpsCheck]);

  return {
    getCurrentLocation,
    position,
    state,
    error,
  };
};

export interface Coordinate {
  lat: number;
  lon: number;
}

export const getDistanceBetweenTwoPoints = (
  cord1: Coordinate,
  cord2: Coordinate
) => {
  if (cord1.lat === cord2.lat && cord1.lon === cord2.lon) {
    return 0;
  }

  const radlat1 = (Math.PI * cord1.lat) / 180;
  const radlat2 = (Math.PI * cord2.lat) / 180;

  const theta = cord1.lon - cord2.lon;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;
  dist *= 1000;

  return dist;
};
