import { CityState, CityResponseProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getAllCity } from "./city.services";

export const initialState: CityState = {
  data: {} as CityResponseProps,
  status: "loading",
  error: {},
};

export const CitySlice = createSlice({
  name: "cities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCity.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getAllCity.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const ProvinceReducer = CitySlice.reducer;
