import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import {
  BCALogo,
  MandiriLogo,
  BRILogo,
  SahabatSampoernaLogo,
  BNILogo,
  PermataLogo,
  CimbLogo,
  BSILogo,
  DBSLogo,
  BJBLogo,
} from "assets/images/bank_logo";

export const logos = [
  {
    code: "BCA",
    logo: BCALogo,
  },
  {
    code: "MANDIRI",
    logo: MandiriLogo,
  },
  {
    code: "BRI",
    logo: BRILogo,
  },
  {
    code: "SAHABAT_SAMPOERNA",
    logo: SahabatSampoernaLogo,
  },
  {
    code: "BNI",
    logo: BNILogo,
  },
  {
    code: "PERMATA",
    logo: PermataLogo,
  },
  {
    code: "CIMB",
    logo: CimbLogo,
  },
  {
    code: "BSI",
    logo: BSILogo,
  },
  {
    code: "DBS",
    logo: DBSLogo,
  },
  {
    code: "BJB",
    logo: BJBLogo,
  },
];

interface BankItemProps {
  onChange: any;
  name: string;
  selected: boolean;
  background?: string;
  code: string;
}

const BankItem = ({
  onChange,
  name,
  code,
  selected,
  background,
}: BankItemProps) => {
  const findLogo = () => {
    let checkLogo = logos.find((logo) => logo.code === code);
    if (checkLogo) {
      return checkLogo.logo;
    }
  };
  return (
    <Box
      padding={"10px 20px"}
      onClick={onChange}
      sx={{ cursor: "pointer" }}
      width={"100%"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <div style={{ width: "70px" }}>
            <img
              src={findLogo()}
              alt={"logo-" + name}
              style={{ height: "15px", width: "auto" }}
            />
          </div>
          <Stack direction={"column"}>
            <NormalText
              fontFamily="D-DIN"
              fontSize={"14px"}
              textAlign={"left"}
              fontWeight={"bold"}
            >
              {name}
            </NormalText>
          </Stack>
        </Stack>
        <Item>
          <RadioButton
            checkedBackground={background ?? "#f8e71c"}
            type="radio"
            name="payment"
            checked={selected}
            onChange={onChange}
          />
          <RadioButtonLabel />
        </Item>
      </Stack>
    </Box>
  );
};

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-sizing: border-box;
  border: 4px solid #ccc;
`;

interface RadioButtonProps {
  checkedBackground?: string;
}

export const RadioButton = styled.input<RadioButtonProps>`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  box-sizing: border-box;
  &:hover ~ ${RadioButtonLabel} {
    &::after {
      font-family: "FontAwesome";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
  }
  &:checked + ${RadioButtonLabel} {
    border: 4px solid ${(props) => props.checkedBackground ?? "yellowgreen"};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &::after {
      content: "";
      font-family: "FontAwesome";
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

export default BankItem;
