import { createAsyncThunk } from '@reduxjs/toolkit';
import { PickupPointState } from './types';
import axios from 'axios';

interface getPickup {
  store_id: number;
}

export const getPickupPoint = createAsyncThunk(
  'pickuppoint/list',
  async ({ store_id }: getPickup) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pickup/point/get/data?store_id=${store_id}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as PickupPointState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as PickupPointState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as PickupPointState;
    }
  },
);

export interface ConfirmPup {
  id?: any;
  pupoint_name?: string;
}

export const ConfirmPickup = createAsyncThunk(
  'pickuppoint/confirm',
  async (props: ConfirmPup) => {
    return {
      ...props,
    } as ConfirmPup;
  },
);
