import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateString } from "helper/general";
import { ReduxStatus } from "redux/types";

export interface CartState {
    items: ProductCart[];
    status: ReduxStatus;
    total_price: number;
    total_product: number;
}

export interface ProductCart {
    brand_id: number;
    brand_name: string;
    products: ProductsTypes[];
}

export interface ProductAdditional {
    id: number;
    name: string;
    qty: number;
    price: string;
    product_id: number;
}

export interface ProductsTypes {
    cart_id?: string;
    product_id: number;
    product_name: string;
    product_image: string;
    brand_id?: number;
    brand_name?: string;
    qty: number;
    price: string;
    notes: string;
    is_customizable: boolean;
    additionals?: ProductAdditional[];
}

export interface ChangeQuantityProps {
    brand_id: number;
    cart_id: string;
    type: 'increase' | 'decrease';
}

export interface ChangeNotesProps {
    brand_id: number;
    cart_id: string;
    notes: string;
}

export const initialState: CartState = {
    items: [],
    status: 'loading',
    total_price: 0,
    total_product: 0
}

export const cartSlice = createSlice({
    name: 'carts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addToCart.fulfilled, (state, action) => {
                if (action.payload) {
                    if (action.payload.brand_id) {
                        let brandIndex = state.items.findIndex((x) => x.brand_id === action.payload.brand_id)
                        if (brandIndex < 0) {
                            state.items.push({
                                brand_id: action.payload.brand_id,
                                brand_name: action.payload.brand_name ?? '',
                                products: [{
                                    cart_id: generateString(10),
                                    product_id: action.payload.product_id,
                                    product_name: action.payload.product_name,
                                    price: action.payload.price,
                                    qty: action.payload.qty,
                                    notes: action.payload.notes,
                                    is_customizable: action.payload.is_customizable,
                                    product_image: action.payload.product_image,
                                    additionals: action.payload.additionals ?? []
                                }]
                            })
                        } else {
                            // Push product to existing brand
                            let brandItem = state.items[brandIndex].products.find((x) => x.product_id === action.payload.product_id)
                            if (brandItem) {
                                if (action.payload.is_customizable) {
                                    state.items[brandIndex].products.push({
                                        cart_id: generateString(10),
                                        product_id: action.payload.product_id,
                                        product_name: action.payload.product_name,
                                        price: action.payload.price,
                                        qty: action.payload.qty,
                                        notes: action.payload.notes,
                                        is_customizable: action.payload.is_customizable,
                                        product_image: action.payload.product_image,
                                        additionals: action.payload.additionals ?? []
                                    })
                                } else {
                                    let productIdx = state.items[brandIndex].products.findIndex((x) => x.product_id === action.payload.product_id)
                                    state.items[brandIndex].products[productIdx].qty += action.payload.qty ?? 1;
                                }
                            } else {
                                state.items[brandIndex].products.push({
                                    cart_id: generateString(10),
                                    product_id: action.payload.product_id,
                                    product_name: action.payload.product_name,
                                    price: action.payload.price,
                                    qty: action.payload.qty,
                                    notes: action.payload.notes,
                                    is_customizable: action.payload.is_customizable,
                                    product_image: action.payload.product_image,
                                    additionals: action.payload.additionals ?? []
                                })
                            }
                        }
                    }
                    state.status = 'success'
                }
            })
            .addCase(addToCart.pending, (state, action) => {
                state.status = 'loading'

            })
            .addCase(addToCart.rejected, (state, action) => {
                state.status = 'error'
            })
            .addCase(changeQuantity.fulfilled, (state, action) => {
                if (action.payload) {
                    let brand = state.items.find((x) => x.brand_id === action.payload.brand_id)
                    if (brand) {
                        let brandIndex = state.items.findIndex((x) => x.brand_id === action.payload.brand_id)
                        let productIndex = state.items[brandIndex].products.findIndex((x) => x.cart_id === action.payload.cart_id)
                        if (action.payload.type === 'increase') {
                            state.items[brandIndex].products[productIndex].qty += 1;
                        } else {
                            if (state.items[brandIndex].products[productIndex].qty > 1) {
                                state.items[brandIndex].products[productIndex].qty -= 1;
                            } else {
                                // remove product from cart
                                if (state.items[brandIndex].products.length === 1) {
                                    state.items.splice(brandIndex, 1)
                                } else {
                                    state.items[brandIndex].products.splice(productIndex, 1)
                                }
                            }
                        }

                    }
                }
            })
            .addCase(changeQuantity.pending, (state, action) => {
                state.status = 'loading'

            })
            .addCase(changeQuantity.rejected, (state, action) => {
                state.status = 'error'
            })
            .addCase(getTotalPrice.fulfilled, (state, action) => {
                let total_prices = 0;
                let total_products = 0;
                let total_product_addons = 0;

                // eslint-disable-next-line array-callback-return
                state.items.map((item) => {
                    var totalPricePerBrand = 0;
                    total_products += item.products.length

                    // eslint-disable-next-line array-callback-return
                    item.products.map((product) => {
                        total_product_addons = 0;
                        if (product.additionals && product.additionals.length > 0) {

                            // eslint-disable-next-line array-callback-return
                            product.additionals.map((addon) => {
                                total_product_addons += Number(addon.price)
                            })
                        }
                        totalPricePerBrand += (product.qty * Number(product.price) + total_product_addons);
                    });
                    total_prices += totalPricePerBrand
                })
                state.total_price = total_prices
                state.total_product = total_products
            })
            .addCase(getTotalPrice.pending, (state, action) => {
                state.status = 'loading'

            })
            .addCase(getTotalPrice.rejected, (state, action) => {
                state.status = 'error'
            })
            .addCase(resetCart.fulfilled, (state, action) => {
                state.status = 'reset'
                state.items = initialState.items
                state.total_price = initialState.total_price
                state.total_product = initialState.total_product
            })
            .addCase(resetCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(resetCart.rejected, (state, action) => {
                state.status = 'error'
            })
            .addCase(changeNotes.fulfilled, (state, action) => {
                if (action.payload) {
                    let brand = state.items.find((x) => x.brand_id === action.payload.brand_id)
                    if (brand) {
                        let brandIndex = state.items.findIndex((x) => x.brand_id === action.payload.brand_id)
                        let productIndex = state.items[brandIndex].products.findIndex((x) => x.cart_id === action.payload.cart_id)
                        state.items[brandIndex].products[productIndex].notes = action.payload.notes


                    }
                }
            })
            .addCase(changeNotes.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(changeNotes.rejected, (state, action) => {
                state.status = 'error'
            })
    }
})

export const addToCart = createAsyncThunk('carts/addtocart', async (props: ProductsTypes) => {
    return {
        ...props
    } as ProductsTypes;
})

export const getTotalPrice = createAsyncThunk('carts/gettotalprice', async () => {
    return 'success'
})

export const changeQuantity = createAsyncThunk('carts/changequantity', async (props: ChangeQuantityProps) => {
    return {
        ...props
    } as ChangeQuantityProps
})

export const changeNotes = createAsyncThunk('carts/changenotes', async (props: ChangeNotesProps) => {
    return {
        ...props
    } as ChangeNotesProps
})

export const resetCart = createAsyncThunk('carts/reset', async () => {
    return 'Keranjang telah di reset!'
})

export const CartReducer = cartSlice.reducer