import { Navigate } from "react-router-dom";
import { QrGroupInfoState } from "redux/qrGroup";

interface ProtectedQrGroupRouteProps {
  data: QrGroupInfoState;
  children: any;
}

const ProtectedQrGroupRoute = ({
  data,
  children,
}: ProtectedQrGroupRouteProps) => {
  if (data && data.data && data.data.data) {
    return children;
  }
  return <Navigate to="/404" replace />;
};

export default ProtectedQrGroupRoute;
