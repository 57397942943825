import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckCustomerState, CheckCustomerProps } from './types';
import axios from 'axios';

export const checkCustomer = createAsyncThunk(
  '/check-customer',
  async (props: CheckCustomerProps) => {
    const config = {
      headers: {
        api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
        // token: props.access_token
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${props.merchant_id}/check-customer?email=${props.email}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as CheckCustomerState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as CheckCustomerState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as CheckCustomerState;
    }
  },
);

export const removeCustomerData = createAsyncThunk(
  'customer/remove',
  async () => {
    return 'Customer data removed!'
  }
);
