import React, { useEffect } from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NormalText } from 'components/styled/text.styled';
import { SpacerLine } from 'components/styled/layout.styled';
import useRedux from 'redux/useRedux';
import IconTimes from 'assets/icons/ic_times.svg';
import styled from 'styled-components';
import history from 'helper/history';
import BottomSheet, { ChekoutButton } from 'components/BottomSheet';
import PickUpPointImage from 'assets/images/pickup_point.png';
import { useNavigate } from 'react-router-dom';
import {
  getPickupPoint,
  PickupPointData,
  ConfirmPickup,
} from 'redux/pickuppoint';

const useStyles = makeStyles({
  labelRadio: {
    fontSize: '12px!important',
  },
});

const PickupPointPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      StoreInfo,
      PickupPoint,
      AppSetting: {
        data: {
          data: { primary_color, merchant_name, app_logo },
        },
      },
    },
  } = useRedux();
  const [pickupPoint, setPickPoint] = React.useState(
    PickupPoint && PickupPoint.items && PickupPoint.items.id
      ? PickupPoint.items.id
      : 0,
  );
  const [listData, setListData] = React.useState<PickupPointData>();

  const labelConfirm = () => {
    let find =
      pickupPoint &&
      listData &&
      listData.pickUpList &&
      listData.pickUpList.length !== 0
        ? listData.pickUpList.find((item) => item.id === pickupPoint)
        : null;
    return find ? find.pupoint_name : '';
  };

  useEffect(() => {
    let data = {
      store_id: StoreInfo?.data?.data?.id ?? '',
    };
    thunkDispatch(getPickupPoint(data))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setListData(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [thunkDispatch, StoreInfo]);

  const HandleConfirm = () => {
    let dataConfirm =
      pickupPoint &&
      listData &&
      listData.pickUpList &&
      listData.pickUpList.length !== 0
        ? listData.pickUpList.find(
            (item) => Number(item.id) === Number(pickupPoint),
          )
        : null;
    let dataObject = {
      id: dataConfirm?.id ?? '',
      pupoint_name: dataConfirm?.pupoint_name ?? '',
    };
    thunkDispatch(ConfirmPickup(dataObject))
      .unwrap()
      .then(() => navigate('/checkout'));
  };

  return (
    <MainLayout
      background="white"
      title={'Checkout | ' + merchant_name}
      favIcon={app_logo}
    >
      <Box margin={'0 0 100px 0'}>
        <Box
          padding={'20px 20px 12px 20px'}
          sx={{ background: 'white' }}
          minHeight={'48px'}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <ImageIcon
              src={IconTimes}
              alt={'ic_times'}
              loading={'lazy'}
              onClick={() => history.back()}
            />
            <NormalText
              fontSize="14px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
            >
              Pilih Pickup Points
            </NormalText>
            <div></div>
          </Stack>
        </Box>
        <SpacerLine height="3px" />
        <Box
          padding={'10px'}
          justifyContent="center"
          alignItems={'center'}
          display="flex"
        >
          <img
            style={{
              width: '312px',
              height: '430px',
              borderRadius: '10px',
            }}
            src={listData?.image_pup ?? PickUpPointImage}
            alt={'pickup_point_image'}
          />
        </Box>
        <SpacerLine height="3px" />
        <Box
          padding="20px 0"
          sx={{
            margin: '5px 0 0 0',
          }}
        >
          <NormalText
            margin="0 0 15px 20px"
            fontSize="16px"
            fontWeight="bold"
            fontFamily="D-DIN"
          >
            Pilih Pickup Points
          </NormalText>
          <FormControl sx={{ marginLeft: '30px' }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={pickupPoint}
              onChange={(event: any) => setPickPoint(event.target.value)}
              sx={{ flexDirection: 'row', display: 'flex' }}
            >
              {listData &&
              listData.pickUpList &&
              listData.pickUpList.length !== 0
                ? listData.pickUpList.map((item, i) => {
                    return (
                      <FormControlLabel
                        value={item.id}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color:
                                Number(pickupPoint) === Number(item.id)
                                  ? primary_color
                                    ? `${primary_color}!important`
                                    : '#B92362!important'
                                  : '#C4C4C4!important',
                            }}
                          />
                        }
                        label={item.pupoint_name}
                        classes={{
                          label: classes.labelRadio,
                        }}
                        sx={{
                          borderRadius: '5px',
                          borderColor:
                            Number(pickupPoint) === Number(item.id)
                              ? primary_color ?? '#B92362'
                              : '#C4C4C4',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          width: '98px',
                        }}
                      />
                    );
                  })
                : null}
            </RadioGroup>
          </FormControl>
        </Box>
        {/* Checkout Button */}
        <BottomSheet height="auto" padding="20px">
          <ChekoutButton
            background={primary_color ?? '#B92362'}
            onClick={() => HandleConfirm()}
          >
            Confirm {labelConfirm()}
          </ChekoutButton>
        </BottomSheet>
      </Box>
      <br />
    </MainLayout>
  );
};

const ImageIcon = styled.img`
  cursor: pointer;
`;

export default PickupPointPage;
