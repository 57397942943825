import axios from 'axios';

export const getStoreInfo = async (code: any) => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
    },
  };
  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/merchant/store/${code}/detail`,
    config,
  );

  return response;
};

export const getAppSetting = async (merchant_id: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/merchant/setting/getsetting?merchant_id=${merchant_id}&device_type=MOBILEQR`,
      config,
    );
  
    return response;
  };
