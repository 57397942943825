import React from "react";
import { AppContainer } from "../../components/styled/layout.styled";
import { NormalText } from "../../components/styled/text.styled";
import { Stack } from "../../components/styled/layout.styled";
import { Box } from "@mui/material";
import MainLayout from "components/Layouts/MainLayout";
import Footer from "components/Layouts/Footer";
import LogoPowerdBy from "../../assets/logo/sandbox-powered-logo.svg";
import useRedux from "../../redux/useRedux";
import { useParams } from "react-router-dom";
import { AppSettingData } from "redux/appsetting";
import InputIcon from "components/Forms/InputIcon";
import { Button } from "components/styled/button.styled";
import { validateEmail } from "helper/general";
import { getHistoryLoyalty } from "redux/loyaltyPoint";

const CheckLoyaltyPage = () => {
  const { storecode } = useParams();
  const [email, setEmail] = React.useState("");
  const [submit, setSubmit] = React.useState(false);
  const {
    thunkDispatch,
    storeState: { AppSetting },
  } = useRedux();

  const dataApp: AppSettingData = AppSetting?.data?.data ?? null;

  const handleSubmit = () => {
    setSubmit(true);
    if (email && validateEmail(email)) {
      thunkDispatch(
        getHistoryLoyalty({
          storeCode: storecode ?? "",
          email: email,
          type: "email",
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            console.log("ASD", res);
          }
        });
    }
  };

  return (
    <MainLayout
      background={dataApp?.secondary_color ?? "#FFF4F8"}
      favIcon={dataApp?.favicon ?? ""}
      title={dataApp?.merchant_name ?? ""}
    >
      <AppContainer>
        <Box padding={"30px 20px"}>
          <Stack direction="column">
            <img
              src={dataApp?.app_logo ?? ""}
              alt={"Store Logo"}
              style={{ width: "70px" }}
            />
            <NormalText
              fontSize="16px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
              color={"#000"}
              margin={"30px 0px 20px 0px"}
            >
              Masukan email untuk melihat transaksi kamu
            </NormalText>
            <InputIcon
              iconStroke={dataApp?.primary_color ?? "yellow"}
              label={"Email *"}
              placeholder={"Masukan email anda"}
              onChange={(e: any) => setEmail(e.target.value)}
              validationType={
                submit && !validateEmail(email) ? "error" : undefined
              }
              validationText={"Silahkan masukan email dengan benar"}
            />
            <Button
              margin="30px 0 0 0 "
              variant="primary"
              style={{ background: dataApp?.primary_color ?? "yellow" }}
              onClick={() => handleSubmit()}
            >
              LANJUTKAN
            </Button>
            <Button
              margin={"10px 0 0 0 "}
              variant="outline"
              style={{
                border: "1px solid " + dataApp?.primary_color ?? "yellow",
              }}
            >
              KEMBALI
            </Button>
          </Stack>
        </Box>
      </AppContainer>
      <Stack direction="row" align="center">
        <Footer>
          <div className="text-center mt-5">
            <img src={LogoPowerdBy} alt="powerdBy" />
          </div>
        </Footer>
      </Stack>
    </MainLayout>
  );
};

export default CheckLoyaltyPage;
