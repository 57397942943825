import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GoogleSignInInfoProps, VerifyGoogleEmailProps } from "./index";

export const setUserGoogle = createAsyncThunk(
  "setUserGoogle",
  async (props: GoogleSignInInfoProps) => {
    return {
      ...props,
    } as GoogleSignInInfoProps;
  }
);

export const clearUserGoogle = createAsyncThunk("clearUserGoogle", async () => {
  return {
    data: {
      email_profile: {
        email: "",
        email_verified: false,
        family_name: "",
        given_name: "",
        hd: "",
        locale: "",
        name: "",
        picture: "",
        sub: "",
      },
      access_token: "",
      authuser: "",
      expires_in: 0,
      hd: "",
      prompt: "",
      scope: "",
      token_type: "",
    },
    error: "",
    status: "",
  };
});

export const getEmailData = createAsyncThunk(
  "getEmailData",
  async (props: any) => {
    const config = {
      headers: {
        Authorization: `Bearer ${props}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${props}`,
        config
      );
      if (response && response.data) {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async (props: VerifyGoogleEmailProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    const body = {
      google_user_id: props.google_user_id,
      access_token: props.access_token,
      first_name: props.first_name,
    };
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/session/login/sandbox/google`,
        body,
        config
      );
      if (response && response.data) {
        return response;
      }
    } catch (error: any) {
      return error;
    }
  }
);
