import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import useRedux from "redux/useRedux";
import IconTimes from "assets/icons/ic_times.svg";
import styled from "styled-components";
//import history from "helper/history";
import { CategoryLabel } from "views/Brand/detail.page";
import CartProduct from "./cart.product";
import CartBottomSheet from "components/CartBottomSheet";
import {
  changeQuantity,
  ChangeQuantityProps,
  changeNotes,
  ChangeNotesProps,
} from "redux/cart/cart.reducer";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "components/Modal/confirmation";

const CartPage = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = React.useState(false);
  const [reducePayload, setReducePayload] =
    React.useState<ChangeQuantityProps>();
  const {
    thunkDispatch,
    storeState: {
      StoreInfo,
      Cart,
      AppSetting: {
        data: {
          data: { primary_color, secondary_color, merchant_name, favicon },
        },
      },
    },
  } = useRedux();

  const reduceQuantity = (props: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(props))
      .unwrap()
      .then(() => null);
  };

  const changeNote = (props: ChangeNotesProps) => {
    thunkDispatch(changeNotes(props))
      .unwrap()
      .then(() => null);
  };

  return (
    <MainLayout
      background="white"
      title={"Keranjang | " + merchant_name}
      favIcon={favicon}
    >
      <ConfirmationModal
        handleClose={() => setConfirm(false)}
        handleConfirm={() => {
          if (reducePayload) {
            reduceQuantity(reducePayload);
            setConfirm(false);
          }
        }}
        backgroundButton={primary_color ?? "#B92362"}
        description={"Apakah anda yakin ingin menghapus item ini?"}
        open={confirm}
        hasCancel={true}
      />
      <Box margin={"0 0 100px 0"}>
        <Box
          padding={"20px 20px 12px 20px"}
          sx={{ background: "white" }}
          minHeight={"48px"}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <ImageIcon
              src={IconTimes}
              alt={"ic_times"}
              loading={"lazy"}
              onClick={() => navigate("/home")}
            />
            <NormalText
              fontSize="14px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
            >
              {StoreInfo?.data?.data?.store_name}
            </NormalText>
            <div></div>
          </Stack>
        </Box>
        <SpacerLine height="5px" />
        <Box
          padding="20px 0"
          sx={{
            margin: "5px 0 0 0",
          }}
        >
          <NormalText
            margin="0 0 15px 20px"
            fontSize="16px"
            fontWeight="bold"
            fontFamily="D-DIN"
          >
            Order Saya
          </NormalText>
          {Cart.items && Cart.items.length > 0 ? (
            Cart.items.map((brand, prodItem) => (
              <div key={"cartcontainer-" + prodItem}>
                <CategoryLabel background={primary_color ?? "#fff4f8"}>
                  <NormalText
                    fontSize={"12px"}
                    color={secondary_color ?? "#B92362"}
                    fontFamily="D-DIN Exp"
                    fontWeight="bold"
                    margin="0 0 0 20px"
                  >
                    {brand.brand_name}
                  </NormalText>
                </CategoryLabel>
                {brand.products &&
                  brand.products.length &&
                  brand.products.map((item, index) => (
                    <CartProduct
                      key={"cartitem-" + index}
                      background={primary_color ?? "#B92362"}
                      brand_id={brand.brand_id}
                      cart_id={item.cart_id ?? ""}
                      onQtyIncrease={() => {
                        reduceQuantity({
                          brand_id: brand.brand_id,
                          type: "increase",
                          cart_id: item.cart_id ?? "",
                        });
                      }}
                      onQtyDecrease={() => {
                        if (item.qty === 1) {
                          setReducePayload({
                            brand_id: brand.brand_id,
                            type: "decrease",
                            cart_id: item.cart_id ?? "",
                          });
                          setConfirm(true);
                        } else {
                          reduceQuantity({
                            brand_id: brand.brand_id,
                            type: "decrease",
                            cart_id: item.cart_id ?? "",
                          });
                        }
                      }}
                      onChangeNote={changeNote}
                      qty={item.qty}
                      customizable={item.additionals}
                      product_name={item.product_name}
                      product_price={Number(item.price)}
                      notes={item.notes}
                      editNote={true}
                    />
                  ))}
              </div>
            ))
          ) : (
            <NormalText fontSize="12px" textAlign="center" margin="50px 0">
              Silahkan pilih produk terlebih dahulu
            </NormalText>
          )}
        </Box>
        <Box padding="0 20px">
          <Stack direction={"row"} justifyContent={"space-between"}>
            <div>
              <NormalText
                fontWeight={"bold"}
                fontSize={"14px"}
                fontFamily={"D-DIN Exp"}
              >
                Butuh tambahan lagi?
              </NormalText>
              <NormalText fontSize={"10px"} fontFamily={"Open Sans"}>
                Kamu bisa tambah orderan dengan klik button disamping
              </NormalText>
            </div>
            <ButtonAddNew
              background={primary_color ?? "#B92362"}
              onClick={() => navigate("/home")}
            >
              Tambah Menu Lain
            </ButtonAddNew>
          </Stack>
        </Box>
        {/* Checkout Button */}
        {Cart && Cart.items.length > 0 && <CartBottomSheet />}
      </Box>
      <br />
    </MainLayout>
  );
};

const ImageIcon = styled.img`
  cursor: pointer;
`;
interface ButtonProps {
  background?: string;
}
const ButtonAddNew = styled.button<ButtonProps>`
  background: ${(props) => props.background ?? "#b92362"};
  color: white;
  font-size: 12px;
  border: none;
  font-family: D-DIN Exp;
`;

export default CartPage;
