import {  BankProps } from "./types";
import axios from "axios";

export const checkOrderStatus = async (orderId: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/order/${orderId}/payment`, config)
    return response;
}

export const getAvailableBanks = async () => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    let banks: BankProps[] = []
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/master/check-availble-bank`, config)

    if (response) {
        if (response.status === 200) {
            banks = response.data.data
            return banks;
        }
    }

}