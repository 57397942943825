import { styled } from "@mui/system";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

interface ITab {
    activeColor?: string;
}

export const Tab = styled(TabUnstyled) <ITab>`
  font-family: 'D-DIN Exp';
  cursor: pointer;
  font-size: 14px;
  min-width: 100px;
  background-color: white;
  padding: 8px 20px;
  border: none;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: start;
  color: grey;
  transition: ease-in-out 0.3s;

  &:hover {
    background-color: #f1f1f1;
    color: black;
  }

  &:focus {
    outline: 2px solid grey;
  }

  &.${tabUnstyledClasses.selected} {
    border-bottom: 1px solid ${(props) => props.activeColor ?? '#FFC535'};
    margin-bottom: -2px;
    color: ${(props) => props.activeColor ?? '#FFC535'};
    font-weight: bold;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: 'D-DIN Exp';
  font-size: 0.875rem;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  font-family: 'D-DIN Exp';
  width: '100%';
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: space-between;
  border-bottom: 1px solid #f1f1f1;
`;
