import { useState, useEffect } from "react";
import useRedux from "redux/useRedux";
import { listAllBanner, BannerType } from "redux/banner";
import { getAppSetting, AppSettingData } from "redux/appsetting";
import { StoreQrGroupInfoTypes, storeList } from "redux/storeInfo";
import {
  listQrGroupProduct,
  listQrGroupNewProduct,
  QrGroupProductType,
} from "redux/product";
import {
  CategoryInterface,
  getCategoryListByQrGroupCode,
} from "redux/category";
import { listAllBrandByQrGroupCode, BrandsType } from "redux/brand";

import HomePageSkeleton from "components/Skeleton/HomePageSkeleton";

import MainLayout from "components/Layouts/MainLayout";
import { Stack, Box, IconButton, Typography, alpha } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import InputSearch from "components/Forms/InputSearch";
import StoreListSection from "components/List/store.list.section";
import StoreListFull from "components/List/store.list.full";
import NewProductListSection from "components/List/newproduct.list.section";
import NewProductListFull from "components/List/newproduct.list.full";
import ProductAndStoreListFull from "components/List/product-and-store.list.full";

import CategoryListSection from "components/List/category.list.section";
import { NormalText } from "components/styled/text.styled";
import CategoryAndBrandListFilter from "components/List/category-and-brand.list.filter";
import ProductDrawer from "components/Drawer/product.drawer";
import { detailProduct, ProductsDetail } from "redux/product";

import "./styles.css";

// @ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const QrGroupMainPage = () => {
  const {
    thunkDispatch,
    storeState: { QrGroupInfo, AppSetting },
  } = useRedux();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openStoreList, setOpenStoreList] = useState(false);
  const [openNewProductList, setOpenNewProductList] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [dataNewProductList, setDataNewProductList] =
    useState<QrGroupProductType[]>();
  const [banners, setBanners] = useState<BannerType[]>();
  const [tabActive, setTabActive] = useState("product");
  const [searchActive, setSearchActive] = useState(false);
  const [appSettingData, setAppSettingData] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );
  const [storeListData, setStoreListData] = useState<StoreQrGroupInfoTypes[]>();
  const [categoryData, setCategoryData] = useState<CategoryInterface[]>();
  const [brandData, setBrandData] = useState<BrandsType[]>();
  const [productsData, setProductsData] = useState<QrGroupProductType[]>();
  const [filterAge, setFilterAge] = useState(false);
  const [filterAsc, setFilterAsc] = useState(false);
  const [filterDesc, setFilterDesc] = useState(false);

  const [openProduct, setOpenProduct] = useState(false);
  const [product, setProduct] = useState<ProductsDetail>({
    product_id: 0,
    product_name: "",
    category: {
      id: 0,
      category_name: "",
    },
    sub_category: {
      id: 0,
      sub_category_name: "",
    },
    subs_category: {
      id: 0,
      subs_category_name: "",
    },
    brand: {
      id: 0,
      brand_name: "",
    },
    status: false,
    merchant_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: false,
      product_price: "",
      discount_type: "",
      discount: 0,
      product_price_after_discount: "",
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
      long_description_html: "",
    },
  });

  const [count, setCount] = useState(0);

  const slideProperties = {
    indicators: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    duration: 4000,
    canSwipe: true,
  };

  const handleProductDrawer = (open: boolean) => {
    setOpenProduct(open);
  };

  const appSetting = () => {
    thunkDispatch(
      getAppSetting({
        merchant_id: QrGroupInfo?.data?.data?.merchant_id,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAppSettingData(res.data.data);
        }
      });
  };

  const getBanner = () => {
    thunkDispatch(listAllBanner(Number(QrGroupInfo?.data?.data?.merchant_id)))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setBanners(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const getNewProductList = () => {
    let data = {
      qr_group_code: QrGroupInfo?.data?.data?.group_code,
      limit: 0,
      offset: 0,
      filter: search,
    };
    thunkDispatch(listQrGroupNewProduct(data))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setDataNewProductList(res.data.data);
        }
      });
  };

  const getProductList = () => {
    let data = {
      qr_group_code: QrGroupInfo?.data?.data?.group_code,
      limit: 0,
      keyword: search,
      offset: 0,
    };
    thunkDispatch(listQrGroupProduct(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setProductsData(res.data.data);
        }
      });
  };

  const getStoreList = () => {
    let data = {
      group_code: QrGroupInfo?.data?.data?.group_code,
      limit: 0,
      offset: 0,
      filter: search,
      sort_name: filterAsc ? "A-Z" : filterDesc ? "Z-A" : "",
      sort_store: filterAge ? "NEW" : "",
    };
    thunkDispatch(storeList(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === "success") {
          setStoreListData(res.data.data);
        }
      });
  };

  const getCategoryList = () => {
    thunkDispatch(
      getCategoryListByQrGroupCode({
        qr_group_code: QrGroupInfo?.data?.data?.group_code,
      })
    )
      .unwrap()
      .then((res: any) => {
        if (res.status === "success") {
          setCategoryData(res?.data.data);
        }
      });
  };

  const getBrandList = () => {
    let data = {
      qr_group_code: QrGroupInfo?.data?.data?.group_code,
      filter: "",
      limit: 0,
      offset: 0,
    };

    thunkDispatch(listAllBrandByQrGroupCode(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setBrandData(res.data.data);
        }
      });
  };

  const getDetailProduct = ({ productId, merchantId, storeId }: any) => {
    let data = {
      product_id: productId,
      merchant_id: merchantId,
      store_id: storeId,
    };
    thunkDispatch(detailProduct(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setLoading(false);
          let detailProduct: ProductsDetail = res.data.data;

          let tempDivElement: any = document.createElement("div");
          detailProduct.product_description.long_description_html =
            detailProduct.product_description.long_description;

          tempDivElement.innerHTML =
            detailProduct?.product_description?.long_description;

          detailProduct.product_description.long_description =
            tempDivElement?.innerText;

          setProduct(detailProduct);

          if (res.data.data?.product_detail?.is_customizable) {
            // navigate(`/product/${product_id}/detail`, {
            //   state: { previousPath: pathName },
            // });
            console.log(product);
          } else {
            handleProductDrawer(true);
          }
        }
      });
  };

  const handleBackButton = () => {
    if (openNewProductList) {
      setOpenNewProductList(false);
    }

    if (openStoreList) {
      setOpenStoreList(false);
    }

    if (!openNewProductList && !openStoreList && searchActive) {
      setSearchActive(false);
      setTabActive("product");
    }

    setSearch("");
  };

  const handleSearchActive = () => {
    if (!openNewProductList && !openStoreList) {
      setSearchActive(true);
    }
  };

  const handleChangeTab = (tab: string) => {
    setTabActive(tab);
  };

  const productToCart = (props: any) => {
    // thunkDispatch(addToCart(props)).then(() => {
    //   handleProductDrawer(false);
    // });
  };

  useEffect(() => {
    if (QrGroupInfo && QrGroupInfo.data && QrGroupInfo.data.data) {
      if (count === 0) {
        setLoading(true);
        getBanner();
        appSetting();
        getCategoryList();
        getStoreList();
        getNewProductList();
        getBrandList();
        setCount(count + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QrGroupInfo, thunkDispatch]);

  useEffect(() => {
    if (
      (searchActive && tabActive === "product") ||
      (search && searchActive && tabActive === "product")
    ) {
      getProductList();
    }

    if (
      (searchActive && tabActive === "store") ||
      (search && searchActive && tabActive === "store")
    ) {
      getStoreList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActive, tabActive, search]);

  useEffect(() => {
    if (QrGroupInfo && QrGroupInfo.data && QrGroupInfo.data.data) {
      if (
        (openNewProductList && search.length === 0) ||
        (openNewProductList && search)
      ) {
        getNewProductList();
      }

      if (
        (openStoreList && search.length === 0) ||
        (openStoreList && search) ||
        (!searchActive &&
          !openNewProductList &&
          !openStoreList &&
          search.length > 0) ||
        filterAge ||
        filterDesc ||
        filterDesc
      ) {
        getStoreList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openNewProductList,
    openStoreList,
    search,
    filterAge,
    filterAsc,
    filterDesc,
  ]);

  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={appSettingData?.favicon ?? ""}
      title={QrGroupInfo?.data?.data?.group_name ?? ""}
    >
      <CategoryAndBrandListFilter
        openModal={openCategory}
        handleClose={() => {
          setOpenCategory(false);
        }}
        page="home"
        background={alpha(appSettingData?.primary_color, 0.4)}
        categoryList={categoryData}
        brandList={brandData}
      />
      {loading ? (
        <HomePageSkeleton />
      ) : (
        <Box className="pb-5">
          <header className="w-100 mt-3 px-3">
            {/* Title & Back button */}
            {searchActive || openNewProductList || openStoreList ? (
              <Stack
                direction="row"
                width={"100%"}
                className="my-3 position-relative"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <IconButton
                  sx={{
                    background: "#888888",
                    color: "#ffffff",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  size="small"
                  onClick={handleBackButton}
                >
                  <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
                <Typography
                  component={"h1"}
                  sx={{
                    textAlign: "center",
                    fontFamily: "D-DIN Exp, sans-serif",
                    fontWeight: "400",
                  }}
                >
                  {openNewProductList ||
                  (searchActive && tabActive === "product")
                    ? "Pilih Produk"
                    : openStoreList || (searchActive && tabActive === "store")
                    ? "Pilih Toko"
                    : ""}
                </Typography>
              </Stack>
            ) : null}

            {/* Search Bar */}
            <Box width={"100%"}>
              <InputSearch
                placeholder={
                  openNewProductList
                    ? "Cari produk terbaru"
                    : openStoreList
                    ? "Cari toko"
                    : "Cari produk pilihanmu"
                }
                onChange={(e: any) => setSearch(e.target.value)}
                value={search}
                onFocus={handleSearchActive}
              />
            </Box>
          </header>

          {searchActive || openNewProductList || openStoreList ? null : (
            <>
              {/* Banner Slider */}

              <Box className="my-3">
                {banners && banners.length > 0 && (
                  <Slide {...slideProperties} style={{ background: "white" }}>
                    {banners.map((banner, index) => (
                      <div
                        key={index + "-banner"}
                        style={{
                          height: "120px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          key={index}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                          src={banner.banner_image_web}
                          alt={banner.banner_name}
                        />
                      </div>
                    ))}
                  </Slide>
                )}
              </Box>

              {/* Category List */}
              <CategoryListSection
                className="mt-3"
                showAllTrigger={() => {
                  setOpenCategory(true);
                }}
                categoryList={categoryData}
              />

              {/* Store List */}
              <StoreListSection
                className="mt-3"
                storeList={storeListData}
                showAllTrigger={() => {
                  setOpenStoreList(true);
                }}
              />

              {/* New Product List */}
              <NewProductListSection
                className="mt-3"
                newProductList={dataNewProductList}
                showAllTrigger={() => {
                  setOpenNewProductList(true);
                }}
                getDetail={getDetailProduct}
              />
            </>
          )}

          {openStoreList ? (
            <StoreListFull
              storeList={storeListData}
              type={2}
              filterAge={filterAge}
              filterAsc={filterAsc}
              filterDesc={filterDesc}
              handleFilterAge={() => setFilterAge(!filterAge)}
              handleFilterAsc={() => setFilterAsc(!filterAsc)}
              handleFilterDesc={() => setFilterDesc(!filterDesc)}
              primaryColor={appSettingData?.primary_color}
            />
          ) : null}

          {openStoreList &&
          search &&
          search.length > 0 &&
          storeListData?.length === 0 ? (
            <Box
              sx={{
                wordBreak: "break-word",
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Tidak ada toko ditemukan{" "}
                  {search ? `dengan kata kunci "${search}"` : ""}
                </NormalText>
              </Stack>
            </Box>
          ) : null}

          {openNewProductList ? (
            <NewProductListFull
              newProductList={dataNewProductList}
              className="mt-3 px-3 pb-5"
              getDetailProduct={getDetailProduct}
            />
          ) : null}

          {openNewProductList &&
          search &&
          search.length > 0 &&
          (!dataNewProductList || dataNewProductList.length === 0) ? (
            <Box
              sx={{
                wordBreak: "break-word",
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Tidak ada produk ditemukan{" "}
                  {search ? `dengan kata kunci "${search}"` : ""}
                </NormalText>
              </Stack>
            </Box>
          ) : null}

          {searchActive && !openNewProductList && !openStoreList ? (
            <ProductAndStoreListFull
              className="px-3 mt-2"
              storeList={storeListData}
              productList={productsData}
              primaryColor={appSettingData?.primary_color}
              tabActive={tabActive}
              handleChangeTab={handleChangeTab}
              getDetailProduct={getDetailProduct}
            />
          ) : null}

          {searchActive &&
          search &&
          search.length > 0 &&
          tabActive === "store" &&
          storeListData?.length === 0 ? (
            <Box
              sx={{
                wordBreak: "break-word",
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Tidak ada toko ditemukan{" "}
                  {search ? `dengan kata kunci "${search}"` : ""}
                </NormalText>
              </Stack>
            </Box>
          ) : null}

          {searchActive &&
          search &&
          search.length > 0 &&
          tabActive === "product" &&
          (!dataNewProductList || dataNewProductList.length === 0) ? (
            <Box
              sx={{
                wordBreak: "break-word",
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Tidak ada produk ditemukan{" "}
                  {search ? `dengan kata kunci "${search}"` : ""}
                </NormalText>
              </Stack>
            </Box>
          ) : null}
        </Box>
      )}
      <ProductDrawer
        open={openProduct}
        onClose={handleProductDrawer}
        onOpen={handleProductDrawer}
        product={product}
        color={AppSetting?.data?.data?.primary_color}
        productToCart={productToCart}
      />
    </MainLayout>
  );
};

export default QrGroupMainPage;
