import React from "react";
import { Box, List, ListItem, Stack } from "@mui/material";
import VirtualAccountImage from "assets/images/va_image.svg";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import CopyIcon from "assets/icons/ic_copy.svg";
import { notify } from "helper/general";
import QRCode from "qrcode.react";
import useRedux from "redux/useRedux";
import { getOrderInvoice } from "redux/checkout";
import { checkOrderStatus } from "redux/payment/payment.services";
import { useNavigate } from "react-router-dom";
import "./paymentView.css";
import { Button } from "components/styled/button.styled";
import QRISLogo from "assets/logo/QRIS-Logo.svg";
import BackButtonV3 from "components/Button/back.button.v2";
import GambarScanQR from "assets/images/scanQr di BCA.jpeg";

export interface IMetadataPayment {
  payment_string: string;
  payment_method: string;
}

interface IPaymentView {
  show: boolean;
  metadata: IMetadataPayment;
}

const PaymentView = ({ show, metadata }: IPaymentView) => {
  const {
    thunkDispatch,
    storeState: { Invoice, AppSetting },
  } = useRedux();
  const [paid, setPaid] = React.useState(false);
  const navigate = useNavigate();

  const downloadQR = () => {
    var canvas = document.querySelector(
      "#qrcode > canvas"
    ) as HTMLCanvasElement;
    console.log("canvas", canvas);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QR-${
      Invoice &&
      Invoice.data &&
      Invoice.data.data &&
      Invoice.data.data.order_number
        ? Invoice.data.data.order_number
        : "Code"
    }.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const checkUserPayment = React.useCallback(() => {
    if (show)
      setTimeout(() => {
        if (
          Invoice &&
          Invoice.data &&
          Invoice.data.data &&
          Invoice.data.data.order_number
        ) {
          console.log(Invoice.data.data.order_number);
          if (show && !paid) {
            checkOrderStatus(Invoice.data.data.order_number)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.data.status === "PAID") {
                    setPaid(true);
                    notify("success", "Pembayaran terkonfirmasi");
                    thunkDispatch(
                      getOrderInvoice({
                        order_number: res.data.data.order_number,
                      })
                    )
                      .unwrap()
                      .then(() => {
                        setTimeout(() => {
                          navigate("/checkout/success");
                        }, 5000);
                      })
                      .catch();
                  } else {
                    checkUserPayment();
                  }
                }
              })
              .catch((e) => {
                console.log("Got Error: PYMNTCK", e);
              });
          }
        }
      }, 5000);
  }, []); //eslint-disable-line

  React.useEffect(() => {
    if (show) {
      checkUserPayment();
    }
  }, [checkUserPayment, show]); //eslint-disable-line

  return (
    <>
      {show ? (
        <Box
          width={"100%"}
          height={"100%"}
          textAlign={"center"}
          sx={{ overflowX: "none", position: "relative", overflowY: "auto" }}
          className={"payment-container"}
        >
          <Box sx={{ position: "absolute", top: "50px", left: "10px" }}>
            <BackButtonV3 prev_url="/" />
          </Box>

          <NormalText
            style={{ textTransform: "uppercase" }}
            fontSize={"16px"}
            fontWeight={"bold"}
            textAlign={"center"}
            margin={"50px auto 10px auto"}
            className={"title"}
            maxWidth="80%"
          >
            pembayaran dengan{" "}
            {metadata.payment_method === "virtual_account"
              ? "Virtual Account"
              : "QRIS"}
          </NormalText>

          {metadata.payment_method === "qr_code" ? (
            <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
              <img src={QRISLogo} alt="" width={"144px"} />
            </Stack>
          ) : null}

          <Box
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
            margin={"2% 0 0 0"}
          >
            <Box width={"80%"}>
              <NormalText
                fontFamily="Roboto"
                fontSize={"12px"}
                color={"grey"}
                textAlign={"center"}
              >
                {metadata.payment_method === "virtual_account"
                  ? "Masukkan kode virtual account yang anda dapatkan untuk melakukan pembayaran transaksi menggunakan m-banking atau atm terdekat."
                  : "Untuk melanjutkan pembayaran, silahkan unduh QRIS dibawah ini dan baca petunjuk dibawah."}
              </NormalText>
            </Box>
          </Box>
          <Box
            textAlign={"center"}
            margin={
              metadata.payment_method === "virtual_account"
                ? "100px 0 40px 0"
                : "0px 0 5px 0"
            }
            className={"info-box"}
          >
            {metadata.payment_method === "virtual_account" ? (
              <img
                src={VirtualAccountImage}
                style={{ width: "300px" }}
                alt={"va_string"}
                className={"illustration"}
              />
            ) : (
              <div id="qrcode">
                <QRCode
                  value={metadata.payment_string}
                  level={"L"}
                  size={300}
                  renderAs={"canvas"}
                  includeMargin={true}
                />
                <div>
                  <Button
                    onClick={() => downloadQR()}
                    margin="0px 0 0 0"
                    padding={"5px 10px"}
                    background={
                      AppSetting?.data?.data?.primary_color
                        ? AppSetting.data.data.primary_color
                        : ""
                    }
                    width="156px"
                    height="44px"
                  >
                    Download QR Code
                  </Button>
                </div>
              </div>
            )}
          </Box>
          {metadata.payment_method === "virtual_account" && (
            <Box display={"flex"} justifyContent={"center"}>
              <AccountContainer>
                <Stack direction={"row"} id={"virtual_account"}>
                  {metadata.payment_string}
                  <CopyBox
                    src={CopyIcon}
                    alt={"ic_copy"}
                    onClick={(e) => {
                      if (navigator && navigator.clipboard) {
                        console.log("ASDASDASD");
                        navigator.clipboard
                          .writeText(metadata.payment_string ?? "AVSCCCC")
                          .then(() => {
                            notify(
                              "success",
                              "Berhasil menyalin virtual account"
                            );
                          })
                          .catch(() =>
                            notify("error", "Gagal menyalin virtual account")
                          );
                      }
                    }}
                  />
                </Stack>
              </AccountContainer>
            </Box>
          )}

          {metadata.payment_method === "virtual_account" ? (
            <Box
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                width={"80%"}
                sx={{ wordBreak: "break-word", overflow: "hidden" }}
              >
                <NormalText
                  fontFamily="Roboto"
                  fontSize={"12px"}
                  color={"grey"}
                  textAlign={"center"}
                  margin={"10px 0"}
                >
                  Gunakan nomor virtual account diatas untuk melanjutkan
                  transaksi anda.
                </NormalText>
              </Box>
            </Box>
          ) : null}

          {metadata.payment_method === "qr_code" ? (
            <>
              <Stack
                direction={"row"}
                alignItems="center"
                padding={1.5}
                sx={{ borderBottom: "2px solid #cccccc", margin: "20px 0 0 0" }}
              >
                <NormalText
                  fontFamily="Roboto"
                  fontSize={"16px"}
                  fontWeight={"600"}
                  color={"#2f3542"}
                  textAlign={"center"}
                  margin={"0 0 0 0px"}
                >
                  Cara Membayar QR Code dengan 1 Device
                </NormalText>
              </Stack>
              <List>
                <ListItem>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#2f3542"}
                    textAlign={"left"}
                    margin={"0"}
                  >
                    1. Download QR Code yang ingin kamu bayar.
                  </NormalText>
                </ListItem>
                <ListItem>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#2f3542"}
                    textAlign={"left"}
                    margin={"0"}
                  >
                    2. Buka E-Wallet / M-Banking kamu yang sudah memiliki fitur
                    Scan QRIS.
                  </NormalText>
                </ListItem>
                <ListItem sx={{ display: "flex", flexDirection: "column" }}>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#2f3542"}
                    textAlign={"left"}
                    margin={"0"}
                  >
                    3. Klik icon gambar untuk masuk ke galeri kamu dan pilih
                    hasil download QR Code.
                  </NormalText>
                  <Stack
                    sx={{
                      width: "300px",
                      height: "500px",
                      overflow: "hidden",
                      margin: "10px 0 10px 0",
                    }}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"flex-end"}
                  >
                    <img
                      src={GambarScanQR}
                      alt="scan qr di M-BCA"
                      style={{ height: "auto", width: "300px" }}
                    />
                  </Stack>
                </ListItem>
                <ListItem>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#2f3542"}
                    textAlign={"left"}
                    margin={"0"}
                  >
                    4. Rincian pembayaran anda akan muncul dan mohon periksa
                    kembali apakah sudah sesuai, jika sudah sesuai lanjutkan
                    pembayaran sampai selesai
                  </NormalText>
                </ListItem>
              </List>
            </>
          ) : null}

          {metadata.payment_method === "qr_code" ? (
            <Box
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              margin={"5% 0 0 0"}
            >
              <Box
                width={"80%"}
                sx={{ wordBreak: "break-word", overflow: "hidden" }}
              >
                <>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"12px"}
                    fontWeight={"600"}
                    color={"grey"}
                    textAlign={"center"}
                    margin={"10px 0"}
                  >
                    ***
                  </NormalText>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"12px"}
                    fontWeight={"600"}
                    color={"grey"}
                    textAlign={"center"}
                    margin={"10px 0"}
                  >
                    QRIS bisa digunakan dengan lebih dari 40 penyedia E-Wallet &
                    M-Banking di Indonesia
                  </NormalText>
                  <NormalText
                    fontFamily="Roboto"
                    fontSize={"12px"}
                    fontWeight={"400"}
                    color={"grey"}
                    textAlign={"center"}
                    margin={"10px 0"}
                  >
                    Untuk menghindari kegagalan membayar pesanan, agar tidak
                    menutup halaman pesanan dan atau menutup paksa aplikasi saat
                    proses secure page sedang di muat.
                  </NormalText>
                </>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

const AccountContainer = styled.div`
  background: #e5e5e5;
  border-radius: 15px;
  min-width: 200px;
  font-weight: bold;
  font-size: 20px;
  color: black;
  padding: 10px 20px;
  letter-spacing: 2px;
`;

const CopyBox = styled.img`
  width: 30px;
  background: #4b9fd8;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0 0 10px;
`;

export default PaymentView;
