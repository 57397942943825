import { NormalText, DivText } from "components/styled/text.styled";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
//import IconRating from "assets/icons/ic_star.svg";
import { formatCurrency } from "helper/general";
import NoPhoto from "assets/images/NoPhotoList.svg";

interface Products {
  image: any;
  name: string;
  brand?: string;
  price?: string;
  onClick?: any;
  discount?: any;
  discount_type?: any;
  price_after_discount?: any;
}

const ProductSearch = ({
  image,
  name,
  brand,
  price,
  onClick,
  discount,
  discount_type,
  price_after_discount,
}: Products) => {
  return (
    <Container onClick={onClick}>
      <Stack direction={"column"} justifyContent={"flex-start"}>
        <Box
          width={"158px"}
          height={"136px"}
          sx={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
            position: "relative",
          }}
        >
          {discount && discount_type === "percent" ? (
            <Box
              sx={{
                background: "#FF2622",
                height: "35px",
                width: "35px",
                position: "absolute",
                top: 0,
                right: 0,
                borderBottomLeftRadius: "8px",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <DivText fontSize="7px" fontWeight="800" lineHeight="1.2">
                Diskon
              </DivText>
              <DivText fontSize="12px" fontWeight="800" lineHeight="1.2">
                {discount + "%"}
              </DivText>
            </Box>
          ) : null}

          <ProductImageDup
            src={image && image.length > 0 ? image : NoPhoto}
            alt={name}
            loading="lazy"
          />
        </Box>

        <DivText
          fontFamily="D-DIN Exp"
          fontSize="12px"
          lineHeight="14px"
          fontWeight="700"
          letterSpacing="0.2px"
          padding="0 0px"
          margin="10px 0 0 0"
          position="static"
        >
          {name.length > 35
            ? name.toUpperCase().substring(0, 35) + "..."
            : name.toUpperCase()}
        </DivText>

        <NormalText
          fontFamily="D-DIN Exp"
          fontWeight="700"
          fontSize="10px"
          lineHeight="12px"
          color="#999999"
          letterSpacing="1px"
          padding="0px 0px 0px 0px"
        >
          {brand && brand.length > 43 ? brand.substring(0, 42) + "..." : brand}
        </NormalText>
      </Stack>

      <Stack direction={"column"}>
        {discount || Number(discount) > 0 ? (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={0}
            sx={{ margin: "5px 0 0 0" }}
          >
            <NormalText
              fontFamily="Open Sans"
              fontWeight="300"
              fontSize="11px"
              lineHeight="14px"
              textAlign="right"
              padding="0 0px 0px 0px"
              textDecoration="line-through"
            >
              {formatCurrency(Number(price))}
            </NormalText>
          </Stack>
        ) : null}

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={0}
          sx={{ margin: "2px 0 0 0" }}
        >
          <NormalText
            fontFamily="Open Sans"
            fontWeight="700"
            fontSize="12px"
            lineHeight="14px"
            textAlign="right"
            padding="0 0px 10px 0px"
          >
            Harga
          </NormalText>

          <NormalText
            fontFamily="Open Sans"
            fontWeight="700"
            fontSize="12px"
            lineHeight="14px"
            textAlign="right"
            padding="0 0px 10px 0px"
          >
            {price_after_discount && Number(price_after_discount) > 0
              ? formatCurrency(Number(price_after_discount))
              : formatCurrency(Number(price))}
          </NormalText>
        </Stack>
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 158px;
  height: 240px;
  margin: 10px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 2px;
  word-break: "break-word";

  &:hover {
    background: #ededed;
    scale: 0.9;
    padding: "0 0px 0 0px";
  }
`;

// Duplicate Product Item Component & Styled Component
const ProductImageDup = styled.img`
  width: auto;
  height: 120px;
`;

export default ProductSearch;
