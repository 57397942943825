import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfileState, ProfileProps } from './types';
import axios from 'axios';

export const postProfile = createAsyncThunk(
  'profile/updateProfile',
  async (props: ProfileProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update-profile-customer`,
        {
          merchant_id: props.merchant_id,
          email: props.email,
          name: props.name,
          phone_number: props.phone_number,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ProfileState;
    }
  },
);

export const getUpdateProfile = createAsyncThunk(
  'profile/updateProfile',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/users/session/profile`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY ?? '',
            // token: props.token,
          },
        },
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'error',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      return {
        data: 'Something went wrong!',
        status: 'error',
        error: err,
      } as unknown as ProfileState;
    }
  },
);
