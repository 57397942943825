import { Stack, Box, Tabs, Tab } from "@mui/material";

import { StoreQrGroupInfoTypes } from "redux/storeInfo";
import { QrGroupProductType } from "redux/product";

import StoreListFull from "./store.list.full";
import NewProductListFull from "./newproduct.list.full";

interface ProductAndStoreListFullProps {
  className?: string;
  productList?: QrGroupProductType[];
  storeList?: StoreQrGroupInfoTypes[];
  tabActive: string;
  handleChangeTab: any;
  primaryColor: string;
  getDetailProduct?:any;
}

const ProductAndStoreListFull = ({
  className,
  productList,
  storeList,
  tabActive,
  handleChangeTab,
  primaryColor,
  getDetailProduct
}: ProductAndStoreListFullProps) => {
  return (
    <Stack className={className}>
      <Box sx={{ borderBottom: 1, borderColor: "#cccccc" }}>
        <Tabs
          value={tabActive}
          aria-label="product and store tab"
          sx={{
            "& .css-1aquho2-MuiTabs-indicator": {
              backgroundColor: primaryColor,
            },
          }}
        >
          <Tab
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                color: primaryColor,
              },
            }}
            label="Produk"
            value="product"
            onClick={() => {
              handleChangeTab("product");
            }}
          />
          <Tab
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                color: primaryColor,
              },
            }}
            label="Toko"
            value="store"
            onClick={() => {
              handleChangeTab("store");
            }}
          />
        </Tabs>
      </Box>

      {tabActive === "store" ? (
        <StoreListFull
          className="mt-2"
          storeList={storeList}
          primaryColor={primaryColor}
          type={1}
        />
      ) : null}

      {tabActive === "product" ? (
        <NewProductListFull className="mt-2" newProductList={productList} getDetailProduct={getDetailProduct}/>
      ) : null}
    </Stack>
  );
};

export default ProductAndStoreListFull;
