import {
  RegisterUserState,
  UserResponseProps,
  RegisterUserProps,
} from './types';
import { createSlice } from '@reduxjs/toolkit';
import { registerUser, registerSetData, removeRegisterdata } from './userService';

export const initialState: RegisterUserState = {
  data: {} as UserResponseProps,
  status: 'loading',
  error: {},
  items: {} as RegisterUserProps,
};

export const registerUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      })
      .addCase(registerSetData.fulfilled, (state, action) => {
        if (action.payload) {
          state.items = action.payload;
          state.status = 'success';
          state.error = '';
        }
      })
      .addCase(registerSetData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(registerSetData.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(removeRegisterdata.fulfilled, (state, action) => {
        state.data = initialState.data
        state.status = 'loading'
        state.items = initialState.items
        state.error = initialState.error
      })
      .addCase(removeRegisterdata.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removeRegisterdata.rejected, (state, action) => {
        state.status = 'error';
      });
  },
});

export const RegisterUserReducer = registerUserSlice.reducer;
