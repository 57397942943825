import React from "react";
import { AppContainer } from "../../components/styled/layout.styled";
import { Stack } from "../../components/styled/layout.styled";
import LogoNoAccess from "../../assets/logo/no-access.png";
import { NormalText } from "../../components/styled/text.styled";
import { Container } from "components/Layouts/MainLayout";
import { Wrapper } from "components/Layouts/styles";
import { Helmet } from "react-helmet";
import "./styles.css";
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";

interface Props {
  message?: string;
  retry?: boolean;
}

const ForbiddenPage = ({ message, retry = false }: Props) => {
  const {
    storeState: { AppSetting },
  } = useRedux();
  // eslint-disable-next-line
  const [data, setData] = React.useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );
  return (
    <Container>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Access Forbiden | Sandbox</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />

        <link
          href="http://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper background={data?.secondary_color ?? "#000"}>
        <AppContainer>
          <Stack direction="row">
            <div
              className="container text-center"
              style={{ margin: "30% 0 0 0" }}
            >
              <img src={LogoNoAccess} alt="logo" />
              <NormalText
                fontSize="18px"
                margin="20px"
                fontWeight="600"
                fontFamily="D-DIN EXP"
              >
                ACCESS FORBIDDEN
              </NormalText>
              <NormalText
                wordWrap="break-word"
                color="#7E7E7E"
                margin="10px"
                fontSize="12px"
                fontFamily="Open Sans"
              >
                {message ??
                  "GPS kamu belum aktif, mohon untuk izinkan/aktifkan GPS untuk melanjutkan"}
              </NormalText>
              {retry && (
                <NormalText
                  fontSize="12px"
                  onClick={() => window.location.reload()}
                  isPressable
                  fontFamily="Open Sans"
                >
                  Coba Lagi
                </NormalText>
              )}
            </div>
          </Stack>
        </AppContainer>
      </Wrapper>
    </Container>
  );
};

export default ForbiddenPage;
