import { Stack, SwipeableDrawer, Typography, IconButton } from "@mui/material";

//component

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ServiceItem from "components/Item/service.item";
import { USER_SERVICE_KEY } from "helper/constant";

const fNbServices = [
  { name: "Santap di Tempat", name_key: "DINE IN" },
  { name: "Bawa Pulang", name_key: "TAKE AWAY" },
  { name: "Ambil di Titik Pengambilan", name_key: "PICKUP POINT" },
  { name: "Pesan Antar", name_key: "DELIVERY" },
];

const retailServices = [
  { name: "Ambil di Toko", name_key: "IN STORE PICKUP" },
  { name: "Ambil di Titik Pengambilan", name_key: "PICKUP POINT" },
  { name: "Pesan Antar", name_key: "DELIVERY" },
];

interface ServicesDrawerProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
  color?: string;
  service: string;
  merchantType: string;
  pickUp: boolean;
  handlePickService: any;
}

const ServicesDrawer = ({
  open,
  onClose,
  onOpen,
  color,
  service,
  merchantType,
  pickUp,
  handlePickService,
}: ServicesDrawerProps) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose();
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #cccccc", padding: "0.9rem 1rem" }}
        >
          <Typography
            sx={{
              color: "#2d3436",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "800",
              fontSize: "0.9rem",
            }}
          >
            Tipe Order
          </Typography>

          <IconButton
            sx={{
              color: "#000000",
              height: "25px",
              width: "25px",
            }}
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Stack>
        {merchantType === "FOOD" ? (
          <>
            {pickUp
              ? fNbServices.map((item, index) => {
                  return (
                    <ServiceItem
                      key={"service-" + index}
                      name={item.name}
                      name_key={item.name_key}
                      selected={item.name_key === service}
                      background={color}
                      onChange={() => {
                        localStorage.setItem(USER_SERVICE_KEY, item.name_key);
                        handlePickService(item.name_key);
                        onClose();
                      }}
                    />
                  );
                })
              : fNbServices
                  .filter((item) => item.name_key !== "PICKUP POINT")
                  .map((item, index) => {
                    return (
                      <ServiceItem
                        key={"service-" + index}
                        name={item.name}
                        name_key={item.name_key}
                        selected={item.name_key === service}
                        background={color}
                        onChange={() => {
                          localStorage.setItem(USER_SERVICE_KEY, item.name_key);
                          handlePickService(item.name_key);
                          onClose();
                        }}
                      />
                    );
                  })}
          </>
        ) : (
          <>
            {pickUp
              ? retailServices.map((item, index) => {
                  return (
                    <ServiceItem
                      key={"service-" + index}
                      name={item.name}
                      name_key={item.name_key}
                      selected={item.name_key === service}
                      background={color}
                      onChange={() => {
                        localStorage.setItem(USER_SERVICE_KEY, item.name_key);
                        handlePickService(item.name_key);
                        onClose();
                      }}
                    />
                  );
                })
              : retailServices
                  .filter((item) => item.name_key !== "PICKUP POINT")
                  .map((item, index) => {
                    return (
                      <ServiceItem
                        key={"service-" + index}
                        name={item.name}
                        name_key={item.name_key}
                        selected={item.name_key === service}
                        background={color}
                        onChange={() => {
                          localStorage.setItem(USER_SERVICE_KEY, item.name_key);
                          handlePickService(item.name_key);
                          onClose();
                        }}
                      />
                    );
                  })}
          </>
        )}

        {/* {methods.map((method, index) => {
          if (service === "DELIVERY") {
            if (method.payment_code === "cash") {
              return null;
            } else {
              return (
                <PaymentItem
                  name={method.payment_name}
                  channel={method.payment_code}
                  key={"payment-" + index}
                  background={color ?? ""}
                  selected={
                    method.payment_code === paymentDetail.payment_channel
                  }
                  onChange={() => {
                    if (method.payment_code === "virtual_account") {
                      handlePickBank(true);
                    }
                    let payFee: number = 0;
                    payFee = Number(method.payment_fee_price);
                    if (method.payment_fee_percentage > 0) {
                      let percent: any = method.payment_fee_percentage;
                      let subTotal = cart.total_price;
                      let result: number = subTotal * (percent / 100);
                      payFee += Math.ceil(result);
                    }

                    handleServices({
                      payment_fee: payFee,
                    });
                    handleChangePaymentDetail({
                      ...paymentDetail,
                      payment_type: method.payment_code,
                      payment_channel: method.payment_code,
                    });
                  }}
                />
              );
            }
          } else {
            return (
              <PaymentItem
                name={method.payment_name}
                channel={method.payment_code}
                key={"payment-" + index}
                background={color ?? ""}
                selected={method.payment_code === paymentDetail.payment_channel}
                onChange={() => {
                  if (method.payment_code === "virtual_account") {
                    handlePickBank(true);
                  }
                  let payFee: number = 0;
                  payFee = Number(method.payment_fee_price);
                  if (method.payment_fee_percentage > 0) {
                    let percent: any = method.payment_fee_percentage;
                    let subTotal = cart.total_price;
                    let result: number = subTotal * (percent / 100);
                    payFee += Math.ceil(result);
                  }

                  handleServices({
                    payment_fee: payFee,
                  });
                  handleChangePaymentDetail({
                    ...paymentDetail,
                    payment_type: method.payment_code,
                    payment_channel: method.payment_code,
                  });
                  onClose();
                }}
              />
            );
          }
        })} */}
      </Stack>
    </SwipeableDrawer>
  );
};

export default ServicesDrawer;
