import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import useRedux from "redux/useRedux";
import IconTimes from "assets/icons/ic_times.svg";
import styled from "styled-components";
import history from "helper/history";
import BottomSheet, {
  ChekoutButton as CheckoutButton,
} from "components/BottomSheet";
import { useNavigate } from "react-router-dom";
import { SELECTED_TABLE_KEY, SELECTED_TABLE_NAME_KEY } from "helper/constant";
import { listAllTable, TableType } from "redux/table";

const TablePage = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<{ id: string; name: string }>({
    id: localStorage.getItem(SELECTED_TABLE_KEY) ?? "",
    name: localStorage.getItem(SELECTED_TABLE_NAME_KEY) ?? "",
  });
  const [tables, setTables] = React.useState<TableType[]>();

  const {
    thunkDispatch,
    storeState: {
      StoreInfo,
      AppSetting: {
        data: {
          data: { merchant_name, app_logo, primary_color },
        },
      },
    },
  } = useRedux();

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.status === "success" &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data
    ) {
      thunkDispatch(listAllTable(StoreInfo.data.data.id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setTables(res.data.data);
          }
        });
    }
  }, [StoreInfo, thunkDispatch]);

  return (
    <MainLayout
      background="white"
      title={"Keranjang | " + merchant_name}
      favIcon={app_logo}
    >
      <Box margin={"0 0 100px 0"}>
        <Box
          padding={"20px 20px 12px 20px"}
          sx={{ background: "white" }}
          minHeight={"48px"}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <ImageIcon
              src={IconTimes}
              alt={"ic_times"}
              loading={"lazy"}
              onClick={() => history.back()}
            />
            <NormalText
              fontSize="14px"
              fontWeight="bold"
              fontFamily="D-DIN Exp"
            >
              Pilih Meja
            </NormalText>
            <div></div>
          </Stack>
        </Box>
        <SpacerLine height="5px" />
        <Box
          padding="20px 0"
          sx={{
            margin: "5px 0 0 0",
          }}
        >
          <TableBox>
            {tables &&
              tables.map((table, index) => (
                <TableItem
                  key={index}
                  selected={String(table.id) === selected.id}
                  booked={table.is_available}
                  onClick={() => {
                    setSelected({
                      id: String(table.id),
                      name: table.table_name,
                    });
                  }}
                >
                  {table.table_name}
                </TableItem>
              ))}
          </TableBox>
        </Box>
        {/* Checkout Button */}
        <BottomSheet>
          <CheckoutButton
            onClick={() => {
              localStorage.setItem(SELECTED_TABLE_KEY, selected.id);
              localStorage.setItem(SELECTED_TABLE_NAME_KEY, selected.name);
              navigate("/checkout");
            }}
            background={primary_color ?? ""}
          >{`Konfirmasi ${selected.name ?? ""}`}</CheckoutButton>
        </BottomSheet>
      </Box>
      <br />
    </MainLayout>
  );
};

const ImageIcon = styled.img`
  cursor: pointer;
`;

const TableBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  gap: 10px;
`;

interface ITableItem {
  selected: boolean;
  booked: boolean;
}

const TableItem = styled.div<ITableItem>`
  border: 1px solid #c4c4c4;
  background: ${(props) =>
    props.selected ? "#81D678" : props.booked ? "#E84C4F" : "white"};
  border-radius: 4px;
  padding: 10px;
  font-size: 10px;
  text-align: center;
  font-family: "D-DIN Exp";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) =>
    props.selected ? "white" : props.booked ? "white" : "black"};
  cursor: ${(props) => (props.booked ? "not-allowed" : "pointer")};

  :hover {
    border: 1px solid #f1f1f1;
  }
`;

export default TablePage;
