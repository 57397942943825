import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryState } from "./types";
import { ProductListState, ProductListParamsTypes } from "./types";
// import { getToken } from "helper/general";
import axios from "axios";

interface getAllCategory {
  merchant: number;
  store_id: number;
}

interface getCategoryListByQrGroupCodeProps {
  qr_group_code: string;
}

export const getCategoryList = createAsyncThunk(
  "product/list",
  async ({ merchant, store_id }: getAllCategory) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/data/category/list?merchant_id=${merchant}&store_id=${store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as CategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as CategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as CategoryState;
    }
  }
);

export const getDetailCategory = createAsyncThunk(
  "product/list",
  async (props: ProductListParamsTypes) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/productstore/mobile-qr/list-by-category?merchant_id=${
          props.merchant_id
        }&store_id=${props.store_id}${
          props.category_id ? `&category_id=${props.category_id}` : ""
        }${
          props.sub_category_id
            ? `&sub_category_id=${props.sub_category_id}`
            : ""
        }${
          props.subs_category_id
            ? `&subs_category_id=${props.subs_category_id}`
            : ""
        }&limit=0`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductListState;
    }
  }
);

export const getCategoryListByQrGroupCode = createAsyncThunk(
  "category/list",
  async ({qr_group_code}: getCategoryListByQrGroupCodeProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/group/${qr_group_code}/category`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as CategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as CategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as CategoryState;
    }
  }
);
