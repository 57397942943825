import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { BrandsType } from "redux/brand";
interface BrandListFilterProps {
  list: BrandsType[];
  handleClose?: any;
}

const BrandListFilter = ({ list, handleClose }: BrandListFilterProps) => {
  return (
    <Box className="filter-container">
      <List
        sx={{
          width: "100%",
          maxWidth: 320,
          bgcolor: "background.paper",
        }}
        component="nav"
      >
        {list &&
          list.length &&
          list
            .filter((brand) => brand.is_active === true)
            .filter((brand) => brand.total_product > 0)
            .map((item, index) => (
              <ListItem key={"brand" + index} sx={{ padding: 0 }}>
                <ListItemButton
                  sx={{
                    paddingX: 1,
                    borderBottom: "1px solid #f1f1f1",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <ListItemText primary={item.brand_name} />
                </ListItemButton>
              </ListItem>
            ))}
      </List>
    </Box>
  );
};

export default BrandListFilter;
