import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Wrapper } from "./styles";
import useRedux from "../../redux/useRedux";
import { useParams } from "react-router-dom";
import { storeInfo } from "redux/storeInfo";
import { qrGroupInfo } from "redux/qrGroup";
import { getConfigSetting } from "helper/general";
import AccessForbidden from "views/General/accessForbidden";
import { AppContainer, Stack } from "components/styled/layout.styled";
import { NormalText } from "components/styled/text.styled";
import {
  useGeoLocation,
  getDistanceBetweenTwoPoints,
  Coordinate,
} from "../../helper/geoLocation";

import SandboxShort from "assets/logo/sandbox64.png";
import { ToastContainer } from "react-toastify";
import { resetCart } from "redux/cart/cart.reducer";

export interface Props {
  title?: string;
  meta?: string;
  description?: string;
  children: ReactNode;
  background?: string;
  padding?: string;
  noGpsCheck?: boolean;
  favIcon?: any;
}

const MainLayout = ({
  title,
  children,
  background,
  padding,
  noGpsCheck = true,
  favIcon,
}: Props) => {
  const { key, store_key, qr_group_key } = useParams();
  const { position, state } = useGeoLocation(noGpsCheck);
  const [show, setShow] = React.useState("main");
  const [loading, setLoading] = React.useState(false);
  const {
    thunkDispatch,
    storeState: { StoreInfo, QrGroupInfo },
  } = useRedux();

  const checkRadius = (storecoordinate: Coordinate) => {
    let cord1 = {
      lon: storecoordinate.lon,
      lat: storecoordinate.lat,
    };
    let cord2 = {
      lon: position?.coords.longitude,
      lat: position?.coords.latitude,
    } as Coordinate;
    let distance = getDistanceBetweenTwoPoints(cord1, cord2);
    if (distance) {
      if (position?.coords.accuracy && position?.coords.accuracy <= 30) {
        getConfigSetting("radius")
          .then((res) => {
            if (res && res.status === 200) {
              let radius = res.data.data.value ?? 50;
              let userdistance = distance - position.coords.accuracy;
              if (userdistance <= radius) {
                setShow("main");
                setLoading(false);
              } else {
                setLoading(false);
                setShow("forbidden");
              }
            }
          })
          .catch((err) => {
            console.log("error", err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setShow("toofar");
      }
    }
  };

  const checkStoreInput = (storecode: string) => {
    thunkDispatch(storeInfo(storecode))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setLoading(false);
          if (noGpsCheck) {
            checkRadius({
              lat: res.data.data.latitude,
              lon: res.data.data.longitude,
            });
          }
        } else {
          setLoading(false);
          console.log("STEP 1", res);
          setShow("invalid");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("STEP 2");
        setShow("invalid");
      });
  };

  const checkQrGroupInput = (groupcode: string) => {
    let payload = {
      group_code: groupcode,
      limit: 0,
      offset: 0,
      filter: "",
    };
    thunkDispatch(qrGroupInfo(payload))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setLoading(false);
          // if (noGpsCheck) {
          //   checkRadius({
          //     lat: res.data.data.latitude,
          //     lon: res.data.data.longitude,
          //   });
          // }
        } else {
          setLoading(false);
          console.log("STEP 1", res);
          setShow("invalid");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("STEP 2");
        setShow("invalid");
      });
  };

  React.useEffect(() => {
    setLoading(true);
    if (
      (key && StoreInfo) ||
      (!key && StoreInfo) ||
      (store_key && StoreInfo) ||
      (!store_key && StoreInfo) ||
      (qr_group_key && QrGroupInfo) ||
      (!qr_group_key && QrGroupInfo)
    ) {
      let storecode = "";
      let qrgroupcode = "";

      if (key && key.length > 0) {
        storecode = key;
      }

      if (store_key && store_key.length > 0) {
        storecode = store_key;
      }

      if (
        (!key || key.length === 0) &&
        (!store_key || store_key.length === 0) &&
        StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.store_code
      ) {
        storecode = StoreInfo.data.data.store_code;
      }

      if (qr_group_key && qr_group_key.length > 0) {
        qrgroupcode = qr_group_key;
      }

      if (
        (!qr_group_key || qr_group_key.length === 0) &&
        QrGroupInfo &&
        QrGroupInfo.data &&
        QrGroupInfo.data.data &&
        QrGroupInfo.data.data.group_code
      ) {
        qrgroupcode = QrGroupInfo.data.data.group_code;
      }

      if (storecode) {
        if (
          StoreInfo &&
          StoreInfo.data &&
          StoreInfo.data.data &&
          StoreInfo.data.data.store_code &&
          StoreInfo.data.data.store_code === storecode
        ) {
          setLoading(false);
          checkStoreInput(storecode);
        } else {
          thunkDispatch(resetCart());
          checkStoreInput(storecode);
        }

        return;
      }

      if (qrgroupcode) {
        if (
          QrGroupInfo &&
          QrGroupInfo.data &&
          QrGroupInfo.data.data &&
          QrGroupInfo.data.data.group_code === qrgroupcode
        ) {
          setLoading(false);
          checkQrGroupInput(qrgroupcode);
        } else {
          thunkDispatch(resetCart());
          checkQrGroupInput(qrgroupcode);
        }

        return;
      }

      setLoading(false);
      setShow("invalid");

      return;
    }

    setLoading(false);
    setShow("invalid");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, state]);

  return (
    <Container>
      <ToastContainer />
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title ?? "Pagii Sandbox Customer"}</title>
        <link
          rel="icon"
          type="image/png"
          href={favIcon ? favIcon : SandboxShort}
          sizes="16x16"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />

        <link
          href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper background={background} padding={padding}>
        {/* Remove this condition if it on production mode */}
        {!noGpsCheck ? (
          loading ? (
            <LoadingFrame background={background} />
          ) : (
            <>
              {state === "prompt" ? (
                <AppContainer background={background}>
                  <Stack
                    direction="row"
                    align="center"
                    display="flex"
                    justify="center"
                    height="100vh"
                  >
                    <NormalText fontFamily="D-DIN EXP" fontSize="13px">
                      Untuk melanjutkan silahkan aktifkan permintaan lokasi.
                    </NormalText>
                  </Stack>
                </AppContainer>
              ) : state === "denied" ? (
                <AccessForbidden
                  message={
                    "GPS kamu belum aktif, untuk melanjutkan, mohon untuk izinkan GPS untuk mengakses browser"
                  }
                />
              ) : (
                <>
                  {show === "main" ? (
                    children
                  ) : show === "toofar" || show === "forbidden" ? (
                    <AccessForbidden
                      retry
                      message={"Perangkat kamu diluar jangkauan toko kami"}
                    />
                  ) : !noGpsCheck && show === "invalid" ? (
                    <AccessForbidden
                      message={
                        "Opsss!,Parameter tidak valid, silahkan lakukan scan ulang QRCode toko untuk melanjutkan."
                      }
                    />
                  ) : (
                    <AccessForbidden
                      message={
                        "Opsss!,Parameter tidak valid, silahkan lakukan scan ulang QRCode toko untuk melanjutkan."
                      }
                    />
                  )}
                </>
              )}
            </>
          )
        ) : (
          <>
            {loading ? (
              <LoadingFrame background={background} />
            ) : show === "main" ? (
              children
            ) : show === "invalid" ? (
              <AccessForbidden
                message={
                  "Opsss!, Parameter tidak valid, silahkan lakukan scan ulang QRCode toko untuk melanjutkan."
                }
              />
            ) : (
              children
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export const Container = styled.div`
  position: relative;
  display: block;
  height: 100vh;
`;

const LoadingFrame = ({ background }: { background?: string }) => {
  return (
    <AppContainer background={background}>
      <Stack
        direction="row"
        align="center"
        display="flex"
        justify="center"
        height="100vh"
      >
        <NormalText fontFamily="D-DIN EXP" fontSize="13px">
          Tunggu Sebentar
        </NormalText>
      </Stack>
    </AppContainer>
  );
};

export default MainLayout;
