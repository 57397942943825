import { Stack, Button, Grid } from "@mui/material";
import CategoryListItem from "./category.list.item";

import { CategoryInterface } from "redux/category";

interface CategoryListSectionProps {
  className?: string;
  categoryList?: CategoryInterface[];
  primaryColor?: string;
  showAllTrigger: any;
}

const CategoryListSection = ({
  className,
  categoryList,
  showAllTrigger,
  primaryColor,
}: CategoryListSectionProps) => {
  return (
    <Stack className={className} direction={"column"} width={"100%"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="ps-3 pe-2"
        width={"100%"}
      >
        <h6 className="store-section-title mb-0">Kategori</h6>

        <Button
          className="store-section-show-all-btn"
          sx={{ color: `${primaryColor ? `${primaryColor}` : "#FFC535"}` }}
          onClick={showAllTrigger}
        >
          Lihat Semua
        </Button>
      </Stack>
      <Grid container className="mt-1 px-2">
        {categoryList && categoryList.length > 0
          ? categoryList.length <= 8
            ? categoryList.map((data, index) => {
                return (
                  <Grid item xs={3} key={index + "category"}>
                    <CategoryListItem
                      categoryLogo={data.image_mobile}
                      categoryName={data.category_name}
                    />
                  </Grid>
                );
              })
            : categoryList.slice(0, 8).map((data, index) => {
                return (
                  <Grid item xs={3} key={index + "category"}>
                    <CategoryListItem
                      categoryLogo={data.image_mobile}
                      categoryName={data.category_name}
                    />
                  </Grid>
                );
              })
          : null}
      </Grid>
    </Stack>
  );
};

export default CategoryListSection;
