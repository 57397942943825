import { createSlice } from "@reduxjs/toolkit";
import {
  setUserGoogle,
  clearUserGoogle,
  verifyEmail,
} from "./googleSignIn.services";
import { GoogleSignInState, GoogleSignInInfoProps } from "./index";

export const initialState: GoogleSignInState = {
  data: {} as GoogleSignInInfoProps,
  status: "loading",
  error: {},
};

export const GoogleSignInSlice = createSlice({
  name: "googleSignInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setUserGoogle.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = {
            email_profile: action.payload.email_profile
              ? action.payload.email_profile
              : state.data.email_profile,
            access_token: action.payload.access_token
              ? action.payload.access_token
              : state.data.access_token,
            authuser: action.payload.authuser
              ? action.payload.authuser
              : state.data.authuser,
            expires_in: action.payload.expires_in
              ? Date.now() / 1000 + action.payload.expires_in
              : state.data.expires_in,
            hd: action.payload.hd ? action.payload.hd : state.data.hd,
            prompt: action.payload.prompt
              ? action.payload.prompt
              : state.data.prompt,
            scope: action.payload.scope
              ? action.payload.scope
              : state.data.scope,
            token_type: action.payload.token_type
              ? action.payload.token_type
              : state.data.token_type,
          };
          // state.status = action.payload.status;
          // state.error = action.payload.error;
        }
      })
      .addCase(clearUserGoogle.fulfilled, (state, action) => {
        state.data = action.payload.data;
        // state.status = "error";
        // state.error = action.error;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          email_profile: {
            email: state.data.email_profile?.email,
            email_verified: state.data.email_profile?.email_verified,
            email_verified_by_server:
              action.payload?.data?.message === "success"
                ? true
                : action.payload?.response?.data.message ===
                  "Your account was disabled"
                ? false
                : false,
            family_name: state.data.email_profile?.family_name,
            given_name: state.data.email_profile?.given_name,
            hd: state.data.email_profile?.hd,
            locale: state.data.email_profile?.locale,
            name: state.data.email_profile?.name,
            picture: state.data.email_profile?.picture,
            sub: state.data.email_profile?.sub,
          },
        };
      });
  },
});

export const GoogleSignInReducer = GoogleSignInSlice.reducer;
