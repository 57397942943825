import React from "react";
import styled from "styled-components";
import { ReactComponent as EmailIcon } from "assets/icons/ic_email.svg";

interface IInputIcon {
  iconStroke?: string;
  label?: string;
  placeholder?: string;
  validationText?: string;
  validationType?: "error";
  onChange?: any;
}

const InputIcon = ({
  iconStroke,
  label,
  placeholder,
  validationType,
  validationText,
  onChange,
}: IInputIcon) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Wrapper>
        <Icon>
          <EmailIcon
            stroke={iconStroke ?? "yellow"}
            fill={iconStroke ?? "yellow"}
          />
        </Icon>
        <Field placeholder={placeholder} onChange={onChange} />
        {validationType && validationType === "error" && (
          <Label style={{ color: "red" }}>
            {validationText ?? "This field is required"}
          </Label>
        )}
      </Wrapper>
    </>
  );
};

const Label = styled.label`
  font-family: "Open Sans";
  font-size: 12px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-family: "Open Sans";
  font-size: 12px;
`;

const Icon = styled.div`
  position: absolute;
  padding: 10px;
  width: 40px;
  text-align: center;
`;

const Field = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-sizing: border-box;

  :focus {
    border: 1px solid pink;
    outline: none;
  }

  :active {
    outline: none;
  }
`;

export default InputIcon;
