import styled, { css } from "styled-components";

interface ButtonProps {
  background?: string | undefined;
  backgroundHover?: string | undefined;
  borderRadius?: string | undefined;
  margin?: string | undefined;
  padding?: string | undefined;
  shadow?: string | undefined;
  textColor?: string | undefined;
  hoverTextColor?: string | undefined;
  variant?:
    | "primary"
    | "secondary"
    | "yellow"
    | "disabled"
    | "danger"
    | "outline";
  width?: string;
  height?: string;
}

export const Button = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  min-width: 100px;
  min-height: 40px;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  box-shadow: ${({ shadow }) => shadow};
  background: ${({ background }) => background};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ textColor }) => textColor};
  border-radius: ${({ borderRadius }) => borderRadius ?? "5px"};
  opacity: 1;
  &:active {
    opacity: 0.4;
  }
  &:hover {
    background: ${({ backgroundHover }) => backgroundHover};
    color: ${({ hoverTextColor }) => hoverTextColor};
    opacity: 0.8;
  }
  ${(props) =>
    props.variant === "primary" &&
    css`
      background: #007bff;
      position: relative;
      background-size: 400%;
      background-position: 0% 0%;
      border-radius: 5px;
      border: none;
      color: white;
      &:hover {
        background-size: 100%;
        background-position: 0% 0%;
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
    `};
  ${(props) =>
    props.variant === "yellow" &&
    css`
      background: #fcb017;
      position: relative;
      background-size: 400%;
      background-position: 0% 0%;
      border-radius: 5px;
      border: none;
      color: white;
      &:hover {
        background-size: 100%;
        background-position: 0% 0%;
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
      &:active {
        color: white;
        opacity: 0.8;
      }
    `};
  ${(props) =>
    props.variant === "outline" &&
    css`
      background: transparent;
      position: relative;
      background-size: 400%;
      background-position: 0% 0%;
      border-radius: 5px;
      border: 1px solid black;
      color: white;
      &:hover {
        background-size: 100%;
        background-position: 0% 0%;
        animation-duration: 2s;
        animation-iteration-count: 2;
      }
      &:active {
        color: white;
        opacity: 0.8;
      }
    `};
  ${(props) =>
    props.variant === "secondary" &&
    css`
      background: #7b7b7b;
      color: white;
      &:active {
        opacity: 0.7;
      }
    `};
  ${(props) =>
    props.variant === "danger" &&
    css`
      background: #e56c75;
      color: white;
      &:active {
        opacity: 0.7;
      }
    `};
  ${(props) =>
    props.variant === "disabled" &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed !important;
    `};
  &:active {
    opacity: 1;
  }
`;
