import { Grid } from "@mui/material";
import ProductListItem from "./product.list.item";

import { ProductType } from "redux/product";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreQrGroupInfoTypes } from "redux/storeInfo";

interface ProductListFullProps {
  className?: string;
  productList?: ProductType[];
  showAllTrigger?: any;
  getDetailProduct?: any;
  storeData?: StoreQrGroupInfoTypes;
}

const ProductListFull = ({
  className,
  productList,
  showAllTrigger,
  getDetailProduct,
  storeData,
}: ProductListFullProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid container className={className} spacing={4} alignItems={"center"}>
      {productList && productList.length > 0
        ? productList.map((product, index) => {
            return (
              <Grid item xs={6} key={index + "-product"}>
                <ProductListItem
                  image={product.product_detail.product_image_main}
                  name={product.product_name}
                  key={index + "-product"}
                  discount={product.product_detail.discount}
                  discount_type={product.product_detail.discount_type}
                  price={product.product_detail.product_price}
                  price_after_discount={
                    product.product_detail.product_price_after_discount
                  }
                  type="column"
                  onClick={() => {
                    product.product_detail.is_customizable
                      ? navigate(
                          `/qr-group/store/${storeData?.store_name
                            ?.toLowerCase()
                            .replace(/ /g, "")}/${product?.product_name
                            ?.toLowerCase()
                            .replace(/ /g, "-")}`,
                          {
                            state: {
                              prevUrl: location.pathname,
                              productDetail: {
                                ...product,
                                store_id: storeData?.store_id,
                              },
                            },
                          }
                        )
                      : getDetailProduct({
                          productId: product.product_id,
                          merchantId: product.merchant_id,
                          storeId: storeData?.store_id,
                        });
                  }}
                />
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export default ProductListFull;
