import styled from "styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useLocation } from "react-router-dom";
import history from "helper/history";

interface BackButtonV2Props {
  prev_url?: string;
}

const BackButtonV2 = ({ prev_url }: BackButtonV2Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const previousPage = () => {
    if (prev_url && prev_url.length > 0) {
      navigate(`${prev_url?.includes("home") ? "/home" : prev_url}`, {
        state: { previousPath: pathname },
      });
    } else {
      history.back();
    }
  };
  return (
    <ButtonStyle onClick={() => previousPage()}>
      <ArrowBackIosNewIcon sx={{ fontSize: "0.7rem", color: "#ffffff" }} />
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  background: #cccccc;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
`;

export default BackButtonV2;
