import React from "react";
import { NormalText } from "../styled/text.styled";
import useRedux from "redux/useRedux";
import { getTotalPrice } from "redux/cart/cart.reducer";
import { Stack, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "helper/general";
import { CheckoutBottomModal, CartChekoutButton, Content, CheckoutIcon } from "components/BottomSheet";
import IconCart from "assets/icons/ic_cart.svg";

interface CartBottomSheetProps {
  summaries?: boolean;
}

const CartBottomSheet = ({ summaries }: CartBottomSheetProps) => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { AppSetting, Cart },
  } = useRedux();
  
  // Logic for product name on Cart BottomSheet
  const cartList = Cart.items;
  const itemsList = cartList.map(item => {
    const productName = item.products.map(product => {
      return product.product_name
    })
    return productName
  })

  let shortOrderList = itemsList.join(', ')
  if (itemsList.length > 2) {
    let orderList = []
    for (let index = 0; index < itemsList.length; index++) {
      orderList.push(itemsList[index])
      shortOrderList = orderList.join(', ')
    }
  }

  if (shortOrderList.length > 20) {
    shortOrderList = shortOrderList.substring(0,20) + "..."
  }
  
  
  React.useEffect(() => {
    thunkDispatch(getTotalPrice())
      .unwrap()
      .then(() => null);
  }, [thunkDispatch, Cart]);
  return (
    <>
      <CheckoutBottomModal animate={{ scale: 1, y: -1 }}>
        <Content>
          {summaries ? (
            <CartChekoutButton
              background={
                AppSetting &&
                AppSetting.data &&
                AppSetting.data.data &&
                AppSetting.data.data.primary_color
                  ? AppSetting.data.data.primary_color
                  : "#FFC535"
              }
              onClick={() => navigate("/cart")}
            >
              <Avatar 
                sx={{ 
                  bgcolor: "rgba(255, 255, 255, 0.4)",
                  fontSize: "unset",
                  margin: "0 10px 0 5px",
                  width: 30,
                  height: 30,
                  boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
                }}
              >
                <CheckoutIcon
                  src={IconCart}
                  alt={"ic_cart"}
                />
              </Avatar>
              <Stack 
                direction={"row"}
                justifyContent={"space-between"}
                sx={{margin: "0 5px 0 0", width: "90%"}}
              >
                <Stack 
                  direction={"column"}
                  justifyContent={"space-between"}
                  spacing={0}
                  sx={{margin: "7px 0 8px 0", gap: "0"}}
                >             
                  <NormalText 
                    fontFamily="Open Sans"
                    fontSize="14px"
                    fontWeight="bold"
                    color="#ffffff"
                    textAlign="left"
                  >
                    {Cart.total_product} Item
                  </NormalText>
                  <NormalText 
                    fontFamily="Open Sans"
                    fontSize="10px"
                    fontWeight="400"
                    color="#ffffff"
                    textAlign="left"
                    margin="-3px 0 0 0"
                  >
                    {shortOrderList}
                  </NormalText>
                </Stack>

                <NormalText
                  fontFamily="Open Sans"
                  fontSize="12px"
                  fontWeight="bold"
                  color="#ffffff"
                  padding="15px 0px"
                >
                  {formatCurrency(Cart.total_price)},-
                </NormalText>
              </Stack>
            </CartChekoutButton>
          ) : (
            <CartChekoutButton
              background={
                AppSetting &&
                AppSetting.data &&
                AppSetting.data.data &&
                AppSetting.data.data.primary_color
                  ? AppSetting.data.data.primary_color
                  : "#FFC535"
              }
              onClick={() => navigate("/checkout")}
            >
              <NormalText
                fontFamily="Open Sans"
                fontSize="12px"
                fontWeight="bold"
                margin="0 auto"
              >
                Checkout
              </NormalText>
            </CartChekoutButton>
          )}
        </Content>
      </CheckoutBottomModal>
    </>
  );
};

export default CartBottomSheet;
