import { Stack, SwipeableDrawer, Typography, IconButton } from "@mui/material";

//component
import { IPaymentMethod } from "redux/payment";
import { CheckoutPaymentDetailProps } from "redux/checkout";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import PaymentItem from "views/Checkout/payment.item";
import { CartState } from "redux/cart/cart.reducer";

interface PaymentMethodsDrawerProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
  methods: IPaymentMethod[];
  color?: string;
  paymentDetail: CheckoutPaymentDetailProps;
  service: string;
  handlePickBank: any;
  handleChangePaymentDetail: any;
  handleServices: any;
  cart: CartState;
}

const PaymentMethodsDrawer = ({
  open,
  onClose,
  onOpen,
  methods,
  color,
  paymentDetail,
  service,
  handlePickBank,
  handleChangePaymentDetail,
  handleServices,
  cart,
}: PaymentMethodsDrawerProps) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose();
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #cccccc", padding: "0.9rem 1rem" }}
        >
          <Typography
            sx={{
              color: "#2d3436",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "800",
              fontSize: "0.9rem",
            }}
          >
            Metode Pembayaran
          </Typography>

          <IconButton
            sx={{
              color: "#000000",
              height: "25px",
              width: "25px",
            }}
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Stack>
        {methods.map((method, index) => {
          if (service === "DELIVERY") {
            if (method.payment_code === "cash") {
              return null;
            } else {
              return (
                <PaymentItem
                  name={method.payment_name}
                  channel={method.payment_code}
                  key={"payment-" + index}
                  background={color ?? ""}
                  selected={
                    method.payment_code === paymentDetail.payment_channel
                  }
                  onChange={() => {
                    if (method.payment_code === "virtual_account") {
                      handlePickBank(true);
                    }
                    let payFee: number = 0;
                    payFee = Number(method.payment_fee_price);
                    if (method.payment_fee_percentage > 0) {
                      let percent: any = method.payment_fee_percentage;
                      let subTotal = cart.total_price;
                      let result: number = subTotal * (percent / 100);
                      payFee += Math.ceil(result);
                    }

                    handleServices({
                      payment_fee: payFee,
                    });
                    handleChangePaymentDetail({
                      ...paymentDetail,
                      payment_type: method.payment_code,
                      payment_channel: method.payment_code,
                    });
                  }}
                />
              );
            }
          } else {
            return (
              <PaymentItem
                name={method.payment_name}
                channel={method.payment_code}
                key={"payment-" + index}
                background={color ?? ""}
                selected={method.payment_code === paymentDetail.payment_channel}
                onChange={() => {
                  if (method.payment_code === "virtual_account") {
                    handlePickBank(true);
                  }
                  let payFee: number = 0;
                  payFee = Number(method.payment_fee_price);
                  if (method.payment_fee_percentage > 0) {
                    let percent: any = method.payment_fee_percentage;
                    let subTotal = cart.total_price;
                    let result: number = subTotal * (percent / 100);
                    payFee += Math.ceil(result);
                  }

                  handleServices({
                    payment_fee: payFee,
                  });
                  handleChangePaymentDetail({
                    ...paymentDetail,
                    payment_type: method.payment_code,
                    payment_channel: method.payment_code,
                  });
                  onClose();
                }}
              />
            );
          }
        })}
      </Stack>
    </SwipeableDrawer>
  );
};

export default PaymentMethodsDrawer;
