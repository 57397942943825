import { createAsyncThunk } from "@reduxjs/toolkit";
import { StoreInfoState ,StoreListState} from "./types";
import axios from "axios";

interface StoreListProps {
  group_code: string;
  limit?: string | number;
  offset?: string | number;
  filter?: string;
  sort_name?: string;
  sort_store?: string;
}

export const storeInfo = createAsyncThunk(
  "store/info",
  async (code: string) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/${code}/detail`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreInfoState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreInfoState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreInfoState;
    }
  }
);

export const storeList = createAsyncThunk(
  "store/list",
  async ({
    group_code,
    limit,
    offset,
    filter,
    sort_name,
    sort_store,
  }: StoreListProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/group/${group_code}/store?limit=${limit ? limit : 0}${
          offset ? `&offset=${offset}` : ""
        }${filter ? `&filter=${filter}` : ""}${
          sort_name ? `&sort_name=${sort_name}` : ""
        }${sort_store ? `&sort_store=${sort_store}` : ""}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);
