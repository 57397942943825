import { Box, Stack, Typography } from "@mui/material";
import NoPhoto from "assets/icons/icon store.svg";

interface StoreTagProps {
  logo?: string;
  name?: string;
  location?: string;
  className?: string;
}

const StoreTagSection = ({
  logo,
  name,
  location,
  className,
}: StoreTagProps) => {
  return (
    <Stack className={className}>
      <Stack
        className="px-3 py-2"
        sx={{
          boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          width: "100%",
        }}
        direction={"row"}
        alignItems={"center"}
      >
        <img
          src={logo ? logo : NoPhoto}
          alt={"logo-" + name}
          style={{ width: "100px", height: "100px", objectFit: "contain" }}
        />
        <Box>
          <Typography
            className="ml-3"
            fontFamily={"D-DIN"}
            fontWeight={800}
            fontSize={"1.2rem"}
          >
            {name}
          </Typography>
          <Typography
            className="ml-3"
            fontFamily={"D-DIN"}
            fontWeight={400}
            fontSize={"0.8rem"}
          >
            {location}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default StoreTagSection;
