import axios from 'axios'

export interface IFeeSetting {
    id: number,
    date: string,
    tax: number,
    service: number,
    platform_fee_type: string,
    payment_fee_type: "CUSTOMER" | "OWNER" | "SPLIT",
    updated_by: string
}

interface FeeProps {
    store_id?: number;
    user_type?: string;
}

export const getFeeSetting = async (props: FeeProps) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };

    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/feesetting/${props.store_id}?user_type=${props.user_type}`, config);
    if (response && response.data && response.data.data) {
        return response.data.data as IFeeSetting;
    } else {
        return {} as IFeeSetting;
    }
}