import React from "react";
import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Stack, Box, Grid, alpha, IconButton } from "@mui/material";
import useDebounce from "hooks/useDebounce";

// @ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Import Components
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import ProductItem from "../../components/Cards/ProductItem";
import ProductSearch from "../../components/Cards/ProductSearch";
import ProductsCategory from "components/Cards/ProductCategory";
import CartBottomSheet from "components/CartBottomSheet";
import CategoryFillter from "components/Modal/category-filter";
import InputSearch from "components/Forms/InputSearch";
import HeaderBigV1 from "components/Header/HeaderBigV1";
import HeaderSmallV1 from "components/Header/HeaderSmallV1";
import ProductDrawer from "components/Drawer/product.drawer";
import HomePageSkeleton from "components/Skeleton/HomePageSkeleton";
// import ProductListItem from "components/List/ProductListItem";

// Import Redux
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";
import { listAllBrand, BrandsType } from "redux/brand";
import { listAllBanner, BannerType } from "redux/banner";
import {
  ProductType,
  listSearchProductResult,
  listSearchNewProductResult,
} from "redux/product";
import { getCategoryList, CategoryInterface } from "redux/category";
import { detailProduct, ProductsDetail } from "redux/product";
import { addToCart, ProductsTypes } from "redux/cart/cart.reducer";

// Import Assets
import ImageAyam from "../../assets/images/ayam.png";
import ImageBurger from "../../assets/images/burger.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { urlExists } from "helper/general";

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const { store_key, store_id, merchant_id, product_id } = useParams();
  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting, Cart },
  } = useRedux();
  const [brands, setBrands] = React.useState<BrandsType[]>();
  const [banners, setBanners] = React.useState<BannerType[]>();
  // eslint-disable-next-line
  const [data, setData] = React.useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newProducts, setNewProducts] = React.useState<ProductType[]>();

  // State for Category
  const [categories, setCategories] = React.useState<CategoryInterface[]>();
  const [search, setSearch] = React.useState("");
  const searchText = useDebounce({ value: search, delay: 500 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [subsCategories, setSubsCategories] = React.useState<any[]>([]);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [product, setProduct] = React.useState<ProductsDetail>({
    product_id: 0,
    product_name: "",
    category: {
      id: 0,
      category_name: "",
    },
    sub_category: {
      id: 0,
      sub_category_name: "",
    },
    subs_category: {
      id: 0,
      subs_category_name: "",
    },
    brand: {
      id: 0,
      brand_name: "",
    },
    status: false,
    merchant_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: false,
      product_price: "",
      discount_type: "",
      discount: 0,
      product_price_after_discount: "",
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
      long_description_html: "",
    },
  });

  const slideProperties = {
    indicators: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    duration: 4000,
    canSwipe: true,
  };

  const handleProductDrawer = (open: boolean) => {
    setOpenProduct(open);
  };

  const productToCart = (props: ProductsTypes) => {
    thunkDispatch(addToCart(props)).then(() => {
      handleProductDrawer(false);
    });
  };

  const fetchProduct = () => {
    if (!openNewProductList) {
      thunkDispatch(
        listSearchProductResult({
          keyword: searchText.toLowerCase(),
          merchant_id: StoreInfo?.data?.data?.merchant_id,
          store_id: StoreInfo?.data?.data?.id,
        })
      )
        .unwrap()
        .then((res: any) => {
          setProducts(res.data.data);
        })
        .catch((error) => console.log(error));
    } else {
      thunkDispatch(
        listSearchNewProductResult({
          keyword: searchText.toLowerCase(),
          merchant_id: StoreInfo?.data?.data?.merchant_id,
          store_id: StoreInfo?.data?.data?.id,
        })
      )
        .unwrap()
        .then((res: any) => {
          setNewProducts(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  };

  const fetchDetailProduct = ({ productId, merchantId, storeId }: any) => {
    let data = {
      product_id: productId,
      merchant_id: merchantId,
      store_id: storeId,
    };
    thunkDispatch(detailProduct(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setLoading(false);
          let detailProduct: ProductsDetail = res.data.data;

          let tempDivElement: any = document.createElement("div");
          detailProduct.product_description.long_description_html =
            detailProduct.product_description.long_description;

          tempDivElement.innerHTML =
            detailProduct?.product_description?.long_description;

          detailProduct.product_description.long_description =
            tempDivElement?.innerText;

          setProduct(detailProduct);

          if (res.data.data?.product_detail?.is_customizable) {
            navigate(`/product/${product_id}/detail`, {
              state: { previousPath: pathName },
            });
          } else {
            handleProductDrawer(true);
          }
        }
      });
  };

  // State and Even Handler for Category Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  // State and Event Handler for Lihat Semua Produk Terbaru
  const [openNewProductList, setOpenNewProductList] = React.useState(false);
  const getNewProductslist = () => {
    thunkDispatch(
      listSearchNewProductResult({
        keyword: null,
        merchant_id: StoreInfo?.data?.data?.merchant_id,
        store_id: StoreInfo?.data?.data?.id,
        limit: openNewProductList ? 16 : 31,
      })
    )
      .unwrap()
      .then((res: any) => {
        setNewProducts(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const handleToggleNewProductList = () => {
    if (search && search.length > 0) {
      setSearch("");
    }

    setOpenNewProductList((prevState) => !prevState);
    getNewProductslist();
  };

  React.useState(() => {
    //console.log(history?.location?.state);
    if (store_key && store_key.length === 8 && product_id) {
      setLoading(true);
      const brand = new Promise((resolve, reject) => {
        thunkDispatch(
          listAllBrand({
            merchant: Number(merchant_id),
            offset: 0,
            store_id: Number(store_id),
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setBrands(res.data.data);
              // resolve({ data_name: "brands", data: res?.data.data });
              resolve("success");
            }
          })
          .catch((error) => console.log(error));
      });

      const banner = new Promise((resolve, reject) => {
        thunkDispatch(listAllBanner(Number(merchant_id)))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setBanners(res.data.data);
              // resolve({ data_name: "banner", data: res?.data.data });
              resolve("success");
            }
          })
          .catch((error) => console.log(error));
      });

      const newProductlist = new Promise((resolve, reject) => {
        thunkDispatch(
          listSearchNewProductResult({
            keyword: null,
            merchant_id: merchant_id,
            store_id: store_id,
            limit: 16,
          })
        )
          .unwrap()
          .then((res: any) => {
            setNewProducts(res.data.data);
            // resolve({ data_name: "new product list", data: res?.data.data });
            resolve("success");
            //setNewProducts([]);
          })
          .catch((error) => console.log(error));
      });

      const categories = new Promise((resolve, reject) => {
        thunkDispatch(
          getCategoryList({
            merchant: Number(merchant_id),
            store_id: Number(store_id),
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setCategories(res?.data?.data ?? []);

              let categoryLevel3: any = [];
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              let getCategoryLevel3 = res.data.data
                .filter((item) => item.is_active === true)
                .map((subCat) => {
                  return subCat.subCategory
                    ?.filter((subActive) => subActive.is_active === true)
                    .map((subsCat) => {
                      return subsCat.subsCategory
                        ?.filter((subsActive) => subsActive.is_active === true)
                        .map((subsScat) => {
                          return categoryLevel3.push(subsScat);
                        });
                    });
                });

              setSubsCategories(categoryLevel3);
              resolve("success");
            }
          });
      });

      Promise.all([brand, banner, newProductlist, categories]).then((res) => {
        //console.log(res);
        if (!location.state) {
          fetchDetailProduct({
            productId: product_id,
            merchantId: merchant_id,
            storeId: store_id,
          });
        }
        setLoading(false);
      });
    } else {
      if (
        StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.merchant_id &&
        StoreInfo.data.data.id
      ) {
        setLoading(true);
        const brand = new Promise((resolve, reject) => {
          thunkDispatch(
            listAllBrand({
              merchant: StoreInfo?.data?.data?.merchant_id,
              offset: 0,
              store_id: StoreInfo?.data?.data?.id,
            })
          )
            .unwrap()
            .then((res) => {
              if (res && res.status === "success") {
                setBrands(res.data.data);
                // resolve({ data_name: "brands", data: res?.data.data });
                resolve("success");
              }
            })
            .catch((error) => console.log(error));
        });

        const banner = new Promise((resolve, reject) => {
          thunkDispatch(listAllBanner(StoreInfo?.data?.data?.merchant_id))
            .unwrap()
            .then((res) => {
              if (res && res.status === "success") {
                setBanners(res.data.data);
                // resolve({ data_name: "banner", data: res?.data.data });
                resolve("success");
              }
            })
            .catch((error) => console.log(error));
        });

        const newProductlist = new Promise((resolve, reject) => {
          thunkDispatch(
            listSearchNewProductResult({
              keyword: null,
              merchant_id: StoreInfo?.data?.data?.merchant_id,
              store_id: StoreInfo?.data?.data?.id,
              limit: 16,
            })
          )
            .unwrap()
            .then((res: any) => {
              setNewProducts(res.data.data);
              // resolve({ data_name: "new product list", data: res?.data.data });
              resolve("success");
              //setNewProducts([]);
            })
            .catch((error) => console.log(error));
        });

        const categories = new Promise((resolve, reject) => {
          thunkDispatch(
            getCategoryList({
              merchant: Number(StoreInfo?.data?.data?.merchant_id),
              store_id: Number(StoreInfo?.data?.data?.id),
            })
          )
            .unwrap()
            .then((res) => {
              if (res && res.status === "success") {
                setCategories(res?.data?.data ?? []);

                let categoryLevel3: any = [];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let getCategoryLevel3 = res.data.data
                  .filter((item) => item.is_active === true)
                  .map((subCat) => {
                    return subCat.subCategory
                      ?.filter((subActive) => subActive.is_active === true)
                      .map((subsCat) => {
                        return subsCat.subsCategory
                          ?.filter(
                            (subsActive) => subsActive.is_active === true
                          )
                          .map((subsScat) => {
                            return categoryLevel3.push(subsScat);
                          });
                      });
                  });

                setSubsCategories(categoryLevel3);
                resolve("success");
              }
            });
        });

        Promise.all([brand, banner, newProductlist, categories]).then((res) => {
          if (res) {
            setTimeout(() => {
              setLoading(false);
            }, 600);
          }
        });
      }
    }
  });

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.id &&
      StoreInfo.data.data.merchant_id
    ) {
      fetchProduct();
    }
  }, [searchText]); // eslint-disable-line

  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={data?.favicon ?? ""}
      title={data?.merchant_name ?? ""}
    >
      {loading ? (
        <HomePageSkeleton />
      ) : (
        <>
          {/* Header */}

          {openNewProductList ? (
            <HeaderSmallV1
              storeName={StoreInfo?.data?.data?.store_name}
              storeLogo={StoreInfo?.data?.data?.store_logo}
              appLogo={AppSetting?.data?.data?.app_logo}
            />
          ) : (
            <HeaderBigV1
              storeName={StoreInfo?.data?.data?.store_name}
              storeLogo={StoreInfo?.data?.data?.store_logo}
              appLogo={AppSetting?.data?.data?.app_logo}
              color={AppSetting?.data?.data?.primary_color}
            />
          )}

          {/* Search Bar */}
          <Box
            sx={{
              background: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            width={"100%"}
            padding={"0 10px 20.5px"}
            margin={"0 0 0 0"}
          >
            {openNewProductList ? (
              <IconButton onClick={handleToggleNewProductList}>
                <ArrowBackIcon />
              </IconButton>
            ) : null}

            <Box width={openNewProductList ? "85%" : "100%"}>
              <InputSearch
                placeholder="Cari produk pilihanmu"
                onChange={(e: any) => setSearch(e.target.value)}
                background={AppSetting.data.data.primary_color}
                value={search}
              />
            </Box>
          </Box>

          {/* Page when search bar is activate #1 */}
          {!openNewProductList && search && search.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "white",
                height: "100vh",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {search && search.length && products && products.length > 0 ? (
                <Grid
                  container
                  sx={{
                    paddingBottom: `${
                      Cart && Cart.items.length > 0 ? "50px" : "0"
                    }`,
                    width: "100%",
                  }}
                >
                  {search &&
                    search.length &&
                    products &&
                    products.length > 0 &&
                    products.map((item: any, index: any) => (
                      <Grid item xs={6} key={item.product_id}>
                        <ProductSearch
                          onClick={() => {
                            item?.product_detail?.is_customizable
                              ? navigate(`/product/${item.product_id}/detail`, {
                                  state: { previousPath: pathName },
                                })
                              : fetchDetailProduct({
                                  productId: item.product_id,
                                  merchantId: StoreInfo.data.data.merchant_id,
                                  storeId: StoreInfo.data.data.id,
                                });
                          }}
                          image={
                            item &&
                            item.product_detail &&
                            item.product_detail.product_image_main
                              ? urlExists(
                                  item.product_detail.product_image_main
                                )
                                ? item.product_detail.product_image_main
                                : ""
                              : ""
                          }
                          name={item.product_name}
                          price={item?.product_detail?.product_price}
                          brand={item.brand?.brand_name ?? ""}
                          key={index + "-recommend"}
                          discount={item?.product_detail?.discount}
                          discount_type={item?.product_detail?.discount_type}
                          price_after_discount={
                            item?.product_detail?.product_price_after_discount
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : null}

              {search && search.length && products && products.length === 0 && (
                <Box
                  sx={{
                    wordBreak: "break-word",
                    height: "calc(100vh - 280px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack>
                    <NormalText
                      textAlign="center"
                      fontWeight="bold"
                      fontSize={"16px"}
                    >
                      Oops!
                    </NormalText>
                    <NormalText textAlign="center" fontSize="12px">
                      Tidak ada produk ditemukan{" "}
                      {searchText ? `dengan kata kunci "${searchText}"` : ""}
                    </NormalText>
                  </Stack>
                </Box>
              )}
            </Box>
          ) : null}

          {/* Page when search bar is activate #2 */}
          {openNewProductList ? (
            <Box
              sx={{
                backgroundColor: "white",
                height: "100vh",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {(search &&
                search.length &&
                newProducts &&
                newProducts.length > 0) ||
              (newProducts && newProducts.length > 0) ? (
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="baseline"
                  sx={{
                    paddingBottom: `${
                      Cart && Cart.items.length > 0 ? "50px" : "0"
                    }`,
                    //width: { xs: "368px", sm: "368px", md: "368px", lg: "100%" },
                    width: "100%",
                  }}
                >
                  {newProducts &&
                  newProducts.length > 0 &&
                  newProducts.filter((product) => product.productStore !== null)
                    .length > 0 ? (
                    <>
                      {newProducts
                        .filter((product) => product.productStore !== null)
                        .map((item, index) => (
                          <Grid item xs={6} key={item.product_id}>
                            <ProductSearch
                              onClick={() => {
                                item?.product_detail?.is_customizable
                                  ? navigate(
                                      `/product/${item.product_id}/detail`,
                                      { state: { previousPath: pathName } }
                                    )
                                  : fetchDetailProduct({
                                      productId: item.product_id,
                                      merchantId:
                                        StoreInfo.data.data.merchant_id,
                                      storeId: StoreInfo.data.data.id,
                                    });
                              }}
                              image={
                                item &&
                                item.product_detail &&
                                item.product_detail.product_image_main
                                  ? urlExists(
                                      item.product_detail.product_image_main
                                    )
                                    ? item.product_detail.product_image_main
                                    : ""
                                  : ""
                              }
                              name={item.product_name}
                              price={item?.product_detail?.product_price}
                              brand={item.brand?.brand_name ?? ""}
                              key={index + "-new"}
                              discount={item?.product_detail?.discount}
                              discount_type={
                                item?.product_detail?.discount_type
                              }
                              price_after_discount={
                                item?.product_detail
                                  ?.product_price_after_discount
                              }
                            />
                          </Grid>
                        ))}
                    </>
                  ) : null}

                  {newProducts &&
                  newProducts.length > 0 &&
                  newProducts.filter((product) => product.productStore === null)
                    .length > 0 ? (
                    <>
                      {newProducts
                        .filter((product) => product.productStore === null)
                        .map((item, index) => (
                          <Grid item xs={6} key={item.product_id}>
                            <ProductSearch
                              onClick={() => {
                                item?.product_detail?.is_customizable
                                  ? navigate(
                                      `/product/${item.product_id}/detail`,
                                      { state: { previousPath: pathName } }
                                    )
                                  : fetchDetailProduct({
                                      productId: item.product_id,
                                      merchantId:
                                        StoreInfo.data.data.merchant_id,
                                      storeId: StoreInfo.data.data.id,
                                    });
                              }}
                              image={
                                item &&
                                item.product_detail &&
                                item.product_detail.product_image_main
                                  ? urlExists(
                                      item.product_detail.product_image_main
                                    )
                                    ? item.product_detail.product_image_main
                                    : ""
                                  : ""
                              }
                              name={item.product_name}
                              price={item?.product_detail?.product_price}
                              brand={item.brand?.brand_name ?? ""}
                              key={index + "-new"}
                              discount={item?.product_detail?.discount}
                              discount_type={
                                item?.product_detail?.discount_type
                              }
                              price_after_discount={
                                item?.product_detail
                                  ?.product_price_after_discount
                              }
                            />
                          </Grid>
                        ))}
                    </>
                  ) : null}
                </Grid>
              ) : null}

              {search &&
                search.length &&
                newProducts &&
                newProducts.length === 0 && (
                  <Box
                    sx={{
                      wordBreak: "break-word",
                      height: "calc(100vh - 280px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stack>
                      <NormalText
                        textAlign="center"
                        fontWeight="bold"
                        fontSize={"16px"}
                      >
                        Oops!
                      </NormalText>
                      <NormalText textAlign="center" fontSize="12px">
                        Tidak ada produk ditemukan{" "}
                        {searchText ? `dengan kata kunci "${searchText}"` : ""}
                      </NormalText>
                    </Stack>
                  </Box>
                )}
            </Box>
          ) : null}

          {/* Modal for Category Filter */}
          <CategoryFillter
            handleClose={() => setOpen(false)}
            open={open}
            background={alpha(AppSetting.data.data.primary_color, 0.4)}
            brands={brands}
            storeInfo={StoreInfo}
            categories={categories}
          />

          {/* Banner Slider */}
          {!openNewProductList && search.length === 0 && (
            <Box sx={{ marginBottom: "-20px" }}>
              {banners && banners.length > 0 && (
                <Slide {...slideProperties} style={{ background: "white" }}>
                  {banners.map((banner, index) => (
                    <div
                      key={index + "-banner"}
                      style={{
                        height: "120px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        key={index}
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        src={banner.banner_image_web}
                        alt={banner.banner_name}
                      />
                    </div>
                  ))}
                </Slide>
              )}
            </Box>
          )}

          {/* List Kategori */}
          {!openNewProductList && search.length === 0 ? (
            <>
              {categories &&
              categories.filter((category) => category.is_active === true)
                .length > 0 ? (
                <Box
                  sx={{
                    background: "white",
                    padding: "20px 10px 0 10px",
                    width: "100%",
                  }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <NormalText
                      fontSize="12px"
                      fontWeight="bold"
                      fontFamily="D-DIN Exp"
                    >
                      Kategori
                    </NormalText>
                    <NormalText
                      fontSize="12px"
                      fontWeight="700"
                      fontFamily="D-DIN Exp"
                      color={`${data.primary_color}`}
                      onClick={handleOpen}
                      isPressable
                    >
                      Lihat Semua
                    </NormalText>
                  </Stack>

                  <Grid
                    container
                    sx={{
                      margin: "10px 0 0 0",
                      width: "100%",
                    }}
                    rowSpacing={1}
                  >
                    {categories &&
                    categories.length &&
                    StoreInfo?.data?.data?.merchant_type === "RETAIL"
                      ? categories
                          ?.filter((category) => category.is_active === true)
                          .filter((category) => category.total_product > 0)
                          .slice(0, 8)
                          .map((item, index) => (
                            // Category Level 1
                            <Grid
                              item
                              xs={3}
                              key={index}
                              onClick={() =>
                                navigate(`/category/1/${item.id}/detail`, {
                                  state: {
                                    prev_url: location.pathname,
                                  },
                                })
                              }
                            >
                              <ProductsCategory
                                key={index}
                                name={item.category_name}
                                image={
                                  item.image_mobile &&
                                  !item.image_mobile.includes("null") &&
                                  item.image_mobile.length > 0
                                    ? item.image_mobile
                                    : null
                                }
                              />
                            </Grid>
                          ))
                      : null}

                    {subsCategories &&
                    subsCategories.length > 0 &&
                    StoreInfo?.data?.data?.merchant_type === "FOOD"
                      ? subsCategories
                          .filter(
                            (subsCategory) => subsCategory.total_product > 0
                          )
                          .slice(0, 8)
                          .map((item, index) => (
                            <Grid
                              item
                              xs={3}
                              sx={{ padding: "8px" }}
                              onClick={() =>
                                navigate(`/category/3/${item.id}/detail`, {
                                  state: {
                                    prev_url: location.pathname,
                                  },
                                })
                              }
                            >
                              <ProductsCategory
                                key={index}
                                name={item.subscategory_name}
                                image={
                                  item.image_mobile &&
                                  !item.image_mobile.includes("null") &&
                                  item.image_mobile.length > 0
                                    ? item.image_mobile
                                    : null
                                }
                              />
                            </Grid>
                            // <h1>{item.subscategory_name}</h1>
                          ))
                      : null}
                  </Grid>
                </Box>
              ) : null}
            </>
          ) : null}

          {/* Produk Terbaru */}
          {!openNewProductList && search.length === 0 ? (
            <>
              {newProducts && newProducts.length > 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    background: "white",
                    padding: "10px 10px 0 10px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    margin={"0 0 15px 0"}
                  >
                    <NormalText
                      fontSize="12px"
                      fontWeight="bold"
                      fontFamily="D-DIN Exp"
                    >
                      Produk Terbaru
                    </NormalText>
                    {/* */}

                    <NormalText
                      fontSize="12px"
                      fontWeight="700"
                      fontFamily="D-DIN Exp"
                      color={`${data.primary_color}`}
                      onClick={handleToggleNewProductList}
                      isPressable
                    >
                      Lihat Semua
                    </NormalText>
                  </Stack>
                  <ScrollBox>
                    <Stack direction="row" spacing={2}>
                      {newProducts &&
                      newProducts.length > 0 &&
                      newProducts.filter(
                        (product) => product.productStore !== null
                      ).length > 0 ? (
                        <>
                          {newProducts
                            .filter((product) => product.productStore !== null)
                            .map((item, index) => (
                              <ProductItem
                                onClick={() => {
                                  item?.product_detail?.is_customizable
                                    ? navigate(
                                        `/product/${item.product_id}/detail`,
                                        { state: { previousPath: pathName } }
                                      )
                                    : fetchDetailProduct({
                                        productId: item.product_id,
                                        merchantId:
                                          StoreInfo.data.data.merchant_id,
                                        storeId: StoreInfo.data.data.id,
                                      });
                                }}
                                image={
                                  item?.product_detail?.product_image_main ?? ""
                                }
                                name={item.product_name}
                                price={item?.product_detail?.product_price}
                                brand={item.brand?.brand_name ?? ""}
                                key={index + "-new"}
                                discount={item?.product_detail?.discount}
                                discount_type={
                                  item?.product_detail?.discount_type
                                }
                                price_after_discount={
                                  item?.product_detail
                                    ?.product_price_after_discount
                                }
                              />
                            ))}
                        </>
                      ) : null}

                      {newProducts &&
                      newProducts.length > 0 &&
                      newProducts.filter(
                        (product) => product.productStore === null
                      ).length > 0 ? (
                        <>
                          {newProducts
                            .filter((product) => product.productStore === null)
                            .map((item, index) => (
                              <ProductItem
                                onClick={() => {
                                  item?.product_detail?.is_customizable
                                    ? navigate(
                                        `/product/${item.product_id}/detail`,
                                        { state: { previousPath: pathName } }
                                      )
                                    : fetchDetailProduct({
                                        productId: item.product_id,
                                        merchantId:
                                          StoreInfo.data.data.merchant_id,
                                        storeId: StoreInfo.data.data.id,
                                      });
                                }}
                                image={
                                  item?.product_detail?.product_image_main ?? ""
                                }
                                name={item.product_name}
                                price={item?.product_detail?.product_price}
                                brand={item.brand?.brand_name ?? ""}
                                key={index + "-new"}
                                discount={item?.product_detail?.discount}
                                discount_type={
                                  item?.product_detail?.discount_type
                                }
                                price_after_discount={
                                  item?.product_detail
                                    ?.product_price_after_discount
                                }
                              />
                            ))}
                        </>
                      ) : null}
                    </Stack>
                  </ScrollBox>
                </Box>
              ) : null}
            </>
          ) : null}

          {/* Drawer */}
          <ProductDrawer
            open={openProduct}
            onClose={handleProductDrawer}
            onOpen={handleProductDrawer}
            product={product}
            color={AppSetting?.data?.data?.primary_color}
            productToCart={productToCart}
          />

          {/* Spacer */}
          <Box height={"70px"}></Box>
          {Cart && Cart.items.length > 0 && <CartBottomSheet summaries />}
        </>
      )}
    </MainLayout>
  );
};

const ScrollBox = styled(Box)`
  width: 100%;
  overflow-x: auto;
  padding: 0 rem;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const products = [
  {
    id: 1,
    image: ImageBurger,
    name: "Supreme Burger",
    brand: "Got Beef",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam dolorem adipisci nulla vitae rerum id libero voluptate eius fugit!",
    price: "Rp. 975.000",
    is_customizable: true,
  },
  {
    id: 2,
    image: ImageAyam,
    name: "Geprek Sultan",
    brand: "Ayam Berbakar",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam dolorem adipisci nulla vitae rerum id libero voluptate eius fugit!",
    price: "Rp. 25.000",
    is_customizable: false,
  },
  {
    id: 3,
    image: ImageAyam,
    name: "ATL Maknyos",
    brand: "Ayam Pangeran",
    price: "Rp. 35.000",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam dolorem adipisci nulla vitae rerum id libero voluptate eius fugit!",
    is_customizable: true,
  },
  {
    id: 4,
    image: ImageBurger,
    name: "Cheese Burger Jumbo",
    brand: "Got Beef",
    price: "Rp. 55.000",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam dolorem adipisci nulla vitae rerum id libero voluptate eius fugit!",
    is_customizable: true,
  },
  {
    id: 5,
    image: ImageBurger,
    name: "Smashed Burger",
    brand: "Got Beef",
    price: "Rp. 75.000",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur laboriosam dolorem adipisci nulla vitae rerum id libero voluptate eius fugit!",
    is_customizable: false,
  },
];

export default LandingPage;
