import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterAddressProps } from "./types";

export const registerSetData = createAsyncThunk(
  "registration-address/data",
  async (props: RegisterAddressProps) => {
    return {
      ...props,
    } as RegisterAddressProps;
  }
);

export const registerClearData = createAsyncThunk(
  "registration-address/clear-data",
  async () => {
    return {
      data:{},
      error:"",
      status:""
    }
  }
);