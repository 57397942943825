import React, { useState, useEffect } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import BrandCover from "../../assets/images/brandcover.png";
import styled from "styled-components";
import { Box, Avatar, Button, alpha } from "@mui/material";
import ProductItem from "./product.item";
import { useNavigate, useParams, useLocation } from "react-router-dom";
//import BackButton from "components/Button/back.button";
import { getDetailBrand, BrandsDetailType, CategoryProps } from "redux/brand";
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";
import CartBottomSheet from "components/CartBottomSheet";
import {
  addToCart,
  changeQuantity,
  ProductsTypes,
  ChangeQuantityProps,
} from "redux/cart/cart.reducer";
import ConfirmationModal from "components/Modal/confirmation";
import ProductDrawer from "components/Drawer/product.drawer";
import { detailProduct, ProductsDetail } from "redux/product";
import history from "helper/history";
import BackIcon from "assets/icons/ic_back-button.svg";
import CategoryFillter from "components/Modal/category-filter";
import IcFilter from "assets/icons/ic_filter.svg";
import { listAllBrand, BrandsType } from "redux/brand";

interface locationStateProps {
  prev_url: string;
}

const BrandDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { prev_url } = location.state as locationStateProps;
  const { brandId } = useParams();
  const [confirm, setConfirm] = React.useState(false);
  const [reducePayload, setReducePayload] =
    React.useState<ChangeQuantityProps>();
  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting, Cart },
  } = useRedux();
  const [brands, setBrands] = useState<BrandsDetailType>();
  const [brandList, setBrandList] = React.useState<BrandsType[]>();
  const [openFilter, setOpenFilter] = React.useState(false);
  // eslint-disable-next-line
  const [data, setData] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );
  const [nav, setNav] = useState<Array<any>>();

  const [product, setProduct] = React.useState<ProductsDetail>({
    product_id: 0,
    product_name: "",
    category: {
      id: 0,
      category_name: "",
    },
    sub_category: {
      id: 0,
      sub_category_name: "",
    },
    subs_category: {
      id: 0,
      subs_category_name: "",
    },
    brand: {
      id: 0,
      brand_name: "",
    },
    status: false,
    merchant_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: false,
      product_price: "",
      discount_type: "",
      discount: 0,
      product_price_after_discount: "",
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
      long_description_html: "",
    },
  });
  const [openProduct, setOpenProduct] = React.useState(false);
  const handleProductDrawer = (open: boolean) => {
    setOpenProduct(open);
  };

  const fetchDetailProduct = (productId: any) => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id &&
      StoreInfo.data.data.id
    ) {
      let data = {
        product_id: productId,
        merchant_id: StoreInfo.data.data.merchant_id,
        store_id: StoreInfo.data.data.id,
      };
      thunkDispatch(detailProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            let detailProduct: ProductsDetail = res.data.data;

            let tempDivElement: any = document.createElement("div");
            detailProduct.product_description.long_description_html =
              detailProduct.product_description.long_description;

            tempDivElement.innerHTML =
              detailProduct?.product_description?.long_description;

            detailProduct.product_description.long_description =
              tempDivElement?.innerText;

            setProduct(detailProduct);

            handleProductDrawer(true);
          }
        });
    }
  };

  const fetchBrand = () => {
    thunkDispatch(
      listAllBrand({
        merchant: StoreInfo.data.data.merchant_id,
        offset: 0,
        store_id: StoreInfo.data.data.id,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setBrandList(res.data.data);
        }
      });
  };

  const convertNav = (data: any) => {
    let temp: Array<any> = [];
    temp.push("All");
    let res = data.forEach((item: CategoryProps) => {
      if (item.products && item.products.length > 0) {
        temp.push(item.subscategory_name);
      }
    });

    setNav(temp);
    return res;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scroll = (query: string) => {
    let el = document.querySelector(`#${query}`);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const productToCart = (props: ProductsTypes) => {
    thunkDispatch(addToCart(props)).then(() => handleProductDrawer(false));
  };

  const reduceQuantity = (props: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(props))
      .unwrap()
      .then(() => null);
  };

  const productExistOnCart = (brand_id: number, product_id: number) => {
    let brand = Cart.items.find((b) => b.brand_id === brand_id);
    if (brand) {
      let product = brand.products.find((p) => p.product_id === product_id);

      if (product) {
        return {
          qty: product.qty,
          cart_id: product.cart_id,
        };
      }
    }
    return {
      qty: 0,
      cart_id: "",
    };
  };

  const [active, setActive] = React.useState(0);

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);
  const handleClickNav = (index: any) => {
    setActive(index);
  };

  useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ) {
      thunkDispatch(
        getDetailBrand({
          merchant_id: StoreInfo.data.data.merchant_id,
          store_id: StoreInfo.data.data.id,
          id: brandId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setBrands(res.data.data);
            if (
              res.data.data &&
              res.data.data.category &&
              res.data.data.category.length !== 0
            ) {
              convertNav(res.data.data.category);
            }
          }
        });

      fetchBrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActive(0);
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ) {
      thunkDispatch(
        getDetailBrand({
          merchant_id: StoreInfo.data.data.merchant_id,
          store_id: StoreInfo.data.data.id,
          id: brandId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setBrands(res.data.data);

            if (
              res.data.data &&
              res.data.data.category &&
              res.data.data.category.length !== 0
            ) {
              convertNav(res.data.data.category);
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  return (
    <MainLayout
      background={"#ffffff"}
      favIcon={data?.favicon ?? ""}
      title={data?.merchant_name ?? ""}
    >
      <ConfirmationModal
        handleClose={() => setConfirm(false)}
        handleConfirm={() => {
          if (reducePayload) {
            reduceQuantity(reducePayload);
            setConfirm(false);
          }
        }}
        backgroundButton={data?.primary_color ?? "#B92362"}
        description={"Apakah anda yakin ingin menghapus item ini?"}
        open={confirm}
        hasCancel={true}
      />

      {/* Modal for Category Filter */}
      <CategoryFillter
        handleClose={handleCloseFilter}
        open={openFilter}
        background={alpha(AppSetting.data.data.primary_color, 0.4)}
        brands={brandList}
        storeInfo={StoreInfo}
        categories_brand={nav}
        page="brand"
        onClick={handleClickNav}
      />

      <Box
        sx={{
          top: 0,
          zIndex: "2 !important;",
          background: "#F2F2F2",
        }}
      >
        {/* Header */}
        <HeaderContainer url={brands?.image_mobile ?? BrandCover}>
          <Avatar
            sx={{
              bgcolor: "white",
              fontSize: "unset",
              width: "25px",
              height: "25px",
              boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.12)",
            }}
            onClick={() => {
              if (prev_url.includes("/home/")) {
                navigate("/home");
              } else {
                history.back();
              }
            }}
          >
            <BackButtonIcon src={BackIcon} alt={"back_icon"} />
          </Avatar>
          <Box sx={{ marginTop: "16.17px" }}>
            <NormalText
              fontFamily="D-DIN Exp"
              fontWeight="700"
              fontSize="25px"
              lineHeight="1"
              color="white"
            >
              {brands?.brand_name ?? ""}
            </NormalText>
            <NormalText
              fontFamily="D-DIN Exp"
              fontStyle="normal"
              fontWeight="700"
              fontSize="12px"
              lineHeight="27px"
              color="white"
              margin="0 0 0 0"
            >
              {brands?.brand_type ?? ""}
            </NormalText>
          </Box>
        </HeaderContainer>

        {/* Navigation Filter Button */}
        <Navigation>
          <Button
            variant="contained"
            endIcon={<FilterIcon src={IcFilter} />}
            sx={{
              background: `${alpha(data?.primary_color, 0.4) ?? "#fff4f8"}`,
              width: "80px",
              height: "30px",
              borderRadius: "50px",
              padding: "9px 17px",
              margin: "10px 0px 12.5px 10px",
              fontFamily: "D-DIN Exp, sans-serif",
              fontSize: "12px",
              fontWeight: "400",
              color: "black",
              textTransform: "capitalize",
              outline: "unset!important",
              "&:hover": {
                background: `${alpha(data?.primary_color, 0.6) ?? "#fff4f8"}`,
              },
            }}
            onClick={handleOpenFilter}
          >
            Filter
          </Button>
        </Navigation>
      </Box>

      <Box
        sx={{
          height: "calc(100vh - 190px)",
          overflowY: "auto",
          paddingBottom: `${Cart && Cart.items.length > 0 ? "50px" : "0"}`,
        }}
      >
        {brands &&
        brands.category &&
        brands.category.length > 0 &&
        brands.category.filter((item) => item.products !== null).length > 0 ? (
          <>
            {active === 0 ? (
              <>
                {brands.category
                  .filter((item) => item.products !== null)
                  .map((item, index) => (
                    <Box
                      key={`promolabel-${index}`}
                      id={item.subscategory_name.replace(/\s/g, "")}
                      sx={{
                        marginBottom:
                          index === brands.category.length - 1 ? "120px" : "",
                      }}
                    >
                      <CategoryLabel
                        background={data?.primary_color ?? "#B92362"}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            background: "rgba(255,255,255,0.8)",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <NormalText
                            fontSize={"12px"}
                            color={data?.primary_color ?? "#B92362"}
                            fontFamily="D-DIN Exp"
                            fontWeight="bold"
                            margin="0 0 0 24px"
                          >
                            {item.subscategory_name}
                          </NormalText>
                        </Box>
                      </CategoryLabel>
                      <Box sx={{ background: "white", width: "100%" }}>
                        {item.products &&
                          item.products.map((item, i) => (
                            <ProductItem
                              isOnCart={
                                productExistOnCart(brands.id, item.product_id)
                                  ?.cart_id !== ""
                              }
                              counterProps={{
                                value:
                                  productExistOnCart(brands.id, item.product_id)
                                    ?.qty ?? 0,
                                background: data?.primary_color ?? "#B92362",
                                onDecrease: () => {
                                  let quantity = productExistOnCart(
                                    brands.id,
                                    item.product_id
                                  );

                                  if (quantity && quantity.qty === 1) {
                                    setReducePayload({
                                      brand_id: brands.id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          brands.id,
                                          item.product_id
                                        )?.cart_id ?? "",
                                    });

                                    setConfirm(true);
                                  } else {
                                    reduceQuantity({
                                      brand_id: brands.id,
                                      type: "decrease",
                                      cart_id:
                                        productExistOnCart(
                                          brands.id,
                                          item.product_id
                                        )?.cart_id ?? "",
                                    });
                                  }
                                },
                                onIncrease: () => {
                                  reduceQuantity({
                                    brand_id: brands.id,
                                    type: "increase",
                                    cart_id:
                                      productExistOnCart(
                                        brands.id,
                                        item.product_id
                                      )?.cart_id ?? "",
                                  });
                                },
                              }}
                              key={`${index}/${i}-product`}
                              product={item}
                              onClick={() => {
                                item?.is_customizable
                                  ? navigate(
                                      `/product/${item.product_id}/detail`
                                    )
                                  : fetchDetailProduct(item.product_id);
                              }}
                              onAddToCart={() => {
                                if (item.is_customizable) {
                                  navigate(
                                    `/product/${item.product_id}/detail`
                                  );
                                } else {
                                  productToCart({
                                    is_customizable: item.is_customizable,
                                    product_id: item.product_id,
                                    qty: 1,
                                    product_name: item.product_name,
                                    product_image: "",
                                    brand_id: brands.id ?? "",
                                    brand_name: brands.brand_name ?? "",
                                    notes: "",
                                    additionals: [],
                                    price:
                                      Number(
                                        item.product_price_after_discount
                                      ) > 0
                                        ? item.product_price_after_discount
                                        : item.product_price,
                                  });
                                }
                              }}
                            />
                          ))}
                      </Box>
                    </Box>
                  ))}
              </>
            ) : (
              <>
                {brands.category
                  .filter((item) => item.products !== null)
                  .map((item, index) => (
                    <>
                      {index === active - 1 ? (
                        <>
                          <Box
                            key={index + "-promolabel"}
                            id={item.subscategory_name.replace(/\s/g, "")}
                            sx={{
                              marginBottom:
                                index === brands.category.length - 1
                                  ? "120px"
                                  : "",
                            }}
                          >
                            <CategoryLabel
                              background={data?.primary_color ?? "#B92362"}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "rgba(255,255,255,0.8)",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <NormalText
                                  fontSize={"12px"}
                                  color={data?.primary_color ?? "#B92362"}
                                  fontFamily="D-DIN Exp"
                                  fontWeight="bold"
                                  margin="0 0 0 24px"
                                >
                                  {item.subscategory_name}
                                </NormalText>
                              </Box>
                            </CategoryLabel>
                            <Box sx={{ background: "white", width: "100%" }}>
                              {item.products &&
                                item.products.map((item, i) => (
                                  <ProductItem
                                    isOnCart={
                                      productExistOnCart(
                                        brands.id,
                                        item.product_id
                                      )?.cart_id !== ""
                                    }
                                    counterProps={{
                                      value:
                                        productExistOnCart(
                                          brands.id,
                                          item.product_id
                                        )?.qty ?? 0,
                                      background:
                                        data?.primary_color ?? "#B92362",
                                      onDecrease: () => {
                                        let quantity = productExistOnCart(
                                          brands.id,
                                          item.product_id
                                        );

                                        if (quantity && quantity.qty === 1) {
                                          setReducePayload({
                                            brand_id: brands.id,
                                            type: "decrease",
                                            cart_id:
                                              productExistOnCart(
                                                brands.id,
                                                item.product_id
                                              )?.cart_id ?? "",
                                          });

                                          setConfirm(true);
                                        } else {
                                          reduceQuantity({
                                            brand_id: brands.id,
                                            type: "decrease",
                                            cart_id:
                                              productExistOnCart(
                                                brands.id,
                                                item.product_id
                                              )?.cart_id ?? "",
                                          });
                                        }
                                      },
                                      onIncrease: () => {
                                        reduceQuantity({
                                          brand_id: brands.id,
                                          type: "increase",
                                          cart_id:
                                            productExistOnCart(
                                              brands.id,
                                              item.product_id
                                            )?.cart_id ?? "",
                                        });
                                      },
                                    }}
                                    key={`${index}/${i}-product`}
                                    product={item}
                                    onClick={() =>
                                      navigate(
                                        `/product/${item.product_id}/detail`
                                      )
                                    }
                                    onAddToCart={() => {
                                      if (item.is_customizable) {
                                        navigate(
                                          `/product/${item.product_id}/detail`
                                        );
                                      } else {
                                        productToCart({
                                          is_customizable: item.is_customizable,
                                          product_id: item.product_id,
                                          qty: 1,
                                          product_name: item.product_name,
                                          product_image: "",
                                          brand_id: brands.id ?? "",
                                          brand_name: brands.brand_name ?? "",
                                          notes: "",
                                          additionals: [],
                                          price:
                                            Number(
                                              item.product_price_after_discount
                                            ) > 0
                                              ? item.product_price_after_discount
                                              : item.product_price,
                                        });
                                      }
                                    }}
                                  />
                                ))}
                            </Box>
                          </Box>
                        </>
                      ) : null}
                    </>
                  ))}
              </>
            )}
          </>
        ) : null}
      </Box>

      <ProductDrawer
        open={openProduct}
        onClose={handleProductDrawer}
        onOpen={handleProductDrawer}
        product={product}
        color={AppSetting?.data?.data?.primary_color}
        productToCart={productToCart}
      />

      {Cart && Cart.items.length > 0 && <CartBottomSheet summaries />}
    </MainLayout>
  );
};

interface HeaderProps {
  url: string;
}

const HeaderContainer = styled.div<HeaderProps>`
  height: 134px;
  width: 100%;
  top: 0;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    ${(props) => "url(" + props.url + ")"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10.83px 8px 51px 8px;
  /* padding:20px; */
`;

interface CategoryLabelProps {
  background?: string;
}

export const CategoryLabel = styled.div<CategoryLabelProps>`
  width: 100%;
  height: 30px;
  align-items: center;
  display: flex;
  background: ${(props) => props.background ?? "#fff4f8"};
`;

const BackButtonIcon = styled.img`
  cursor: pointer;
  width: 12px;
  height: auto;
`;

const Navigation = styled.div`
  transition: ease-in-out 0.5s;
  background: white;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  /* @media only screen and (max-width: 768px) {
    width: 410px;
  } */
`;

const FilterIcon = styled.img`
  cursor: pointer;
  height: 11.25px;
  width: 11.25px;
  margin-left: -5px;
`;

export default BrandDetail;
