import { logos } from "../bank.item";
import { Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";

interface BankSelectedProps {
  bank_code: string | undefined;
  payment_method: string | undefined;
  className?: string;
  style?: any;
}

interface Logo {
  code: string;
  logo: any;
  name?: string;
}

const BankSelected = ({
  bank_code,
  payment_method,
  className,
  style,
}: BankSelectedProps) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      className={className}
      sx={{ style }}
    >
      <img
        src={
          logos.find((logo: Logo) => {
            return logo.code === bank_code;
          })?.logo
        }
        alt={bank_code}
        style={{ height: "20px", width: "auto" }}
        className="mr-3"
      />
      <NormalText fontSize="12px" fontWeight="800" color="#949494">{`${
        logos.find((logo: Logo) => {
          return logo.code === bank_code;
        })?.name
      }`}</NormalText>
    </Stack>
  );
};

export default BankSelected;
