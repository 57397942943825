import { Stack, SwipeableDrawer, Typography, Box } from "@mui/material";
import { ProductsDetail } from "redux/product";
import { formatCurrency } from "helper/general";
import { useState } from "react";
import NoPhoto from "assets/images/NoPhotoBottomSheet.svg";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Splide as SplideTypes,
  Components as ComponentsTypes,
} from "@splidejs/splide";
import "@splidejs/react-splide/css";

//component
import { ChekoutButton } from "components/BottomSheet";
import CounterButton from "components/Button/counter";
import InputField from "components/Forms/InputField";
import { urlExists } from "helper/general";

export function SlideNumber(Splide: SplideTypes, Components: ComponentsTypes) {
  const { track } = Components.Elements;

  let elm: any;

  let elmOptions = {
    textAlign: "left",
    position: "absolute",
    bottom: "0",
    left: "1rem",
    padding: "0.2rem 0.3rem",
    borderRadius: "3px",
    background: "rgba(0,0,0,0.7)",
    color: "#ffffff",
    fontSize: "0.8rem",
    fontWeight: "800",
  };

  function mount() {
    elm = document.createElement("div");
    const {
      textAlign,
      position,
      padding,
      borderRadius,
      bottom,
      background,
      left,
      color,
      fontSize,
      fontWeight,
    } = elmOptions;
    elm.style.textAlign = textAlign;
    elm.style.position = position;
    elm.style.bottom = bottom;
    elm.style.left = left;
    elm.style.padding = padding;
    elm.style.background = background;
    elm.style.color = color;
    elm.style.borderRadius = borderRadius;
    elm.style.fontSize = fontSize;
    elm.style.fontWeight = fontWeight;

    track?.parentElement?.insertBefore(elm, track.nextSibling);

    update();
    Splide.on("move", update);
  }

  function update() {
    elm.textContent = `${Splide.index + 1}/${Splide.length}`;
  }

  return {
    mount,
  };
}

const slideProperties = {
  perPage: 1,
  start: 0,
  arrows: false,
  isNavigation: false,
  pagination: false,
};

interface ProductDrawerProps {
  open: boolean;
  onOpen: any;
  onClose: any;
  product: ProductsDetail;
  color?: string;
  productToCart: any;
}

const ProductDrawer = ({
  open,
  onClose,
  onOpen,
  product,
  color,
  productToCart,
}: ProductDrawerProps) => {
  const [qty, setQty] = useState(1);
  const [notes, setNotes] = useState("");
  const [moreDescription, setMoreDescription] = useState(false);
  const toggleMoreDescription = () => {
    setMoreDescription((prevState) => {
      return !prevState;
    });
  };

  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose(false);
        setMoreDescription(false);
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          height={"221px"}
          direction={"row"}
          justifyContent={"center"}
          alignItems="flex-start"
          sx={{ position: "relative" }}
        >
          {/* <img
            src={
              product.product_detail.product_image_main &&
              product.product_detail.product_image_main.length > 0
                ? product.product_detail.product_image_main
                : NoPhoto
            }
            alt=""
            style={{ width: "auto", height: "100%", objectFit: "contain" }}
          /> */}

          <Splide
            extensions={{ SlideNumber }}
            options={slideProperties}
            tag="section"
            aria-label="Product Images"
            className="w-100"
          >
            <SplideSlide
              key="splide-slide-1"
              className="d-flex justify-content-center"
            >
              <img
                src={
                  product.product_detail.product_image_main &&
                  product.product_detail.product_image_main.length > 0
                    ? urlExists(product.product_detail.product_image_main)
                      ? product.product_detail.product_image_main
                      : NoPhoto
                    : NoPhoto
                }
                alt="gambar 1"
                style={{ width: "auto", height: "221px", objectFit: "contain" }}
                data-splide-lazy="path-to-the-image"
              />
            </SplideSlide>
            <SplideSlide
              key="splide-slide-2"
              className="d-flex justify-content-center"
            >
              <img
                src={
                  product.product_detail.product_image_1 &&
                  product.product_detail.product_image_1.length > 0
                    ? product.product_detail.product_image_1
                    : NoPhoto
                }
                alt="gambar 2"
                style={{ width: "auto", height: "221px", objectFit: "contain" }}
                data-splide-lazy="path-to-the-image"
              />
            </SplideSlide>
            <SplideSlide
              key="splide-slide-3"
              className="d-flex justify-content-center"
            >
              <img
                src={
                  product.product_detail.product_image_2 &&
                  product.product_detail.product_image_2.length > 0
                    ? product.product_detail.product_image_2
                    : NoPhoto
                }
                alt="gambar 3"
                style={{ width: "auto", height: "221px", objectFit: "contain" }}
                data-splide-lazy="path-to-the-image"
              />
            </SplideSlide>
            <SplideSlide
              key="splide-slide-4"
              className="d-flex justify-content-center"
            >
              <img
                src={
                  product.product_detail.product_image_3 &&
                  product.product_detail.product_image_3.length > 0
                    ? product.product_detail.product_image_3
                    : NoPhoto
                }
                alt="gambar 4"
                style={{ width: "auto", height: "221px", objectFit: "contain" }}
                data-splide-lazy="path-to-the-image"
              />
            </SplideSlide>
            <SplideSlide
              key="splide-slide-5"
              className="d-flex justify-content-center"
            >
              <img
                src={
                  product.product_detail.product_image_4 &&
                  product.product_detail.product_image_4.length > 0
                    ? product.product_detail.product_image_4
                    : NoPhoto
                }
                alt="gambar 5"
                style={{ width: "auto", height: "221px", objectFit: "contain" }}
                data-splide-lazy="path-to-the-image"
              />
            </SplideSlide>
          </Splide>
          <div
            style={{
              background: "rgba(127, 140, 141,0.7)",
              position: "absolute",
              top: "2%",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "13000",
              height: "10px",
              width: "60px",
              borderRadius: 8,
            }}
          ></div>
        </Stack>

        <Typography
          sx={{
            mt: 4,
            px: 2,
            color: "#2d3436",
            fontFamily: "D-DIN Exp, Open Sans, sans-serif",
            fontWeight: "600",
            fontSize: "1.2rem",
            lineHeight: "1",
          }}
        >
          {product?.product_name}
        </Typography>

        <Typography
          sx={{
            mt: 1.5,
            px: 2,
            color: "#95a5a6",
            fontFamily: "D-DIN Exp, Open Sans, sans-serif",
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1",
          }}
        >
          {product.product_description.short_description}
        </Typography>

        {product.product_description.long_description.length <= 120 &&
        !moreDescription ? (
          <Typography
            sx={{
              mt: 1,
              px: 2,
              color: "#95a5a6",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "400",
              fontSize: "0.8rem",
              lineHeight: "1.5",
              height: "auto",
            }}
          >
            {product.product_description.long_description}
          </Typography>
        ) : null}

        {product.product_description.long_description.length > 120 &&
        !moreDescription ? (
          <Typography
            sx={{
              mt: 1,
              px: 2,
              color: "#95a5a6",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "400",
              fontSize: "0.8rem",
              lineHeight: "1.5",
              height: "60px",
              overflow: "hidden",
            }}
          >
            {product.product_description.long_description}
          </Typography>
        ) : null}

        {product.product_description.long_description.length > 120 &&
        moreDescription ? (
          <Box
            sx={{
              width: "100%",
              mt: 1,
              px: 2,
              height: "10vh",
              overflowY: "scroll",
            }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: product.product_description.long_description_html,
              }}
              style={{
                fontFamily: "D-DIN Exp, Open Sans, sans-serif",
                fontSize: "0.8rem",
                color: "#95a5a6",
              }}
            ></p>
          </Box>
        ) : null}

        {product.product_description.long_description.length > 120 ? (
          <Box
            sx={{
              mt: 1,
              width: "100%",
              px: 2,
              py: 0,
            }}
          >
            <button
              style={{
                background: "#ffffff",
                width: "100%",
                height: "35px",
                display: "flex",
                alignItems: "center",
                fontFamily: "sans-serif",
                fontWeight: "800",
                border: "none",
                paddingLeft: "0",
                fontSize: "0.75rem",
                color: color,
              }}
              onClick={toggleMoreDescription}
            >
              {moreDescription ? "Lihat Lebih Sedikit" : "Baca Selengkapnya"}
            </button>
          </Box>
        ) : null}

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ px: 2, mt: 1, width: "100%" }}
        >
          {Number(product.product_detail.discount) > 0 ? (
            <Stack direction={"column"}>
              <Typography
                sx={{
                  color: "#2d3436",
                  fontFamily: "D-DIN Exp, Open Sans, sans-serif",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                }}
              >
                {formatCurrency(
                  Number(product?.product_detail?.product_price_after_discount)
                )}
              </Typography>
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    color: "#949494",
                    fontFamily: "D-DIN Exp, Open Sans, sans-serif",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                    textDecoration: "line-through",
                  }}
                >
                  {formatCurrency(
                    Number(product?.product_detail?.product_price)
                  )}
                </Typography>
                {product.product_detail.discount_type === "percent" ? (
                  <Stack
                    ml={1}
                    px={0.5}
                    sx={{ background: "#FF2622", borderRadius: "4px" }}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontFamily: "D-DIN Exp, Open Sans, sans-serif",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                      }}
                    >
                      {product?.product_detail?.discount}%
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          ) : (
            <Typography
              sx={{
                color: "#2d3436",
                fontFamily: "D-DIN Exp, Open Sans, sans-serif",
                fontWeight: "600",
                fontSize: "1.2rem",
              }}
            >
              {product &&
              product?.product_detail &&
              Number(product?.product_detail?.product_price_after_discount) > 0
                ? formatCurrency(
                    Number(
                      product?.product_detail?.product_price_after_discount
                    )
                  )
                : formatCurrency(
                    Number(product?.product_detail?.product_price)
                  )}
            </Typography>
          )}

          <CounterButton
            value={qty}
            background={color ?? "black"}
            onIncrease={() => setQty(qty + 1)}
            onDecrease={() => {
              if (qty === 1) {
                setQty(1);
              } else {
                setQty(qty - 1);
              }
            }}
          />
        </Stack>

        <Box margin={"10px 0"} px={2} width={"100%"}>
          <InputField
            value={notes}
            placeholder={"Notes"}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="center"
          sx={{ px: 2, mt: 2, width: "100%" }}
        >
          <ChekoutButton
            background={color ?? "black"}
            onClick={() => {
              if (product) {
                productToCart({
                  is_customizable: product?.product_detail?.is_customizable,
                  product_id: product?.product_id,
                  qty: qty,
                  product_name: product?.product_name,
                  product_image: "",
                  brand_id: product?.brand?.id ?? "",
                  brand_name: product?.brand?.brand_name ?? "",
                  additionals: [],
                  price:
                    Number(
                      product?.product_detail?.product_price_after_discount
                    ) > 0
                      ? product?.product_detail?.product_price_after_discount
                      : product?.product_detail?.product_price,
                  notes: notes,
                });
              }
            }}
          >
            Tambah Ke Keranjang
          </ChekoutButton>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ProductDrawer;
