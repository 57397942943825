import React from "react";
import { AppContainer } from "../../components/styled/layout.styled";
import { Stack } from "../../components/styled/layout.styled";
import LogoNoAccess from "../../assets/logo/no-access.png";
import { NormalText } from "../../components/styled/text.styled";
import "./styles.css";
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";

interface Props {
  message?: string;
  retry?: boolean;
}

const AccessForbiddenPage = ({ message, retry = false }: Props) => {
  const {
    storeState: { AppSetting },
  } = useRedux();
  // eslint-disable-next-line
  const [data, setData] = React.useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );

  return (
    <AppContainer background={data?.secondary_color ?? "#FFF4F8"}>
      <Stack direction="row">
        <div className="container text-center" style={{ margin: "30% 0 0 0" }}>
          <img src={LogoNoAccess} alt="logo" />
          <NormalText
            fontSize="18px"
            margin="20px"
            fontWeight="600"
            fontFamily="D-DIN EXP"
          >
            ACCESS FORBIDDEN
          </NormalText>
          <NormalText
            wordWrap="break-word"
            color="#7E7E7E"
            margin="10px"
            fontSize="12px"
            fontFamily="Open Sans"
          >
            {message ??
              "GPS kamu belum aktif, mohon untuk izinkan/aktifkan GPS untuk melanjutkan"}
          </NormalText>
          {retry && (
            <NormalText
              fontSize="12px"
              onClick={() => window.location.reload()}
              isPressable
              fontFamily="Open Sans"
            >
              Coba Lagi
            </NormalText>
          )}
        </div>
      </Stack>
    </AppContainer>
  );
};

export default AccessForbiddenPage;
