import axios from 'axios';

const ApiService = () => {
    let instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`
        },
    });

    instance.interceptors.response.use(
        res => {
            return res;
        },
        err => {
            if (err.response.status === 401) {
                console.log('Got Error 401')
            }
            if (err.response.status === 503) {
                console.log('Got Error 503')
            }
            if (err.response.status === 500) {
                console.log('Got Error 503')
            }
            return Promise.reject(err);
        },
    );
    return instance;
}

export default ApiService();
