import ArrowRightIcons from "assets/icons/ic_arrow_right.svg";
import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { formatCurrency } from "helper/general";

interface DeliveryTypeComponentProps {
  time: any;
  courier: any;
  handlerClickTime: any;
  handlerClickCourier: any;
}

const DeliveryTypeComponent = ({
  time,
  courier,
  handlerClickTime,
  handlerClickCourier,
}: DeliveryTypeComponentProps) => {
  return (
    <Box padding={"20px 20px"} sx={{ cursor: "pointer" }}>
      {time ? (
        <Stack direction={"column"} width="100%">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            spacing={1}
            alignItems={"center"}
            onClick={handlerClickTime}
          >
            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                {time === "trucking"
                  ? "Kargo"
                  : time === "regular"
                  ? "Reguler"
                  : time === "same-day"
                  ? "Same Day"
                  : time === "instant"
                  ? "Instant"
                  : time === "express"
                  ? "Express"
                  : time
                  ? time
                  : "Pilih Pengiriman"}
              </NormalText>
            </Stack>
            <img src={ArrowRightIcons} alt={"arrow away icon"} />
          </Stack>

          <hr />

          {courier && courier.courier_name.length > 0 && courier.id > 0 ? (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
              alignItems={"center"}
              onClick={handlerClickCourier}
            >
              <Stack direction={"column"}>
                <NormalText
                  fontFamily="D-DIN"
                  fontSize={"14px"}
                  fontWeight={"bold"}
                >
                  {`${courier.courier_name} ${
                    courier.courier_name_rate
                  } (${formatCurrency(courier.total_price)})`}
                </NormalText>

                <NormalText
                  fontFamily="D-DIN"
                  fontSize={"12px"}
                  fontWeight={"light"}
                >
                  {" "}
                  {courier.min_day === courier.max_day ||
                  courier.max_day === courier.min_day
                    ? `${courier.min_day} hari`
                    : `${courier.min_day} hari -  ${courier.max_day} hari`}
                </NormalText>
              </Stack>
              <img src={ArrowRightIcons} alt={"arrow away icon"} />
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
              alignItems={"center"}
              onClick={handlerClickCourier}
            >
              <Stack direction={"column"}>
                <NormalText
                  fontFamily="D-DIN"
                  fontSize={"14px"}
                  fontWeight={"bold"}
                >
                  Pilih Kurir
                </NormalText>
              </Stack>
              <img src={ArrowRightIcons} alt={"arrow away icon"} />
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          onClick={handlerClickTime}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Stack direction={"column"}>
              <NormalText
                fontFamily="D-DIN"
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                Pilih Pengiriman
              </NormalText>
            </Stack>
          </Stack>
          <img src={ArrowRightIcons} alt={"arrow away icon"} />
        </Stack>
      )}
    </Box>
  );
};

export default DeliveryTypeComponent;
