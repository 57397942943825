import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterUserState, RegisterUserProps } from "./types";
import axios from "axios";

export const registerUser = createAsyncThunk('user/register', async (props: RegisterUserProps) => {
    const config = {
        headers: {
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            // token: props.access_token
        }
    };
    const data = {
        full_name: props.full_name,
        email: props.email,
        phone: props.phone,
        merchant_id: props.merchant_id
    }
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/session/signup`,
            data,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as RegisterUserState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as RegisterUserState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as RegisterUserState;

    }
})

export const registerSetData = createAsyncThunk(
    'user/data',
    async (props: RegisterUserProps) => {
        return {
            ...props,
        } as RegisterUserProps;
    },
);

export const removeRegisterdata = createAsyncThunk(
    'user/register/remove',
    async () => {
        return 'OK'
    },
);