import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import { formatCurrency } from "helper/general";
import CounterButton, { CounterProps } from "components/Button/counter";
import PlusAddIcon from "assets/icons/ic_plus_addItem.svg";
import NoPhoto from "assets/images/NoPhotoList.svg";
import { urlExists } from "helper/general";

interface ProductItemTypes {
  onClick?: any;
  product: any;
  productStore?: any;
  onAddToCart?: any;
  isOnCart?: boolean;
  counterProps?: CounterProps;
  style?: React.CSSProperties;
  name: string;
}

const ProductItem = ({
  onClick,
  product,
  productStore,
  onAddToCart,
  isOnCart,
  counterProps,
  style,
  name,
}: ProductItemTypes) => {
  return (
    <Container style={style}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/* Product Image */}
        <Box
          sx={{
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            width: "75px!important",
            height: "75px!important",
          }}
        >
          <ProductImage
            src={
              product.product_image_main &&
              product.product_image_main.length > 0
                ? urlExists(product.product_image_main)
                  ? product.product_image_main
                  : NoPhoto
                : NoPhoto
            }
            alt={"Product 1"}
            onClick={onClick}
            loading="lazy"
          />
        </Box>

        {/* Product Item Information */}
        <Box width={"50%"} margin={"0 0 0 10px"}>
          <Stack direction={"column"}>
            <NormalText
              fontSize="14px"
              fontWeight="700"
              fontFamily="D-DIN Exp"
              lineHeight="15.2px"
              style={{ cursor: "pointer" }}
              onClick={onClick}
              margin="0 0 0 0"
            >
              {name[0].toLocaleUpperCase() + name.substring(1)}
            </NormalText>

            {product && product.discount && Number(product.discount) > 0 ? (
              <NormalText
                fontSize="10px"
                fontFamily="D-DIN Exp"
                style={{ cursor: "pointer" }}
                onClick={onClick}
              >
                Disc{" "}
                {product.discount_type === "percent"
                  ? product.discount + "%"
                  : formatCurrency(Number(product.discount))}
              </NormalText>
            ) : null}

            {/* {Number(product.discount) > 0 ? (
              <NormalText
                fontSize="10px"
                fontFamily="D-DIN Exp"
                style={{ cursor: "pointer" }}
                onClick={onClick}
              >
                Disc{" "}
                {product.discount_type === "percent"
                  ? product.discount + "%"
                  : formatCurrency(Number(product.discount))}
              </NormalText>
            ) : null} */}
            {/* checked */}

            {/* {(!productStore || Number(productStore.store_price) > 0) &&
            Number(productStore.discount) === 0 &&
            product.discount &&
            Number(product.discount) > 0 ? (
              <NormalText
                fontSize="10px"
                fontFamily="D-DIN Exp"
                style={{ cursor: "pointer" }}
                onClick={onClick}
              >
                Disc{" "}
                {product.discount_type === "percent"
                  ? product.discount + "%"
                  : formatCurrency(Number(product.discount))}
              </NormalText>
            ) : null} */}

            {/* {productStore &&
            productStore.discount_type &&
            Number(productStore.discount) > 0 &&
            Number(productStore.final_price) > 0 ? (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                  textDecoration={"line-through"}
                >
                  {formatCurrency(Number(productStore.store_price))}{" "}
                </NormalText>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                >
                  {formatCurrency(Number(productStore.final_price))}
                </NormalText>
              </Stack>
            ) : null} */}

            {product &&
            product.product_price &&
            product.product_price_after_discount &&
            product.discount_type &&
            product.discount &&
            Number(product.discount) > 0 &&
            Number(product.product_price) > 0 &&
            Number(product.product_price_after_discount) > 0 ? (
              <Stack direction={"column"} alignItems={"flex-start"}>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                  textDecoration={"line-through"}
                >
                  {formatCurrency(Number(product.product_price))}{" "}
                </NormalText>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                >
                  {formatCurrency(Number(product.product_price_after_discount))}
                </NormalText>
              </Stack>
            ) : null}

            {/* {productStore &&
            Number(productStore.discount) === 0 &&
            Number(productStore.store_price) > 0 ? (
              <NormalText
                fontSize="14px"
                fontWeight="400"
                fontFamily="D-DIN Exp"
                lineHeight="15.2px"
              >
                {formatCurrency(
                  Number(productStore.final_price) > 0
                    ? Number(productStore.final_price)
                    : Number(productStore.store_price)
                )}
              </NormalText>
            ) : null} */}

            {/* {(!productStore || Number(productStore.store_price) === 0) &&
            Number(product.product_price) > 0 &&
            Number(product.discount) > 0 &&
            Number(product.product_price_after_discount) > 0 ? (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                  textDecoration={"line-through"}
                >
                  {formatCurrency(Number(product.product_price))}{" "}
                </NormalText>
                <NormalText
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="D-DIN Exp"
                  lineHeight="15.2px"
                >
                  {formatCurrency(Number(product.product_price_after_discount))}
                </NormalText>
              </Stack>
            ) : null} */}

            {product &&
            product.product_price &&
            Number(product.product_price) > 0 &&
            Number(product.discount) === 0 ? (
              <NormalText
                fontSize="14px"
                fontWeight="400"
                fontFamily="D-DIN Exp"
                lineHeight="15.2px"
                margin="5px 0 0 0"
              >
                {formatCurrency(
                  Number(product.product_price_after_discount) > 0
                    ? Number(product.product_price_after_discount)
                    : Number(product.product_price)
                )}
              </NormalText>
            ) : null}
          </Stack>
        </Box>

        {/* Add Button */}
        <Box margin={"auto 0 auto 0"}>
          {isOnCart && counterProps ? (
            product.is_customizable ? (
              <ButtonAdd style={{ fontSize: "10px" }} onClick={onAddToCart}>
                Customize Another
              </ButtonAdd>
            ) : (
              <CounterButton
                background={counterProps?.background}
                value={
                  counterProps && counterProps.value ? counterProps.value : 0
                }
                onIncrease={counterProps?.onIncrease}
                onDecrease={counterProps?.onDecrease}
              />
            )
          ) : product.is_customizable ? (
            <Stack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <ButtonAdd onClick={onAddToCart}>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontWeight="400"
                  fontSize="10px"
                  lineHeight="1"
                  margin="15px 0 0 0"
                >
                  Add
                </NormalText>{" "}
                <PlusIcon src={PlusAddIcon} />
              </ButtonAdd>

              <NormalText
                fontFamily="Roboto Condensed"
                fontWeight="400"
                fontSize="10px"
                lineHeight="1"
                margin="15px 0 0 0"
              >
                + Bisa Custom
              </NormalText>
            </Stack>
          ) : (
            <ButtonAdd onClick={onAddToCart}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                width={"100%"}
                justifyContent="center"
              >
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontWeight="400"
                  fontSize="10px"
                  lineHeight="1"
                  margin="0 5px 0 0"
                >
                  Add{" "}
                </NormalText>{" "}
                <PlusIcon src={PlusAddIcon} />
              </Stack>
            </ButtonAdd>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background: white;
  min-height: 110px;
  padding: 32px 0px;
  margin: 0px 10px;
  border-bottom: 1px solid #cccccc;
`;

const ButtonAdd = styled.button`
  background: #ffffff;
  width: 61.82px;
  height: 30px;
  font-family: "D-DIN Exp";
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;

  :hover,
  :focus,
  :active {
    opacity: 0.5;
  }
`;

const ProductImage = styled.img`
  width: 75px;
  height: auto;
  cursor: pointer;
  background: #ffffff;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  object-fit: cover;
  border-radius: 10px; */
`;

const PlusIcon = styled.img`
  width: 5px;
  height: 5px;
`;

export default ProductItem;
