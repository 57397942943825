import { Grid } from "@mui/material";
import NewProductListItem from "./newproduct.list.item";
import { useNavigate, useLocation } from "react-router-dom";

import { QrGroupProductType } from "redux/product";

interface NewProductListFullProps {
  className?: string;
  newProductList?: QrGroupProductType[];
  showAllTrigger?: any;
  getDetailProduct?: any;
}

const NewProductListFull = ({
  className,
  newProductList,
  showAllTrigger,
  getDetailProduct,
}: NewProductListFullProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid container className={className} spacing={4} alignItems={"center"}>
      {newProductList && newProductList.length > 0
        ? newProductList.map((product, index) => {
            return (
              <Grid item xs={6} key={index + "-product"}>
                <NewProductListItem
                  image={product.product_detail.product_image_main}
                  name={product.product_name}
                  storeName={product.productStore.store_name}
                  key={index + "-product"}
                  discount={product.product_detail.discount}
                  discount_type={product.product_detail.discount_type}
                  price={product.product_detail.product_price}
                  price_after_discount={
                    product.product_detail.product_price_after_discount
                  }
                  type="column"
                  onClick={() => {
                    product.product_detail.is_customizable
                      ? navigate(
                          `/qr-group/store/${product?.productStore?.store_name
                            ?.toLowerCase()
                            .replace(/ /g, "")}/${product?.product_name
                            ?.toLowerCase()
                            .replace(/ /g, "-")}`,
                          {
                            state: {
                              prevUrl: location.pathname,
                              productDetail: product,
                            },
                          }
                        )
                      : getDetailProduct({
                          productId: product.product_id,
                          merchantId: product.merchant_id,
                          storeId: product.productStore.store_id,
                        });
                  }}
                />
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

export default NewProductListFull;
