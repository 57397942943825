import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaymentMethodState } from "./types";
import axios from "axios";

export const getPaymentMethodList = createAsyncThunk('product/list', async (merchant_id: number) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`
        },
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/master/paymentmethod?device=MOBILEQR&merchant_id=${merchant_id}`,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null,
            } as unknown as PaymentMethodState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null,
            } as unknown as PaymentMethodState;
        }
    } catch (err) {
        if (err)
            return {
                data: "Something went wrong!",
                status: "error",
                error: err,
            } as unknown as PaymentMethodState;
    }
})