import React from "react";
import { NormalText } from "../../components/styled/text.styled";
import { Box, Stack } from "@mui/material";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { Tab, TabsList, TabPanel } from "components/Tabs";
import MainLayout from "components/Layouts/MainLayout";
import useRedux from "../../redux/useRedux";
import { useNavigate } from "react-router-dom";
import { AppSettingData } from "redux/appsetting";
import styled from "styled-components";
import IconBack from "assets/icons/kembali_ic_black.svg";
import IncomeView from "./type/income";
import ExpenseView from "./type/expense";
import { getLoyaltyPoint, LoyaltyPointData } from "redux/loyaltyPoint";
import moment from "moment";

const DetailLoyaltyPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { AppSetting, CheckCustomer, StoreInfo, RegisterUser },
  } = useRedux();

  const [info, setInfo] = React.useState<LoyaltyPointData>();
  const dataApp: AppSettingData = AppSetting?.data?.data ?? null;

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.status === "success" &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.store_code
    ) {
      if (
        CheckCustomer &&
        CheckCustomer.data &&
        CheckCustomer.data &&
        CheckCustomer.data.data
      ) {
        thunkDispatch(
          getLoyaltyPoint({
            storeCode: StoreInfo.data.data.store_code,
            phoneNumber: CheckCustomer && CheckCustomer.data && CheckCustomer.data.data && CheckCustomer.data.data.phone_number ?
              CheckCustomer.data.data.phone_number
              : RegisterUser.items && RegisterUser.items.phone
                ? RegisterUser.items.phone : '',
          })
        )
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setInfo(res.data.data);
            }
          });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout
      background={"#FFFFFF"}
      favIcon={dataApp?.favicon ?? ""}
      title={dataApp?.merchant_name ?? ""}
    >
      <Box width={"100%"} sx={{ background: "white" }} padding={"10px"}>
        <Stack direction={"row"}>
          <div
            onClick={() => navigate("/customer-page")}
            style={{ cursor: "pointer" }}
          >
            <img src={IconBack} alt={"back icon"} />
          </div>
          <NormalText
            fontSize="14px"
            fontWeight="bold"
            fontFamily="D-DIN Exp"
            color={"#000"}
            textAlign={"center"}
            style={{ width: "100%" }}
          >
            Loyality Point
          </NormalText>
        </Stack>
      </Box>
      <Box
        width={"100%"}
        height={"159px"}
        padding={"20px"}
        sx={{ background: dataApp?.primary_color ?? "#FFC535" }}
      >
        <NormalText fontSize="12px" fontFamily="D-DIN Exp" color={"#FFF"}>
          Email
        </NormalText>
        <NormalText
          fontSize="18px"
          fontWeight="bold"
          fontFamily="D-DIN Exp"
          color={"#FFF"}
        >
          {info && info.email ? info.email : "-"}
        </NormalText>
        <NormalText
          fontSize="12px"
          fontFamily="D-DIN Exp"
          color={"#FFF"}
          margin={"10px 0 0 0"}
        >
          Berlaku Hingga
        </NormalText>
        <NormalText
          fontSize="18px"
          fontWeight="bold"
          fontFamily="D-DIN Exp"
          color={"#FFF"}
        >
          {info && info.expired_date
            ? moment(info.expired_date).format("DD/MM/YYYY")
            : "-"}
        </NormalText>
      </Box>
      <InformationBox>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <NormalText fontSize="12px" fontFamily="D-DIN Exp">
            Total Points
          </NormalText>
          <NormalText
            fontSize="12px"
            fontWeight={"bold"}
            fontFamily="D-DIN Exp"
          >
            {info && info.loyalty_point ? info.loyalty_point : "0"}{" "}
            <span style={{ fontSize: "10px", fontWeight: "unset !important" }}>
              Points
            </span>
          </NormalText>
        </Stack>
      </InformationBox>
      <Box margin={"30px 0 0 0"}>
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <Tab
              value={0}
              onClick={() => null}
              activeColor={dataApp?.primary_color ?? ""}
            >
              Pendapatan
            </Tab>
            <Tab
              value={1}
              onClick={() => null}
              activeColor={dataApp?.primary_color ?? ""}
            >
              Pengeluaran
            </Tab>
          </TabsList>
          <TabPanel value={0}>
            <Box
              width={"100%"}
              sx={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
            >
              <IncomeView />
            </Box>
          </TabPanel>
          <TabPanel value={1}>
            <Box
              width={"100%"}
              sx={{ height: "calc(100vh - 300px)", overflowY: "auto" }}
            >
              <ExpenseView />
            </Box>
          </TabPanel>
        </TabsUnstyled>
      </Box>
    </MainLayout>
  );
};

const InformationBox = styled.div`
  position: absolute;
  width: 314px;
  left: 27px;
  top: 24%;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
`;

export default DetailLoyaltyPage;
