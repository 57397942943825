import React, { useState } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import styled from "styled-components";
import { Box, Stack, Grid } from "@mui/material";
import useRedux from "../../redux/useRedux";
import { AppSettingData } from "redux/appsetting";
import ImageCook from "../../assets/images/cookingImage.png";
import ImageConfirm from "../../assets/images/confirmImage.png";
import ImageReady from "../../assets/images/readyImage.png";
import ImageClose from "../../assets/images/close.svg";
import ProductOrder from "./product.order";
import { SpacerLine } from "components/styled/layout.styled";
import { orderDetail, OrderDetailTypes } from "redux/order";
import { useParams } from "react-router-dom";
import { formatCurrency } from "helper/general";
//import { useNavigate } from "react-router-dom";
//import KembaliIcon from '../../assets/icons/kembali_ic.svg';
import OrderDetailLoading from "./order.detail.loading";
import { getOrderInvoice } from "redux/checkout";
import {
  ConsigneeProps,
  ConsignerProps,
  getStatus,
  //ShipmentStatus,
  ShipmentTrackingProps,
} from "redux/delivery";
import moment from "moment";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BackButtonV2 from "components/Button/back.button.v2";

const dataDesc = [
  {
    value: "orderan anda sedang dimasak",
    desc: "Silahkan tunggu sampai pesanan anda siap untuk diambil",
  },
  {
    value: "orderan anda terkonfirmasi",
    desc: "Silahkan tunggu sampai pesanan anda siap untuk diambil",
  },
  {
    value: "orderan anda sudah siap diambil",
    desc: "Silahkan menuju tempat pengambilan yang sudah anda pilih sebelumnya!",
  },
  {
    value: "orderan anda sudah siap diambil!",
    desc: "Silahkan menuju tempat pengambilan yang sudah anda pilih sebelumnya!",
  },
];

const ProductDetail = () => {
  const [shipmentTrackingData, setShipmentTrackingData] =
    useState<ShipmentTrackingProps[]>();
  const [recieverData, setRecieverData] = useState<ConsigneeProps>();
  const [senderData, setSenderData] = useState<ConsignerProps>();
  const {
    thunkDispatch,
    storeState: { AppSetting },
  } = useRedux();
  // const navigate = useNavigate();
  const { order_number } = useParams();
  // eslint-disable-next-line
  const [dataApp, setDataApp] = useState<AppSettingData>(
    AppSetting?.data?.data ?? null
  );
  const [loading, setLoading] = useState(true);
  const [dataDetail, setDataDetail] = useState<OrderDetailTypes>();
  const [meesages, setMessages] = useState("");

  const checkOrderStatus = React.useCallback(() => {
    setTimeout(() => {
      setLoading(true);
      let data = {
        order_number: order_number ?? "",
      };
      thunkDispatch(orderDetail(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setDataDetail(res.data.data);

            if (res?.data?.data?.order_type === "DELIVERY") {
              thunkDispatch(getOrderInvoice(data))
                .unwrap()
                .then((res: any) => {
                  if (res && res.status === "success") {
                    if (res?.data?.data?.shipper_order_id) {
                      thunkDispatch(
                        getStatus(res?.data?.data?.shipper_order_id)
                      )
                        .unwrap()
                        .then((res: any) => {
                          // console.log(res);
                          // setNoResi(
                          //   res.data.data.awb_number ? res.data.data.awb_number : "-"
                          // );
                          // setDataStatus(res.data.data.shipment_status);
                          setShipmentTrackingData(res.data.data.trackings);
                          setSenderData({
                            email: res.data.data.consigner.email,
                            name: res.data.data.consigner.name,
                            phone_number: res.data.data.consigner.phone_number,
                            address: res.data.data.origin,
                          });
                          setRecieverData({
                            email: res.data.data.consignee.email,
                            name: res.data.data.consignee.name,
                            phone_number: res.data.data.consignee.phone_number,
                            address: res.data.data.destination,
                          });
                          setLoading(false);
                        });
                    }
                  }
                });
            } else {
              setMessages(res.data.message);
              setLoading(false);
            }

            // if (res.data.data.order_status === "CLOSED") {
            //   navigate("/");
            // }
          }
        });
    }, 5000);
  }, []); //eslint-disable-line

  React.useEffect(() => {
    checkOrderStatus();
  }, [checkOrderStatus]); //eslint-disable-line

  React.useEffect(() => {
    let data = {
      order_number: order_number ?? "",
    };
    thunkDispatch(orderDetail(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setDataDetail(res.data.data);
          // if (res.data.data.order_status === "CLOSED") {
          //   navigate("/");
          // }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thunkDispatch, order_number]);

  const generateDescription = () => {
    const temp = dataDesc.findIndex((item) =>
      item.value.toLocaleLowerCase().includes(meesages.toLocaleLowerCase())
    );
    return temp !== -1 ? dataDesc[temp].value : null;
  };

  return (
    <MainLayout
      background={dataApp?.secondary_color ?? "#fff4f8"}
      favIcon={dataApp?.favicon ?? ""}
      title={"Detail Produk | " + dataApp?.merchant_name}
    >
      {loading ? (
        <OrderDetailLoading />
      ) : (
        <Stack direction={"column"}>
          <Stack
            alignItems={"center"}
            width={"100%"}
            direction={"row"}
            marginTop={2}
          >
            {/* <img
              src={KembaliIcon}
              alt={'Sandbox Logo'}
              style={{
                marginTop: '15px',
                marginLeft: '15px',
                width: '14px',
                height: '14px',
              }}
            /> */}
            <BackButtonV2 prev_url={""} />
            <NormalText
              fontFamily="sans-serif"
              fontSize="16px"
              color="gray"
              margin="0px 0 0 5px"
            >
              Kembali
            </NormalText>
          </Stack>
          <Box
            style={{
              backgroundColor: dataApp?.secondary_color ?? "#000",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={
                dataDetail && dataDetail.order_status
                  ? dataDetail.order_status === "PAID" ||
                    dataDetail.order_status === "NEW_ORDER"
                    ? ImageConfirm
                    : dataDetail.payment_status === "PROCESS" ||
                      dataDetail.order_status === "PREPARING"
                    ? ImageCook
                    : dataDetail.order_status === "CLOSED"
                    ? ImageClose
                    : ImageReady
                  : ImageCook
              }
              alt="logo"
              style={{ width: "250px", height: "300px" }}
            />
            {dataDetail && dataDetail.order_type === "DELIVERY" ? (
              <Box sx={{ padding: "0 20px", width: "100%" }}>
                <NormalText
                  fontSize={"20px"}
                  fontFamily="Open Sans"
                  fontWeight="bold"
                  margin="0 0px 20px 0"
                  textAlign="center"
                >
                  Detail Pesanan
                </NormalText>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 0px 0"
                  >
                    No.Order
                  </NormalText>
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 0px 0"
                  >
                    {dataDetail?.order_number}
                  </NormalText>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 0px 0"
                  >
                    No.Resi
                  </NormalText>
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 0px 0"
                  >
                    {noResi}
                  </NormalText>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 10px 0"
                  >
                    Jenis Order
                  </NormalText>
                  <NormalText
                    fontSize={"16px"}
                    fontFamily="Open Sans"
                    fontWeight="bold"
                    margin="0 0px 10px 0"
                  >
                    {dataDetail?.order_type}
                  </NormalText>
                </Box>
                <hr />
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "2px",
                        margin: "0 10px 0 0",
                        border: "1px solid #BABABA",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NorthIcon
                        sx={{
                          color: `${AppSetting?.data?.data?.primary_color}`,
                          fontSize: "10px",
                        }}
                      />
                    </Box>
                    <Box>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1"
                      >
                        Pengirim
                      </NormalText>
                      <NormalText
                        fontSize={"18px"}
                        fontFamily="Open Sans"
                        fontWeight="bold"
                        lineHeight="1.5"
                      >
                        {senderData?.name}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {senderData?.phone_number}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {`${senderData?.address?.address}, ${senderData?.address?.suburb_name}, ${senderData?.address?.area_name}`}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {`${senderData?.address?.city_name}, ${senderData?.address?.postcode} - ${senderData?.address?.country_name}`}
                      </NormalText>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      margin: "50px 0 0 0",
                    }}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "2px",
                        margin: "0 10px 0 0",
                        border: `1px solid ${AppSetting?.data?.data?.primary_color}`,
                        background: `${AppSetting?.data?.data?.primary_color}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SouthIcon
                        sx={{
                          color: `#FFFFFF`,
                          fontSize: "10px",
                        }}
                      />
                    </Box>
                    <Box>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1"
                      >
                        Penerima
                      </NormalText>
                      <NormalText
                        fontSize={"18px"}
                        fontFamily="Open Sans"
                        fontWeight="bold"
                        lineHeight="1.5"
                      >
                        {recieverData?.name}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {recieverData?.phone_number}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {`${recieverData?.address?.address}, ${recieverData?.address?.suburb_name}, ${recieverData?.address?.area_name}`}
                      </NormalText>
                      <NormalText
                        fontSize={"14px"}
                        fontFamily="Open Sans"
                        fontWeight="regular"
                        lineHeight="1.2"
                      >
                        {`${recieverData?.address?.city_name}, ${recieverData?.address?.postcode} - ${recieverData?.address?.country_name}`}
                      </NormalText>
                    </Box>
                  </Box>
                </Box>
                <hr />
                <Box sx={{ margin: "1rem 0 0 0" }}>
                  <NormalText
                    fontSize={"18px"}
                    fontFamily="Open Sans"
                    fontWeight="700"
                    lineHeight="1.2"
                    textAlign="center"
                    margin="0 0 1rem 0"
                  >
                    Status
                  </NormalText>
                  {shipmentTrackingData && shipmentTrackingData.length > 0 ? (
                    <>
                      {console.log(shipmentTrackingData)}
                      {shipmentTrackingData
                        .reverse()
                        .map((item: any, index: any) => (
                          <Box
                            key={`status-${index}`}
                            sx={{ display: "flex", alignItems: "flex-start" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <CheckCircleIcon
                                sx={{
                                  color: index === 0 ? "#2ecc71" : "#ededed",
                                }}
                              />
                              <Box
                                sx={{
                                  border: `2px dashed ${
                                    index === 0 ? "#2ecc71" : "#ededed"
                                  }`,
                                  height: "50px",
                                }}
                              ></Box>
                            </Box>
                            <Box className="ms-3">
                              <NormalText
                                fontSize={"12px"}
                                fontFamily="Open Sans"
                                fontWeight="400"
                                lineHeight="1.2"
                              >
                                {moment(item?.created_date).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </NormalText>
                              <NormalText
                                fontSize={"16px"}
                                fontFamily="Open Sans"
                                fontWeight="600"
                                lineHeight="1.2"
                              >
                                {item?.shipper_status?.name}
                              </NormalText>
                              <NormalText
                                fontSize={"12px"}
                                fontFamily="Open Sans"
                                fontWeight="400"
                                lineHeight="1.2"
                              >
                                {item?.shipper_status?.description}
                              </NormalText>
                            </Box>
                          </Box>
                        ))}
                    </>
                  ) : null}
                </Box>
                <hr />
              </Box>
            ) : (
              <NormalText
                fontSize={"16px"}
                fontFamily="Open Sans"
                fontWeight="bold"
                margin="0 0px 30px 0"
              >
                {meesages ? meesages : "Orderan anda sedang dimasak"}
              </NormalText>
            )}
            {dataDetail && dataDetail.order_type === "DELIVERY" ? null : (
              <NormalText
                fontSize={"12px"}
                fontFamily="Open Sans"
                color="#7E7E7E"
                margin="0 0px 40px 0"
              >
                {meesages
                  ? generateDescription()
                  : "Silahkan tunggu sampai pesanan anda siap untuk diambil"}
              </NormalText>
            )}
          </Box>

          {dataDetail && dataDetail.order_status === "CLOSED" ? (
            <NormalText
              fontSize={"14px"}
              fontFamily="Open Sans"
              fontWeight="bold"
              padding={"0px 20px 0px 20px"}
              margin={"0px 0px 40px 0px"}
              textAlign="center"
            >
              Terima Kasih
            </NormalText>
          ) : null}

          {dataDetail && dataDetail.order_type !== "DELIVERY" ? (
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              paddingX={2.5}
              marginBottom={4}
            >
              <NormalText fontSize="0.9rem" fontWeight="800" textAlign="center">
                Alamat Toko:
              </NormalText>
              <NormalText
                fontSize="0.85rem"
                fontWeight="600"
                textAlign="center"
                lineHeight="1.5"
              >
                {dataDetail?.store_address}
              </NormalText>

              <NormalText
                margin="10px 0 0 0"
                fontSize="0.9rem"
                fontWeight="800"
                textAlign="center"
              >
                No Telp Toko:
              </NormalText>

              <NormalText fontSize="0.85rem" fontWeight="600">
                {dataDetail?.store_phone}
              </NormalText>
            </Stack>
          ) : null}

          <Box style={{ backgroundColor: "white", width: "100%" }}>
            <NormalText
              fontSize={"14px"}
              fontFamily="Open Sans"
              fontWeight="bold"
              padding={"10px 20px 10px 20px"}
            >
              Order Detail
            </NormalText>
            {dataDetail && dataDetail.brand && dataDetail.brand.length !== 0
              ? dataDetail.brand.map((dataBrand, i) => {
                  return (
                    <div key={i}>
                      <CategoryLabel
                        style={{
                          backgroundColor: dataApp?.primary_color ?? "#F2F2F2",
                        }}
                      >
                        <NormalText
                          fontSize={"12px"}
                          color={dataApp?.secondary_color ?? "#000"}
                          fontFamily="D-DIN Exp"
                          fontWeight="bold"
                          margin="0 0 0 24px"
                        >
                          {dataBrand.brand_name ?? ""}
                        </NormalText>
                      </CategoryLabel>
                      {dataBrand.order_detail &&
                      dataBrand.order_detail.length !== 0
                        ? dataBrand.order_detail.map((x, i) => {
                            return (
                              <ProductOrder
                                key={i + "-" + x.product_name ?? ""}
                                product_name={x.product_name}
                                product_price={
                                  x.total ? formatCurrency(Number(x.total)) : ""
                                }
                                qty={x.qty ?? 0}
                                customizable={
                                  x.order_detail_add_on &&
                                  x.order_detail_add_on.length !== 0
                                    ? x.order_detail_add_on
                                    : []
                                }
                              />
                            );
                          })
                        : null}
                    </div>
                  );
                })
              : null}
            <CategoryLabel
              style={{
                backgroundColor: dataApp?.primary_color ?? "#F2F2F2",
              }}
            >
              <NormalText
                fontSize={"12px"}
                color={dataApp?.secondary_color ?? "#000"}
                fontFamily="D-DIN Exp"
                fontWeight="bold"
                margin="0 0 0 24px"
              >
                Payment Detail
              </NormalText>
            </CategoryLabel>
            <Grid container sx={{ padding: "10px 17px" }} direction="row">
              <Grid item xs={9}>
                <NormalText fontSize="12px" fontFamily="Open Sans">
                  Order Fee
                </NormalText>
              </Grid>
              <Grid item xs={3}>
                <NormalText
                  fontSize="12px"
                  fontFamily="Open Sans"
                  textAlign="end"
                >
                  {dataDetail && dataDetail.payment_fee_customer
                    ? formatCurrency(Number(dataDetail.payment_fee_customer))
                    : ""}
                </NormalText>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "10px 17px" }}>
              <Grid item xs={9}>
                <NormalText fontSize="12px" fontFamily="Open Sans">
                  Tax & Services
                </NormalText>
              </Grid>
              <Grid item xs={3}>
                <NormalText
                  fontSize="12px"
                  fontFamily="Open Sans"
                  textAlign="end"
                >
                  {dataDetail && dataDetail.tax && dataDetail.service_fee
                    ? formatCurrency(
                        Number(
                          Number(dataDetail.tax) +
                            Number(dataDetail.service_fee)
                        )
                      )
                    : ""}
                </NormalText>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "10px 17px" }}>
              <Grid item xs={9}>
                <NormalText fontSize="12px" fontFamily="Open Sans">
                  Total
                </NormalText>
              </Grid>
              <Grid item xs={3}>
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  fontFamily="Open Sans"
                  textAlign="end"
                >
                  {dataDetail && dataDetail.sub_total
                    ? formatCurrency(Number(dataDetail.sub_total))
                    : ""}
                </NormalText>
              </Grid>
            </Grid>
            <SpacerLine />
            <Grid
              container
              sx={{
                padding: "10px 17px",
              }}
            >
              <Grid item xs={9}>
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  fontFamily="Open Sans"
                >
                  Total Payment
                </NormalText>
              </Grid>
              <Grid item xs={3}>
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  fontFamily="Open Sans"
                  textAlign="end"
                >
                  {dataDetail && dataDetail.total
                    ? formatCurrency(Number(dataDetail.total))
                    : ""}
                </NormalText>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
    </MainLayout>
  );
};

const CategoryLabel = styled.div`
  width: 100%;
  height: 35px;
  align-items: center;
  display: flex;
`;

export default ProductDetail;
