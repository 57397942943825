import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoyaltyPointState, HistoryLoyaltyState } from './types';
import axios from 'axios';

interface getApp {
  storeCode: any;
  phoneNumber?: string | "";
}

export const getLoyaltyPoint = createAsyncThunk(
  'loyaltypoint/list',
  async ({ storeCode, phoneNumber }: getApp) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/member/loyalty/${storeCode}/get/data?phonenumber=${phoneNumber}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as LoyaltyPointState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as LoyaltyPointState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as LoyaltyPointState;
    }
  },
);


interface IGetHistoryParam {
  storeCode: string;
  loyaltyType?: 'pendapatan' | 'pengeluaran';
  type?: 'email' | 'phone';
  email?: string;
  phone?: string;
}

export const getHistoryLoyalty = createAsyncThunk(
  'loyaltypoint/history',
  async ({ storeCode, type = 'email', email, phone, loyaltyType }: IGetHistoryParam) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      let url = '';

      if (type === 'email') {
        url = `member/loyalty/${storeCode}/history?email=${email}&type=${loyaltyType}`
      } else {
        url = `member/loyalty/${storeCode}/history?phonenumber=${phone}&type=${loyaltyType}`
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${url}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as HistoryLoyaltyState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as HistoryLoyaltyState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as HistoryLoyaltyState;
    }
  },
);