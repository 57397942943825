import { Box, CircularProgress } from '@mui/material'

const OrderDetailLoading = () => {
    return (
        <Box display={'flex'} width={'100%'} height={'100vh'} sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <div>
                <CircularProgress />
            </div>
        </Box>
    )
}

export default OrderDetailLoading