import { Box, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import CallIcon from "@mui/icons-material/Call";

interface AddressStore {
  address: string;
  phone_number: any;
  primary_color?: any;
}

const AddressStoreCheckout = ({
  address,
  phone_number,
  primary_color,
}: AddressStore) => {
  return (
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Box width={"75%"} sx={{ wordBreak: "break-word" }}>
        <NormalText
          fontSize="0.95rem"
          fontFamily="D-DIN"
          fontWeight="800"
          color="#000000"
        >
          Detail Toko
        </NormalText>
        <NormalText
          fontFamily="D-DIN"
          fontSize="0.9rem"
          fontWeight="400"
          lineHeight="1.2"
          color="#000000"
        >
          {address ? address : "-"}
        </NormalText>
      </Box>
      <Box sx={{ height: "3vh", width: "0.1vw", background: "#cccccc" }}></Box>
      <a href={`tel:${phone_number}`}>
        <CallIcon sx={{ color: `${primary_color}` }} />
      </a>
    </Stack>
  );
};

export default AddressStoreCheckout;
