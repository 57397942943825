import {
  Stack,
  SwipeableDrawer,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

//component
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DeliveryTypesItem from "components/Item/delivery.types.item";
import "./drawer.css";

const deliveryTimeOptions = [
  { name: "Express", type: "express" },
  { name: "Instant", type: "instant" },
  { name: "Reguler", type: "regular" },
  { name: "Same Day", type: "same-day" },
  { name: "Kargo", type: "trucking" },
];

const deliveryTimeOptionsFnB = [
  { name: "Express", type: "express" },
  { name: "Instant", type: "instant" },
  { name: "Same Day", type: "same-day" },
];

interface DeliveryTypesDrawerProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
  color?: string;
  merchantType: string;
  deliveryType: string;
  handleDeliveryTime: any;
  handleDeliveryCourier: any;
}

const DeliveryTypesDrawer = ({
  open,
  onClose,
  onOpen,
  color,
  merchantType,
  deliveryType,
  handleDeliveryTime,
  handleDeliveryCourier,
}: DeliveryTypesDrawerProps) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose();
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #cccccc", padding: "0.9rem 1rem" }}
        >
          <Typography
            sx={{
              color: "#2d3436",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "800",
              fontSize: "0.9rem",
            }}
          >
            Pilih Pengiriman
          </Typography>

          <IconButton
            sx={{
              color: "#000000",
              height: "25px",
              width: "25px",
            }}
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Stack>
        <Box height={"auto"} width={"100%"} sx={{ overflow: "auto" }}>
          {merchantType === "FOOD"
            ? deliveryTimeOptionsFnB.map((item, index) => {
                return (
                  <DeliveryTypesItem
                    key={"deliveryType-" + index}
                    type={item.type}
                    name={item.name}
                    background={color ?? "#b92362"}
                    selected={deliveryType === item.type}
                    onChange={() => {
                      handleDeliveryTime(item.type);
                      handleDeliveryCourier({
                        id: 0,
                        courier_name: "",
                        courier_name_rate: "",
                        base_price: 0,
                        insurance_applied: false,
                        insurance_fee: 0,
                        max_day: 0,
                        min_day: 0,
                        must_use_insurance: false,
                        total_price: 0,
                      });
                      onClose();
                    }}
                  />
                );
              })
            : deliveryTimeOptions.map((item, index) => {
                return (
                  <DeliveryTypesItem
                    key={"deliveryType-" + index}
                    type={item.type}
                    name={item.name}
                    background={color ?? "#b92362"}
                    selected={deliveryType === item.type}
                    onChange={() => {
                      handleDeliveryTime(item.type);
                      handleDeliveryCourier({
                        id: 0,
                        courier_name: "",
                        courier_name_rate: "",
                        base_price: 0,
                        insurance_applied: false,
                        insurance_fee: 0,
                        max_day: 0,
                        min_day: 0,
                        must_use_insurance: false,
                        total_price: 0,
                      });
                      onClose();
                    }}
                  />
                );
              })}
          {/* {deliveryTimeOptions.map((item, index) => {
            return (
              <DeliveryTypesItem
                key={"deliveryType-" + index}
                type={item.type}
                name={item.name}
                background={color ?? "#b92362"}
                selected={deliveryType === item.type}
                onChange={() => {
                  handleDeliveryTime(item.type);
                  handleDeliveryCourier({
                    id: 0,
                    courier_name: "",
                    courier_name_rate: "",
                    base_price: 0,
                    insurance_applied: false,
                    insurance_fee: 0,
                    max_day: 0,
                    min_day: 0,
                    must_use_insurance: false,
                    total_price: 0,
                  });
                  onClose();
                }}
              />
            );
          })} */}
        </Box>
      </Stack>
    </SwipeableDrawer>
  );
};

export default DeliveryTypesDrawer;
