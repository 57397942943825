// import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { persistCombineReducers } from "redux-persist";
import { RegisterUserReducer } from "../userRegister";
import { RegisterAddressReducer } from "redux/registerAddress";
import { StoreInfoReducer } from "../storeInfo";
import { InvoiceReducer } from "redux/checkout/invoice.reducers";
import { AppSettingReducer } from "../appsetting";
import { ProductReducer } from "../product";
import StoreState from "../state";
import { CartReducer } from "../cart/cart.reducer";
//order
import { OrderReducer } from "redux/order";
import { PickupPointReducer } from "redux/pickuppoint";
import { CheckCustomerReducer } from "redux/checkCustomer";

//loyalty point
import { LoyaltyPointReducer } from "redux/loyaltyPoint";
//queue
import { QueueReducer } from "redux/queue";

import { ProfileReducer } from "redux/profile";
import { GoogleSignInReducer } from "redux/googleSignIn";
import { QrGroupInfoReducer } from "redux/qrGroup";

const AppReducer = persistCombineReducers<StoreState>(
  {
    keyPrefix: "@",
    key: "sandboxcustomer",
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
    // transforms: process.env.NODE_ENV === 'production' ? [
    //     encryptTransform({
    //         secretKey: process.env.REACT_APP_REDUX_SECRET_KEY ?? 'sandbox-secret--key',
    //         onError: err => {
    //             if (process.env.NODE_ENV === 'development') console.error(err)
    //         }
    //     })
    // ] : undefined,
  },
  {
    RegisterUser: RegisterUserReducer,
    RegisterAddress: RegisterAddressReducer,
    StoreInfo: StoreInfoReducer,
    AppSetting: AppSettingReducer,
    Product: ProductReducer,
    Cart: CartReducer,
    Invoice: InvoiceReducer,
    Order: OrderReducer,
    PickupPoint: PickupPointReducer,
    CheckCustomer: CheckCustomerReducer,
    //loyalty point
    LoyaltyPoint: LoyaltyPointReducer,
    Queue: QueueReducer,
    Profile: ProfileReducer,
    GoogleSignIn: GoogleSignInReducer,
    QrGroupInfo: QrGroupInfoReducer,
  }
);

export default AppReducer;
