import styled from "styled-components";

interface StandardButtonProps {
  background?: string;
}

export const StandardButton = styled.button<StandardButtonProps>`
  background: ${(props) => props.background ?? "rgba(185, 35, 98, 1)"};
  border: none;
  width: 100%;
  color: white;
  height: 50px;

  :focus,
  :hover {
    opacity: 0.8;
  }
`;
