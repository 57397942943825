import { useEffect, useState } from "react";
import MainLayout from "components/Layouts/MainLayout";
//import Footer from "components/Layouts/Footer";

import NewSandboxLogo from "assets/logo/Logo-Pagii-Sandbox.svg";

import "./styles.css";
import { Box, Stack } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { NormalText } from "components/styled/text.styled";
import { getAppSetting, AppSettingData } from "redux/appsetting";
import useRedux from "redux/useRedux";
import { getPickupPoint } from "redux/pickuppoint";
import { listAllTable } from "redux/table";
import { StandardButton } from "components/Button/standard.button";
import HeaderPickServicesPage from "components/Header/HeaderPickServicesPage";
import ServiceList from "components/List/ServiceList";

const HomePage = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const { order_number } = useParams();
  const [data, setData] = useState<AppSettingData>();
  const [customerCanPickup, setCustomerCanPickup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerCanPickTable, setCustomerCanPickTable] = useState(false);
  const {
    thunkDispatch,
    storeState: {
      StoreInfo,
      AppSetting: {
        data: {
          data: { primary_color },
        },
      },
    },
  } = useRedux();

  useEffect(() => {
    console.log("GETT APP SETTING 0");
    console.log("STORE INFO", StoreInfo);
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ) {
      console.log("GETT APP SETTING 1");
      thunkDispatch(
        getAppSetting({
          merchant_id: StoreInfo.data.data.merchant_id,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setData(res.data.data);
          }
        });
    }
  }, [StoreInfo, thunkDispatch]);

  useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.id
    ) {
      fetchPickup();
      fetchTables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StoreInfo, thunkDispatch]);

  const fetchPickup = () => {
    let data = {
      store_id: StoreInfo?.data?.data?.id ?? "",
    };
    thunkDispatch(getPickupPoint(data))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.pickUpList &&
            res.data.data.pickUpList.length > 0
          ) {
            console.log("PIKCUP YES");
            setCustomerCanPickup(true);
          } else {
            console.log("PIKCUP NO");
            setCustomerCanPickup(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        console.log("PIKCUP NO");
        setCustomerCanPickup(false);
      });
  };

  const fetchTables = () => {
    if (
      StoreInfo &&
      StoreInfo.status === "success" &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data
    ) {
      thunkDispatch(listAllTable(StoreInfo.data.data.id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (res.data.data && res.data.data.length > 0) {
              console.log("TABLE YES");
              setCustomerCanPickTable(true);
            } else {
              console.log("TABLE NO");
              setCustomerCanPickTable(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("TABLE NO");
          setCustomerCanPickTable(false);
        });
    }
  };

  const handleMoveToHome = () => {
    navigate("/home");
  };

  useEffect(() => {
    setTimeout(() => {
      if (order_number) {
        navigate(`/order-detail/${order_number}`, {
          state: { prev_url: `${location.pathname}` },
        });
      }
    }, 2000);
  }, [navigate, order_number, location.pathname]);

  return (
    <MainLayout
      background={data?.secondary_color ?? "#FFF4F8"}
      favIcon={data?.favicon ?? ""}
      title={data?.merchant_name ?? ""}
    >
      <Stack height={"auto"} direction={"column"} justifyContent={"flex-start"}>
        <div className="pt-4">
          <HeaderPickServicesPage
            app_logo={data?.app_logo ? data.app_logo : NewSandboxLogo}
            store_logo={StoreInfo?.data?.data?.store_logo}
            access_type={
              StoreInfo?.data?.data?.store_code ? "store" : "merchant"
            }
          />
          <NormalText
            fontSize="14px"
            fontWeight="bold"
            letterSpacing="3px"
            lineHeight="1.5"
            margin="30px 0 0 0"
            color={"#000000"}
            className={"intro-text-1"}
            textAlign="center"
            fontFamily="D-DIN Exp"
          >
            SELAMAT DATANG DI
          </NormalText>
          <NormalText
            fontSize="14px"
            fontWeight="bold"
            letterSpacing="3px"
            lineHeight="1.5"
            margin="0 0 30px 0"
            style={{ textTransform: "uppercase" }}
            color={"#000000"}
            className={"intro-text-2"}
            textAlign="center"
            fontFamily="D-DIN Exp"
          >
            {data?.merchant_name ?? ""}
          </NormalText>
          <ServiceList
            merchant_type={StoreInfo?.data?.data?.merchant_type}
            pickUp={customerCanPickup}
            goToHome={handleMoveToHome}
          />
          <Box sx={{ width: "100%", padding: "0 0.5rem", marginTop: "30px" }}>
            <StandardButton
              background={primary_color ?? "#B92362"}
              style={{
                borderRadius: "5px",
                fontSize: "16px",
                fontFamily: "D-DIN Exp, sans-serif",
                fontWeight: "600",
                boxShadow: "0px 8px 10px 0px #0000001f",
              }}
              onClick={() => {
                setTimeout(() => {
                  navigate("/order-detail-status");
                }, 500);
              }}
            >
              Cek Pesanan Saya
            </StandardButton>
          </Box>
        </div>

        <footer>
          <div className="text-center mt-4 pb-3">
            <NormalText
              fontSize="10px"
              fontWeight="bold"
              letterSpacing="1px"
              lineHeight="1.5"
              margin="30px 0 10px 0"
              color={"#000000"}
              className={"intro-text-2"}
              textAlign="center"
              fontFamily="D-DIN Exp"
            >
              Powered by
            </NormalText>
            <img
              src={NewSandboxLogo}
              alt="powerdBy"
              className="powered-image"
              height={"30px"}
            />
          </div>
        </footer>
      </Stack>
    </MainLayout>
  );
};

export default HomePage;
