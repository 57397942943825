import {
  Stack,
  SwipeableDrawer,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

//component
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CourierItem from "components/Item/courier.item";
import { NormalText } from "components/styled/text.styled";
import "./drawer.css";

import { PricingsProps, CourierProps } from "redux/delivery";

interface CourierDrawerProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
  color?: string;
  courier: CourierProps | null;
  handleDeliveryCourier: any;
  couriers: PricingsProps[];
}

const CourierDrawer = ({
  open,
  onClose,
  onOpen,
  color,
  courier,
  handleDeliveryCourier,
  couriers,
}: CourierDrawerProps) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={true}
      open={open}
      onClose={() => {
        onClose();
      }}
      onOpen={() => onOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          overflow: "auto",
        },
      }}
    >
      <Stack
        direction={"column"}
        width="100%"
        justifyContent={"center"}
        paddingBottom={2}
        alignItems={"flex-start"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #cccccc", padding: "0.9rem 1rem" }}
        >
          <Typography
            sx={{
              color: "#2d3436",
              fontFamily: "D-DIN Exp, Open Sans, sans-serif",
              fontWeight: "800",
              fontSize: "0.9rem",
            }}
          >
            Pilih Kurir
          </Typography>

          <IconButton
            sx={{
              color: "#000000",
              height: "25px",
              width: "25px",
            }}
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Stack>
        {couriers && couriers.length > 0 ? (
          <Box
            className={"courier-drawer"}
            maxHeight={"40vh"}
            width={"100%"}
            sx={{ overflow: "auto" }}
          >
            {couriers.map((item, index) => {
              return (
                <CourierItem
                  key={"select time - " + index}
                  data={item}
                  courier={courier}
                  onChange={() => {
                    handleDeliveryCourier({
                      id: item.rate.id,
                      courier_name: item.logistic.name,
                      courier_name_rate: item.rate.name,
                      base_price: item.base_price,
                      insurance_applied: item.insurance_applied,
                      insurance_fee: item.insurance_fee,
                      max_day: item.max_day,
                      min_day: item.min_day,
                      must_use_insurance: item.must_use_insurance,
                      total_price: item.final_price,
                    });
                    onClose();
                  }}
                  background={color}
                  selected={courier?.id === item.rate.id}
                />
              );
            })}
          </Box>
        ) : (
          <Box padding={"10px 20px 10px 15px"} width={"100%"}>
            <NormalText
              fontFamily="D-DIN"
              fontSize={"14px"}
              textAlign={"left"}
              fontWeight={"bold"}
            >
              Pilihan kurir tidak tersedia
            </NormalText>
          </Box>
        )}
      </Stack>
    </SwipeableDrawer>
  );
};

export default CourierDrawer;
